import React from 'react'
import {Flex, Box, Heading, Text} from '@chakra-ui/react'
import {ChevronLeftIcon} from '@chakra-ui/icons'
import deepEqual from 'deep-equal'

import {ActionButton} from '../ActionButton/ActionButton'
import {useStatusToast} from '../../app/ToastManager'
import {
  OptionSelector,
  OptionList,
  BatchSelector,
} from '../OptionSelector/OptionSelector'
import {CamperSegment} from '../../types'
import {useSegments} from './contextManagers/SegmentManager'
import {useComposeMicropostActions} from './contextManagers/MicropostManager'
import {isLastElement} from '../../utils'
import {useModalContext} from './subcomps/Modal'
import {
  useBunks,
  useBunkPlans,
  useDivisions,
  useGenders,
  useSessions,
} from '../../queries'
import {useTerm} from '../../hooks/useTerm'
import {useRequiredAuth} from '../../stores/AuthStore'

type Option = {
  ID: string
  label: string
}

const Header: React.FC<React.PropsWithChildren> = () => {
  const {dispatch} = useSegments()
  const {setCurrentStep} = useModalContext()

  return (
    <Flex>
      <ChevronLeftIcon
        aria-label='back'
        boxSize='30px'
        onClick={(): void => {
          dispatch({type: 'RESET'})

          setCurrentStep('compose')
        }}
      />
      <Box flex={1} textAlign='center'>
        Audience Segments
      </Box>
    </Flex>
  )
}

const Body: React.FC<React.PropsWithChildren> = () => {
  const {segments, dispatch, mode} = useSegments()
  const {client} = useRequiredAuth()

  const bunkTerm = useTerm('bunk').titleCase().value()

  const genderQuery = useGenders()
  const divisionsQuery = useDivisions()
  const sessionsQuery = useSessions({season: client.currentSeasonID}) // TODO: Currently microposts don't have season data. We need to add that at some point because a micropost created 2 seasons ago might have different session values than what get fetched in current season.
  const bunksQuery = useBunks({season: client.currentSeasonID})
  const bunkPlansQuery = useBunkPlans({season: client.currentSeasonID})

  const [isDirty, setIsDirty] = React.useState({
    gender: false,
    divisions: false,
    sessions: false,
    bunks: false,
    bunkPlans: false,
  })

  const isEditing = mode === 'edit'

  const options = React.useMemo(() => {
    const convertToLookup = (data?: {
      pages: Array<{results: Array<{ID: string; name: string}>}>
    }): Option[] => {
      if (!data) return []

      return data.pages.flatMap((p) =>
        p.results.map((item) => ({
          ID: item.ID,
          label: item.name,
        })),
      )
    }

    return {
      gender: genderQuery.data.map(({ID, name}) => ({ID, label: name})),
      divisions: convertToLookup(divisionsQuery.data),
      sessions: convertToLookup(sessionsQuery.data),
      bunks: convertToLookup(bunksQuery.data),
      bunkPlans: convertToLookup(bunkPlansQuery.data),
    }
  }, [
    divisionsQuery.data,
    sessionsQuery.data,
    genderQuery.data,
    bunksQuery.data,
    bunkPlansQuery.data,
  ])

  const selectedIDs = React.useMemo(
    () => ({
      gender:
        isDirty.gender || segments.genderIDs.length
          ? segments.genderIDs
          : options.gender.map((opt) => opt.ID),
      divisions:
        isDirty.divisions || segments.divisionIDs.length
          ? segments.divisionIDs
          : options.divisions.map((opt) => opt.ID),
      sessions:
        isDirty.sessions || segments.sessionIDs.length
          ? segments.sessionIDs
          : options.sessions.map((opt) => opt.ID),
      bunkIDs:
        isDirty.bunks || segments.bunkIDs.length
          ? segments.bunkIDs
          : options.bunks.map((opt) => opt.ID),
      bunkPlanIDs:
        isDirty.bunkPlans || segments.bunkPlanIDs.length
          ? segments.bunkPlanIDs
          : options.bunkPlans.map((opt) => opt.ID),
    }),
    [segments, isDirty, options],
  )

  const handleOnChange = (
    key: string,
    filter: keyof CamperSegment,
    nextIDs: string[],
  ): void => {
    // If user has selected all options either individually or via 'select all', then don't track for segment
    if (nextIDs.length === options[key].length) {
      setIsDirty((prev) => ({...prev, [key]: false}))
      dispatch({
        type: 'UPDATE_SEGMENT_FILTER',
        payload: {
          filter,
          values: [],
        },
      })
    } else {
      setIsDirty((prev) => ({...prev, [key]: true}))

      dispatch({
        type: 'UPDATE_SEGMENT_FILTER',
        payload: {filter, values: nextIDs},
      })
    }
  }

  const segmentSelectors = [
    {
      name: 'Camper Gender',
      description: 'You must select at least one.',
      options: options.gender,
      selectedIDs: selectedIDs.gender,
      onChange: (nextIDs: string[]): void => {
        handleOnChange('gender', 'genderIDs', nextIDs)
      },
      hideBatchSelectors: true,
    },
    {
      name: 'Camper Division',
      description: 'You must select at least one.',
      options: options.divisions,
      selectedIDs: selectedIDs.divisions,
      onChange: (nextIDs: string[]): void => {
        handleOnChange('divisions', 'divisionIDs', nextIDs)
      },
      hideBatchSelectors: isEditing,
      isLoading: divisionsQuery.isLoading,
    },
    {
      name: 'Camper Session',
      description: 'You must select at least one.',
      options: options.sessions,
      selectedIDs: selectedIDs.sessions,
      onChange: (nextIDs: string[]): void => {
        handleOnChange('sessions', 'sessionIDs', nextIDs)
      },
      hideBatchSelectors: isEditing,
      isLoading: sessionsQuery.isLoading,
    },
    {
      name: `Camper ${bunkTerm} Plan`,
      description: 'You must select at least one.',
      options: options.bunkPlans,
      selectedIDs: selectedIDs.bunkPlanIDs,
      onChange: (nextIDs: string[]): void => {
        handleOnChange('bunkPlans', 'bunkPlanIDs', nextIDs)
      },
      hideBatchSelectors: isEditing,
      isLoading: bunkPlansQuery.isLoading,
    },
    {
      name: `Camper ${bunkTerm}`,
      description: 'You must select at least one.',
      options: options.bunks,
      selectedIDs: selectedIDs.bunkIDs,
      onChange: (nextIDs: string[]): void => {
        handleOnChange('bunks', 'bunkIDs', nextIDs)
      },
      hideBatchSelectors: isEditing,
      isLoading: bunksQuery.isLoading,
    },
  ]

  return (
    <>
      {/* For the bunk/bunk plan work within the compose micropost modal, the bunk/bunk plan sections should be hidden if no lookups for those
       categories are found. */}
      {segmentSelectors
        .filter(
          (option) =>
            !(
              option.options.length === 0 &&
              option.name.startsWith(`Camper ${bunkTerm}`)
            ),
        )
        .map((selector, index) => (
          <OptionSelector
            key={selector.name}
            options={selector.options}
            selectedIDs={selector.selectedIDs}
            onChange={selector.onChange}>
            <Box px={10} pt={6} data-testid='segment-filter-options'>
              <Box
                borderBottom={
                  isLastElement(segmentSelectors, index)
                    ? 'unset'
                    : '1px solid #CED2D5'
                }
                pb={6}>
                <Flex justifyContent='space-between' mb={4}>
                  <Flex direction='column'>
                    <Heading size='md'>{selector.name}</Heading>
                    <Text fontSize='sm' color='gray.500'>
                      {selector.description}
                    </Text>
                  </Flex>

                  {selector.hideBatchSelectors || <BatchSelector />}
                </Flex>

                <Flex wrap='wrap'>
                  <OptionList
                    colorScheme='secondary'
                    flex={['1 1 100%', '1 1 50%']}
                    isDisabled={isEditing}
                    isLoading={selector.isLoading}
                  />
                </Flex>
              </Box>
            </Box>
          </OptionSelector>
        ))}
    </>
  )
}

const Footer: React.FC<React.PropsWithChildren> = () => {
  const {
    dispatch,
    createSegment,
    deleteSegment,
    getSegmentCurrentSnapShot,
    segments,
    storedSegments,
    status,
    mode,
  } = useSegments()
  const {setValue} = useComposeMicropostActions()
  const {toast} = useStatusToast()
  const {setCurrentStep} = useModalContext()

  const handleOnApply = async (): Promise<void> => {
    const response = await createSegment()

    if (response.status !== 'success' || !response.data) {
      toast.error('Unable to save segments')
      return
    }

    if (response.data.segmentID) {
      const snapshot = await getSegmentCurrentSnapShot(response.data.segmentID)

      if (snapshot.data?.personIDs.length === 0) {
        toast.error(
          'No recipients for the custom audience. Select a different custom audience.',
        )

        await deleteSegment(response.data.segmentID)

        dispatch({
          type: 'CREATE_FAILURE',
        })

        return
      }
    }

    await deleteSegment()

    dispatch({
      type: 'CREATE_SUCCESS',
      payload: response.data,
    })

    setValue('segmentID', response.data.segmentID) // Add to compose micropost
    setCurrentStep('compose')
  }

  const isDisabled = mode === 'edit' || deepEqual(segments, storedSegments)

  return (
    <Flex
      direction={['column', 'row']}
      flex='1 1 auto'
      justifyContent='flex-end'>
      <ActionButton
        mr={3}
        onClick={handleOnApply}
        isDisabled={isDisabled}
        isLoading={status === 'pending'}
        loadingText='Applying'>
        Apply
      </ActionButton>
    </Flex>
  )
}

export const SegmentStepUI = {
  Header,
  Body,
  Footer,
}
