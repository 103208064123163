import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Flex, Divider} from '@chakra-ui/react'

import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {
  AlbumDetails,
  AlbumViewPageHeader,
  EditAlbumModal,
  PhotoErrorsModal,
  PhotoGallery,
} from './subcomps'
import {AlbumViewManager} from './AlbumViewManager'
import {AlbumManager} from './AlbumManager'
import {AlbumPhotosManager} from './AlbumPhotosManager'

export const AlbumView: React.FC<React.PropsWithChildren> = () => {
  const {id: albumID} = useParams<{id: string}>()

  // Only scroll on mount, not all rerenders
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  if (!albumID) return null

  return (
    <AlbumViewManager>
      <AlbumManager ID={albumID}>
        <AlbumPhotosManager ID={albumID}>
          <PageTemplate
            header={<AlbumViewPageHeader />}
            width={['100%']}
            maxWidth={['1030px']}
            borderX={['0px']}>
            <AlbumDetails mx={4} fontSize='0.85em' mb={[0, 2]} />
            <Flex direction='column' margin={3} py={1}>
              <Divider mb='4' opacity={1} border-bottom='1px solid' />
              <PhotoGallery />
            </Flex>

            <EditAlbumModal />

            <PhotoErrorsModal />
          </PageTemplate>
        </AlbumPhotosManager>
      </AlbumManager>
    </AlbumViewManager>
  )
}
