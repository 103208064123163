import React from 'react'
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

import {APP_VERSION} from '../../constants'
import {SideMenuItem} from './SideMenuItem'
import copy from './copy.json'
import {SideMenuDivider} from './SideMenuDivider'
import {CustomIcon} from '../../components'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const SideMenuTemplate: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => (
  <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
    <DrawerOverlay />

    <DrawerContent bg='#293135' data-testid='side-menu'>
      <DrawerHeader>
        <Flex
          flexDirection='row'
          alignItems='center'
          flex='1'
          justifyContent='space-between'>
          <CustomIcon alignSelf='center' h={['70%']} w={['70%']} name='moon' />
          <DrawerCloseButton
            color='white'
            size='lg'
            position='static'
            id='side-menu-drawer-close'
          />
        </Flex>
      </DrawerHeader>

      <DrawerBody bg='#293135' p='0'>
        <Flex flexDirection='column' alignItems='flex-start' lineHeight='24px'>
          <SideMenuItem url='/home' icon='home'>
            {copy.navigationLinks.home}
          </SideMenuItem>
          <SideMenuItem
            feature='view-photo-uploader'
            url='/albums'
            icon='camera'>
            {copy.navigationLinks.albums}
          </SideMenuItem>
          <SideMenuItem
            feature='texting-audience'
            url='/audiences'
            icon='people'>
            {copy.navigationLinks.audiences}
          </SideMenuItem>
          <SideMenuItem
            url='/microposts'
            icon='calendar' // used to be 'calendar'
          >
            {copy.navigationLinks.microposts}
          </SideMenuItem>
          <SideMenuItem
            url='/healthcheck'
            feature='covid-health-check'
            icon='mask'>
            {copy.navigationLinks.healthCheck}
          </SideMenuItem>
          <SideMenuItem
            url='/registration'
            feature='admin-menu-registration'
            icon='register'>
            {copy.navigationLinks.registation}
          </SideMenuItem>
          <SideMenuDivider feature='texting-enabled'>Texting</SideMenuDivider>
          <SideMenuItem
            url='/integrations/eztexting'
            feature='texting-enabled'
            icon='texting'>
            {copy.navigationLinks.texting}
          </SideMenuItem>
          <SideMenuItem
            url='/formreminders'
            feature='forms-reminder'
            icon='reminder' // used to be 'reminder'
          >
            {copy.navigationLinks.formReminders}
          </SideMenuItem>
        </Flex>
      </DrawerBody>

      <DrawerFooter>
        <Box _hover={{color: '#CCC'}}>{APP_VERSION}</Box>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
)
