import React from 'react'
import {Flex, Box, Heading, Text} from '@chakra-ui/react'
import {ChevronLeftIcon} from '@chakra-ui/icons'

import {useStatusToast} from '../../app/ToastManager'
import {ActionButton} from '../ActionButton/ActionButton'
import {
  OptionSelector,
  OptionList,
  BatchSelector,
} from '../OptionSelector/OptionSelector'
import {Album, AlbumAudienceFilter} from '../../types'
import {useConstraints} from './contextManagers/ConstraintManager'
import {isLastElement} from '../../utils'
import {useModalContext} from './subcomps/ComposeAlbumModalContext'
import {useSessions, usePrograms} from '../../queries'
import {
  useComposeAlbumActions,
  useComposeAlbumState,
} from './contextManagers/ComposeAlbumManager'
import {useAlbumService} from '../../services/useAlbumService'
import copy from './copy.json'

type Option = {
  ID: string
  label: string
}

const Header: React.FC<React.PropsWithChildren> = () => {
  const {reset} = useConstraints()
  const {setCurrentStep} = useModalContext()

  return (
    <Flex flex={1}>
      <ChevronLeftIcon
        aria-label='back'
        data-testid='back-icon'
        boxSize='30px'
        onClick={(): void => {
          reset()
          setCurrentStep('compose')
        }}
      />
      <Box flex={1} textAlign='center'>
        Audience Segments
      </Box>
    </Flex>
  )
}

const Body: React.FC<React.PropsWithChildren> = () => {
  const {selected, dispatch} = useConstraints()
  const {season} = useComposeAlbumState()

  const sessionsQuery = useSessions({season})
  const programsQuery = usePrograms({season})

  const [isDirty, setIsDirty] = React.useState({
    sessions: false,
    programs: false,
  })

  const options = React.useMemo(() => {
    const convertToLookup = (data?: {
      pages: Array<{results: Array<{ID: string; name: string}>}>
    }): Option[] => {
      if (!data) return []

      return data.pages.flatMap((p) =>
        p.results.map((item) => ({
          ID: item.ID,
          label: item.name,
        })),
      )
    }

    return {
      sessions: convertToLookup(sessionsQuery.data),
      programs: convertToLookup(programsQuery.data),
    }
  }, [sessionsQuery.data, programsQuery.data])

  const selectedIDs = React.useMemo(
    () => ({
      sessions:
        isDirty.sessions || selected.sessionIDs.length
          ? selected.sessionIDs // Select options if already selected from album
          : options.sessions.map((opt) => opt.ID), // If no selection on album, then show all as selected
      programs:
        isDirty.programs || selected.programIDs.length
          ? selected.programIDs
          : options.programs.map((opt) => opt.ID),
    }),
    [selected, isDirty, options],
  )

  const handleOnChange = (
    key: string,
    filter: keyof AlbumAudienceFilter,
    nextIDs: string[],
  ): void => {
    // If user has selected all options either individually or via 'select all', then don't track for audience filter
    if (nextIDs.length === options[key].length) {
      setIsDirty((prev) => ({...prev, [key]: false}))
      dispatch({
        type: 'UPDATE_CONSTRAINT_FILTER',
        payload: {
          filter,
          values: [],
        },
      })
    } else {
      setIsDirty((prev) => ({...prev, [key]: true}))

      dispatch({
        type: 'UPDATE_CONSTRAINT_FILTER',
        payload: {filter, values: nextIDs},
      })
    }
  }

  const filterGroupsConfig = [
    {
      name: 'Camper Session',
      description: 'You must select at least one.',
      options: options.sessions,
      selectedIDs: selectedIDs.sessions,
      onChange: (nextIDs: string[]): void => {
        handleOnChange('sessions', 'sessionIDs', nextIDs)
      },
      hideBatchSelectors: false,
      isLoading: sessionsQuery.isLoading,
    },
    {
      name: 'Camper Program',
      description:
        options.programs.length > 0 ? 'You must select at least one.' : '',
      options: options.programs,
      selectedIDs: selectedIDs.programs,
      onChange: (nextIDs: string[]): void => {
        handleOnChange('programs', 'programIDs', nextIDs)
      },
      hideBatchSelectors: false,
      isLoading: programsQuery.isLoading,
    },
  ]

  return (
    <>
      {filterGroupsConfig.map((selector, index) => (
        <OptionSelector
          key={selector.name}
          options={selector.options}
          selectedIDs={selector.selectedIDs}
          onChange={selector.onChange}>
          <Box px={10} pt={6} data-testid='segment-filter-options'>
            <Box
              borderBottom={
                isLastElement(filterGroupsConfig, index)
                  ? 'unset'
                  : '1px solid #CED2D5'
              }
              pb={6}>
              <Flex justifyContent='space-between' mb={4}>
                <Flex direction='column'>
                  <Heading size='md'>{selector.name}</Heading>
                  <Text fontSize='sm' color='gray.500'>
                    {selector.description}
                  </Text>
                </Flex>

                {selector.hideBatchSelectors || <BatchSelector />}
              </Flex>

              <Flex wrap='wrap'>
                <OptionList
                  colorScheme='secondary'
                  flex={['1 1 100%', '1 1 50%']}
                  isLoading={selector.isLoading}
                />
              </Flex>
            </Box>
          </Box>
        </OptionSelector>
      ))}
    </>
  )
}

const Footer: React.FC<React.PropsWithChildren> = () => {
  const {setConstraints} = useComposeAlbumActions()
  const {selected, areConstraintsChanged} = useConstraints()
  const {getConstraintCount} = useAlbumService()
  const {season} = useComposeAlbumState()
  const {setCurrentStep} = useModalContext()
  const {toast} = useStatusToast()

  const handleOnApply = async (): Promise<void> => {
    const sessionConstraints: Album['constraints'] = selected.sessionIDs.map(
      (sID) => ({type: 'Session', constraintID: sID}),
    )
    const programConstraints: Album['constraints'] = selected.programIDs.map(
      (pID) => ({type: 'Program', constraintID: pID}),
    )

    setConstraints([...sessionConstraints, ...programConstraints]) // Add to album
    setCurrentStep('compose')

    if ([...sessionConstraints, ...programConstraints].length > 0) {
      const response = await getConstraintCount(season, [
        ...sessionConstraints,
        ...programConstraints,
      ])

      if (response.result === 'success') {
        if (response.count === 0) {
          toast.warn(copy.warning.zeroEnrollmentCount)
        }
      } else {
        toast.error(response.message)
      }
    }
  }

  return (
    <Flex
      direction={['column', 'row']}
      flex='1 1 auto'
      justifyContent='flex-end'>
      <ActionButton
        mr={3}
        onClick={handleOnApply}
        isDisabled={areConstraintsChanged}>
        Apply
      </ActionButton>
    </Flex>
  )
}

export const FilterAudienceStepUI = {
  Header,
  Body,
  Footer,
}
