import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {CurrentRequest, CurrentResponse} from './types'

export interface Current<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const current: Current<CurrentRequest, CurrentResponse> = (
  http,
  params,
) => {
  const {SeasonID, ClientID, ID} = params

  const path = '/v0/segments/{id}/current'.replace('{id}', ID.toString())

  const queryParams = []
  if (SeasonID !== undefined) {
    queryParams.push(`seasonid=${SeasonID}`)
  }
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<CurrentResponse>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
