import React from 'react'
import {log} from '@vanguard/logger'
import {useHistory} from 'react-router-dom'
import {
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import '../../assets/stylesheets/react_dates_overrides.css'
import {useStatusToast} from '../../app/ToastManager'
import {Album} from '../../types'
import {ModalStep} from './subcomps/ModalStep'
import {ComposeStepUI, FooterProps} from './ComposeStepUI'
import {StateWrapper} from './subcomps/StateWrapper'
import {ScheduleStepUI} from './ScheduleStepUI'
import {PostPublishedStepUI} from './PostPublishedStepUI'
import {FilterAudienceStepUI} from './FilterAudienceStepUI'

export interface ComposeAlbumModalProps {
  initial?: Album
  onSuccess?: (album: Album) => void
  onClose: (shouldRefresh?: boolean) => void
  resetPhotos?: () => void
}

export const ComposeAlbumModal: React.FC<ComposeAlbumModalProps> = ({
  onSuccess,
  onClose,
  initial,
  resetPhotos,
}) => {
  const history = useHistory()
  const handleSaveResponse: FooterProps['handleReponse'] = (
    response,
    sortType,
  ) => {
    if (response.status !== 'success') {
      toast.error(response.message)
    } else {
      if (onSuccess) {
        onSuccess(response.data)
      }
      if (!initial) {
        history.push(`/album/${response.data.ID}`)
      } else if (sortType !== initial?.sortType && resetPhotos) {
        resetPhotos()
      }
    }
  }
  const {toast} = useStatusToast()
  const initialFocusRef = React.useRef(null)

  const handleOnClose = (): void => {
    log.breadcrumb('Close Modal')

    if (onClose) {
      onClose()
    }
  }
  return (
    <StateWrapper
      initial={initial}
      data-testid='compose-album-modal'
      isOpen
      onClose={handleOnClose}
      size='xl'
      closeOnOverlayClick={false}
      autoFocus={false}
      initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent
        borderRadius={['0px', '3px']}
        minHeight={['100%', 'unset']}
        my={[0, '3.75rem']}>
        <ModalHeader
          borderBottom='1px'
          borderBottomColor='#CED2D5'
          width='100%'>
          <Flex
            flexDir='row'
            width='100%'
            justifyContent='center'
            alignItems='center'>
            <ModalStep name='compose'>
              <ComposeStepUI.Header />
            </ModalStep>

            <ModalStep name='schedule'>
              <ScheduleStepUI.Header />
            </ModalStep>

            <ModalStep name='published'>
              <PostPublishedStepUI.Header />
            </ModalStep>

            <ModalStep name='segment'>
              <FilterAudienceStepUI.Header />
            </ModalStep>

            <ModalCloseButton
              position='initial'
              onClick={(): void => onClose()}
            />
          </Flex>
        </ModalHeader>

        <ModalBody paddingX={8}>
          <ModalStep name='compose'>
            <ComposeStepUI.Body />
          </ModalStep>

          <ModalStep name='schedule'>
            <ScheduleStepUI.Body />
          </ModalStep>

          <ModalStep name='published'>
            <PostPublishedStepUI.Body />
          </ModalStep>

          <ModalStep name='segment'>
            <FilterAudienceStepUI.Body />
          </ModalStep>
        </ModalBody>

        <ModalFooter
          borderTop='1px'
          borderTopColor='#CED2D5'
          paddingRight={['1.5rem', 8]}
          paddingY={2}>
          <ModalStep name='compose'>
            <ComposeStepUI.Footer />
          </ModalStep>

          <ModalStep name='schedule'>
            <ScheduleStepUI.Footer handleReponse={handleSaveResponse} />
          </ModalStep>

          <ModalStep name='published'>
            <PostPublishedStepUI.Footer handleReponse={handleSaveResponse} />
          </ModalStep>

          <ModalStep name='segment'>
            <FilterAudienceStepUI.Footer />
          </ModalStep>
        </ModalFooter>
      </ModalContent>
    </StateWrapper>
  )
}
