import React from 'react'
import {IconButton} from '@chakra-ui/react'
import {DeleteIcon} from '@chakra-ui/icons'

import copy from './copy.json'
import {CustomIcon} from '../CustomIcon/CustomIcon'

type IconButtonType = React.ComponentProps<typeof IconButton>
// type IconType = React.ComponentProps<typeof Icon>
interface Props extends Omit<IconButtonType, 'aria-label'> {
  attachmentType: 'hyperlink' | 'image' | 'video' | 'clear'
}

export const AttachmentButton: React.FC<React.PropsWithChildren<Props>> = ({
  attachmentType,
  ...rest
}) => {
  if (['hyperlink', 'image', 'video'].includes(attachmentType)) {
    const attachmentProps: Partial<IconButtonType> = {
      variant: 'ghost',
      size: 'lg',
    }

    let iconName = ''

    if (attachmentType === 'hyperlink') {
      iconName = 'link'
      attachmentProps['aria-label'] = copy.button.hyperlink.ariaLabel
    } else if (attachmentType === 'image') {
      iconName = 'sun'
      attachmentProps['aria-label'] = copy.button.image.ariaLabel
    } else if (attachmentType === 'video') {
      iconName = 'spinner'
      attachmentProps['aria-label'] = copy.button.video.ariaLabel
    }

    attachmentProps.icon = <CustomIcon name={iconName} />

    // aria-label is required, but Typescript doesn't understand if I set it in attachment props, so it is expected to be
    // overridden with attachmentType customization
    return <IconButton aria-label='attachment' {...rest} {...attachmentProps} />
  }

  if (attachmentType === 'clear') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const attachmentProps: any = {
      'aria-label': rest.title,
      name: 'delete',
      boxSize: '5',
    }

    // aria-label is required, but Typescript doesn't understand if I set it in attachment props, so it is expected to be
    // overridden with attachmentType customization
    return <DeleteIcon {...rest} {...attachmentProps} />
  }

  return null
}
