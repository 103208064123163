import React from 'react'
import {Micropost} from '../types'
import {useFetchMicroposts} from '../services/useFetchMicroposts'
import {dedupeArray} from '../utils/dedupeArray'
import {api} from '../services/api'
import {useRequiredAuth} from './AuthStore'
// import {logger} from '../services/api/logger'

interface MicropostsInfo {
  selectedMicropost: Micropost | null
  microposts: Micropost[]
  hasError: boolean
  isLoading: boolean
  hasMore: boolean
  total: number
}

interface StatusReturn {
  status: 'success' | 'failure'
  message: string
}

export interface MicropostsStore extends MicropostsInfo {
  setSelectedMicropost: React.Dispatch<React.SetStateAction<Micropost | null>>
  setMicroposts: React.Dispatch<React.SetStateAction<Micropost[]>>
  updateMicropost(micropost: Micropost): Promise<StatusReturn>
  createMicropost(micropost: Micropost): Promise<StatusReturn>
  deleteMicropost(ID: Micropost['ID']): Promise<StatusReturn>
  maybeFetchMore: Function
}

const MicropostsContext = React.createContext<MicropostsStore | undefined>(
  undefined,
)

export const useMicropostsStore = (): MicropostsStore => {
  const context = React.useContext(MicropostsContext)
  if (!context) {
    throw new Error(
      'useMicropostsStore must be used within a MicropostsProvider',
    )
  }

  return context
}

interface MicropostsContextProviderProps {
  children?: React.ReactNode
}

export const MicropostsProvider = ({
  children,
}: MicropostsContextProviderProps): React.ReactElement => {
  const [selectedMicropost, setSelectedMicropost] =
    React.useState<Micropost | null>(null)
  const [microposts, setMicroposts] = React.useState<Micropost[]>([])
  const {data, isLoading, hasError, maybeFetchMore, hasMore, total} =
    useFetchMicroposts() // TODO: Add control method when to fetch first page, not on mount like this currently is
  const {client, person} = useRequiredAuth()

  React.useMemo(() => {
    setMicroposts((prev) => dedupeArray([...prev, ...data]))
  }, [data, setMicroposts])

  const updateMicropost: MicropostsStore['updateMicropost'] = async (
    micropost,
  ) => {
    const response = await api.microposts.updateMicropost({
      clientID: client.ID,
      personID: person.ID,
      ID: micropost.ID,
      publishDate: micropost.publishDate,
      content: micropost.content,
      notify: micropost.notify,
      imageURL: micropost.imageURL,
      videoURL: micropost.videoURL ? `https://${micropost.videoURL}` : '',
      hyperlinkText:
        micropost.hyperlinkText || (micropost.hyperlinkURL ? 'Learn More' : ''), // If there is a URL, but no text, add one.
      hyperlinkURL: micropost.hyperlinkURL
        ? `https://${micropost.hyperlinkURL}`
        : '',
      segmentID: micropost.segmentID,
    })

    if (response.result === 'failure') {
      return Promise.resolve({
        status: 'failure',
        message: 'Unable to Save Micropost',
      })
    }

    // if (response.result !== 'success' || !response.data) {
    //   throw new Error('Unsuccessful Micropost Upsert')
    // }
    // someday we'll need to sort if they can edit publish date
    // NOTE: currently backend is sorting by CreatedDateUTC, not publish date -
    //       as requirements shake out, may need to always do publisDate for display?
    //
    // const sortedNewList = newList.sort((a:Micropost, b:Micropost) => Number(b.publishDate) - Number(a.publishDate)) // descending

    // Replace the client-side data with the new data.
    setMicroposts((prev) => {
      const updatedIndex = prev.findIndex((mp) => mp.ID === response.data.ID)

      return [
        ...prev.slice(0, updatedIndex),
        response.data,
        ...prev.slice(updatedIndex + 1),
      ]
    })

    return Promise.resolve({
      status: 'success',
      message: 'Micropost Saved',
    })
  }

  const createMicropost: MicropostsStore['createMicropost'] = async (
    micropost,
  ) => {
    const response = await api.microposts.createMicropost({
      clientID: client.ID,
      personID: person.ID,
      ID: micropost.ID,
      publishDate: new Date(Date.now()), // This let's us mock Date.now in tests
      content: micropost.content,
      imageURL: micropost.imageURL,
      notify: micropost.notify,
      videoURL: micropost.videoURL ? `https://${micropost.videoURL}` : '',
      hyperlinkText:
        micropost.hyperlinkText || (micropost.hyperlinkURL ? 'Learn More' : ''), // If there is a URL, but no text, add one.
      hyperlinkURL: micropost.hyperlinkURL
        ? `https://${micropost.hyperlinkURL}`
        : '',
      segmentID: micropost.segmentID,
    })

    if (response.result === 'failure') {
      return Promise.resolve({
        status: 'failure',
        message: response.error.message,
      })
    }

    // Update the client-side data with the new mp
    setMicroposts((prev) => [response.data, ...prev])

    return Promise.resolve({
      status: 'success',
      message: 'Micropost Saved',
    })
  }

  const deleteMicropost: MicropostsStore['deleteMicropost'] = async (ID) => {
    const response = await api.microposts.deleteMicropost({
      ID,
      clientID: client.ID,
    })

    if (response.result === 'failure') {
      return Promise.resolve({
        status: 'failure',
        message: response.error.message,
      })
    }

    const removedIndex = microposts.findIndex((m) => m.ID === ID)
    const removedMP = microposts[removedIndex]

    // Cleanup attached segments. It is now an orphan since only MP currently uses segments
    if (removedMP.segmentID) {
      await api.segments.deleteSegment({
        clientID: client.ID,
        ID: removedMP.segmentID,
      })
    }

    setMicroposts([
      ...microposts.slice(0, removedIndex),
      ...microposts.slice(removedIndex + 1),
    ])

    return Promise.resolve({
      status: 'success',
      message: 'Micropost Deleted',
    })
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    selectedMicropost,
    setSelectedMicropost,
    microposts,
    setMicroposts,
    deleteMicropost,
    isLoading,
    hasError,
    maybeFetchMore,
    hasMore,
    total,
    createMicropost,
    updateMicropost,
  }

  return (
    <MicropostsContext.Provider value={value}>
      {children}
    </MicropostsContext.Provider>
  )
}
