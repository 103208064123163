import {http} from '../http'
import * as coreEzTexting from '../../coreApi/eztexting'
import {APIResponse} from '../types'
import {EzTextingCredits, ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
}

type ResolvedReturn = APIResponse<EzTextingCredits>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.fetchCredits()'
const GENERIC_ERROR =
  'Something went wrong while getting your credits. Please try again.'

export const fetchCredits: ServiceMethod = async ({clientID}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
    }

    const response = await coreEzTexting.fetchCredits(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Fetch Credits')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        planCredits: response.data.PlanCredits,
        anytimeCredits: response.data.AnytimeCredits,
        totalCredits: response.data.TotalCredits,
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
