import {createAlbumUploadSession} from './createAlbumUploadSession'
import {createAlbum} from './createAlbum'
import {deleteAlbum} from './deleteAlbum'
import {fetchAlbums} from './fetchAlbums'
import {getAlbumUploadSession} from './getAlbumUploadSession'
import {getConstraintCount} from './getConstraintCount'
import {getAlbum} from './getAlbum'
import {listAlbumUploadSessions} from './listAlbumUploadSessions'
import {sortAlbumPhotos} from './sortAlbumPhotos'
import {updateAlbum} from './updateAlbum'
import {updateConstraints} from './updateConstraints'

export const albums = {
  createAlbum,
  createAlbumUploadSession,
  deleteAlbum,
  fetchAlbums,
  getAlbum,
  getAlbumUploadSession,
  listAlbumUploadSessions,
  sortAlbumPhotos,
  updateAlbum,
  updateConstraints,
  getConstraintCount,
}
