import React from 'react'
import {log} from '@vanguard/logger'
import {useRequiredAuth} from '../stores/AuthStore'
import {api} from './api'

type Status = 'idle' | 'pending' | 'notfound' | 'rejected' | 'success'

interface UseEztCreditsReturn {
  totalCredits: number | null
  status: Status | null
  fetchTotalCredits: () => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseEztCredits {
  (): UseEztCreditsReturn
}

export const useEztCredits: UseEztCredits = () => {
  const {client} = useRequiredAuth()
  const [totalCredits, setTotalCredits] = React.useState<number | null>(null)
  const [status, setStatus] = React.useState<Status>('idle')

  const fetchTotalCredits = React.useCallback<
    UseEztCreditsReturn['fetchTotalCredits']
  >(async () => {
    log.breadcrumb('Begin Fetch Credits')

    setStatus('pending')

    const response = await api.eztexting.fetchCredits({
      clientID: client.ID,
    })

    if (response.result === 'failure') {
      log.error(new Error(response.error.message))

      if (response.error.message === 'notfound') {
        setStatus('notfound')
      } else {
        setStatus('rejected')
      }
    }

    if (response.result === 'success') {
      setStatus('success')
      setTotalCredits(response.data.totalCredits)
    }
  }, [client.ID, setTotalCredits])

  React.useEffect(() => {
    fetchTotalCredits()
  }, [fetchTotalCredits])

  return React.useMemo(
    () => ({
      totalCredits,
      status,
      fetchTotalCredits,
    }),
    [totalCredits, status, fetchTotalCredits],
  )
}
