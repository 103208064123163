import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useStatusToast} from '../app/ToastManager'
import {useReleaseFlags} from '../hooks/useReleaseFlags'
import {useRequiredAuth} from '../stores/AuthStore'
import {api} from './api'

export const useEztCredValidation = (): void => {
  const history = useHistory()
  const {client} = useRequiredAuth()
  const {toast} = useStatusToast()

  const {getIsReleasedToUser} = useReleaseFlags()

  useEffect(() => {
    const init = async (): Promise<void> => {
      const checkResponse = await api.eztexting.credentials.check({
        clientID: client.ID,
      })
      if (checkResponse.result === 'success') {
        if (!checkResponse.data.validated) {
          toast.error(
            'Your EZ Texting credentials are invalid or may have changed. Please log in again.',
          )
          history.push('/eztexting')
        }
      } else {
        // No decision on what should happen in this case
      }
    }
    init()
  }, [client.ID, getIsReleasedToUser, history, toast])
}
