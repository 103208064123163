import {http} from '../http'
import {APIResponse} from '../types'
import * as coreSegments from '../../coreApi/segments'
import {ResourceID, Segment} from '../../../types'
import {handleError} from '../handleError'
import {transformSegment} from './segment.transform'

interface Params {
  clientID: ResourceID
  ID: ResourceID
}

type ResolvedReturn = APIResponse<Segment>

interface GetSegment {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.segments.getSegment()'

export const getSegment: GetSegment = async ({clientID, ID}) => {
  let resolvedReturn: ResolvedReturn
  const rawParamMap = {
    ClientID: parseInt(clientID, 10),
    ID: parseInt(ID, 10),
  }

  try {
    const response = await coreSegments.get(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Fetch Segment')
    }

    const segmentGroup = transformSegment(response.data)

    resolvedReturn = {
      result: 'success',
      data: segmentGroup.content,
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, 'general', e)
  }
  return Promise.resolve(resolvedReturn)
}
