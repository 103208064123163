import {convertToInt, convertToString} from '../../../utils'
import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
  personID: ResourceID
  albumID: ResourceID
  nextPhotoID: ResourceID | null
  photoIDs: ResourceID[]
}

type ResolvedReturn = APIResponse<{
  nextPhotoID: string | null
  photoIDs: string[]
}>

interface SortAlbumPhotos {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.sortAlbumPhotos()'
const GENERIC_ERROR =
  'Something went wrong while sorting your album. Please try again.'

export const sortAlbumPhotos: SortAlbumPhotos = async ({
  clientID,
  personID,
  albumID,
  nextPhotoID,
  photoIDs,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      PersonID: parseInt(personID, 10),
      AlbumID: parseInt(albumID, 10),
      Next: nextPhotoID ? parseInt(nextPhotoID, 10) : null,
      PhotoID: convertToInt(photoIDs),
    }

    const response = await coreAlbums.sort(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Sort Photo Albums')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        nextPhotoID: response.data.Next?.toString() || null,
        photoIDs: convertToString(response.data.PhotoIDs),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
