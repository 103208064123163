import {processUploadSessionCompleteSSE as coreProcessUploadSessionCompleteSSE} from '../../coreApi/photos'
import {SSEMessage} from '../../coreApi/types'

export {TopicUploadSessionComplete} from '../../coreApi/photos'

type ProcessUploadSessionCompleteSSE = (msg: SSEMessage) => {
  albumID?: string
  uploadSessionID?: string
  details: {
    completed: boolean
    error?: string
  }
}

export const processUploadSessionCompleteSSE: ProcessUploadSessionCompleteSSE =
  (msg) => {
    const coreEvent = coreProcessUploadSessionCompleteSSE(msg)

    return {
      albumID: coreEvent.UploadSessionCompleteMeta?.AlbumID.toString(),
      uploadSessionID:
        coreEvent.UploadSessionCompleteMeta?.UploadSessionID.toString(),
      details: {
        completed: coreEvent.Response?.completed || false,
        error: coreEvent.Response?.error,
      },
    }
  }
