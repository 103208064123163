import React from 'react'
import {Flex, Spinner, Text} from '@chakra-ui/react'

import {ViewError} from '../ViewError/ViewError'
import {BlankSlate} from '../BlankSlate/BlankSlate'

interface Props {
  isLoading: boolean
  hasError: boolean
  onNext: Function
  emptyComponent?: React.ReactElement
  loadingText?: string
}

const renderLoading = (loadingText?: string): React.ReactElement => (
  // Note: For tests the spinner renders its label of 'Loading...'
  <Flex direction='column' alignItems='center'>
    <Flex justifyContent='center' py={8}>
      <Spinner />
    </Flex>
    {loadingText ? <Text>{loadingText}</Text> : null}
  </Flex>
)

const DefaultBlankSlate: React.FC<React.PropsWithChildren> = () => (
  <BlankSlate>No Items</BlankSlate>
)

export const ScrollList: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  hasError,
  onNext,
  emptyComponent = <DefaultBlankSlate />,
  loadingText,
  children,
}) => {
  const list = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    // When at the bottom, see if we should fetch more microposts
    const handleScroll = (): void => {
      if (
        list &&
        list.current &&
        Math.ceil(window.scrollY + window.innerHeight + 50) >=
          list.current.clientHeight + list.current.offsetTop
      ) {
        onNext()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return (): void => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [onNext])

  if (hasError) {
    return (
      <ViewError>
        <Text>There was a problem loading.</Text>
        <Text>Please refresh to try again.</Text>
      </ViewError>
    )
  }

  // No items
  if (!React.Children.count(children) && !isLoading) {
    return emptyComponent
  }

  return (
    <div ref={list}>
      <Flex flexDirection='column'>
        {children}
        {isLoading && renderLoading(loadingText)}
      </Flex>
    </div>
  )
}
