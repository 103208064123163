import {PhotoProps} from 'react-photo-gallery'

export interface GalleryPhoto extends PhotoProps {
  rotation: number
  processing: boolean
}

export const ORDERED_PHOTO_SORT_TYPES = [
  {name: 'Newest First', value: 3},
  {name: 'Oldest First', value: 2},
  {name: 'File Name Ascending', value: 4},
  {name: 'File Name Descending', value: 5},
  {name: 'Custom', value: 1},
] as const
