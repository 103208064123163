import {ResourceID, EzTextingGroup} from '../../../types'
import {handleError} from '../handleError'
import {http} from '../http'
import {APIResponse} from '../types'
import * as coreGroups from '../../coreApi/eztexting'

interface Params {
  EzTextingID: ResourceID
  ID: ResourceID
  clientID: EzTextingGroup['clientID']
}

type ResolvedReturn = APIResponse<null>

interface DeleteGroup {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.deleteGroup()'
const GENERIC_ERROR = 'general'

export const deleteGroup: DeleteGroup = async ({EzTextingID, ID, clientID}) => {
  let resolvedReturn: ResolvedReturn

  const rawParamMap = {
    ID: parseInt(ID, 10),
    ClientID: Number(clientID),
    EzTextingID,
  }

  try {
    await coreGroups.deleteGroup(http, rawParamMap)

    resolvedReturn = {
      result: 'success',
      data: null,
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }
  return Promise.resolve(resolvedReturn)
}
