import {v1 as uuid} from 'uuid'
import {createHttpInstance} from '../coreApi/createHttpInstance'
import {transformRequests} from '../coreApi/transformRequests'
import {transformResponse} from '../coreApi/transformResponse'
import {NotAuthorizedError} from '../../customErrors'
import {API_URL} from '../../constants'
import {sessionManager} from '../../utils'

/*
   Making a special point to use the same history object for the Router instead of browser api.
   I belive this serves as a bit of a polyfill and should avoid hard to debug scenarios where stuff works in most browsers,
   but user's are properly being redirected on some
*/
// import {history} from '../../app/history'

if (!API_URL) {
  throw new Error('No API URL Environment Variable')
}

const http = createHttpInstance({baseURL: API_URL})

transformRequests(http, (request) => {
  if (!request.headers) throw new Error('request.headers is undefined.')

  request.headers['X-Request-ID'] = uuid()

  return request
})

transformResponse(
  http,
  (response) => {
    // Couldn't get revoke to work after 5 minute delay, so I used this to trigger the same effect as a 401 when fetching the microposts
    // if (response.config.url?.indexOf('microposts') !== -1) {
    //   const location = {
    //     pathname: '/',
    //     state: {httpStatus: 401},
    //   }
    //   history.push(location)

    //   sessionStorage.clear()
    // }
    sessionManager.reset() // active use resets sessionTimer
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionManager.immediateExpire()
      // // history.replace('/?status=401')
      // sessionStorage.clear()

      // TODO: make a /logout route that can properly clear everything
      // const location = {
      //   pathname: '/login', // TODO: I need to find a way to do a proper logout (AuthContext and SessionStorage) else the Route redirects seem to infinite loop
      //   state: {httpStatus: 401},
      // }
      // history.push(location)
      return Promise.reject(new NotAuthorizedError('Not authorized'))
    }
    return Promise.reject(error)
  },
)

export {http}
