import * as React from 'react'
import {Flex} from '@chakra-ui/react'
import type {ColorHues} from '@chakra-ui/react'

export interface Column {
  title: string
  value: string | React.ReactElement
}

export interface Props {
  columns: Column[]
  color: ColorHues
}

export const SummaryPanel: React.FC<React.PropsWithChildren<Props>> = ({
  columns,
  color,
}) => (
  <Flex flexDir={['column', 'row']} justifyContent='space-between' m='1em 0'>
    {columns.map((col, i) => (
      <Flex
        key={col.title}
        p={['1em', '2em']}
        className='summaryPanelColumn'
        flexDir='column'
        flexGrow={1}
        borderStyle='solid'
        borderColor={color[500]}
        borderWidth='1px'
        borderRightWidth={['1px', i === columns.length - 1 ? '1px' : '0']}
        borderBottomWidth={[i === columns.length - 1 ? '1px' : '0', '1px']}>
        <Flex justifyContent='center' fontWeight='bold' fontSize='1.4em'>
          {col.value}
        </Flex>
        <Flex justifyContent='center'>{col.title}</Flex>
      </Flex>
    ))}
  </Flex>
)
