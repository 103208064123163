import React from 'react'
import {
  Flex,
  Box,
  Collapse,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import {CheckCircleIcon, WarningTwoIcon} from '@chakra-ui/icons'
import {log} from '@vanguard/logger'

import {PickerFileMetadata} from 'filestack-js/build/main/lib/picker'
import './filestackOverrides.css'

import {
  useAttachmentControlsState,
  useAttachmentControlsDispatch,
} from '../contextManagers/AttachmentControlsManager'
import {AttachmentButton} from '../../AttachmentButton/AttachmentButton'
import {useStatusToast} from '../../../app/ToastManager'
import {getFileUrl, stripUrlProtocol} from '../../../utils'
import {SimplePhotoUploader} from './SimplePhotoUploader'
import copy from '../copy.json'
import {
  useComposeMicropostState,
  useComposeMicropostActions,
} from '../contextManagers/MicropostManager'
import {CustomIcon} from '../../CustomIcon/CustomIcon'

const ImageControlToggle: React.FC<React.PropsWithChildren> = () => {
  const state = useAttachmentControlsState()
  const dispatch = useAttachmentControlsDispatch()

  return (
    <AttachmentButton
      attachmentType='image'
      onClick={(): void => {
        dispatch({type: 'TOGGLE_IMAGE'})
      }}
      isDisabled={
        state.enabledMedia !== 'all' && state.enabledMedia !== 'image'
      }
    />
  )
}

const VideoControlToggle: React.FC<React.PropsWithChildren> = () => {
  const state = useAttachmentControlsState()
  const dispatch = useAttachmentControlsDispatch()

  return (
    <AttachmentButton
      attachmentType='video'
      onClick={(): void => {
        dispatch({type: 'TOGGLE_VIDEO'})
      }}
      isDisabled={
        state.enabledMedia !== 'all' && state.enabledMedia !== 'video'
      }
    />
  )
}

const Control: React.FC<React.PropsWithChildren> = () => {
  const {videoURL, isVideoValid} = useComposeMicropostState()
  const {handleInputChange, setValue} = useComposeMicropostActions()
  const state = useAttachmentControlsState()
  const dispatch = useAttachmentControlsDispatch()
  const {toast} = useStatusToast()

  const displayError = React.useCallback(
    (err: string | null): void => {
      if (err != null) {
        log.error(err)
      }

      toast.error('File Upload Error')
    },
    [toast],
  )

  const filestackSuccess = React.useCallback(
    (res: PickerFileMetadata): void => {
      if (!res || !res.filename) {
        displayError('invalid success response')
        dispatch({type: 'IMAGE_UPLOAD_ERROR'})
      } else {
        const fileUrl = getFileUrl(res)

        setValue('imageURL', fileUrl)
        dispatch({type: 'IMAGE_UPLOAD_SUCCESS'})
      }
    },
    [displayError, dispatch, setValue],
  )

  const nativeFilestackError = React.useCallback(
    (res: PickerFileMetadata): void => {
      dispatch({type: 'IMAGE_UPLOAD_ERROR'})

      if (res && res.filename) {
        const failed = `file upload failed: ${JSON.stringify(res.filename)}`
        displayError(failed)
      } else {
        displayError(null)
      }
    },
    [displayError, dispatch],
  )

  const filestackStarted = React.useCallback(
    (_res: PickerFileMetadata): void => {
      dispatch({type: 'IMAGE_UPLOAD_IN_PROGRESS'})
    },
    [dispatch],
  )

  const renderValidVideoUrlIcon = (): React.ReactNode => {
    if (!videoURL.length) {
      return null
    }

    return isVideoValid ? (
      <CheckCircleIcon color='#7ABC9B' />
    ) : (
      <WarningTwoIcon color='#E58D55' />
    )
  }

  return (
    <Collapse in={state.mediaControl !== 'inactive'}>
      <Box bgColor='#DFE8ED' px='6' py='3'>
        {/* There is a lag when filestack renders where it gets its drag/drop UI, so
      I am having it render with the micropost load, but hidden so hopefully there won't be a lag       *}
      {/* {state.mediaControl === 'imageUploader' && ( */}
        <Flex
          direction='column'
          display={
            state.mediaControl === 'imageUploader' || state.isImageUploading
              ? 'block'
              : 'none'
          }>
          <SimplePhotoUploader
            onFileUploadFailed={nativeFilestackError}
            onFileUploadFinished={filestackSuccess}
            onFileUploadStarted={filestackStarted}
          />
        </Flex>
        {/* )} */}
        {state.mediaControl === 'imageAdded' && (
          <Flex flex='1 1 auto' alignItems='center'>
            <Flex flex='1 1 auto' direction='row'>
              <CustomIcon name='sun' mr={4} boxSize='6' />
              <Text flex='1'>(1) Image Attached</Text>

              <AttachmentButton
                attachmentType='clear'
                title='clear image'
                mx={4}
                onClick={(): void => {
                  dispatch({type: 'TOGGLE_IMAGE'})
                  setValue('imageURL', '')
                }}
              />
            </Flex>
          </Flex>
        )}

        {state.mediaControl === 'video' && (
          <Flex alignItems='center'>
            <InputGroup flex='1 1 auto'>
              <InputLeftAddon>
                <CustomIcon
                  name='spinner'
                  boxSize='6'
                  color='#535353'
                  pr={[0, 2]}
                />
                <Box display={['none', 'unset']}>https://</Box>
              </InputLeftAddon>
              <Input
                bgColor='white'
                name='videoURL'
                placeholder={copy.inputs.videoURL.placeholder}
                value={videoURL}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  e.currentTarget.value = stripUrlProtocol(
                    e.currentTarget.value,
                  )
                  handleInputChange(e)
                }}
              />
              <InputRightElement>{renderValidVideoUrlIcon()}</InputRightElement>
            </InputGroup>
            <Flex flex='0 0 auto'>
              <AttachmentButton
                attachmentType='clear'
                title='clear video'
                mx={4}
                onClick={(): void => {
                  setValue('videoURL', '')
                }}
              />
            </Flex>
          </Flex>
        )}
      </Box>
    </Collapse>
  )
}

const MediaAttachment = {
  ImageControlToggle,
  VideoControlToggle,
  Control,
}

export {MediaAttachment}
