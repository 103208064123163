import {http} from '../http'
import * as featureSettingsApi from '../../coreApi/feature-settings'

import {APIResponse} from '../types'
import {Client, FeatureSettings} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: Client['ID']
}

type GetFeatureSettingsReturn = APIResponse<FeatureSettings>

export interface GetFeatureSettings {
  (params: Params): Promise<GetFeatureSettingsReturn>
}

const ERROR_PATH = 'api.featureSettings.getFeatureSettings()'

export const getFeatureSettings: GetFeatureSettings = async ({clientID}) => {
  try {
    const response = await featureSettingsApi.fetchClientFeatures(http, {
      clientID,
      pageNumber: 1,
      pageSize: 100,
      featureID: null,
    })

    // Not using Dates for release flags so ignoring this for now
    // const clientFeatures = response.data.Results.filter((raw) => {
    //   // if there a date range make sure its within today and return, otherwise skip
    //   let good = false
    //   let start
    //   let end
    //   const now = new Date(Date.now())
    //   if (raw.StartDate === null && raw.EndDate === null) {
    //     good = true
    //   } else {
    //     if (raw.StartDate !== null) {
    //       start = new Date(raw.StartDate)
    //       good = start <= now
    //     }
    //     if (raw.EndDate !== null) {
    //       end = new Date(raw.EndDate)
    //       good = now <= end
    //     }
    //   }
    //   return good
    // })

    const resolved: GetFeatureSettingsReturn = {
      result: 'success',
      data: {
        clientFeatures: response.data.Results.map((raw) => ({
          clientID: raw.ClientID.toString(),
          featureID: raw.FeatureID.toString(),
          startDate: raw.StartDate !== null ? new Date(raw.StartDate) : null,
          endDate: raw.EndDate !== null ? new Date(raw.EndDate) : null,
        })),
      },
    }
    return Promise.resolve(resolved)
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e && e.message ? e.message : e.toString()
    }
    const result = handleError(ERROR_PATH, message, e)
    return Promise.resolve(result)
  }
}
