import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {ListRequest, SegmentResponse} from './types'

export interface Paginated<T> {
  TotalCount: number
  Next: string
  Results: T[]
}

export interface List<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const list: List<ListRequest, Paginated<SegmentResponse>> = (
  http,
  params,
) => {
  const {ClientID, OrderAscending, OrderBy, PageNumber, PageSize} = params

  const path = '/v0/segments'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (OrderAscending !== undefined) {
    queryParams.push(`orderascending=${OrderAscending}`)
  }
  if (OrderBy !== undefined) {
    queryParams.push(`orderby=${OrderBy}`)
  }
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<Paginated<SegmentResponse>>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
