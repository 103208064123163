import {transformSegment} from './segment.transform'
import {http} from '../http'
import {APIResponse} from '../types'
import * as coreSegments from '../../coreApi/segments'
import {SegmentGroup} from '../../../types'
import {SegmentResponse} from '../../coreApi/segments/types'
import {handleError} from '../handleError'

type FetchSegmentsReturn = APIResponse<SegmentGroup[]>

export interface Params {
  clientID: string
  all?: boolean
  pageSize: number
}

interface FetchSegments {
  (params: Params): Promise<FetchSegmentsReturn>
}

const get = async (
  clientID: string,
  pageSize: number,
  pageNumber: number,
): Promise<coreSegments.Paginated<SegmentResponse>> => {
  const response = await coreSegments.list(http, {
    PageNumber: pageNumber,
    PageSize: pageSize,
    ClientID: Number(clientID),
    OrderAscending: true,
    OrderBy: 'Name',
  })
  return response?.data
}

const getAll = async (
  clientID: string,
  pageSize: number,
  pageNumber: number,
): Promise<SegmentResponse[]> => {
  let hasNext = true
  let responseData: SegmentResponse[] = []
  let page = pageNumber
  do {
    // eslint-disable-next-line no-await-in-loop
    const results = await get(clientID, pageSize, page)
    responseData = [...responseData, ...results.Results]
    page += 1
    hasNext = Boolean(results.Next)
  } while (hasNext)

  return responseData
}

const ERROR_PATH = 'api.segments.fetchSegments()'
const GENERIC_ERROR =
  'Something went wrong while retrieving the data. Please try again.'

export const fetchSegments: FetchSegments = async ({
  clientID,
  pageSize,
  all = false,
}) => {
  try {
    const pageNumber = 1

    let responseData: SegmentResponse[] = []

    if (all) {
      responseData = await getAll(clientID, pageSize, pageNumber)
    } else {
      const response = await get(clientID, pageSize, pageNumber)
      responseData = response.Results
    }

    if (responseData) {
      return Promise.resolve({
        result: 'success',
        data: responseData.map((result) => transformSegment(result)),
        error: null,
      })
    }

    throw new Error('Unsuccessful segments fetch')
  } catch (e) {
    const error = handleError(ERROR_PATH, GENERIC_ERROR, e)
    return Promise.resolve(error)
  }
}
