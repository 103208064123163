import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {PhotoSortRequest, PhotoSortResponse} from './types'

export interface Sort<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const sort: Sort<PhotoSortRequest, PhotoSortResponse> = (
  http,
  params,
) => {
  const {ClientID, PersonID, Next, PhotoID, AlbumID} = params

  const path = '/v1/albums/{albumid}/sort'.replace(
    '{albumid}',
    AlbumID.toString(),
  )

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (PersonID !== undefined) {
    queryParams.push(`personid=${PersonID}`)
  }
  if (Next !== undefined) {
    queryParams.push(`next=${Next}`)
  }
  if (PhotoID !== undefined) {
    for (let i = 0; i < PhotoID.length; i += 1) {
      queryParams.push(`photoid=${PhotoID[i]}`)
    }
  }
  const query = `?${queryParams.join('&')}`

  return http.request<PhotoSortResponse>({
    method: 'POST',
    url: `${path}${query}`,
  })
}
