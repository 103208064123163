import React, {useReducer, createContext, useContext} from 'react'
import {Album} from '../../../types'
import {INSERT_PLACEHOLDER_ID} from '../../../constants'
import {ORDERED_PHOTO_SORT_TYPES} from '../../../views/AlbumView/types'
import {useRequiredAuth} from '../../../stores/AuthStore'

// User a collection of props instead of an Album
/* *********************************************** */
type ReducerState =
  | Album
  | Omit<Album, 'coverPhotoID' | 'photoCount' | 'isPrivate'>

type Action =
  | {
      type: 'UPDATE_VALUE'
      payload: {
        key: keyof ReducerState
        value: string | boolean | null | Date
      }
    }
  | {
      type: 'UPDATE_CONSTRAINTS'
      payload: Album['constraints']
    }

const reducer: React.Reducer<ReducerState, Action> = (state, action) => {
  switch (action.type) {
    case 'UPDATE_VALUE': {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    }
    case 'UPDATE_CONSTRAINTS': {
      return {
        ...state,
        constraints: action.payload,
      }
    }

    default:
      throw new Error('Unhandled action')
  }
}

/* *********************************************** */

type ContextState = ReducerState

const ComposeAlbumManagerStateContext = createContext<ContextState | undefined>(
  undefined,
)

const ComposeAlbumManagerDispatchContext = createContext<
  React.Dispatch<Action> | undefined
>(undefined)

/* *********************************************** */
export const ComposeAlbumManager: React.FC<
  React.PropsWithChildren<{
    initial?: Album | null
  }>
> = ({initial, children}) => {
  const {client} = useRequiredAuth()

  const initialState: ReducerState = initial
    ? {
        ...initial,
      }
    : {
        ID: INSERT_PLACEHOLDER_ID,
        date: new Date(),
        name: '',
        sortType: ORDERED_PHOTO_SORT_TYPES[0].name,
        isDraft: false,
        publishDate: null,
        season: client.currentSeasonID,
        constraints: [],
      }

  const [state, dispatch] = useReducer(reducer, initialState)

  const stateValue: ContextState = state

  return (
    <ComposeAlbumManagerStateContext.Provider value={stateValue}>
      <ComposeAlbumManagerDispatchContext.Provider value={dispatch}>
        {children}
      </ComposeAlbumManagerDispatchContext.Provider>
    </ComposeAlbumManagerStateContext.Provider>
  )
}

export const useComposeAlbumState = (): ContextState => {
  const state = useContext(ComposeAlbumManagerStateContext)
  if (!state) {
    throw new Error('useComposeAlbumState must be used within a StepProvider')
  }

  return state
}

/* *********************************************** */
interface ComposeAlbumActions {
  setValue: (
    name: keyof ReducerState,
    value: string | boolean | null | Date,
  ) => void
  setConstraints: (constraints: Album['constraints']) => void
}

export const useComposeAlbumActions = (): ComposeAlbumActions => {
  const dispatch = useContext(ComposeAlbumManagerDispatchContext)
  if (!dispatch) {
    throw new Error(
      'useComposeAlbumDispatch must be used within a StepProvider',
    )
  }

  const setValue: ComposeAlbumActions['setValue'] = React.useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_VALUE',
        payload: {key: name, value},
      })
    },
    [dispatch],
  )

  const setConstraints: ComposeAlbumActions['setConstraints'] =
    React.useCallback(
      (constraints) => {
        dispatch({
          type: 'UPDATE_CONSTRAINTS',
          payload: constraints,
        })
      },
      [dispatch],
    )

  return {
    setValue,
    setConstraints,
  }
}
