import {decode} from 'he'
import {handleError} from '../handleError'
import {http} from '../http'
import * as coreClients from '../../coreApi/clients'

import {APIResponse} from '../types'
import {Client} from '../../../types'
import {transformTerm} from './term.transform'

interface Params {
  clientID: Client['ID']
}

type GetClientReturn = APIResponse<Client>

export interface GetClient {
  (params: Params): Promise<GetClientReturn>
}

const ERROR_PATH = 'api.clients.getClient()'

export const getClient: GetClient = async ({clientID}) => {
  try {
    const response = await coreClients.getClient(http, {
      clientID,
    })

    const resolved: GetClientReturn = {
      result: 'success',
      data: {
        ID: response.data.ID.toString(),
        citURL: decode(response.data.CITURL),
        name: decode(response.data.Name),
        features: {
          eLetters: {
            enabled: response.data.Features.ELetters.Enabled,
          },
          letters: {
            enabled: response.data.Features.Letters.Enabled,
            chargeCampstamps: response.data.Features.Letters.ChargeCampstamps,
            handWrittenReplyEnabled:
              response.data.Features.Letters.HandWrittenReplyEnabled,
          },
        },
        firstSeasonID: response.data.FirstSeasonID,
        latestSeasonID: response.data.LatestSeasonID,
        currentSeasonID: response.data.CurrentSeasonID,
        terms: {
          bunk: transformTerm(response.data.Terms.Bunk),
        },
        salesforceAccountID: response.data.SalesforceAccountID,
      },
    }
    return Promise.resolve(resolved)
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e.message ? e.message : e.toString()
    }
    const result = handleError(ERROR_PATH, message, e)
    return Promise.resolve(result)
  }
}
