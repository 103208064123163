import {ResourceID} from '../../../types'
import * as corePhotos from '../../coreApi/photos'
import {http} from '../http'

interface Params {
  clientID: ResourceID
  ID: ResourceID
}

export interface ServiceMethod {
  (params: Params): string
}

export const getThumbnail: ServiceMethod = ({clientID, ID}) => {
  const rawParamMap = {
    ClientID: parseInt(clientID, 10),
    ID: parseInt(ID, 10),
  }

  try {
    return corePhotos.getThumbnail(http, rawParamMap)
  } catch {
    return ''
  }
}
