import React from 'react'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import {Flex, Text} from '@chakra-ui/react'

import {PropsOf} from '../../types'
import {browserSupportsInputType} from '../../utils'
import {TimeInputFallback} from '../TimeInputFallback/TimeInputFallback'

interface Props extends Omit<PropsOf<typeof Flex>, 'onChange'> {
  value: string
  onChange(militaryTime: string): void
}

export const AlbumTimeControl: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  ...rest
}) => {
  const classes = makeStyles(() => ({
    textField: {
      border: '1px solid #ced2d5',
      borderRadius: '3px',
      width: '100%',
    },
    root: {
      height: '38px',
      justifyContent: 'flex-end',
      paddingLeft: '1rem',

      '& input::-webkit-clear-button': {
        '-webkitAppearance': 'none',
      },
    },

    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  }))()

  const InputProps = {
    classes: {
      root: classes.root,
      underline: classes.underline,
    },
    required: true,
    steps: 900,
  }

  return (
    <Flex direction='column' {...rest}>
      <Text fontWeight='bold' pt={4} fontSize='16px'>
        Time
      </Text>

      <Flex width={['100%']} mt={[1, 0]} pt={1}>
        {browserSupportsInputType('time') ? (
          <TextField
            id='time'
            placeholder='enter time'
            type='time'
            data-testid='time input'
            variant='standard'
            value={value}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={InputProps}
            onChange={(e): void => onChange(e.target.value)}
          />
        ) : (
          <TimeInputFallback value={value} onChange={onChange} />
        )}
      </Flex>
    </Flex>
  )
}
