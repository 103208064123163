import React, {useState} from 'react'
import {Box, useDisclosure} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'

import {MissingEZTextingView} from './MissingEZTextingView'
import {FormRemindersUnauthorizedView} from './FormRemindersUnauthorizedView'
import {useReleaseFlags} from '../../hooks/useReleaseFlags'
import {useUserPermissions} from '../../hooks/useUserPermissions'

import {PageHeader} from '../../components/PageHeader/PageHeader'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'

import copy from './copy.json'
import {useEztCredits} from '../../services/useEztCredits'
import {useFormRemindersRecipients} from '../../services/useFormRemindersRecipients'
import {LabelledSectionBox, SendFormRemindersModal} from '../../components'
import {CalculateCreditsDisplay} from './CalculateCreditsDisplay'
import {useStatusToast} from '../../app/ToastManager'
import {useTextMessaging} from '../../services/useTextMessaging'
import {CalculateCreditsDisplayFooter} from './CalculateCreditsDisplayFooter'
import {ComposeMessage, DEFAULT_MESSAGE_TEXT} from './Sections/ComposeMessage'
import {
  MessageSettings,
  messageSettingsOptions,
  MessageSettingsState,
} from './Sections/MessageSettings'
import {useEztCredValidation} from '../../services/useEztCredValidation'

export const FormRemindersView: React.FC<React.PropsWithChildren> = () => {
  const {getIsReleasedToUser} = useReleaseFlags()
  const {checkUserHasPermissionAccess} = useUserPermissions()

  if (
    !getIsReleasedToUser('integrations') ||
    !getIsReleasedToUser('texting-enabled')
  ) {
    return <MissingEZTextingView />
  }

  if (
    !checkUserHasPermissionAccess('Parent/Camper Communication', 'Authorized')
  ) {
    return <FormRemindersUnauthorizedView />
  }

  return <FormRemindersAuthorizedView />
}

const FormRemindersAuthorizedView: React.FC<React.PropsWithChildren> = () => {
  const {toast} = useStatusToast()
  const {isOpen, onOpen, onClose: closeModal} = useDisclosure()

  const [isMessageValid, setIsMessageValid] = useState(true)

  const [messageSettings, setMessageSettings] = useState<MessageSettingsState>({
    householdSettings: [messageSettingsOptions.householdOptions[0].ID],
    typeSetting: messageSettingsOptions.typeOptions[0].ID,
    statusSetting: messageSettingsOptions.statusOptions[0].ID,
  })

  const [messageText, setMessageText] = useState(DEFAULT_MESSAGE_TEXT)

  useEztCredValidation()

  const {
    totalCredits: creditsBalance,
    status: fetchTotalCreditsStatus,
    fetchTotalCredits,
  } = useEztCredits()

  const {
    validatedPersonIDs,
    creditsNeeded,
    status: fetchValidatedRecipientsStatus,
    fetchValidatedRecipients,
    reset: resetRecipients,
  } = useFormRemindersRecipients()

  const {
    status: sendReminderTextsStatus,
    lastMessageSentDetails,
    sendTexts,
    fetchLastMessageSentDetails,
  } = useTextMessaging()

  React.useEffect(() => {
    fetchLastMessageSentDetails()
  }, [fetchLastMessageSentDetails])

  React.useEffect(() => {
    if (
      fetchTotalCreditsStatus === 'rejected' ||
      fetchTotalCreditsStatus === 'notfound'
    ) {
      toast.error(copy.authorized.errors.fetchCreditError)
    }
  }, [fetchTotalCreditsStatus, toast])

  React.useEffect(() => {
    if (
      fetchValidatedRecipientsStatus === 'rejected' ||
      fetchValidatedRecipientsStatus === 'notfound'
    ) {
      toast.error(copy.authorized.errors.fetchValidatedRecipientsError)
    }
  }, [fetchValidatedRecipientsStatus, toast])

  React.useEffect(() => {
    if (sendReminderTextsStatus === 'failed') {
      toast.error(copy.authorized.errors.sendTextsFailure)
    }

    if (sendReminderTextsStatus === 'success') {
      toast.success(copy.authorized.success.sendTextsSuccess)
    }
  }, [sendReminderTextsStatus, toast])

  const insufficientCredits = (creditsBalance || 0) < (creditsNeeded || 0)

  const noRecipientsReturned =
    !creditsNeeded && fetchValidatedRecipientsStatus === 'success'

  const isStatusPending =
    fetchTotalCreditsStatus === 'pending' ||
    fetchValidatedRecipientsStatus === 'pending'

  const footerSendButtonDisabled =
    insufficientCredits || !creditsNeeded || !isMessageValid

  const footerCalculateButtonDisabled =
    !messageSettings.householdSettings.length || !isMessageValid

  const createChangeSettingsHandler =
    (group: keyof MessageSettingsState) =>
    (value: string[] | string): void => {
      setMessageSettings((settings) => ({
        ...settings,
        [group]: value,
      }))

      resetRecipients()
    }

  const fetchCreditsData = (): void => {
    const overDueSetting = messageSettings.statusSetting === 'overdue'

    let requiredSetting: boolean | null = null

    if (messageSettings.typeSetting === 'required') {
      requiredSetting = true
    } else if (messageSettings.typeSetting === 'non-required') {
      requiredSetting = false
    }

    // Add this data to GTM dataLayer so a GA4 event can use it to track event
    TagManager.dataLayer({
      dataLayer: {
        formType: messageSettings.typeSetting,
        formStatus: messageSettings.statusSetting,
      },
    })

    fetchTotalCredits()

    fetchValidatedRecipients({
      household1Parent1:
        messageSettings.householdSettings.includes('household1Parent1'),
      household1Parent2:
        messageSettings.householdSettings.includes('household1Parent2'),
      household2Parent1:
        messageSettings.householdSettings.includes('household2Parent1'),
      household2Parent2:
        messageSettings.householdSettings.includes('household2Parent2'),
      required: requiredSetting,
      overdue: overDueSetting,
    })
  }

  const handleSendTexts = async (): Promise<void> => {
    const payload = {
      personIDs: validatedPersonIDs,
      message: messageText,
    }

    await sendTexts(payload)

    resetRecipients()
    fetchTotalCredits()
    fetchLastMessageSentDetails()
    closeModal()
  }

  const pageHeader = (
    <PageHeader
      title={copy.default.header.title}
      logContext='FormsAuthorizedView'
      description={copy.default.header.description}
    />
  )

  return (
    <PageTemplate
      header={pageHeader}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Box borderTop='1px' margin={4} />
        <LabelledSectionBox label='1. Message Settings'>
          <MessageSettings
            createChangeSettingsHandler={createChangeSettingsHandler}
            houseHoldSettingsValue={messageSettings.householdSettings}
            typeSettingValue={messageSettings.typeSetting}
            statusSettingValue={messageSettings.statusSetting}
          />
        </LabelledSectionBox>

        <LabelledSectionBox label='2. Compose Message'>
          <ComposeMessage
            messageText={messageText}
            onChange={setMessageText}
            isMessageValid={isMessageValid}
            setIsMessageValid={setIsMessageValid}
          />
        </LabelledSectionBox>

        <LabelledSectionBox
          label='3. Send Message'
          footer={
            <CalculateCreditsDisplayFooter
              handleFetchCreditsData={fetchCreditsData}
              isStatusPending={isStatusPending}
              handleOnOpen={onOpen}
              sendButtonDisabled={footerSendButtonDisabled}
              calculateButtonDisabled={footerCalculateButtonDisabled}
              lastMessageSentDetails={lastMessageSentDetails}
            />
          }>
          <CalculateCreditsDisplay
            creditsBalance={creditsBalance}
            creditsNeeded={creditsNeeded}
            insufficientCredits={insufficientCredits}
            noRecipientsReturned={noRecipientsReturned}
          />
        </LabelledSectionBox>

        <SendFormRemindersModal
          handleSend={handleSendTexts}
          sendStatus={sendReminderTextsStatus}
          recipientsCount={creditsNeeded}
          isOpen={isOpen}
          onClose={closeModal}
        />
      </>
    </PageTemplate>
  )
}
