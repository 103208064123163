import React, {
  useEffect,
  useCallback,
  useReducer,
  createContext,
  useContext,
  useMemo,
} from 'react'
import {log} from '@vanguard/logger'
import {useHistory} from 'react-router-dom'

import {api} from '../../services/api'

import {useRequiredAuth} from '../../stores/AuthStore'
import {Album} from '../../types'
import {handleProcessPhotoSSE} from '../../services/api/photos/processPhotoSSE'

/* *********************************************** */
interface State {
  status: 'idle' | 'fetching' | 'resolved' | 'rejected' | 'notfound'
  data?: Album
  error?: string
}

type Action =
  | {
      type: 'SET_STATUS'
      payload: State['status']
    }
  | {
      type: 'FETCH_ALBUM_INIT'
    }
  | {
      type: 'FETCH_ALBUM_SUCCESS'
      payload: {
        data: Album
      }
    }
  | {
      type: 'FETCH_ALBUM_FAILURE'
      payload: {
        message: string
      }
    }
  | {
      type: 'SAVE_ALBUM'
      payload: Album
    }
  | {
      type: 'REMOVE_PHOTO'
    }
  | {
      type: 'PROCESS_SSE'
      payload: ReturnType<typeof handleProcessPhotoSSE>
    }
  | {
      type: 'SET_COVER_PHOTO'
      payload: Album['coverPhotoID']
    }
  | {
      type: 'RESET'
    }

const AlbumManagerStateContext = createContext<State | undefined>(undefined)
const AlbumManagerDispatchContext = createContext<
  React.Dispatch<Action> | undefined
>(undefined)

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_STATUS': {
      return {
        ...state,
        status: action.payload,
      }
    }
    case 'SAVE_ALBUM': {
      return {
        ...state,
        data: action.payload,
      }
    }
    case 'FETCH_ALBUM_INIT': {
      return {
        ...state,
        status: 'fetching',
      }
    }

    case 'FETCH_ALBUM_SUCCESS': {
      return {
        ...state,
        status: 'resolved',
        data: action.payload.data,
      }
    }

    case 'FETCH_ALBUM_FAILURE': {
      return {
        ...state,
        error: action.payload.message,
        status: 'rejected',
      }
    }
    case 'PROCESS_SSE': {
      const {
        payload: {photo},
      } = action
      if (!state.data) return state

      return {
        ...state,
        data: {
          ...state.data,
          photoCount: photo ? state.data.photoCount + 1 : state.data.photoCount,
        },
      }
    }
    case 'REMOVE_PHOTO': {
      if (!state.data) return state

      return {
        ...state,
        data: {
          ...state.data,
          photoCount: state.data.photoCount - 1,
        },
      }
    }

    case 'SET_COVER_PHOTO': {
      if (!state.data) return state

      return {
        ...state,
        data: {
          ...state.data,
          coverPhotoID: action.payload,
        },
      }
    }

    case 'RESET': {
      return {
        ...state,
        status: 'idle',
      }
    }

    default:
      throw new Error('Unhandled action')
  }
}

interface Props {
  ID: Album['ID']
}

/* *********************************************** */
export const AlbumManager: React.FC<React.PropsWithChildren<Props>> = ({
  ID,
  children,
}) => {
  const {client} = useRequiredAuth()
  const history = useHistory()

  const initialState: State = {
    status: 'idle',
    data: undefined,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // Fetch Album
  const fetchData = useCallback(
    async (didCancel = false) => {
      dispatch({type: 'FETCH_ALBUM_INIT'})

      const response = await api.albums.getAlbum({
        clientID: client.ID,
        ID,
      })
      if (!didCancel) {
        if (response.result === 'failure') {
          if (response.error.message === 'notfound') {
            dispatch({
              type: 'SET_STATUS',
              payload: 'notfound',
            })
          } else {
            dispatch({
              type: 'FETCH_ALBUM_FAILURE',
              payload: {
                message: 'Fetch Album Failed',
              },
            })
          }
        }
        if (response.result === 'success') {
          dispatch({
            type: 'FETCH_ALBUM_SUCCESS',
            payload: {data: response.data},
          })
        }
      }
    },
    [client, ID],
  )

  // Fetch Album when mounted
  useEffect(() => {
    let didCancel = false

    if (state.status === 'idle') {
      fetchData(didCancel)
    }

    return (): void => {
      didCancel = true
    }
  }, [state.status, fetchData])

  // Album Not Found
  useEffect(() => {
    if (state.status === 'notfound') {
      log.breadcrumb('Album Not Found')
      history.replace('/albumnotfound') // Replace, don't push so that going back doesn't take user to page URL
    }
  }, [state.status, history])

  const stateReturn = useMemo(
    () => ({
      ...state,
    }),
    [state],
  )

  return (
    <AlbumManagerStateContext.Provider value={stateReturn}>
      <AlbumManagerDispatchContext.Provider value={dispatch}>
        {children}
      </AlbumManagerDispatchContext.Provider>
    </AlbumManagerStateContext.Provider>
  )
}

export const useAlbumState = (): State => {
  const context = useContext(AlbumManagerStateContext)
  if (!context) {
    throw new Error(
      'useAlbumsState must be used within a AlbumManagerStateContextProvider',
    )
  }

  return context
}

export const useAlbumDispatch = (): React.Dispatch<Action> => {
  const context = useContext(AlbumManagerDispatchContext)
  if (!context) {
    throw new Error(
      'useAlbumDispatch must be used within a AlbumManagerStateContextProvider',
    )
  }

  return context
}
