import React from 'react'
import {useHistory} from 'react-router-dom'

export const usePreventTabNavigation = (enabled = true): void => {
  const history = useHistory()
  React.useEffect(() => {
    const unblock = enabled
      ? history.block('Are you sure you want to leave this page?')
      : // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}

    return () => {
      unblock()
    }
  }, [enabled, history])
}
