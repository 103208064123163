import {AxiosRequestConfig} from 'axios'
import {HttpInstance} from './createHttpInstance'

interface TransformRequest {
  (
    http: HttpInstance,
    transformFn: (request: AxiosRequestConfig) => AxiosRequestConfig,
  ): void
}

export const transformRequests: TransformRequest = (http, transformFn) => {
  http.interceptors.request.use(transformFn)
}
