import {AxiosResponse} from 'axios'
import {DeleteRequest, DeleteResponse} from './types'
import {HttpInstance} from '../createHttpInstance'

export interface Delete<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const deleteRawPhoto: Delete<DeleteRequest, DeleteResponse> = (
  http,
  params,
) => {
  const {ClientID, ID} = params

  const path = '/v1/photos/{id}'.replace('{id}', ID.toString())

  return http.request<DeleteResponse>({
    method: 'DELETE',
    url: `${path}?clientid=${ClientID}`,
  })
}
