import React, {useState} from 'react'
import {
  FormControl,
  FormLabel,
  forwardRef,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react'
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons'
import {FormInputProps} from '../FormInput/FormInput'

export const FormInputPassword: React.FC<FormInputProps> = forwardRef(
  (
    {
      label,
      labelFontSize = '2xl',
      labelFontWeight = 'normal',
      labelPaddingTop = '',
      labelPaddingLeft = '',
      labelPaddingBottom = '',
      labelPaddingRight = '',
      autoComplete = 'current-password',
      id,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <FormControl d='flex' flexDir='column'>
        <FormLabel
          htmlFor={id}
          fontSize={labelFontSize}
          fontWeight={labelFontWeight}
          paddingTop={labelPaddingTop}
          paddingLeft={labelPaddingLeft}
          paddingBottom={labelPaddingBottom}
          paddingRight={labelPaddingRight}>
          {label}
        </FormLabel>
        <InputGroup>
          <Input
            id={id}
            type={showPassword ? 'text' : 'password'}
            autoComplete={autoComplete}
            roundedRight={0}
            ref={ref}
            {...rest}
          />
          <InputRightAddon
            cursor='pointer'
            onClick={(): void => {
              setShowPassword((sp) => !sp)
            }}>
            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
          </InputRightAddon>
        </InputGroup>
      </FormControl>
    )
  },
)

FormInputPassword.displayName = 'FormInputPassword'
