import React from 'react'
import {
  Flex,
  Box,
  Stack,
  Collapse,
  RadioGroup,
  Radio,
  Text,
} from '@chakra-ui/react'
import {ChevronLeftIcon} from '@chakra-ui/icons'
import {log} from '@vanguard/logger'
import moment, {Moment} from 'moment'

import copy from './copy.json'
import {
  useComposeAlbumActions,
  useComposeAlbumState,
} from './contextManagers/ComposeAlbumManager'
import {AlbumSortType, PropsOf} from '../../types'
import {INSERT_PLACEHOLDER_ID} from '../../constants'
import {ActionButton} from '../ActionButton/ActionButton'
import {StatusReturn, useAlbumService} from '../../services/useAlbumService'
import {AlbumDateControl} from './AlbumDateControl'
import {useModalContext} from './subcomps/ComposeAlbumModalContext'
import {PublishTimePicker} from './subcomps/PublishTimePicker'
import {useConstraints} from './contextManagers/ConstraintManager'
import {Visible} from '../Visible/Visible'

const Header: React.FC<React.PropsWithChildren> = () => {
  const {setCurrentStep} = useModalContext()
  return (
    <Flex flex={1}>
      <ChevronLeftIcon
        data-testid='back-icon'
        aria-label='back'
        boxSize='30px'
        onClick={(): void => {
          setCurrentStep('compose')
        }}
      />
      <Box textAlign='center' flex={1}>
        Publishing
      </Box>
    </Flex>
  )
}

const Body: React.FC<React.PropsWithChildren> = () => {
  type ScheduleState = 'publish' | 'draft' | 'scheduled'

  const {publishDate, isDraft} = useComposeAlbumState()
  const {setValue} = useComposeAlbumActions()

  const getNextValidTime = (newPublishDate?: Date | null): Moment => {
    let publishTime = moment(newPublishDate || moment())
      .minute(Math.ceil(moment().minute() / 15) * 15)
      .second(0)
      .millisecond(0)

    // if the next 15 minute interval is less than 15 minutes from the current time, add another 15 minutes
    publishTime = publishTime.isBefore(moment().add(15, 'minute'))
      ? publishTime.add(15, 'minute')
      : publishTime

    return publishTime
  }

  const originalPublishDate = React.useRef(getNextValidTime(publishDate))

  const scheduleState: ScheduleState = React.useMemo(() => {
    if (isDraft) {
      return 'draft'
    }
    if (publishDate) {
      return 'scheduled'
    }
    return 'publish'
  }, [isDraft, publishDate])

  type ScheduleOption = {
    title: string
    description: string
    testID: string
    value: ScheduleState
  }

  const options: ScheduleOption[] = [
    {
      title: copy.steps.schedule.options.schedulenow.title,
      description: copy.steps.schedule.options.schedulenow.description,
      testID: 'schedule-select-now',
      value: 'publish',
    },
    {
      title: copy.steps.schedule.options.saveasdraft.title,
      description: copy.steps.schedule.options.saveasdraft.description,
      testID: 'schedule-select-draft',
      value: 'draft',
    },
    {
      title: copy.steps.schedule.options.scheduleforlater.title,
      description: copy.steps.schedule.options.scheduleforlater.description,
      testID: 'schedule-select-later',
      value: 'scheduled',
    },
  ]

  const handleScheduleChange = (currentSelection: ScheduleState): void => {
    switch (currentSelection) {
      case 'publish':
        setValue('isDraft', false)
        setValue('publishDate', null)
        break
      case 'draft':
        setValue('isDraft', true)
        setValue('publishDate', null)
        break
      case 'scheduled':
        setValue('isDraft', false)
        setValue(
          'publishDate',
          new Date(
            `${originalPublishDate.current.format('LL HH:mm')}
            `,
          ),
        )
        break
      default:
        break
    }
  }

  const handleDateControlChange: PropsOf<
    typeof AlbumDateControl
  >['onChange'] = (newDate): void => {
    if (newDate !== null) {
      setValue(
        'publishDate',
        new Date(
          `${moment(newDate).format('LL')} ${
            moment(publishDate).format('HH:mm') || null
          }`,
        ),
      )
    }
  }

  const handleTimeControlChange = (militaryTime: string): void => {
    setValue(
      'publishDate',
      new Date(`${moment(publishDate).format('LL')} ${militaryTime || null}`),
    )
  }

  return (
    <>
      <Text pt='24px' fontSize='lg' fontWeight={500}>
        {copy.steps.schedule.title}
      </Text>
      <Text fontSize='sm' color='#69747D'>
        {copy.steps.schedule.description}
      </Text>

      <RadioGroup
        mt='30px'
        onChange={handleScheduleChange}
        value={scheduleState}>
        <Stack direction='column'>
          {options.map((option) => (
            <Flex as='label' key={option.title}>
              <Flex direction='column' flexGrow={2}>
                <Text fontSize='lg' fontWeight='bold'>
                  {option.title}
                </Text>
                <Text mb='10px' fontSize='sm' color='#69747D'>
                  {option.description}
                </Text>
              </Flex>
              <Flex ml='30px'>
                <Radio
                  data-testid={option.testID}
                  width='100%'
                  value={option.value}
                  size='lg'
                  colorScheme='secondary'
                />
              </Flex>
            </Flex>
          ))}
        </Stack>
      </RadioGroup>
      <Box mb='30px'>
        <Collapse
          in={scheduleState === 'scheduled'}
          animateOpacity
          // below fixes a ChakraUI bug where collapse overflow is hidden so cuts off the date picker. revist later to see if fixed
          style={{overflow: 'initial'}}>
          <Flex direction={['column', 'row']} justifyContent='space-between'>
            <AlbumDateControl
              value={moment(publishDate || new Date())}
              onChange={handleDateControlChange}
              width={['100%', '50%']}
              isOutsideRange={(day: Moment): boolean =>
                day.isBefore(moment().subtract(1, 'd'))
              }
            />
            <PublishTimePicker
              value={moment(publishDate || moment())}
              onChange={handleTimeControlChange}
              minuteStep={15}
              pl={1}
              width={['100%', '50%']}
            />
          </Flex>
          <Visible
            when={moment(
              publishDate || moment().second(0).millisecond(0),
            ).isBefore(moment().second(0).millisecond(0).add(15, 'minute'))}>
            <Flex height={9}>
              <Text fontSize='14px' color='#E05535'>
                {copy.warning.scheduleForLater}
              </Text>
            </Flex>
          </Visible>
        </Collapse>
      </Box>
    </>
  )
}

export interface FooterProps {
  handleReponse: (response: StatusReturn, sortType: AlbumSortType) => void
}

const Footer: React.FC<FooterProps> = ({handleReponse}) => {
  const album = useComposeAlbumState()
  const {areConstraintsChanged} = useConstraints()

  const [isLoading, setIsLoading] = React.useState(false)

  const {setValue} = useComposeAlbumActions()

  const isTimeValid = (): boolean =>
    moment(album.publishDate).isSameOrAfter(
      moment().second(0).millisecond(0).add(15, 'minute'),
    )

  const areInputsValid = (): boolean =>
    !!album.name &&
    album.name.length > 0 &&
    !Number.isNaN(album.date.getTime()) &&
    (!album.publishDate || isTimeValid())

  const isEditMode = album.ID !== INSERT_PLACEHOLDER_ID

  const {upsert} = useAlbumService()

  const handleOnSubmit = async (): Promise<void> => {
    // Run one more time check on submit to make sure the time is still on or after 15 minutes
    if (!album.publishDate || isTimeValid()) {
      setIsLoading(true)
      log.breadcrumb('Submit', album)

      const response = await upsert(
        {...album},
        {
          shouldUpdateConstraints: areConstraintsChanged,
        },
      )

      setIsLoading(false)
      handleReponse(response, album.sortType)
    } else {
      // Trigger a state update to force components to re-evaluate scheduling time
      setValue('publishDate', album.publishDate)
    }
  }

  return (
    <Flex flexDir='row' justifyContent={['center', 'flex-end']} width='100%'>
      <ActionButton
        width={['100%', 'unset']}
        isDisabled={!areInputsValid()}
        isLoading={isLoading}
        onClick={handleOnSubmit}>
        {!isEditMode
          ? copy.buttons.primaryAction.create
          : copy.buttons.primaryAction.save}
      </ActionButton>
    </Flex>
  )
}

export const ScheduleStepUI = {
  Header,
  Body,
  Footer,
}
