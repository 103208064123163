import {http} from '../http'
import * as coreEzTexting from '../../coreApi/eztexting'
import {APIResponse} from '../types'

interface Params {
  clientID: string
  personID: string
  personIDs: number[]
  message: string
}

export type ResolvedReturn = APIResponse<null>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

export const sendTextMessage: ServiceMethod = async ({
  clientID,
  personID,
  personIDs,
  message,
}) => {
  const rawParamMap = {
    ClientID: parseInt(clientID, 10),
    PersonID: parseInt(personID, 10),
    PersonIDs: personIDs,
    Message: message,
  }

  // Not try/catching here because this is used in React Query's mutation method that needs to retry.
  // Only rejected promises will cause it to retry
  const response = await coreEzTexting.send(http, rawParamMap)

  if (!response || !response.data) {
    throw new Error('Unsuccessful Send Text Message Request')
  }

  const resolvedReturn: ResolvedReturn = {
    result: 'success',
    data: null,
  }

  return Promise.resolve(resolvedReturn)
}
