import * as React from 'react'
import {Flex, Fade, Text} from '@chakra-ui/react'

interface FilenameOverlayProps {
  filename: string | null
  margin: number
  clickRef?: React.RefObject<HTMLImageElement>
  visible: boolean
  // setVisible: (vis: boolean) => void
}

export const FilenameOverlay: React.FC<FilenameOverlayProps> = ({
  filename,
  margin,
  clickRef,
  visible,
}) => (
  <Flex
    position='absolute'
    top={0}
    left={0}
    height='100%'
    width='100%'
    onClick={(): void => {
      if (clickRef && clickRef.current) {
        clickRef.current.click()
      }
    }}>
    <Fade
      in={visible && !!filename}
      style={{
        maxWidth: '100%',
        width: '100%',
        padding: margin,
      }}
      data-testid='overlay-gradient-fade'>
      <Flex
        height='100%'
        width='100%'
        maxWidth='100%'
        direction='column'
        justifyContent='flex-end'
        background='linear-gradient(black 0%, transparent 30%, transparent 70%, black 100%)'>
        <Text
          color='white'
          margin={margin}
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='clip'>
          {filename}
        </Text>
      </Flex>
    </Fade>
  </Flex>
)
