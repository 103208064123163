import React from 'react'
import {Divider, Flex} from '@chakra-ui/react'
import * as filestack from 'filestack-js'
import {useParams, useHistory} from 'react-router-dom'

import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {PageHeader, PhotoSelector} from '../../components'

import {AlbumViewManager} from '../AlbumView/AlbumViewManager'
import {AlbumManager} from '../AlbumView/AlbumManager'
import {AlbumPhotosManager} from '../AlbumView/AlbumPhotosManager'

import {copy} from './copy'

export const PhotoUploaderView: React.FC<React.PropsWithChildren> = () => {
  const {id: albumID} = useParams<{id: string}>()
  const history = useHistory()

  return (
    <AlbumViewManager>
      <AlbumManager ID={albumID}>
        <AlbumPhotosManager ID={albumID}>
          <PageTemplate
            header={
              <PageHeader
                title={copy.PhotoUploaderPageHeader}
                logContext='PhotoUploaderView'
                showBackButton
                backButtonText='Back to Album'
              />
            }
            width={['100%']}
            maxWidth={['1030px']}
            borderX={['0px']}>
            <Flex
              height='100%'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'>
              <Flex width='100%' p={[4]}>
                {copy.PhotoUploaderInstructions}
              </Flex>
              <Divider
                mb='4'
                mt={1}
                opacity={1}
                border-bottom='1px solid'
                mx={4}
                width='unset'
              />
              <Flex
                id='divContainer'
                ml={4}
                data-testid='divContainer'
                height='50vh'
              />
              <PhotoSelector
                container='divContainer'
                albumID={albumID}
                openPickerByDefault
                displayMode={filestack.PickerDisplayMode.inline}
                modalSize={[1029, 500]}
                onDone={() => {
                  if (
                    history.location.pathname === `/album/${albumID}/upload`
                  ) {
                    history.goBack()
                  }
                }}
              />
            </Flex>
          </PageTemplate>
        </AlbumPhotosManager>
      </AlbumManager>
    </AlbumViewManager>
  )
}
