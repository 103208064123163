import {AxiosResponse} from 'axios'
import {ListRequest, Photo} from './types'
import {RawPagedCollectionResponse} from '../types'
import {HttpInstance} from '../createHttpInstance'

// export interface List<P, R> {
//   (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
// }

// export const list: List<ListRequest, RawPagedCollectionResponse<Photo>> = (
//   http,
//   params,
// ) => {
//   const {ClientID, PageNumber, PageSize, AlbumID} = params

//   const path = `/v1/photos`

//   return http.request<RawPagedCollectionResponse<Photo>>({
//     method: 'GET',
//     url: `${path}?clientid=${ClientID}&pagenumber=${PageNumber}&pagesize=${PageSize}&albumid=${AlbumID}`,
//   })
// }

export interface List<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const list: List<ListRequest, RawPagedCollectionResponse<Photo>> = (
  http,
  params,
) => {
  const {ClientID, PageNumber, PageSize, ID, AlbumID} = params

  const path = '/v1/photos'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  if (ID !== undefined) {
    for (let i = 0; i < ID.length; i += 1) {
      queryParams.push(`id=${ID[i]}`)
    }

    // ID.forEach((id) => {
    //   queryParams.push(`id=id`)
    // })
  }
  if (AlbumID !== undefined) {
    queryParams.push(`albumid=${AlbumID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<RawPagedCollectionResponse<Photo>>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
