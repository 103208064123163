import {http} from '../http'
import * as forms from '../../coreApi/forms'
import {APIResponse} from '../types'
import {Recipients, ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
  household1Parent1?: boolean
  household1Parent2?: boolean
  household2Parent1?: boolean
  household2Parent2?: boolean
  required?: boolean | null
  overdue?: boolean | null
}

type ResolvedReturn = APIResponse<Recipients>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.forms.getFormApplicability()'
const GENERIC_ERROR =
  'Something went wrong while getting Ez Texting recipients. Please try again.'

export const getFormApplicability: ServiceMethod = async (params) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: Number(params.clientID),
      Household1Parent1: params.household1Parent1,
      Household1Parent2: params.household1Parent2,
      Household2Parent1: params.household2Parent1,
      Household2Parent2: params.household2Parent2,
      Required: params.required,
      Overdue: params.overdue,
    }

    const response = await forms.formApplicability(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Get Form Applicability')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        clientID: response.data.ClientID.toString(),
        personIDs: response.data.PersonIDs.map((personID) =>
          personID.toString(),
        ),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
