import React from 'react'
import {
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'
import {log, setLogContext} from '@vanguard/logger'

import {Modal} from './subcomps/Modal'
import {ModalStep} from './subcomps/ModalStep'
import {ComposeStepUI} from './ComposeStepUI'
import {SegmentStepUI} from './SegmentStepUI'
import {AudienceManager} from './contextManagers/AudienceManager'
import {PropsOf, SegmentGroup} from '../../../types'
import {TypeStepUI} from './TypeStepUI'

interface Props {
  initial: SegmentGroup | null
  onClose: PropsOf<typeof SegmentStepUI.Footer>['onClose']
  isOpen: boolean
}

export const ComposeAudienceModal: React.FC<React.PropsWithChildren<Props>> = ({
  initial,
  onClose,
  isOpen,
}) => {
  setLogContext('ComposeAudienceModal')

  const initialFocusRef = React.useRef<HTMLTextAreaElement>(null)

  return (
    <AudienceManager initial={initial}>
      <Modal
        isOpen={isOpen}
        steps={['compose', 'segment']}
        onClose={(): void => {
          log.breadcrumb('Close Modal')
          onClose()
        }}
        initialFocusRef={initialFocusRef}
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent
          data-testid='compose-content'
          borderRadius={['none', 'md']}
          my={[0, '2.5%']}
          minHeight={['100%', 'unset']}>
          <ModalHeader
            borderBottom='1px'
            borderColor='#DFE8ED'
            display='flex'
            justifyContent='space-between'>
            <ModalStep name='compose'>
              <ComposeStepUI.Header />
            </ModalStep>

            <ModalStep name='type'>
              <TypeStepUI.Header />
            </ModalStep>

            <ModalStep name='segment'>
              <SegmentStepUI.Header />
            </ModalStep>

            <ModalCloseButton title='close' position='initial' />
          </ModalHeader>

          <ModalBody
            paddingX={8}
            style={{overflowY: 'auto'}}
            maxHeight={['100%', '75vh']}>
            <ModalStep name='compose'>
              <ComposeStepUI.Body />
            </ModalStep>

            <ModalStep name='type'>
              <TypeStepUI.Body />
            </ModalStep>

            <ModalStep name='segment'>
              <Box position='relative'>
                <SegmentStepUI.Body />
              </Box>
            </ModalStep>
          </ModalBody>

          <ModalFooter borderTop='1px' borderColor='#DFE8ED'>
            <ModalStep name='compose'>
              <ComposeStepUI.Footer />
            </ModalStep>

            <ModalStep name='type'>
              <TypeStepUI.Footer />
            </ModalStep>

            <ModalStep name='segment'>
              <SegmentStepUI.Footer onClose={onClose} />
            </ModalStep>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AudienceManager>
  )
}
