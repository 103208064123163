import React, {ReactElement, useState, useEffect, useCallback} from 'react'
import {ExternalLinkIcon} from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
  Flex,
  CloseButton,
  Divider,
  Link,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {Column, Row} from 'react-table'

import copy from './copy.json'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {PageHeader} from '../../components/PageHeader/PageHeader'
import {
  ActionButton,
  BlankSlate,
  CustomIcon,
  LoadingState,
  PageControl,
  Table,
  ViewError,
  Visible,
} from '../../components'
import {
  EzTextingGroup,
  SegmentGroup,
  EzTextGroup,
  SyncResult,
  ValueOf,
} from '../../types'
import {useRequiredAuth} from '../../stores/AuthStore'
import {fetchSegments} from '../../services/api/segments/fetchSegments'
import {api} from '../../services/api'
import {useStatusToast} from '../../app/ToastManager'
import {ComposeGroupModal} from '../../components/ComposeGroupModal/ComposeGroupModal'
import {SummaryPanel} from './SummaryPanel/SummaryPanel'
import {simpleSort} from '../../utils/simpleSort'
import {UPR_URL, EZT_GROUP_URL} from '../../constants'
import {
  handleSSE,
  TopicSyncGroupComplete,
} from '../../services/api/eztexting/sse'
import {SSEMessage} from '../../services/coreApi/types'
import {useSSE} from '../../app/SSEProvider'
import {useEztCredValidation} from '../../services/useEztCredValidation'
import {appTheme} from '../../app/theme'
import {useStoredState} from '../../hooks'

const rehydrateGroup = (
  groupToHydrate: EzTextingGroup,
  audienceList: SegmentGroup[],
): EzTextGroup => ({
  ...groupToHydrate,
  audience: groupToHydrate?.audienceIDs?.length
    ? audienceList?.find((a) => a.ID === groupToHydrate.audienceIDs[0])?.name ||
      ''
    : '',
})

type Syncable<T extends object> = T & {
  syncing?: boolean
}

export const SyncResultStatus = {
  Complete: 'Successfully Synced',
  NotValidUSPhoneNumber: 'Not valid US phone number',
  InvalidUSNumber: 'Invalid US number',
  DuplicateNumber: 'Duplicate Number',
  NumberMissing: 'Number Missing',
  InvalidName: 'Invalid Name',
  SyncError: 'Sync Error',
  OptedOut: 'Opted Out',
  SuccessfullySynced: 'Successfully Synced',
} as const

export const EzTextingGroupView: React.FC<React.PropsWithChildren> = () => {
  const {id: groupID} = useParams<{id: string}>()
  const {client} = useRequiredAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [isSyncing, setIsSyncing] = useState(false)
  const [errorLoading, setErrorLoading] = useState(false)
  const [audiences, setAudiences] = useState<SegmentGroup[]>([])
  const [group, setGroup] = useState<EzTextGroup | null>(null)
  const [syncResults, setSyncResults] = useState<Syncable<SyncResult>[]>([])
  const [sort, setSort] = useState([{id: copy.columns.name, desc: false}])
  const {toast} = useStatusToast()
  const modalDisclosure = useDisclosure()
  const subscribe = useSSE()
  const pageSize = 25
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(0)

  const [localState, setLocalState] = useStoredState<string[]>(
    'eztGroup:attentionBannerDismissed',
  )

  useEztCredValidation()

  useEffect(() => {
    const initData = async (): Promise<void> => {
      const audiencesResponse = fetchSegments({
        clientID: client.ID,
        pageSize: 100,
        all: true,
      })
      const ezgroupResponse = api.eztexting.getGroup({
        clientID: client.ID,
        ID: groupID,
      })

      const [audiencesResult, groupResult] = await Promise.all([
        audiencesResponse,
        ezgroupResponse,
      ])
      if (
        audiencesResult.result === 'success' &&
        groupResult.result === 'success'
      ) {
        setAudiences(audiencesResult.data)
        setGroup(rehydrateGroup(groupResult.data, audiencesResult.data))
        setIsLoading(false)
        setIsSyncing(groupResult.data.syncing)
      } else {
        setErrorLoading(true)
      }
    }
    initData()
  }, [client.ID, groupID])

  const fetchData = useCallback(
    async (pageNum: number): Promise<void> => {
      if (pageNumber !== pageNum + 1) {
        setPageNumber(pageNum + 1)
      }
    },
    [pageNumber],
  )

  useEffect(() => {
    const f = async (): Promise<void> => {
      const syncResultsResult = await api.eztexting.fetchSyncResults({
        clientID: client.ID,
        groupID,
        pageSize,
        pageNumber,
        orderAscending: !sort[0].desc,
        orderBy: ((): Parameters<
          typeof api.eztexting.fetchSyncResults
        >[0]['orderBy'] => {
          switch (sort[0].id) {
            case copy.columns.number:
              return 'CellNumber'
            case copy.columns.status:
              return 'FriendlyMessage'
            case copy.columns.name:
            default:
              return 'LastName'
          }
        })(),
      })
      if (syncResultsResult.result === 'success') {
        setSyncResults(syncResultsResult.data.results)
        setPageCount(Math.ceil(syncResultsResult.data.total / pageSize))
      } else {
        setErrorLoading(true)
      }
    }
    f()
  }, [client.ID, groupID, pageNumber, sort])

  useEffect((): (() => void) => {
    if (!subscribe) {
      return (): void => {
        // don't unsubscribe before you can subscribe
      }
    }
    const unsubscribe = subscribe(
      TopicSyncGroupComplete,
      async (msg: SSEMessage) => {
        const event = handleSSE(msg)
        if (event.ezTextingGroup) {
          const g = rehydrateGroup(event.ezTextingGroup, audiences)
          setGroup(g)

          const syncResultsResult = await api.eztexting.fetchAllSyncResults({
            clientID: client.ID,
            groupID: g.ID,
          })
          if (syncResultsResult.result === 'success') {
            setSyncResults(syncResultsResult.data)
          } else {
            setErrorLoading(true)
          }

          if (event.syncDetails) {
            if (event.syncDetails.errors) {
              toast.warn(
                event.syncDetails.friendlyMessage ||
                  `${g.ez.name} synced with errors.`,
              )
            } else {
              toast.success(
                event.syncDetails.friendlyMessage ||
                  `Group ${g.ez.name} was successfully synced.`,
              )
            }
          }
        }
        setIsSyncing(false)
      },
    )
    return unsubscribe
  }, [audiences, client.ID, subscribe, toast])

  const onEditClose = async (g: EzTextGroup): Promise<void> => {
    setGroup(g)
    toast.success(`Group ${g.ez.name} was successfully updated.`)
  }

  const handleModalClose = (g?: EzTextingGroup): void => {
    if (g) {
      onEditClose(rehydrateGroup(g, audiences))
    }
    modalDisclosure.onClose()
  }

  const onGroupSync = async (): Promise<void> => {
    if (group == null) return
    setGroup({
      ...group,
      syncing: true,
    })

    if (localState !== null) {
      setLocalState(
        localState.filter((item: string): boolean => item !== groupID),
      )
    }

    setSyncResults([])

    const response = await api.eztexting.sync({
      ID: groupID,
      clientID: client.ID,
    })

    setIsSyncing(true)

    if (response.result === 'success') {
      toast.success(copy.success.sync)
    } else {
      toast.error(response.error.message)
    }
  }

  const onOpenEzTexting = (): void => {
    window.open(EZT_GROUP_URL + (group?.ezTextingID || ''))
  }

  const formatDate = (d: Date | string): string => {
    if (typeof d === 'string') {
      return d
    }
    return moment(d).format('MM/DD/YYYY, LT')
  }

  const formatSubtitle = (): ReactElement => {
    const dates: string[] = []
    if (group?.lastSynced)
      dates.push(`Group last synced: ${formatDate(group?.lastSynced)}`)
    if (group?.dateModified)
      dates.push(`Last modified: ${formatDate(group?.dateModified)}`)
    return (
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}>
        <Visible when={!!group?.ez.description}>
          {group?.ez.description}
          <br />
        </Visible>
        {dates.join(' | ')}
      </div>
    )
  }

  const pageHeader = (
    <PageHeader
      logContext='EzTextingGroupView'
      showBackButton
      backButtonText='Back to Texting'
      title={group?.ez.name || 'No Group'}
      description={formatSubtitle()}
      callToAction={
        <>
          <ActionButton
            variant='outline'
            icon='external-link'
            width={['100%', 'unset']}
            marginRight='0.5em'
            onClick={onOpenEzTexting}>
            EZ Texting
          </ActionButton>
          <ActionButton width={['100%', 'unset']} onClick={onGroupSync}>
            {copy.buttons.cta}
          </ActionButton>
        </>
      }
      controls={
        <PageControl
          icon='pen'
          onClick={modalDisclosure.onOpen}
          isDisabled={isSyncing}>
          {copy.buttons.edit}
        </PageControl>
      }
    />
  )

  const columns = React.useMemo<Column<Syncable<SyncResult>>[]>((): Column<
    Syncable<SyncResult>
  >[] => {
    const statusMap: {
      [key in ValueOf<typeof SyncResultStatus>]: {
        color: string
        fixable: boolean
        sortKey: number
      }
    } = {
      [SyncResultStatus.NotValidUSPhoneNumber]: {
        color: appTheme.colors.attention[300],
        fixable: true,
        sortKey: 3.21,
      },
      [SyncResultStatus.InvalidUSNumber]: {
        color: appTheme.colors.attention[300],
        fixable: true,
        sortKey: 3.22,
      },
      [SyncResultStatus.DuplicateNumber]: {
        color: appTheme.colors.attention[300],
        fixable: true,
        sortKey: 3.4,
      },
      [SyncResultStatus.NumberMissing]: {
        color: appTheme.colors.attention[300],
        fixable: true,
        sortKey: 3.3,
      },
      [SyncResultStatus.InvalidName]: {
        color: appTheme.colors.attention[300],
        fixable: true,
        sortKey: 3.1,
      },
      [SyncResultStatus.SyncError]: {
        color: appTheme.colors.error[300],
        fixable: false,
        sortKey: 4,
      },
      [SyncResultStatus.OptedOut]: {
        color: appTheme.colors.gray[200],
        fixable: false,
        sortKey: 2,
      },
      [SyncResultStatus.SuccessfullySynced]: {
        color: appTheme.colors.success[300],
        fixable: false,
        sortKey: 1,
      },
    }
    const canBeFixed = (status: string): boolean => !!statusMap[status]?.fixable
    const colorOf = (status: string): string =>
      statusMap[status]?.color || appTheme.colors.gray[200]
    const sortKey = (status: string): number => statusMap[status]?.sortKey || 3
    const sortStatus = <T extends object>(
      ra: Row<T>,
      rb: Row<T>,
      columnID: string,
    ): number =>
      simpleSort(sortKey(ra.values[columnID]), sortKey(rb.values[columnID]))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statusAction = (cell: any): ReactElement => (
      <Visible when={!!cell.value}>
        <Flex justifyContent='space-between' flexGrow={[1]} flexDirection='row'>
          <div>
            <Tag
              flex={[1]}
              variant='solid'
              borderRadius='full'
              backgroundColor={colorOf(cell.value)}
              fontWeight='400'
              color='#333333'
              mr='0.5em'
              size='sm'
              whiteSpace='nowrap'>
              {cell.value}
            </Tag>
          </div>
          <Visible when={canBeFixed(cell.value)}>
            <Box>
              <Link
                href={UPR_URL + cell.row.original.personID}
                isExternal
                fontWeight='500'
                color='secondary.500'
                _focus={{
                  boxShadow: '0',
                }}
                display={['none', 'initial']}>
                <span style={{paddingRight: '.5em'}}>Fix in UPR</span>
                <span style={{verticalAlign: 'bottom'}}>
                  <ExternalLinkIcon boxSize='24px' />
                </span>
              </Link>
            </Box>
          </Visible>
        </Flex>
      </Visible>
    )

    const onSync = async (
      selectedResult: Syncable<SyncResult>,
    ): Promise<void> => {
      setSyncResults((results) =>
        results.map((result) => {
          if (result.ID !== selectedResult.ID) {
            return result
          }
          return {
            ...result,
            syncing: true,
          }
        }),
      )

      const response = await api.eztexting.syncIndividual({
        groupID,
        personID: selectedResult.personID,
      })

      if (response.result !== 'success') {
        toast.error(response.error.message)
        return
      }

      setSyncResults((results) =>
        results.map((result) => {
          if (result.ID !== selectedResult.ID) {
            return result
          }
          return {
            ...response.data,
            syncing: false,
            ID: result.ID,
          }
        }),
      )

      if (sortKey(response.data.friendlyMessage) === 4) {
        toast.error(copy.errors.individualSync)
      } else if (Math.floor(sortKey(response.data.friendlyMessage)) === 3) {
        toast.warn(copy.warnings.individualSync)
      } else {
        toast.success(copy.success.individualSync)
      }

      const groupResponse = await api.eztexting.getGroup({
        clientID: client.ID,
        ID: groupID,
      })
      if (groupResponse.result === 'success') {
        setGroup(rehydrateGroup(groupResponse.data, audiences))
      } else {
        toast.error(copy.errors.getGroup)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const syncAction = (cell: any): ReactElement => (
      <Visible when={sortKey(cell.row.original.friendlyMessage) >= 3}>
        <Button
          variant='link'
          color='secondary.500'
          isLoading={cell.value}
          loadingText={window.innerWidth < 480 ? '' : 'Syncing'}
          onClick={(): Promise<void> => onSync(cell.row.original)}
          data-testid='individualSyncButton'
          _focus={{
            boxShadow: '0',
          }}>
          <CustomIcon name='sync' boxSize='24px' />
          <span style={{paddingLeft: '.5em'}}>
            {window.innerWidth < 480 ? '' : 'Sync'}
          </span>
        </Button>
      </Visible>
    )

    const cols: Column<Syncable<SyncResult>>[] = [
      {
        id: copy.columns.name,
        Header: copy.columns.name,
        accessor: 'name',
        width: ((): string => (window.innerWidth < 730 ? '30%' : '25%'))(),
        style: {
          // flexGrow: 1,
          padding: '0',
        },
        cellStyle: {
          // flexGrow: 1,
          lineHeight: '20px',
          padding: '0',
        },
      },
      {
        id: copy.columns.number,
        Header: copy.columns.number,
        accessor: 'cellNumber',
        width: ((): string => (window.innerWidth < 730 ? '20%' : '45%'))(),
        style: {
          // flexGrow: 1,
          padding: '0',
        },
        cellStyle: {
          // flexGrow: 1,
          lineHeight: '24px',
          padding: '0',
        },
        hideWhileUnder: 600,
      },
      {
        id: copy.columns.status,
        Header: copy.columns.status,
        accessor: 'friendlyMessage',
        Cell: statusAction,
        width: ((): string => (window.innerWidth < 480 ? '70%' : '30%'))(),
        minWidth: ((): number => (window.innerWidth < 480 ? 100 : 315))(),
        style: {
          // flexGrow: 1,
        },
        cellStyle: {
          // flexGrow: 1,
          // width: '50%',
        },
        sortType: sortStatus,
      },
      {
        id: 'syncing',
        Header: '',
        accessor: 'syncing',
        width: '12%',
        minWidth: window.innerWidth < 480 ? 40 : 90,
        Cell: syncAction,
        cellStyle: {
          color: appTheme.colors.secondary[500],
        },
        disableSortBy: true,
      },
    ]

    return cols
  }, [audiences, client.ID, groupID, toast])

  const shouldShowAlert = useCallback((): boolean => {
    if (!groupID) {
      return false
    }
    const hasFailures = !!group?.result.failureCount
    if (!localState || !localState.includes) {
      return hasFailures
    }
    const hasBeenDismissed = localState.includes(groupID)

    // console.log(`JSON.stringify(localState): ${JSON.stringify(localState)}`)
    // console.log(`!hasBeenDismissed: ${!hasBeenDismissed}`)
    // console.log(`hasFailures: ${hasFailures}`)

    return !hasBeenDismissed && hasFailures
  }, [group, groupID, localState])

  return (
    <PageTemplate
      header={pageHeader}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Flex direction='column' px='.75em' py={1} pt={2}>
          <Divider mb='4' opacity={1} border-bottom='1px solid' mx='0.2em' />
          <Visible when={errorLoading}>
            <ViewError>
              <Text>{copy.errors.loading}</Text>
              <Text>{copy.errors.loadingAction}</Text>
            </ViewError>
          </Visible>
          <Visible when={!errorLoading}>
            <Visible when={isLoading}>
              <LoadingState>{copy.loading}</LoadingState>
            </Visible>
            <Visible when={isSyncing}>
              <LoadingState>{copy.syncing}</LoadingState>
            </Visible>
            <Visible when={!syncResults.length && !isLoading && !isSyncing}>
              <BlankSlate iconName='sync'>{copy.blankSlate}</BlankSlate>
            </Visible>
            <Visible when={!!syncResults.length && !isLoading && !isSyncing}>
              <Visible when={shouldShowAlert()}>
                <Alert
                  status='error'
                  borderWidth='1px'
                  borderStyle='solid'
                  borderRadius='4px'
                  borderColor={appTheme.colors.error[500]}
                  backgroundColor={appTheme.colors.error[100]}
                  p={['1em']}>
                  <AlertIcon
                    // name='info' see what to do about this
                    color={appTheme.colors.error[500]}
                    boxSize='3em'
                  />
                  <AlertDescription>{copy.banners.attention}</AlertDescription>
                  <CloseButton
                    data-testid='alertCloseButton'
                    onClick={(): void => {
                      if (localState === null) {
                        setLocalState(new Array<string>(groupID))
                      } else {
                        setLocalState([...localState, groupID])
                      }
                    }}
                  />
                </Alert>
              </Visible>
              <SummaryPanel
                color={appTheme.colors.gray}
                columns={[
                  {
                    title: 'All Contacts',
                    value: group?.result?.resultCount?.toString() || '',
                  },
                  {
                    title: 'Accepted',
                    value: group?.result?.successCount?.toString() || '',
                  },
                  {
                    title: 'Not Accepted',
                    value: group?.result?.failureCount?.toString() || '',
                  },
                ]}
              />
              <Table<Syncable<SyncResult>>
                name='SyncResults'
                pageSize={pageSize}
                data={syncResults}
                columns={columns}
                sortBy={sort}
                setSort={setSort}
                manualPagination
                fetchData={fetchData}
                pageCount={pageCount}
              />
            </Visible>
          </Visible>
        </Flex>
        <Visible when={modalDisclosure.isOpen}>
          <ComposeGroupModal
            clientID={client.ID}
            onClose={handleModalClose}
            initial={group}
            audiences={audiences}
          />
        </Visible>
      </>
    </PageTemplate>
  )
}
