import {decode} from 'he'
import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'

import {APIResponse, PagedCollectionResponse} from '../types'
import {Album} from '../../../types'
import {handleError} from '../handleError'
import {translateAlbumSortType} from './utils/translateAlbumSortType'

interface Params {
  pageNumber: number
  pageSize: number
  clientID: string
}

type ResolvedReturn = APIResponse<PagedCollectionResponse<Album>>

export interface FetchAlbums {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.fetchAlbums()'
const GENERIC_ERROR =
  'Something went wrong while fetch your albums. Please try again.'

export const fetchAlbums: FetchAlbums = async ({
  pageNumber,
  pageSize,
  clientID,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      PageNumber: pageNumber,
      PageSize: pageSize,
      IncludeEmpty: true,
      IncludePrivate: true,
      IncludeDraft: true,
      IncludeUnpublished: true,
      IncludeConstraints: true,
    }

    const response = await coreAlbums.fetchAlbums(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Fetch Albums')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        total: response.data.TotalCount,
        nextURL: response.data.Next,
        results: response.data.Results.map((result) => ({
          ID: result.ID.toString(),
          coverPhotoID: result.CoverPhotoID
            ? result.CoverPhotoID.toString()
            : '0',
          date: new Date(result.Date),
          name: decode(result.Name.toString()),
          photoCount: result.PhotoCount,
          isPrivate: result.IsPrivate,
          season: result.Season,
          sortType: translateAlbumSortType(result.SortTypeID),
          isDraft: result.IsDraft,
          publishDate: result.PublishDate ? new Date(result.PublishDate) : null,
          constraints: (result.Constraints || []).map((constraint) => ({
            type: constraint.Type,
            constraintID: constraint.ConstraintID.toString(),
          })),
        })),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
