import {transformGroup} from './group.transform'
import {http} from '../http'
import * as coreEzTexting from '../../coreApi/eztexting'
import {APIResponse} from '../types'
import {ResourceID, EzTextingGroup} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
  ID: EzTextingGroup['ID']
}

type ResolvedReturn = APIResponse<EzTextingGroup>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.getGroup()'
const GENERIC_ERROR =
  'Something went wrong while getting your group. Please try again.'

export const getGroup: ServiceMethod = async ({clientID, ID}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const response = await coreEzTexting.getGroup(http, {
      ClientID: parseInt(clientID, 10),
      ID: parseInt(ID, 10),
    })
    if (!response || !response.data) {
      throw new Error('Unsuccessful Get Group')
    }
    resolvedReturn = {
      result: 'success',
      data: transformGroup(response.data),
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
