import React from 'react'
import {Flex, Text, Textarea} from '@chakra-ui/react'

import {MAX_CONTENT_CHARS} from '../../../constants'
import {useModalContext} from './Modal'
import {
  useComposeMicropostActions,
  useComposeMicropostState,
} from '../contextManagers/MicropostManager'
import copy from '../copy.json'

type FlexProps = React.ComponentProps<typeof Flex>

export const ContentControl: React.FC<FlexProps> = (props) => {
  const {content} = useComposeMicropostState()
  const {handleInputChange} = useComposeMicropostActions()
  const {initialFocusRef} = useModalContext()

  const handleContentTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    e.currentTarget.value = e.currentTarget.value.replace(/[\r\n]/g, '') // prevent new lines

    if (e.currentTarget.value.length <= MAX_CONTENT_CHARS) {
      handleInputChange(e)
    }
  }

  return (
    <Flex {...props} direction='column' h='100%'>
      <Textarea
        name='content'
        placeholder={copy.inputs.content.placeholder}
        ref={initialFocusRef as React.RefObject<HTMLTextAreaElement>}
        value={content}
        onChange={handleContentTextChange}
        border={0}
        resize='none'
        size='md'
        h={['100%', '10rem']}
      />
      <Text py={3} color='#69747D'>
        {MAX_CONTENT_CHARS} character max
      </Text>
    </Flex>
  )
}
