import React from 'react'

import {MicropostManager} from '../contextManagers/MicropostManager'
import {SegmentManager} from '../contextManagers/SegmentManager'
import {AttachmentControlsManager} from '../contextManagers/AttachmentControlsManager'

import {Micropost} from '../../../types'

interface Props {
  initial: Micropost | null
}

export const StateWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  initial,
  children,
}) => (
  <MicropostManager initial={initial}>
    <AttachmentControlsManager
      initial={{
        hyperlinkURL: initial?.hyperlinkURL,
        videoURL: initial?.videoURL,
        imageURL: initial?.imageURL,
      }}>
      <SegmentManager
        initial={{
          segmentID: initial?.segmentID,
        }}>
        {children}
      </SegmentManager>
    </AttachmentControlsManager>
  </MicropostManager>
)
