import {
  QueryFunction,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query'
import {useInfiniteQueryUntilEnd} from '../hooks/useInfiniteQueryUntilEnd'
import {http} from '../services/api/http'
import {positions as fetchPositions} from '../services/coreApi/staff'
import {useRequiredAuth} from '../stores/AuthStore'

type Position = {
  ID: string
  name: string
}

type QueryFnData = {
  results: Position[]
  totalCount: number
  nextPage: string
}
type Client = ReturnType<typeof useRequiredAuth>['client'] // I think there is a better way with Generics, but don't have time to figure it out
type GetQueryOptions = (client: Client) => UseInfiniteQueryOptions<QueryFnData>
type QueryFn = (client: Client) => QueryFunction<QueryFnData>

const PAGE_SIZE = 100
const QUERY_STALE_TIME = 1000 * 60 * 30 // 30 minutes

const queryKeys = {
  all: () => [{resource: 'position'}] as const, // [{ resource: 'position'}]
  lists: () => [{...queryKeys.all()[0], type: 'list'}] as const, // [{ resource: 'position', type: 'list}]
  list: () => [{...queryKeys.all()[0], type: 'list'}] as const, // [{ resource: 'position', type: 'list,}]
}

const queryFn: QueryFn =
  (client) =>
  async ({pageParam = 1}) => {
    const response = await fetchPositions(http, {
      ClientID: Number(client.ID), // technically doesn't need to be apart of query key, but currently passing it for queryFn so kind of should be
      PageNumber: pageParam,
      PageSize: PAGE_SIZE,
    })

    const {Results, TotalCount, Next} = response.data

    return {
      results: Results.map((result) => ({
        ID: result.ID.toString(),
        name: result.Name,
      })),
      totalCount: TotalCount,
      nextPage: Next,
    }
  }

const getQueryOptions: GetQueryOptions = (client) => ({
  queryKey: queryKeys.list(),
  queryFn: queryFn(client),
  staleTime: QUERY_STALE_TIME,
  getNextPageParam: (lastPage, allPages) =>
    // If the last page has some real next URL string, then set the pageParam to the next page
    lastPage.nextPage !== '' ? allPages.length + 1 : undefined,
})

export const usePositions = (): UseInfiniteQueryResult<QueryFnData> => {
  const {client} = useRequiredAuth()

  return useInfiniteQueryUntilEnd<QueryFnData>(getQueryOptions(client))
}
