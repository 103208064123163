import {decode} from 'he'
import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {ResourceID, Album} from '../../../types'
import {handleError} from '../handleError'
import {translateAlbumSortType} from './utils/translateAlbumSortType'

interface Params {
  clientID: ResourceID
  ID: Album['ID']
}

type ResolvedReturn = APIResponse<Album>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.getAlbum()'
const GENERIC_ERROR =
  'Something went wrong while getting your album. Please try again.'

export const getAlbum: ServiceMethod = async ({clientID, ID}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      ID: parseInt(ID, 10),
    }

    const response = await coreAlbums.get(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Get Album')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        ID: response.data.ID.toString(),
        name: decode(response.data.Name),
        coverPhotoID: response.data.CoverPhotoID
          ? response.data.CoverPhotoID.toString()
          : null,
        date: new Date(response.data.Date.toString()),
        photoCount: response.data.PhotoCount,
        isPrivate: response.data.IsPrivate,
        season: response.data.Season,
        sortType: translateAlbumSortType(response.data.SortTypeID),
        isDraft: response.data.IsDraft,
        publishDate: response.data.PublishDate
          ? new Date(response.data.PublishDate)
          : null,
        constraints: (response.data.Constraints || []).map((constraint) => ({
          type: constraint.Type,
          constraintID: constraint.ConstraintID.toString(),
        })),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
