import React from 'react'
import {Box, Flex, Image, Text} from '@chakra-ui/react'

import copy from './copy.json'

import {PageHeader} from '../../components'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {useImagesContext} from '../../hooks/useImageContext'

export const MissingEZTextingView: React.FC<React.PropsWithChildren> = () => {
  const {images} = useImagesContext()

  const logo = images(`./formsReminders/${copy.paywall.images.logo}`)
  const screenShot = images(
    `./formsReminders/${copy.paywall.images.screenShot}`,
  )

  const pageHeader = (
    <PageHeader
      title={copy.default.header.title}
      logContext='FormsPaywallView'
      description={copy.default.header.description}
    />
  )

  return (
    <PageTemplate
      header={pageHeader}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Box borderTop='1px' margin={3} />
        <Flex direction='column' alignItems='center'>
          <Image
            data-testid='screenshot-image'
            alt='Phone Texting Screenshot'
            src={screenShot}
            maxWidth={['90%', '65%', '45%']}
          />
          <Text
            data-testid='body-text'
            textAlign='center'
            maxWidth={['90%', '75%', '55%']}>
            {copy.paywall.body.text}
          </Text>
          <Image
            data-testid='eztexting-image'
            alt='EZ Texting'
            pt={8}
            src={logo}
            maxWidth={['45%', '30%', '20%']}
          />
        </Flex>
      </>
    </PageTemplate>
  )
}
