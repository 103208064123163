import React from 'react'
import {
  FormControl,
  FormLabel,
  forwardRef,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react'

export interface FormTextAreaProps extends TextareaProps {
  label: string
  labelFontSize?: string | string[]
  labelFontWeight?: string | string[]
  labelPaddingTop?: string | string[] | number
  labelPaddingLeft?: string | string[] | number
  labelPaddingBottom?: string | string[] | number
  labelPaddingRight?: string | string[] | number
  labelMarginBottom?: string | string[] | number
  id: string
}

interface Props extends FormTextAreaProps {
  preventNewLines: boolean
}

export const FormTextArea: React.FC<React.PropsWithChildren<Props>> =
  forwardRef(
    (
      {
        label,
        labelFontSize = '2xl',
        labelFontWeight = 'normal',
        labelPaddingTop = '',
        labelPaddingLeft = '',
        labelPaddingBottom = '',
        labelPaddingRight = '',
        labelMarginBottom = '',
        id,
        onChange,
        preventNewLines = false,
        ...rest
      },
      ref,
    ) => {
      const handleOnChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
      ): void => {
        if (onChange) {
          if (preventNewLines) {
            // eslint-disable-next-line no-param-reassign
            event.currentTarget.value = event.currentTarget.value.replace(
              /[\r\n]/g,
              '',
            ) // prevent new lines
          }

          onChange(event)
        }
      }

      const inputProps = {
        type: 'text',
        id,
        ref,
        onChange: handleOnChange,
        ...rest,
      }

      return (
        <FormControl d='flex' flexDir='column'>
          <FormLabel
            htmlFor={id}
            fontSize={labelFontSize}
            fontWeight={labelFontWeight}
            paddingTop={labelPaddingTop}
            paddingLeft={labelPaddingLeft}
            paddingBottom={labelPaddingBottom}
            paddingRight={labelPaddingRight}
            marginBottom={labelMarginBottom}>
            {label}
          </FormLabel>
          <Textarea {...inputProps} />
        </FormControl>
      )
    },
  )
FormTextArea.displayName = 'FormTextArea'
