import React, {PropsWithChildren} from 'react'
import {Box, Flex} from '@chakra-ui/react'

import {Feature} from '../../components'
import {FeatureName} from '../../types'

interface Props {
  feature?: FeatureName
}

export const SideMenuDivider: React.FC<PropsWithChildren<Props>> = ({
  feature,
  children,
}): React.ReactElement => {
  const divider = (
    <Flex
      opacity={0.5}
      color='white'
      fontSize='sm'
      flexDirection='column'
      mt='1rem'
      width='100%'
      cursor='default'>
      <Box margin='0 1rem'>{children}</Box>
      <hr />
    </Flex>
  )
  return !feature ? divider : <Feature name={feature}>{divider}</Feature>
}
