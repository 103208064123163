/* eslint-disable no-console */
import {http} from '../http'
import * as coreMicroposts from '../../coreApi/microposts'

import {APIResponse} from '../types'
import {ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  ID: ResourceID
  clientID: string
}

type DeleteMicropostReturn = APIResponse<null>

export interface DeleteMicropost {
  (params: Params): Promise<DeleteMicropostReturn>
}

const ERROR_PATH = 'api.microposts.deleteMicropost()'
const GENERIC_ERROR = 'failed'

export const deleteMicropost: DeleteMicropost = async ({ID, clientID}) => {
  let resolvedResponse: DeleteMicropostReturn

  try {
    const params = {
      ClientID: parseInt(clientID, 10),
      ID: parseInt(ID, 10),
    }

    await coreMicroposts.deletetMicropost(http, params)

    resolvedResponse = {
      result: 'success',
      data: null,
    }
  } catch (e) {
    resolvedResponse = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedResponse)
}
