import {handleError} from '../handleError'
import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {Album} from '../../../types'

interface Params {
  ID: Album['ID']
  clientID: string
}

type DeleteAlbumReturn = APIResponse<null>

export interface DeleteAlbum {
  (params: Params): Promise<DeleteAlbumReturn>
}

const ERROR_PATH = 'api.albums.deleteAlbum()'
const GENERIC_ERROR = 'general'

export const deleteAlbum: DeleteAlbum = async ({ID, clientID}) => {
  let resolvedResponse: DeleteAlbumReturn

  try {
    const params = {
      ClientID: parseInt(clientID, 10),
      ID: parseInt(ID, 10),
    }

    await coreAlbums.delete(http, params)

    resolvedResponse = {
      result: 'success',
      data: null,
    }
  } catch (e) {
    resolvedResponse = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedResponse)
}
