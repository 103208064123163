import axios from 'axios'
import {http} from '../http'
import * as coreAuth from '../../coreApi/auth'

import {APIResponse, APIResponseFailure} from '../types'
import {ClientUser} from '../../../types'

interface LoginParams {
  username: string
  password: string
}

export interface Login {
  (params: LoginParams): Promise<APIResponse<ClientUser>>
}

const ERROR_PATH = 'loginview/login'

export const login: Login = async ({username, password}) => {
  let resolved: APIResponse<ClientUser>

  const failure: APIResponseFailure = {
    result: 'failure',
    error: {
      path: ERROR_PATH,
      message: 'general',
    },
  }

  try {
    const response = await coreAuth.login(http, {
      username,
      password,
      origin: 'system',
    })

    if (
      response.data.Status === 'Success' &&
      response.data.ClientUsers.length > 0
    ) {
      const {ClientID, PersonID, Locked} = response.data.ClientUsers[0]
      if (Locked) {
        failure.error.message = 'locked'
        resolved = failure
      } else {
        resolved = {
          result: 'success',
          data: {
            clientID: ClientID.toString(),
            personID: PersonID.toString(),
          },
        }
      }
    } else if (response.data.Status === 'Failure') {
      failure.error.message = 'invalid'
      resolved = failure
    } else {
      resolved = failure
    }

    return Promise.resolve(resolved)
  } catch (e) {
    failure.error.message = 'error' // this is meaningful to Austhstore when NOT 400 bad request

    // It is expected that Auth Service will give 400 for login attempst with impossible usernames
    // These types of errors can be handled with client-side validation, since they have no chance of working.
    if (axios.isAxiosError(e)) {
      if (e.response && e.response.status === 400) {
        failure.error.message = 'invalid'
      }
    }

    return Promise.resolve(failure)
  }
}
