import {http} from '../http'
import * as api from '../../coreApi/auth'

import {APIResponse} from '../types'
import {Client, Person, UserPermissions} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: Client['ID']
  personID: Person['ID']
}

type GetUserPermissionsReturn = APIResponse<UserPermissions>

export interface GetUserPermissions {
  (params: Params): Promise<GetUserPermissionsReturn>
}

const ERROR_PATH = 'api.permissions.getUserPermissions()'

export const getUserPermissions: GetUserPermissions = async ({
  clientID,
  personID,
}) => {
  try {
    const response = await api.listUserPermission(http, {
      ClientID: Number(clientID),
      PageNumber: 1,
      PageSize: 100,
      PersonID: Number(personID),
    })

    const resolved: GetUserPermissionsReturn = {
      result: 'success',
      data: {
        permissions: response.data.Results.map((raw) => ({
          permissionName: raw.PermissionName.toString(),
          accessLevel: raw.AccessLevel.toString(),
        })),
      },
    }
    return Promise.resolve(resolved)
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e && e.message ? e.message : e.toString()
    }
    const result = handleError(ERROR_PATH, message, e)
    return Promise.resolve(result)
  }
}
