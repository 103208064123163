import * as corePhotos from '../../coreApi/photos'
import {http} from '../http'
import {APIResponse} from '../types'
import {handleError} from '../handleError'
import {PhotoRotateRequest} from '../../coreApi/photos/types'

export type ResolvedReturn = APIResponse<string>

const ERROR_PATH = 'api.photos.processPhoto()'
const GENERIC_ERROR = 'Unsuccessful Process Photo'
interface RotatePhoto {
  (params: PhotoRotateRequest): Promise<ResolvedReturn>
}

export const rotatePhoto: RotatePhoto = async (params) => {
  let resolvedReturn: ResolvedReturn

  try {
    const res = await corePhotos.rotatePhoto(http, params)
    if (!res || !res.data) {
      throw new Error(GENERIC_ERROR)
    }

    resolvedReturn = {
      result: 'success',
      data: '',
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
