export const removeStoredPhotoState = (
  ID: string | number,
  location: 'local' | 'session' = 'local',
): void => {
  const storageObject = location === 'session' ? sessionStorage : localStorage

  if (storageObject.getItem(`__${ID}_queuedPhotos__`)) {
    storageObject.removeItem(`__${ID}_queuedPhotos__`)
  }

  if (storageObject.getItem(`__${ID}_failedPhotos__`)) {
    storageObject.removeItem(`__${ID}_failedPhotos__`)
  }

  if (storageObject.getItem(`__${ID}_likelyFailedPhotos__`)) {
    storageObject.removeItem(`__${ID}_likelyFailedPhotos__`)
  }
}
