import {http} from '../http'
import {APIResponse} from '../types'
import {handleError} from '../handleError'
import * as coreApi from '../../coreApi/eztexting'
import {EzTextingGroup, SyncResult} from '../../../types'

interface Params {
  groupID: EzTextingGroup['ID']
  personID: number
}

type ResolvedReturn = APIResponse<SyncResult>

interface SyncIndividual {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.syncIndividual()'
const GENERIC_ERROR =
  'Something went wrong while syncing your individual. Please try again.'

export const syncIndividual: SyncIndividual = async ({groupID, personID}) => {
  let resolvedReturn: ResolvedReturn

  const rawParamMap = {
    GroupID: parseInt(groupID, 10),
    PersonID: personID,
  }

  try {
    const response = await coreApi.synchronizeIndividual(http, rawParamMap)

    resolvedReturn = {
      result: 'success',
      data: {
        ID: '???', // The ID is UI only... It's set by the consumer
        groupID: response.data.GroupID.toString(),
        cellNumber: response.data.CellNumber,
        friendlyMessage: response.data.FriendlyMessage,
        name: `${response.data.LastName}, ${response.data.FirstName}`,
        personID: response.data.PersonID,
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }
  return Promise.resolve(resolvedReturn)
}
