import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {CreateGroupRequest, GroupResponse} from './types'

export interface CreateGroup<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const createGroup: CreateGroup<CreateGroupRequest, GroupResponse> = (
  http,
  params,
) => {
  const path = '/v1/eztexting/group'

  return http.request<GroupResponse>({
    method: 'POST',
    url: `${path}`,
    data: params,
  })
}
