import {AxiosResponse} from 'axios'
import {RawPagedCollectionResponse, RawFeature} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  pageNumber: number
  pageSize: number
}

type FetchFeaturesResponse = RawPagedCollectionResponse<RawFeature>

export interface FetchFeatures<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchFeatures: FetchFeatures<Params, FetchFeaturesResponse> = (
  http,
  {pageNumber, pageSize},
) => {
  try {
    return http.get<FetchFeaturesResponse>(
      `/v1/featuresettings/feature?pagenumber=${pageNumber}&pagesize=${pageSize}`,
    )
  } catch (e) {
    if (e instanceof Error) {
      throw e
    } else {
      throw new Error(e as string)
    }
  }
}
