import {log} from '@vanguard/logger'

import {Album, Modify, Photo} from '../types'

import {useRequiredAuth} from '../stores/AuthStore'
import {api} from './api'
import {shouldDoCreate} from '../utils/shouldDoCreate'

interface StatusReturnSuccess {
  status: 'success'
  message: string
  data: Album
}

interface StatusReturnFailure {
  status: 'failure'
  message: string
}

export type StatusReturn = StatusReturnSuccess | StatusReturnFailure

interface Return {
  upsert(
    album: Modify<
      Album,
      Partial<Omit<Album, 'ID' | 'name' | 'date' | 'sortType' | 'constraints'>>
    >, // Require some Album properties for upsert, but make the rest optional
    config?: {shouldUpdateConstraints: boolean},
  ): Promise<StatusReturn>
  getCoverPhoto(photoID: Photo['ID'] | null): string
  deleteAlbum(albumID: Album['ID']): Promise<'success' | 'failure'>
  sortPhotos(
    params: Omit<
      Parameters<typeof api.albums.sortAlbumPhotos>[0],
      'personID' | 'clientID'
    >,
  ): Promise<'success' | 'failure'>
  getConstraintCount(
    season: number,
    constraints: Album['constraints'],
  ): Promise<
    {result: 'success'; count: number} | {result: 'failure'; message: string}
  >
}

export const useAlbumService = (): Return => {
  const {client, person} = useRequiredAuth()

  const upsert: Return['upsert'] = async (
    albumParams,
    {shouldUpdateConstraints} = {shouldUpdateConstraints: false},
  ) => {
    const upsertParams = {
      clientID: client.ID,
      ID: albumParams.ID,
      name: albumParams.name,
      date: albumParams.date,
      season: albumParams.season || client.currentSeasonID,
      coverPhotoID: albumParams.coverPhotoID || null,
      isPrivate: albumParams.isPrivate || false,
      sortType: albumParams.sortType,
      isDraft: albumParams.isDraft,
      publishDate: albumParams.publishDate,
    }

    const response = shouldDoCreate(upsertParams)
      ? await api.albums.createAlbum(upsertParams)
      : await api.albums.updateAlbum(upsertParams)

    if (response.result === 'failure') {
      return Promise.resolve({
        status: 'failure',
        message: response.error.message,
      })
    }

    let updatedConstraints = albumParams.constraints || []
    if (shouldUpdateConstraints) {
      const constraintsResponse = await api.albums.updateConstraints({
        ID: response.data.ID,
        clientID: upsertParams.clientID,
        constraints: albumParams.constraints,
      })

      if (constraintsResponse.result === 'success') {
        updatedConstraints = constraintsResponse.data.constraints
      } else {
        // return Promise.resolve({
        //   status: 'failure',
        //   message: constraintsResponse.error.message,
        // })
        log.error(new Error(JSON.stringify(constraintsResponse.error))) // Logging instead of failing since Album saved correctly. Probably a product question to decide how to handle.
        // keep initial constraints on failure
      }
    }

    const updatedAlbum = {
      ...response.data,
      constraints: updatedConstraints,
    }

    return Promise.resolve({
      status: 'success',
      message: 'Album Created',
      data: updatedAlbum,
    })
  }

  const getCoverPhoto: Return['getCoverPhoto'] = (photoID) => {
    if (!photoID || photoID === '0') return ''

    return api.photos.getThumbnail({clientID: client.ID, ID: photoID})
  }

  const deleteAlbum: Return['deleteAlbum'] = async (albumID) => {
    const response = await api.albums.deleteAlbum({
      ID: albumID,
      clientID: client.ID,
    })

    return Promise.resolve(response.result)
  }

  const getConstraintCount: Return['getConstraintCount'] = async (
    season,
    constraints,
  ) => {
    const response = await api.albums.getConstraintCount({
      clientID: client.ID,
      season,
      constraints,
    })

    if (response.result === 'success') {
      return Promise.resolve({
        result: 'success',
        count: response.data.constraintCount,
      })
    }

    return Promise.resolve({result: 'failure', message: response.error.message})
  }

  const sortPhotos: Return['sortPhotos'] = async ({
    albumID,
    nextPhotoID,
    photoIDs,
  }) => {
    const response = await api.albums.sortAlbumPhotos({
      clientID: client.ID,
      personID: person.ID,
      albumID,
      nextPhotoID,
      photoIDs,
    })

    return Promise.resolve(response.result)
  }

  return {
    upsert,
    getCoverPhoto,
    deleteAlbum,
    sortPhotos,
    getConstraintCount,
  }
}
