import {parseVideoURL} from './parseVideoURL'

export const isValidVideoURL = (
  url: string,
  validVideoTypes: Array<'youtube' | 'vimeo'>,
): boolean => {
  const parsedURL = parseVideoURL(url)

  let isValid = false
  validVideoTypes.forEach((validType) => {
    if (parsedURL.type === validType) {
      isValid = true
    }
  })

  return isValid
}
