import React from 'react'
import deepEqual from 'deep-equal'

import {createContextHelper} from '../../../utils/createContextHelper'
import {AlbumAudienceFilter} from '../../../types'

// import {api} from '../../../services/api'
// import {useRequiredAuth} from '../../../stores/AuthStore'

import {useComposeAlbumState} from './ComposeAlbumManager'

/* ***************************************************** */
interface Context extends ReducerState {
  reset: () => void
  dispatch: React.Dispatch<Action>
  areConstraintsChanged: boolean
}

const [ConstraintManagerContextProvider, useConstraintManagerContext] =
  createContextHelper<Context>()

/* ***************************************************** */
interface ReducerState {
  selected: AlbumAudienceFilter
}

type Action =
  | {
      type: 'UPDATE_CONSTRAINT_FILTER'
      payload: {
        filter: keyof AlbumAudienceFilter
        values: AlbumAudienceFilter[keyof AlbumAudienceFilter]
      }
    }
  | {
      type: 'RESET'
      payload: ReducerState['selected']
    }

const reducer: React.Reducer<ReducerState, Action> = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CONSTRAINT_FILTER': {
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.filter]: action.payload.values,
        },
      }
    }
    case 'RESET': {
      return {
        ...state,
        selected: action.payload,
      }
    }
    default:
      throw new Error('Unhandled action')
  }
}

/* ***************************************************** */

export const ConstraintManager: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // const {client} = useRequiredAuth()
  const initial = useComposeAlbumState()

  const initialSelected = React.useMemo(
    () => ({
      sessionIDs: initial.constraints
        .filter((c) => c.type === 'Session')
        .map((c) => c.constraintID),
      programIDs: initial.constraints
        .filter((c) => c.type === 'Program')
        .map((c) => c.constraintID),
    }),
    [initial],
  )

  const initialState: ReducerState = {
    selected: initialSelected,
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const areConstraintsChanged = deepEqual(state.selected, initialSelected)

  const reset = React.useCallback(() => {
    dispatch({type: 'RESET', payload: initialSelected})
  }, [initialSelected])

  const value: Context = {
    ...state,
    areConstraintsChanged,
    reset,
    dispatch,
  }

  return (
    <ConstraintManagerContextProvider value={value}>
      {children}
    </ConstraintManagerContextProvider>
  )
}

/* ***************************************************** */
export const useConstraints = useConstraintManagerContext
