import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {GetRequest, AlbumResponse} from './types'

export interface Get<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const get: Get<GetRequest, AlbumResponse> = (http, params) => {
  const {ClientID, ID} = params

  const path = '/v1/albums/{id}'.replace('{id}', ID.toString())

  return http.request<AlbumResponse>({
    method: 'GET',
    url: `${path}?clientid=${ClientID}`,
  })
}
