import React from 'react'
import {Text, Flex, Spinner} from '@chakra-ui/react'

export const LoadingState: React.FC<React.PropsWithChildren> = ({children}) => (
  <Flex direction='column' alignItems='center'>
    <Flex
      boxSize='64px'
      borderRadius='32px'
      justifyContent='center'
      alignItems='center'
      mt={20}
      mb={5}>
      <Spinner size='xl' color='secondary.400' emptyColor='gray.200' />
    </Flex>
    <Text>{children}</Text>
  </Flex>
)
