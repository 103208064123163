export const formatDate = (date: Date, type: 'longString'): string => {
  if (type === 'longString') {
    const dateString = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(date)

    const timeString = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    })

    return `${dateString} at ${timeString}`
  }

  return date.toDateString()
}
