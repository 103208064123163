import {AxiosResponse} from 'axios'
import {ProcessRequest, BeginProcessResponse} from './types'
import {HttpInstance} from '../createHttpInstance'

export interface ProcessPhoto<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const processPhoto: ProcessPhoto<
  ProcessRequest,
  BeginProcessResponse
> = (http, params) => {
  const path = '/v1/photos'

  return http.request<ProcessRequest>({
    url: `${path}`,
    method: 'POST',
    data: params,
  })
}
