import {Photo} from '../../../types'
import * as coreApi from '../../coreApi/photos'
import {SSEMessage} from '../../coreApi/types'

export const {TopicRotatePhotoComplete} = coreApi
export type Topic = coreApi.TopicRotatePhotoCompleteType

export type RotateDetails = {
  completed: boolean
  error: string
}

export type PhotoRotationCompleteEvent = {
  photo?: Photo
  details?: RotateDetails
}

export function handleRotatePhotoSSE(
  msg: SSEMessage,
): PhotoRotationCompleteEvent {
  const coreEvent = coreApi.handleRotatePhotoSSE(msg)
  const event: PhotoRotationCompleteEvent = {}
  if (coreEvent.RotateMeta) {
    event.photo = {
      ID: coreEvent.RotateMeta.ID.toString(),
      self: coreEvent.RotateMeta.Self,
      albumID: coreEvent.RotateMeta.AlbumID.toString(),
      isMustSee: coreEvent.RotateMeta.IsMustSee,
      date: new Date(coreEvent.RotateMeta.Date),
      sortOrder: coreEvent.RotateMeta.SortOrder,
      thumbnailURL: coreEvent.RotateMeta.ThumbnailURL,
      thumbnailHeight: coreEvent.RotateMeta.ThumbnailHeight,
      thumbnailWidth: coreEvent.RotateMeta.ThumbnailWidth,
      standardURL: coreEvent.RotateMeta.StandardURL,
      standardHeight: coreEvent.RotateMeta.StandardHeight,
      standardWidth: coreEvent.RotateMeta.StandardWidth,
      nativeURL: coreEvent.RotateMeta.NativeURL,
      nativeHeight: coreEvent.RotateMeta.NativeHeight,
      nativeWidth: coreEvent.RotateMeta.NativeWidth,
      originalFileName: coreEvent.RotateMeta.OriginalFileName,
    }
  }
  if (coreEvent.Response) {
    event.details = {
      completed: coreEvent.Response.Completed,
      error: coreEvent.Response.Error,
    }
  }
  return event
}
