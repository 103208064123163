import React, {useReducer} from 'react'
import {api} from './api'

import {AlbumUploadSession, Album} from '../types'
import {useRequiredAuth} from '../stores/AuthStore'

export const PAGE_SIZE = 100

/* *******************************  */

interface State {
  status: 'idle' | 'pending' | 'resolved' | 'rejected'
  numUnprocessedFiles: number
  failedProcessingFiles: string[]
}

type Action =
  | {type: 'FETCH_INIT'}
  | {
      type: 'FETCH_SUCCESS'
      payload: {
        data: AlbumUploadSession[]
      }
    }
  | {type: 'FETCH_FAILURE'}

const fetchReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_INIT': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'FETCH_SUCCESS': {
      // only use the latest session for the unprocessed count
      let numUnprocessedFiles = 0
      if (action.payload.data.length) {
        numUnprocessedFiles =
          action.payload.data[0].fileCount -
          action.payload.data[0].successCount -
          action.payload.data[0].failuresCount
      }

      // use the entire sessions list (last 5 sessions) for the failed files list
      const failedProcessingFiles = action.payload.data
        .map((session) => session.files)
        .flat()
        .filter((f) => f.received && f.failed)
        .map((f) => f.fileName)

      return {
        ...state,
        status: 'resolved',
        failedProcessingFiles,
        numUnprocessedFiles,
      }
    }
    case 'FETCH_FAILURE': {
      return {
        ...state,
        status: 'rejected',
      }
    }
    default:
      throw new Error('Unhandled action')
  }
}

/* ******************************************** */
interface Return extends State {
  fetchData(didCancel?: boolean): Promise<void>
}

interface UseFetchAlbumUploadSessions {
  (albumID?: Album['ID']): Return
}

export const useFetchAlbumUploadSessions: UseFetchAlbumUploadSessions = (
  albumID,
) => {
  const {client} = useRequiredAuth()

  const [state, dispatch] = useReducer(fetchReducer, {
    status: 'idle',
    numUnprocessedFiles: 0,
    failedProcessingFiles: [],
  })

  const fetchData = React.useCallback<Return['fetchData']>(
    async (didCancel = false) => {
      if (!albumID) return

      dispatch({type: 'FETCH_INIT'})

      const response = await api.albums.listAlbumUploadSessions({
        albumID,
        clientID: client.ID,
      })

      if (!didCancel) {
        if (response.result === 'failure') {
          dispatch({type: 'FETCH_FAILURE'})
        } else {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: {
              data: response.data.results,
            },
          })
        }
      }
    },
    [client.ID, albumID],
  )

  return React.useMemo(
    () => ({
      ...state,
      fetchData,
    }),
    [state, fetchData],
  )
}
