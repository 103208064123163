import React from 'react'
import {CompatRoute, Navigate, useLocation} from 'react-router-dom-v5-compat'

import {useAuth} from '../../stores/AuthStore'
import {useReleaseFlags} from '../../hooks/useReleaseFlags'
import {FeatureName} from '../../types'

type Props = React.ComponentProps<typeof CompatRoute> & {
  clientAuthorization?: FeatureName
  releaseRender?: {
    releaseFlag: FeatureName
    render: () => React.ReactNode
  }
}

export const AuthRoute: React.FC<React.PropsWithChildren<Props>> = ({
  clientAuthorization,
  releaseRender,
  compat,
  ...props
}) => {
  const location = useLocation()
  const {person} = useAuth()
  const {getIsReleasedToUser} = useReleaseFlags()

  const isAuthenticated = Boolean(person)

  if (!isAuthenticated) {
    return (
      <Navigate
        to='/login'
        state={{
          from: location,
        }}
        replace
      />
    )
  }

  if (clientAuthorization) {
    const isAuthorized = getIsReleasedToUser(clientAuthorization)

    if (!isAuthorized) {
      return <Navigate to='/home' replace />
    }
  }

  // Used to conditionally render a component based on a release flag.
  // To be used for temporary release flags only to ensure large view
  // changes can be verified in production first and possibly canary release
  const additionalProps: {
    render?: Props['render']
  } = {}

  if (releaseRender) {
    const isReleased = getIsReleasedToUser(releaseRender.releaseFlag)

    if (isReleased) {
      additionalProps.render = releaseRender.render
    }
  }

  return <CompatRoute {...props} {...additionalProps} />
}
