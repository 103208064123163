import Bugsnag, {NotifiableError, OnErrorCallback, Event} from '@bugsnag/js'

const processErrorLog =
  (severity: Event['severity']) =>
  (error: NotifiableError, onError?: OnErrorCallback | undefined): void => {
    Bugsnag.notify(
      error,
      (event) => {
        // eslint-disable-next-line no-param-reassign
        event.severity = severity

        if (onError) {
          onError(event, (e) => {
            // eslint-disable-next-line no-console
            console.error(e)
          })
        }
      },
      // (cberr, cbevent) => {
      // This seemed to fire everytime regardless if the onError sent an event to BugSnag or not.
      // My hope was to use this for the send to our log service, but if I did that here, I'd lose the benefit filtering items we didn't want to log or have to dupe the logic here
      //   console.log('onErrorCB::per-event::', cberr, cbevent)
      // },
    )
  }

export const log = {
  error: processErrorLog('error'),
  warn: processErrorLog('warning'),
  info: processErrorLog('info'),
  breadcrumb: Bugsnag.leaveBreadcrumb,
  // track: processAnalyticsLog('trace')
}
