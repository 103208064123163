import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {CreateRequest, AlbumResponse} from './types'

export interface Create<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const create: Create<CreateRequest, AlbumResponse> = (http, params) => {
  const path = '/v1/albums'

  return http.request<AlbumResponse>({
    method: 'POST',
    url: `${path}`,
    data: params,
  })
}
