import React from 'react'
import {Flex, Heading, useDisclosure} from '@chakra-ui/react'

import {PageHeader} from '../../components/PageHeader/PageHeader'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {useMicropostsStore} from '../../stores/MicropostsStore'
import {
  ComposeMicropostModal,
  ScrollList,
  BlankSlate,
  ActionButton,
  Feature,
} from '../../components'
import {MicropostItem} from './MicropostItem/MicropostItem'
import {Micropost} from '../../types'
import {useConfirmation} from '../../app/ConfirmationManager'
import {useStatusToast} from '../../app/ToastManager'
import copy from './copy.json'

export const DELETE_MICROPOST_SUCCESS_TEXT = 'Micropost successfully deleted.'
export const DELETE_MICROPOST_FAILURE_TEXT =
  'Something went wrong, your micropost could not be deleted. Please try again.'

export const MicropostsView: React.FC<React.PropsWithChildren> = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {toast} = useStatusToast()
  const {
    microposts,
    setSelectedMicropost,
    isLoading,
    hasError,
    maybeFetchMore,
    deleteMicropost,
  } = useMicropostsStore()

  const showConfirmation = useConfirmation()

  const handleEdit = (mp: Micropost) => (): void => {
    setSelectedMicropost(mp)
    onOpen()
  }

  const handleDelete = (ID: Micropost['ID']) => (): void => {
    showConfirmation({
      variant: 'danger',
      title: 'Delete Micropost',
      description: "Are you sure? You can't undo this action afterwards.",
      confirmButtonText: 'Delete',
      testID: 'delete-micropost',
      onConfirm: async (): Promise<void> => {
        const response = await deleteMicropost(ID)

        if (response.status === 'success') {
          toast.success(DELETE_MICROPOST_SUCCESS_TEXT)
        } else {
          toast.error(DELETE_MICROPOST_FAILURE_TEXT)
        }
      },
    })
  }

  const pageHeader = (
    <PageHeader
      title='Microposts'
      logContext='MicropostsView'
      description='Use microposts as a short and informal way to communicate with your camp parents. Add a photo or video, button and text to engage with your audience.'
      callToAction={
        <Feature name='manage-microposts'>
          <ActionButton
            width={['100%', 'unset']}
            onClick={(): void => {
              setSelectedMicropost(null)
              onOpen()
            }}>
            {copy.buttons.newMicropost}
          </ActionButton>
        </Feature>
      }
    />
  )

  return (
    <PageTemplate header={pageHeader} maxContentWidth={660}>
      <>
        <Flex borderBottom='1px solid #CED2D5'>
          <Heading as='h5' size='sm' ml={4} mt={6} flex={1}>
            POSTED
          </Heading>
        </Flex>

        <ScrollList
          isLoading={isLoading}
          hasError={hasError}
          onNext={maybeFetchMore}
          emptyComponent={
            <BlankSlate iconName='email'>Send your first Micropost</BlankSlate>
          }>
          {microposts.map(
            (mp): React.ReactNode => (
              <MicropostItem
                key={mp.ID}
                micropost={mp}
                handleEdit={handleEdit(mp)}
                handleDelete={handleDelete(mp.ID)}
              />
            ),
          )}
        </ScrollList>

        {isOpen && <ComposeMicropostModal onClose={onClose} />}
      </>
    </PageTemplate>
  )
}
