import {decode} from 'he'
import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {ResourceID, Album} from '../../../types'
import {handleError} from '../handleError'
import {translateAlbumSortType} from './utils/translateAlbumSortType'

interface Params extends Omit<Album, 'photoCount' | 'constraints'> {
  clientID: ResourceID
}

type ResolvedReturn = APIResponse<Album>

export interface CreateAlbum {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.createAlbum()'
const GENERIC_ERROR =
  'Something went wrong while creating your album. Please try again.'

export const createAlbum: CreateAlbum = async ({
  clientID,
  name,
  date,
  season,
  isPrivate,
  sortType,
  isDraft,
  publishDate,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      Name: name,
      Date: date.toISOString(),
      Season: season,
      IsPrivate: isPrivate,
      CoverPhotoID: null,
      SortTypeID: translateAlbumSortType(sortType),
      IsDraft: isDraft,
      PublishDate: publishDate ? publishDate.toISOString() : null,
    }

    const response = await coreAlbums.create(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Album Create')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        ID: response.data.ID.toString(),
        name: decode(response.data.Name),
        coverPhotoID: response.data.CoverPhotoID
          ? response.data.CoverPhotoID.toString()
          : null,
        date: new Date(response.data.Date),
        photoCount: response.data.PhotoCount,
        isPrivate: response.data.IsPrivate,
        season: response.data.Season,
        sortType: translateAlbumSortType(response.data.SortTypeID),
        isDraft: response.data.IsDraft,
        publishDate: response.data.PublishDate
          ? new Date(response.data.PublishDate)
          : null,
        constraints: (response.data.Constraints || []).map((constraint) => ({
          type: constraint.Type,
          constraintID: constraint.ConstraintID.toString(),
        })),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
