import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {GetGroupRequest, GroupResponse} from './types'

export interface GetGroup<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const getGroup: GetGroup<GetGroupRequest, GroupResponse> = (
  http,
  params,
) => {
  const {ClientID, ID} = params

  const path = '/v1/eztexting/group/{id}'.replace('{id}', ID.toString())

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<GroupResponse>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
