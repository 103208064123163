import React from 'react'
import {Moment} from 'moment'
import {Flex, Text} from '@chakra-ui/react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import {SingleDatePicker} from 'react-dates'

import {PropsOf} from '../../types'

interface Props extends Omit<PropsOf<typeof Flex>, 'onChange'> {
  value: Moment | null
  isOutsideRange?: PropsOf<typeof SingleDatePicker>['isOutsideRange']
  onChange: (date: Moment | null) => void
}

export const AlbumDateControl: React.FC<Props> = ({
  value,
  onChange,
  isOutsideRange,
  ...rest
}) => {
  const [focusedControl, setFocusedControl] = React.useState(false)
  return (
    <Flex direction='column' {...rest}>
      <Text fontWeight='bold' pt={4} fontSize='16px'>
        Date
      </Text>
      <Flex pt={1} flexDir={['column', 'row']}>
        <SingleDatePicker
          numberOfMonths={1}
          date={value}
          onDateChange={(date): void => onChange(date)}
          focused={focusedControl}
          isOutsideRange={isOutsideRange || ((): boolean => false)}
          onFocusChange={({focused}): void => setFocusedControl(focused)}
          id='album-date'
          placeholder='Select a date'
          regular
          small={false}
        />
      </Flex>
    </Flex>
  )
}
