import React from 'react'
import {
  init,
  getErrorBoundary,
  ErrorLoggingConfig,
  OnError,
} from '@vanguard/logger'

// import {ErrorFallbackUI} from '../components/ErrorFallbackUI/ErrorFallbackUI'
import {BUGSNAG_API_KEY, APP_VERSION, BUGSNAG_STAGE} from '../constants'
import {NotAuthorizedError} from '../customErrors'

const filterErrorLogging: OnError = (event) => {
  // Global rules
  // NOTE: I'm keeping this uncommented as I'm doing frequent testing in dev server mode
  // At some point this should probably be added back to prevent sending errors to bugsnag under the 'development' release stage
  // if (process.env.NODE_ENV !== 'production') {
  //   return false
  // }

  // Per-error Rules
  if (event.errors.length) {
    const error = event.errors[0]
    const ignoredErrors = [NotAuthorizedError]
    if (ignoredErrors.some((err) => err.name === error.errorClass)) {
      return false
    }
  }

  return true
}

const loggingConfig: ErrorLoggingConfig = {
  apiKey: BUGSNAG_API_KEY,
  appVersion: APP_VERSION,
  appType: 'client',
  releaseStage: BUGSNAG_STAGE,
  onError: [filterErrorLogging],
}

init(loggingConfig)

const ErrorBoundary = getErrorBoundary()

export const Logger = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement =>
  ErrorBoundary ? (
    <ErrorBoundary>{children}</ErrorBoundary>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</> // needed due to conflict of children as React.ReactNode and FC return of React.ReactElement
  )
