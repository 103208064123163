import {BeginPhotoProcess} from '../../../types'
import {ProcessRequest} from '../../coreApi/photos/types'
import * as corePhotos from '../../coreApi/photos'
import {http} from '../http'
import {APIResponse} from '../types'
import {handleError} from '../handleError'

export type ResolvedReturn = APIResponse<''>

const ERROR_PATH = 'api.photos.processPhoto()'
const GENERIC_ERROR = 'Unsuccessful Process Photo'

interface ProcessPhoto {
  (req: BeginPhotoProcess): Promise<ResolvedReturn>
}

export const processPhoto: ProcessPhoto = async (req) => {
  let resolvedReturn: ResolvedReturn
  const rawParamMap: ProcessRequest = {
    ClientID: parseInt(req.clientID, 10),
    AlbumID: parseInt(req.albumID, 10),
    SeasonID: req.seasonID,
    FileName: req.fileName,
    UploadSessionID: req.uploadSessionID,
  }

  try {
    const res = await corePhotos.processPhoto(http, rawParamMap)
    if (!res || !res.data) {
      throw new Error(GENERIC_ERROR)
    }

    resolvedReturn = {
      result: 'success',
      data: '',
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
