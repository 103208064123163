import {http} from '../http'
import * as coreEzTexting from '../../coreApi/eztexting'
import {APIResponse} from '../types'
import {ValidatedRecipients, ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
  personIDs: number[]
}

type ResolvedReturn = APIResponse<ValidatedRecipients>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.validateNumbers()'
const GENERIC_ERROR =
  'Something went wrong while validating recipient phone numbers. Please try again.'

export const validateNumbers: ServiceMethod = async ({clientID, personIDs}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      PersonIDs: personIDs,
    }

    const response = await coreEzTexting.validateNumbers(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Validate Numbers')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        recipients: response.data.PersonIDs?.map((personID) =>
          personID.toString(),
        ),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
