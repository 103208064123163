import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {RawMicropost} from '../types'

interface Params {
  ID: RawMicropost['ID']
  ClientID: RawMicropost['ClientID']
  CreatedBy: RawMicropost['CreatedBy']
  PublishDate: RawMicropost['PublishDate']
  Content: RawMicropost['Content']
  ImageURL: RawMicropost['ImageURL']
  VideoURL: RawMicropost['VideoURL']
  TargetText: RawMicropost['TargetText']
  TargetURL: RawMicropost['TargetURL']
  SegmentID: RawMicropost['SegmentID']
}

export interface UpdateMicropost<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const updateMicropost: UpdateMicropost<Params, RawMicropost> = (
  http,
  {
    ID,
    ClientID,
    CreatedBy,
    PublishDate,
    Content,
    ImageURL,
    VideoURL,
    TargetText,
    TargetURL,
    SegmentID,
  },
) =>
  http.put<RawMicropost>(`/v1/microposts/${ID}`, {
    ClientID,
    CreatedBy,
    PublishDate,
    Content,
    ImageURL: ImageURL && ImageURL.length ? ImageURL : null,
    VideoURL: VideoURL && VideoURL.length ? VideoURL : null,
    TargetText: TargetText && TargetText.length ? TargetText : null,
    TargetURL: TargetURL && TargetURL.length ? TargetURL : null,
    SegmentID,
  })
