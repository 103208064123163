import React from 'react'
import {Link, useNavigate} from 'react-router-dom-v5-compat'
import {Box, Button, Divider, Flex, Heading} from '@chakra-ui/react'
import {ArrowBackIcon} from '@chakra-ui/icons'
import {Column} from 'react-table'

import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {ActionButton, Table} from '../../components'

type TableColumns = {
  ID: string
  name: string
  edit: string
}

const data = ['123', '345', '567', '624', '724', '729'].map((ID) => ({
  ID,
  name: `Filter Set: ${ID}`,
  edit: 'x',
  new: 'x',
}))

const PageHeader: React.FC<{goBack: () => void; onActionClick: () => void}> = ({
  goBack,
  onActionClick,
}) => (
  <Flex
    p={[4]}
    mt={1}
    mb={0}
    pb={[0, 2]}
    direction='column'
    data-testid='page-header'>
    <Flex
      mb={4}
      alignItems='center'
      justifyContent='space-between'
      data-testid='header-controls'>
      <Flex flexGrow={[1, 0]}>
        <Button
          variant='link'
          display='flex'
          alignItems='center'
          color='#000'
          fontWeight='500'
          lineHeight='1.25em'
          aria-label='Go Back'
          data-testid='back-button'
          height='2.5em'
          onClick={goBack}
          flexGrow={1}
          justifyContent='flex-start'>
          <ArrowBackIcon boxSize='1.5em' mr='.5em' />
          <Box display={['none', 'initial']}>Emails</Box>
        </Button>
      </Flex>
    </Flex>

    <Flex
      flexDirection={['column', 'row']}
      justifyContent='space-between'
      alignItems='flex-end'>
      <Flex flexDirection='column' width={['100%', '66%']}>
        <Heading
          data-testid='header-title'
          fontSize={['32px']}
          textAlign='left'
          fontWeight={400}>
          Filter Sets
        </Heading>
      </Flex>

      <Flex
        ml={[0]}
        width={['100%', '33%']}
        justifyContent={['flex-end']}
        my={['1', 'unset']}>
        <ActionButton width={['100%', 'unset']} onClick={onActionClick}>
          New Filter Set
        </ActionButton>
      </Flex>
    </Flex>
  </Flex>
)
export const FilterSetListView: React.FC<React.PropsWithChildren> = () => {
  const navigate = useNavigate()

  const columns = React.useMemo<Column<TableColumns>[]>(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        width: '18%',
        minWidth: 130,
        style: {
          textAlign: 'left',
          flexGrow: 1,
        },
        cellStyle: {
          flexGrow: 1,
        },
      },
      {
        id: 'edit',
        Header: '',
        width: '10%',
        // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-explicit-any
        Cell: ({row}: any) => (
          <Link to={`/communications/filters/${row.id}`}>edit</Link>
        ),
      },
      {
        id: 'new',
        Header: '',
        width: '10%',
        // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-explicit-any
        Cell: ({row}: any) => (
          <Link to={`/communications/compose?filterSetID=${row.id}&type=email`}>
            new email
          </Link>
        ),
      },
    ],
    [],
  )
  return (
    <PageTemplate
      header={
        <PageHeader
          goBack={(): void => {
            navigate('/communications')
          }}
          onActionClick={(): void => {
            navigate('/communications/filters/compose')
          }}
        />
      }
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Divider mb='4' mt={1} opacity={1} border-bottom='1px solid' mx={4} />

        <Table<TableColumns>
          name='EmailList'
          columns={columns}
          data={data}
          pageSize={10}
        />
      </>
    </PageTemplate>
  )
}
