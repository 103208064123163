import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {RawMicropost, ResourceID} from '../types'

interface Params {
  ClientID: number
  ID: ResourceID
}

export interface DeleteMicropost<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const deletetMicropost: DeleteMicropost<Params, RawMicropost> = (
  http,
  {ClientID, ID},
) => http.delete(`/v1/microposts/${ID}?clientid=${ClientID}`)
