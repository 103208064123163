/* eslint-disable */
// @ts-nocheck
import React from 'react'
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Collapse,
  Link,
  Text,
} from '@chakra-ui/react'
import {setLogContext} from '@vanguard/logger'

import {useAuth} from '../../stores/AuthStore'
import {useAuthService} from '../../services/useAuthService'
import {Legalese} from './Legalese'
import {
  ActionButton,
  CustomIcon,
  FormInput,
  FormInputPassword,
} from '../../components'
import {SYSTEM_URL} from '../../constants'
import copy from './copy.json'
import {isObjectWithKey} from '../../utils'

export const LoginView: React.FC<React.PropsWithChildren> = () => {
  setLogContext('LoginView')

  const navigate = useNavigate()
  const {state} = useLocation()

  const {login} = useAuth()
  const [searchParams] = useSearchParams()

  const expiredSearchParam = searchParams.get('expired')

  const {
    data: {inputs, error},
    handleInputChange,
    setError,
    hasValidInputs,
  } = useAuthService({
    username: '',
    password: '',
  })

  const handleFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()

    if (!hasValidInputs(false)) {
      return
    }

    // If redirected from somewhere, go back to it after login
    const from = state?.from || {
      pathname: '/home', // TODO: ideally this will got to '/' to let it redirect to the correct route, but doing the react router v5-v6 compat package seems to make this not work, so fix when fully on v6
    }

    const responseStatus = await login(inputs.username, inputs.password)

    switch (responseStatus) {
      case 'success':
        navigate(from, {replace: true})

        break
      case 'locked':
        setError({
          title: copy.errors.locked.title,
          description: copy.errors.locked.description,
        })
        break
      case 'failure':
        setError({
          title: copy.errors.invalid.title,
          description: copy.errors.invalid.description,
        })
        break
      default:
        setError({
          title: copy.errors.generic.title,
          description: copy.errors.generic.description,
        })
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Flex
        bg='#F7F7F7'
        justifyContent='center'
        alignItems='center'
        h='100vh'
        flexDir='column'>
        <Flex
          bg='white'
          flexDir='column'
          p={50}
          w={['100%', '460px']}
          h={600}
          justifyContent='space-between'
          borderRadius='md'
          border='1px'>
          {expiredSearchParam === '1' && (
            // WIP. Not sure if I wanna stick with location.state or just add a query param and parse it there.
            // I need to see how each affects browser history e.g. clicking back/forward.
            // Couldn't just use setError cause it causes infinite looping and I kind of think this message should be
            // independent of the validation errors
            <Alert status='warning'>
              <AlertIcon />
              <AlertTitle mr={2}>{copy.alerts.sessionExpired.title}</AlertTitle>
              <AlertDescription>
                {copy.alerts.sessionExpired.description}
              </AlertDescription>
            </Alert>
          )}
          <Flex alignItems='center' justifyContent='center'>
            <CustomIcon
              name='phone'
              h={['90%', '100%']}
              w={['90%', '100%']}
              maxH={['60px']}
            />
          </Flex>
          <Box>
            <Flex flexDir='column' justifyContent='space-between' h={250}>
              <Text
                fontSize={['2.25rem', '2rem']}
                textAlign={['center', 'left']}
                color='#1F2223'>
                {copy.headers.primary}
              </Text>

              <Collapse
                in={!!error}
                style={{
                  border: '1px',
                  borderRadius: 'md',
                  borderColor: 'rgb(255,0,0)',
                  background: 'rgba(255,0,0,0.5)',
                  padding: 1,
                }}
                data-testid='error-viewer'>
                <Box
                  style={{
                    border: '1px',
                    borderRadius: 'md',
                    borderColor: 'rgb(255,0,0)',
                    background: 'rgba(255,0,0,0.5)',
                    padding: 1,
                  }}>
                  {error?.description}
                </Box>
              </Collapse>
              <FormInput
                title='username'
                id='username'
                name='username'
                type='text'
                label={copy.inputs.username.label}
                value={inputs.username}
                onChange={handleInputChange}
              />
              <FormInputPassword
                title='password'
                id='password'
                name='password'
                autoComplete='current-password'
                label={copy.inputs.password.label}
                value={inputs.password}
                onChange={handleInputChange}
              />
            </Flex>
          </Box>
          <Flex justifyContent={['flex-start', 'flex-end']}>
            <ActionButton
              variant='solid'
              type='submit'
              isDisabled={!hasValidInputs()}
              width={['100%', '30%']}>
              {copy.buttons.submit}
            </ActionButton>
          </Flex>
          <Legalese />
        </Flex>
        <Flex pt={4} fontSize={['.915rem']}>
          <Link
            href={SYSTEM_URL}
            isExternal
            style={{textDecoration: 'underline'}}>
            {copy.links.cmSystem}
          </Link>
        </Flex>
      </Flex>
    </form>
  )
}
