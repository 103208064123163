import {Row} from 'react-table'

export const simpleSort = (a: string | number, b: string | number): number =>
  // eslint-disable-next-line no-nested-ternary
  a === b ? 0 : a > b ? 1 : -1

export const columnSort = <T extends object>(
  ra: Row<T>,
  rb: Row<T>,
  columnID: string,
  // desc?: boolean,
): number => {
  const a = ra.values[columnID]
  const b = rb.values[columnID]
  return caseInsensitiveSort(a, b)
}

export const caseInsensitiveSort = (a: string, b: string): number => {
  const aLower = a.toLowerCase()
  const bLower = b.toLowerCase()
  return simpleSort(aLower, bLower)
}
