import {http} from '../http'
import {APIResponse} from '../types'
import * as coreEzTexting from '../../coreApi/eztexting'
import {EzTextingGroup} from '../../../types'
import {Paginated, GroupResponse} from '../../coreApi/eztexting/types'
import {transformGroup} from './group.transform'
import {handleError} from '../handleError'

const ERROR_PATH = 'api.eztexting.fetchEzTextingGroups()'

type FetchAllGroupsReturn = APIResponse<EzTextingGroup[]>

export interface FetchAllParams {
  clientID: EzTextingGroup['clientID']
  pageSize: number
}

interface FetchAllGroups {
  (params: FetchAllParams): Promise<FetchAllGroupsReturn>
}

export const fetchAllEzTextingGroups: FetchAllGroups = async ({
  clientID,
  pageSize,
}) => {
  try {
    let hasNext = true
    let results: GroupResponse[] = []
    let pageNumber = 1
    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await coreEzTexting.listGroup(http, {
        ClientID: Number(clientID),
        PageNumber: pageNumber,
        PageSize: pageSize,
        OrderAscending: true,
        OrderBy: 'Name',
      })

      results = [...results, ...response.data.Results]
      pageNumber += 1
      hasNext = Boolean(response.data.Next)
    } while (hasNext)

    if (results) {
      return Promise.resolve({
        result: 'success',
        data: results.map((data) => transformGroup(data)),
      })
    }

    throw new Error('Unsuccessful eztexting all groups fetch')
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e.message ? e.message : e.toString()
    }
    const result = handleError(ERROR_PATH, message, e)
    return Promise.resolve(result)
  }
}

export interface FetchParams extends FetchAllParams {
  pageNumber: number
  orderAscending: boolean
  orderBy: keyof GroupResponse
}

type FetchGroupsReturn = APIResponse<Paginated<EzTextingGroup>>

interface FetchGroups {
  (params: FetchParams): Promise<FetchGroupsReturn>
}

export const fetchEzTextingGroups: FetchGroups = async ({
  clientID,
  pageSize,
  pageNumber,
  orderAscending = true,
  orderBy = 'Name',
}) => {
  try {
    const response = await coreEzTexting.listGroup(http, {
      ClientID: Number(clientID),
      PageNumber: pageNumber,
      PageSize: pageSize,
      OrderAscending: orderAscending,
      OrderBy: orderBy,
    })

    if (response.data) {
      return Promise.resolve({
        result: 'success',
        data: {
          ...response.data,
          Results: response.data.Results.map((data) => transformGroup(data)),
        },
      })
    }

    throw new Error('Unsuccessful eztexting groups fetch')
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e.message ? e.message : e.toString()
    }
    const result = handleError(ERROR_PATH, message, e)
    return Promise.resolve(result)
  }
}
