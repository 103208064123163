import React from 'react'
import {Modal as UIModal} from '@chakra-ui/react'
import type {FocusableElement} from '@chakra-ui/utils'
import {useSegments} from '../contextManagers/SegmentManager'
import {PropsOf} from '../../../types'
import {createContextHelper} from '../../../utils/createContextHelper'

export type Step = 'compose' | 'segment' // TODO: Find a way to pass this in from root component to make this component generic
// maybe? https://mariusschulz.com/blog/passing-generics-to-jsx-elements-in-typescript

interface Props extends PropsOf<typeof UIModal> {
  steps: Step[]
}

interface ModalContext {
  currentStep: Step
  setCurrentStep: React.Dispatch<React.SetStateAction<Step>>
  initialFocusRef: React.RefObject<FocusableElement> | undefined
}

const [ModalContextProvider, useModalContext] =
  createContextHelper<ModalContext>()

export {useModalContext}

export const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  steps,
  onClose,
  initialFocusRef,
  ...props
}) => {
  const {status, deleteSegment} = useSegments()
  const [currentStep, setCurrentStep] = React.useState(steps[0])

  const handleOnClose = (): void => {
    // I was unable to find a more elegant way to cleanup a segment if the micropost is abandonded, sigh :(
    if (status === 'unowned') {
      deleteSegment()
    }

    if (onClose) {
      onClose()
    }
  }

  const value = {
    currentStep,
    setCurrentStep,
    initialFocusRef,
  }

  return (
    <ModalContextProvider value={value}>
      <UIModal
        {...props}
        onClose={handleOnClose}
        initialFocusRef={initialFocusRef}>
        {children}
      </UIModal>
    </ModalContextProvider>
  )
}
