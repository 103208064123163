export type Casing = 'title' | 'lower' | 'upper' | 'sentence'

type ApplyCasing = (term: string, casing: Casing) => string

export const applyCasing: ApplyCasing = (term, casing) => {
  const upperCaseFirstLetter = (word: string): string => {
    const characters = word.split('')
    characters[0] = characters[0].toUpperCase()
    return characters.join('')
  }

  const applyTitleCase = (): string => {
    const words = term.split(' ')
    const capitalizedWords = words.map((word) => upperCaseFirstLetter(word))
    return capitalizedWords.join(' ')
  }

  const applySentenceCase = (): string => upperCaseFirstLetter(term)

  switch (casing) {
    case 'title':
      return applyTitleCase()
    case 'lower':
      return term.toLowerCase()
    case 'upper':
      return term.toUpperCase()
    case 'sentence':
      return applySentenceCase()
    default:
      throw new Error(
        'Incorrect case. Valid case options include: title, lower, upper, sentence',
      )
  }
}
