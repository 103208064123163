import React, {FC} from 'react'

import {Icon as CIcon} from '@chakra-ui/react'
import {appTheme} from '../../app/theme'

type Props = React.ComponentProps<typeof CIcon>

interface IconProps extends Props {
  name: string
}

const customIcons = {
  star: {
    path: (
      <g fill='none'>
        <path
          d='M55.814 314.8a2.097 2.097 0 0 1-2.976-.008c-16.261-16.628-29.079-35.874-38.115-57.238C5.082 234.76.193 210.555.193 185.614c0-24.942 4.889-49.146 14.53-71.94 9.309-22.008 22.632-41.771 39.599-58.738 16.967-16.967 36.729-30.29 58.738-39.598C135.854 5.696 160.059.808 185 .808c24.941 0 49.146 4.888 71.94 14.53 22.008 9.308 41.771 22.631 58.738 39.598s30.29 36.73 39.599 58.738c9.641 22.794 14.53 46.998 14.53 71.94 0 24.941-4.889 49.146-14.531 71.94-9.035 21.363-21.852 40.61-38.114 57.237a2.096 2.096 0 0 1-2.975.009l-20.296-20.296a2.075 2.075 0 0 1-.009-2.923c28.138-28.891 43.444-67.04 43.045-107.51-.384-38.971-15.867-76.53-43.065-104.444-29.326-30.097-68.876-46.48-110.895-45.934-38.969.506-76.475 16.116-104.304 43.401-29.391 28.817-45.598 67.423-45.598 108.52 0 39.895 15.273 77.444 43.054 105.968a2.076 2.076 0 0 1-.009 2.922L55.814 314.8'
          fill='#CF5038'
        />
        <path
          d='M92.536 275.086c-24.986-25.799-37.935-60.251-36.014-96.517 1.568-29.603 13.476-57.917 33.508-79.768 24.383-26.593 57.828-41.472 93.595-41.844 33.409-.348 65.893 12.402 90.077 35.454 25.741 24.536 39.963 57.776 39.963 93.203 0 33.662-12.84 65.349-36.201 89.472a2.096 2.096 0 0 1-2.979.013L254.187 254.8a2.08 2.08 0 0 1-.014-2.917C272.484 232.79 282 207.41 280.67 180.687c-1.087-21.841-9.731-42.797-24.365-59.047-19.595-21.761-47.251-33.215-76.494-31.682-21.838 1.144-42.768 9.85-58.979 24.526-20.347 18.419-31.625 43.908-31.625 71.13 0 24.902 9.438 48.353 26.621 66.269a2.08 2.08 0 0 1-.014 2.917l-20.298 20.299c-.823.822-2.171.822-2.98-.013'
          fill='#D9844E'
        />
        <path
          d='M234.783 235.398l-20.308-20.31c-.775-.774-.835-2.03-.099-2.84 7.169-7.889 10.835-18.198 10.206-29.017-1.152-19.826-17.264-35.971-37.086-37.191-22.99-1.416-42.147 16.884-42.147 39.574 0 9.93 3.625 19.302 10.249 26.605.751.828.684 2.114-.106 2.904l-20.272 20.271c-.824.825-2.182.826-2.983-.022-13.403-14.191-20.451-32.879-19.707-52.587 1.482-39.224 33.248-69.71 72.499-69.695 39.977.017 72.494 32.544 72.494 72.524 0 18.664-7.002 36.252-19.764 49.763-.8.846-2.153.844-2.976.021'
          fill='#F0CB64'
        />
      </g>
    ),
    viewBox: '0 0 370 316',
  },
  bell: {
    path: (
      <path
        fill='currentColor'
        d='M3,18 L21,18 L21,16 L3,16 L3,18 L3,18 Z M3,13 L21,13 L21,11 L3,11 L3,13 L3,13 Z M3,6 L3,8 L21,8 L21,6 L3,6 L3,6 Z'
        id='Shape'
      />
    ),
  },
  'drag-handle': {
    path: (
      <g
        id='Material/Icons-black/snowman'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'>
        <g id='more_vert-24px'>
          <polygon id='Path' points='0 0 24 0 24 24 0 24' />
          <path
            d='M12,8 C13.1,8 14,7.1 14,6 C14,4.9 13.1,4 12,4 C10.9,4 10,4.9 10,6 C10,7.1 10.9,8 12,8 Z M12,10 C10.9,10 10,10.9 10,12 C10,13.1 10.9,14 12,14 C13.1,14 14,13.1 14,12 C14,10.9 13.1,10 12,10 Z M12,16 C10.9,16 10,16.9 10,18 C10,19.1 10.9,20 12,20 C13.1,20 14,19.1 14,18 C14,16.9 13.1,16 12,16 Z'
            id='Shape'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </g>
    ),
  },
  link: {
    path: (
      <path
        fill='currentColor'
        d='M3.9,12 C3.9,10.29 5.29,8.9 7,8.9 L11,8.9 L11,7 L7,7 C4.24,7 2,9.24 2,12 C2,14.76 4.24,17 7,17 L11,17 L11,15.1 L7,15.1 C5.29,15.1 3.9,13.71 3.9,12 Z M8,13 L16,13 L16,11 L8,11 L8,13 Z M17,7 L13,7 L13,8.9 L17,8.9 C18.71,8.9 20.1,10.29 20.1,12 C20.1,13.71 18.71,15.1 17,15.1 L13,15.1 L13,17 L17,17 C19.76,17 22,14.76 22,12 C22,9.24 19.76,7 17,7 Z'
        id='Shape'
        fillRule='nonzero'
      />
    ),
  },
  sun: {
    path: (
      <path
        fill='currentColor'
        d='M19,5 L19,19 L5,19 L5,5 L19,5 M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M14.14,11.86 L11.14,15.73 L9,13.14 L6,17 L18,17 L14.14,11.86 Z'
        id='Shape'
        fillRule='nonzero'
      />
    ),
  },
  spinner: {
    path: (
      <path
        fill='currentColor'
        d='M17,10.5 L17,7 C17,6.45 16.55,6 16,6 L4,6 C3.45,6 3,6.45 3,7 L3,17 C3,17.55 3.45,18 4,18 L16,18 C16.55,18 17,17.55 17,17 L17,13.5 L21,17.5 L21,6.5 L17,10.5 Z'
        id='Path'
      />
    ),
  },
  phone: {
    path: (
      <g fill='none'>
        <g id='Group-3'>
          <path
            id='Fill-1'
            d='M6.59,37.61a.24.24,0,0,1-.35,0,22,22,0,0,1-4.52-6.85A22.28,22.28,0,0,1,6.41,6.49a21.89,21.89,0,0,1,35.69,7,22.28,22.28,0,0,1-4.51,24.08.25.25,0,0,1-.36,0l-2.4-2.43a.24.24,0,0,1,0-.34h0a18.33,18.33,0,0,0,0-25.39A17.88,17.88,0,0,0,9.56,8.87l-.23.23A18.34,18.34,0,0,0,9,34.83a.26.26,0,0,1,0,.35Z'
            fill='#e05535'
            fillRule='evenodd'
          />
        </g>
        <path
          id='Fill-4'
          d='M10.94,32.83a15.54,15.54,0,0,1-.29-21.11A15.14,15.14,0,0,1,32,10.6l.38.35a15.52,15.52,0,0,1,.45,21.88.24.24,0,0,1-.34,0h0L30.1,30.42a.26.26,0,0,1,0-.35,11.56,11.56,0,0,0,.25-15.6,11.26,11.26,0,0,0-15.9-.94l-.1.09A11.56,11.56,0,0,0,13.64,30l.11.12a.24.24,0,0,1,0,.34h0L11.3,32.83a.25.25,0,0,1-.35,0h0'
          fill='#e68c4e'
          fillRule='evenodd'
        />
        <path
          id='Fill-6'
          d='M27.8,28.1l-2.4-2.44a.25.25,0,0,1,0-.34,4.7,4.7,0,1,0-7,0,.26.26,0,0,1,0,.35L16,28.1a.22.22,0,0,1-.32,0l0,0a8.7,8.7,0,0,1-2.34-6.27,8.6,8.6,0,1,1,14.84,6.29.22.22,0,0,1-.32,0l0,0'
          fill='#f5cf63'
          fillRule='evenodd'
        />
        <path
          id='Fill-8'
          d='M53.21,22.15a6,6,0,0,1,.68-2.87,5.05,5.05,0,0,1,1.89-2,5.25,5.25,0,0,1,2.76-.72,5,5,0,0,1,3.2,1,4.85,4.85,0,0,1,1.67,2.68H61.12a2.47,2.47,0,0,0-2.58-1.69,2.83,2.83,0,0,0-2.28,1,3.78,3.78,0,0,0-.87,2.58,3.85,3.85,0,0,0,.87,2.6,3.23,3.23,0,0,0,4,.52,2.58,2.58,0,0,0,1-1.32H63.5a5,5,0,0,1-1.68,2.79,5.06,5.06,0,0,1-3.23,1A5.29,5.29,0,0,1,55.82,27a5.05,5.05,0,0,1-1.89-2,6.1,6.1,0,0,1-.68-2.87'
          fill='#17294c'
          fillRule='evenodd'
        />
        <path
          id='Fill-10'
          d='M68.55,23.31h2.92l-1.26-3.37-.2-.6-.19.6Zm-3.8,4.23,4.12-10.71h2.3l4.12,10.76H73l-.88-2.35H67.9l-.9,2.3Z'
          fill='#17294c'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-12'
          points='77.81 27.53 77.81 16.77 80.21 16.77 83.26 23.71 83.48 24.34 83.72 23.71 86.78 16.77 89.19 16.77 89.19 27.53 87.1 27.53 87.1 20.73 86.8 21.67 84.38 27.53 82.62 27.53 80.2 21.67 79.89 20.73 79.89 27.53 77.81 27.53'
          fill='#17294c'
          fillRule='evenodd'
        />
        <path
          id='Fill-14'
          d='M94.64,21.74H96a1.74,1.74,0,0,0,1.18-.39,1.62,1.62,0,0,0,0-2.21A1.74,1.74,0,0,0,96,18.75H94.64Zm-2.09,5.8V16.83H96a3.8,3.8,0,0,1,2.72,1,3.28,3.28,0,0,1,1,2.51,3.35,3.35,0,0,1-1,2.5,3.8,3.8,0,0,1-2.72,1H94.64v3.82Z'
          fill='#17294c'
          fillRule='evenodd'
        />
        <path
          id='Fill-16'
          d='M103.86,23.31h2.91l-1.26-3.37-.2-.6-.19.6Zm-3.81,4.23,4.12-10.71h2.3l4.12,10.76h-2.26l-.89-2.35H103.2l-.88,2.35Z'
          fill='#17294c'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-18'
          points='113.41 27.53 113.41 16.77 115.48 16.77 119.85 23.29 120.29 24.03 120.29 16.77 122.37 16.77 122.37 27.53 120.29 27.53 115.91 21.04 115.5 20.32 115.5 27.53 113.41 27.53'
          fill='#17294c'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-20'
          points='125.74 27.53 127.83 27.53 127.83 16.77 125.74 16.77 125.74 27.53'
          fill='#17294c'
          fillRule='evenodd'
        />
        <path
          id='Fill-22'
          d='M132.86,22.15a3.71,3.71,0,0,0,.92,2.6,3.38,3.38,0,0,0,4.78.06l.06-.06a3.81,3.81,0,0,0,.92-2.6,3.65,3.65,0,0,0-.92-2.58,3.4,3.4,0,0,0-4.81,0l0,0a3.65,3.65,0,0,0-.92,2.58m-2.17,0a5.88,5.88,0,0,1,.41-2.23,5.36,5.36,0,0,1,1.13-1.76A5.23,5.23,0,0,1,134,17a5.89,5.89,0,0,1,2.23-.42,5.66,5.66,0,0,1,2.84.72,4.94,4.94,0,0,1,2,2,6.14,6.14,0,0,1,0,5.74,5.06,5.06,0,0,1-2,2,6,6,0,0,1-5.7,0,4.94,4.94,0,0,1-2-2,5.75,5.75,0,0,1-.7-2.87'
          fill='#17294c'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-23'
          points='143.96 27.53 143.96 16.77 146.03 16.77 150.4 23.29 150.83 24.03 150.83 16.77 152.92 16.77 152.92 27.53 150.83 27.53 146.45 21.04 146.04 20.32 146.04 27.53 143.96 27.53'
          fill='#17294c'
          fillRule='evenodd'
        />
        <path
          d='M58.17,36.37H55.6l-.49,1.46H53.55l2.65-7.11h1.36l2.66,7.11H58.66ZM56,35.18h1.78l-.89-2.66Z'
          fill='#7f899c'
        />
        <path
          d='M61.88,37.83V30.72h2.19a3.38,3.38,0,0,1,1.68.42,3,3,0,0,1,1.15,1.2,3.64,3.64,0,0,1,.42,1.77v.33a3.7,3.7,0,0,1-.41,1.76,3,3,0,0,1-1.15,1.2,3.29,3.29,0,0,1-1.68.43Zm1.47-5.92v4.74h.7a1.56,1.56,0,0,0,1.32-.56,2.54,2.54,0,0,0,.46-1.6v-.38a2.59,2.59,0,0,0-.45-1.64,1.54,1.54,0,0,0-1.31-.56Z'
          fill='#7f899c'
        />
        <path
          d='M71.29,30.72l1.83,5.16,1.82-5.16h1.92v7.11H75.39V35.89l.15-3.36-1.92,5.3h-1L70.7,32.54l.15,3.35v1.94H69.38V30.72Z'
          fill='#7f899c'
        />
        <path d='M80.7,37.83H79.23V30.72H80.7Z' fill='#7f899c' />
        <path
          d='M88.84,37.83H87.37l-2.85-4.68v4.68H83.06V30.72h1.46l2.86,4.69V30.72h1.46Z'
          fill='#7f899c'
          fillRule='evenodd'
        />
      </g>
    ),
    viewBox: '0 0 152.92 37.83',
  },
  moon: {
    path: (
      <g>
        <g id='Group-3'>
          <path
            id='Fill-1'
            d='M6.59,37.61a.24.24,0,0,1-.35,0,22,22,0,0,1-4.52-6.85A22.28,22.28,0,0,1,6.41,6.49a21.89,21.89,0,0,1,35.69,7,22.28,22.28,0,0,1-4.51,24.08.25.25,0,0,1-.36,0l-2.4-2.43a.24.24,0,0,1,0-.34h0a18.33,18.33,0,0,0,0-25.39A17.88,17.88,0,0,0,9.56,8.87l-.23.23A18.34,18.34,0,0,0,9,34.83a.26.26,0,0,1,0,.35Z'
            fill='#e05535'
            fillRule='evenodd'
          />
        </g>
        <path
          id='Fill-4'
          d='M10.94,32.83a15.54,15.54,0,0,1-.29-21.11A15.14,15.14,0,0,1,32,10.6l.38.35a15.52,15.52,0,0,1,.45,21.88.24.24,0,0,1-.34,0h0L30.1,30.42a.26.26,0,0,1,0-.35,11.56,11.56,0,0,0,.25-15.6,11.26,11.26,0,0,0-15.9-.94l-.1.09A11.56,11.56,0,0,0,13.64,30l.11.12a.24.24,0,0,1,0,.34h0L11.3,32.83a.25.25,0,0,1-.35,0h0'
          fill='#e68c4e'
          fillRule='evenodd'
        />
        <path
          id='Fill-6'
          d='M27.8,28.1l-2.4-2.44a.25.25,0,0,1,0-.34,4.7,4.7,0,1,0-7,0,.26.26,0,0,1,0,.35L16,28.1a.22.22,0,0,1-.32,0l0,0a8.7,8.7,0,0,1-2.34-6.27,8.6,8.6,0,1,1,14.84,6.29.22.22,0,0,1-.32,0l0,0'
          fill='#f5cf63'
          fillRule='evenodd'
        />
        <path
          id='Fill-8'
          d='M53.21,22.15a6,6,0,0,1,.68-2.87,5.05,5.05,0,0,1,1.89-2,5.25,5.25,0,0,1,2.76-.72,5,5,0,0,1,3.2,1,4.85,4.85,0,0,1,1.67,2.68H61.12a2.47,2.47,0,0,0-2.58-1.69,2.83,2.83,0,0,0-2.28,1,3.78,3.78,0,0,0-.87,2.58,3.85,3.85,0,0,0,.87,2.6,3.23,3.23,0,0,0,4,.52,2.58,2.58,0,0,0,1-1.32H63.5a5,5,0,0,1-1.68,2.79,5.06,5.06,0,0,1-3.23,1A5.29,5.29,0,0,1,55.82,27a5.05,5.05,0,0,1-1.89-2,6.1,6.1,0,0,1-.68-2.87'
          fill='#fff'
          fillRule='evenodd'
        />
        <path
          id='Fill-10'
          d='M68.55,23.31h2.92l-1.26-3.37-.2-.6-.19.6Zm-3.8,4.23,4.12-10.71h2.3l4.12,10.76H73l-.88-2.35H67.9l-.9,2.3Z'
          fill='#fff'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-12'
          points='77.81 27.53 77.81 16.77 80.21 16.77 83.26 23.71 83.48 24.34 83.72 23.71 86.78 16.77 89.19 16.77 89.19 27.53 87.1 27.53 87.1 20.73 86.8 21.67 84.38 27.53 82.62 27.53 80.2 21.67 79.89 20.73 79.89 27.53 77.81 27.53'
          fill='#fff'
          fillRule='evenodd'
        />
        <path
          id='Fill-14'
          d='M94.64,21.74H96a1.74,1.74,0,0,0,1.18-.39,1.62,1.62,0,0,0,0-2.21A1.74,1.74,0,0,0,96,18.75H94.64Zm-2.09,5.8V16.83H96a3.8,3.8,0,0,1,2.72,1,3.28,3.28,0,0,1,1,2.51,3.35,3.35,0,0,1-1,2.5,3.8,3.8,0,0,1-2.72,1H94.64v3.82Z'
          fill='#fff'
          fillRule='evenodd'
        />
        <path
          id='Fill-16'
          d='M103.86,23.31h2.91l-1.26-3.37-.2-.6-.19.6Zm-3.81,4.23,4.12-10.71h2.3l4.12,10.76h-2.26l-.89-2.35H103.2l-.88,2.35Z'
          fill='#fff'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-18'
          points='113.41 27.53 113.41 16.77 115.48 16.77 119.85 23.29 120.29 24.03 120.29 16.77 122.37 16.77 122.37 27.53 120.29 27.53 115.91 21.04 115.5 20.32 115.5 27.53 113.41 27.53'
          fill='#fff'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-20'
          points='125.74 27.53 127.83 27.53 127.83 16.77 125.74 16.77 125.74 27.53'
          fill='#fff'
          fillRule='evenodd'
        />
        <path
          id='Fill-22'
          d='M132.86,22.15a3.71,3.71,0,0,0,.92,2.6,3.38,3.38,0,0,0,4.78.06l.06-.06a3.81,3.81,0,0,0,.92-2.6,3.65,3.65,0,0,0-.92-2.58,3.4,3.4,0,0,0-4.81,0l0,0a3.65,3.65,0,0,0-.92,2.58m-2.17,0a5.88,5.88,0,0,1,.41-2.23,5.36,5.36,0,0,1,1.13-1.76A5.23,5.23,0,0,1,134,17a5.89,5.89,0,0,1,2.23-.42,5.66,5.66,0,0,1,2.84.72,4.94,4.94,0,0,1,2,2,6.14,6.14,0,0,1,0,5.74,5.06,5.06,0,0,1-2,2,6,6,0,0,1-5.7,0,4.94,4.94,0,0,1-2-2,5.75,5.75,0,0,1-.7-2.87'
          fill='#fff'
          fillRule='evenodd'
        />
        <polygon
          id='Fill-23'
          points='143.96 27.53 143.96 16.77 146.03 16.77 150.4 23.29 150.83 24.03 150.83 16.77 152.92 16.77 152.92 27.53 150.83 27.53 146.45 21.04 146.04 20.32 146.04 27.53 143.96 27.53'
          fill='#fff'
          fillRule='evenodd'
        />
        <g opacity={0.6000000238418579}>
          <path
            d='M58.17,36.37H55.6l-.49,1.46H53.55l2.65-7.11h1.36l2.66,7.11H58.66ZM56,35.18h1.78l-.89-2.66Z'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M61.88,37.83V30.72h2.19a3.38,3.38,0,0,1,1.68.42,3,3,0,0,1,1.15,1.2,3.64,3.64,0,0,1,.42,1.77v.33a3.7,3.7,0,0,1-.41,1.76,3,3,0,0,1-1.15,1.2,3.29,3.29,0,0,1-1.68.43Zm1.47-5.92v4.74h.7a1.56,1.56,0,0,0,1.32-.56,2.54,2.54,0,0,0,.46-1.6v-.38a2.59,2.59,0,0,0-.45-1.64,1.54,1.54,0,0,0-1.31-.56Z'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M71.29,30.72l1.83,5.16,1.82-5.16h1.92v7.11H75.39V35.89l.15-3.36-1.92,5.3h-1L70.7,32.54l.15,3.35v1.94H69.38V30.72Z'
            fill='#fff'
            fillRule='evenodd'
          />
          <path d='M80.7,37.83H79.23V30.72H80.7Z' fill='#fff' />
          <path
            d='M88.84,37.83H87.37l-2.85-4.68v4.68H83.06V30.72h1.46l2.86,4.69V30.72h1.46Z'
            fill='#fff'
            fillRule='evenodd'
          />
        </g>
      </g>
    ),
    viewBox: '0 0 152.92 37.83',
  },
  snowman: {
    path: (
      <svg width='24px' height='24px'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path
          fill='currentColor'
          d='M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  camera: {
    path: (
      <g>
        <g>
          <path d='M0 0h24v24H0z' fill='none' />
          <path
            fill='white'
            d='M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z'
          />
        </g>
      </g>
    ),
    viewBox: '0 0 24 24',
  },
  pen: {
    path: (
      <g>
        <g>
          <path d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
        </g>
      </g>
    ),
    viewBox: '0 0 24 24',
  },
  panorama: {
    path: (
      <g>
        <g>
          <path d='M19,5V19H5V5H19m0-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm-4.86,8.86-3,3.87L9,13.14,6,17H18Z' />
        </g>
      </g>
    ),
    viewBox: '0 0 24 24',
  },
  people: {
    path: (
      <svg fill='white' width='24px' height='24px'>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z' />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  'add-group': {
    path: (
      <svg width='26px' height='24px'>
        <g
          id='Texting'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'>
          <g
            id='Audience---empty'
            transform='translate(-706.000000, -288.000000)'>
            <g
              id='group_add-24px'
              transform='translate(706.000000, 288.000000)'>
              <polygon id='Path' points='2 0 26 0 26 24 2 24' />
              <path
                d='M8,10 L5,10 L5,7 L3,7 L3,10 L0,10 L0,12 L3,12 L3,15 L5,15 L5,12 L8,12 L8,10 Z M18,11 C19.66,11 20.99,9.66 20.99,8 C20.99,6.34 19.66,5 18,5 C17.68,5 17.37,5.05 17.09,5.14 C17.66,5.95 17.99,6.93 17.99,8 C17.99,9.07 17.65,10.04 17.09,10.86 C17.37,10.95 17.68,11 18,11 Z M13,11 C14.66,11 15.99,9.66 15.99,8 C15.99,6.34 14.66,5 13,5 C11.34,5 10,6.34 10,8 C10,9.66 11.34,11 13,11 Z M19.62,13.16 C20.45,13.89 21,14.82 21,16 L21,18 L24,18 L24,16 C24,14.46 21.63,13.51 19.62,13.16 Z M13,13 C11,13 7,14 7,16 L7,18 L19,18 L19,16 C19,14 15,13 13,13 Z'
                id='Shape'
                fill='#17294C'
                fillRule='nonzero'
              />
            </g>
          </g>
        </g>
      </svg>
    ),
    viewBox: '0 0 26 24',
  },
  'sort-asc': {
    path: (
      <svg width='1em' height='1em' fill='currentColor'>
        <path d='M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' />
      </svg>
    ),
    viewBox: '0 0 16 16',
  },
  'sort-dsc': {
    path: (
      <svg width='1em' height='1em' fill='currentColor'>
        <path d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
      </svg>
    ),
    viewBox: '0 0 16 16',
  },
  'next-arrow': {
    path: (
      <svg height='24' width='24'>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path
          d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'
          fill='currentColor'
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  'prev-arrow': {
    path: (
      <svg height='24' width='24'>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path
          d='M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z'
          fill='currentColor'
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  audience: {
    path: (
      <g fill='currentColor' fillRule='evenodd'>
        <g fill='currentColor' fillRule='nonzero'>
          <path d='M22.05,27.5 C14.679,27.5 0,31.1771429 0,38.5 L0,44 L44.1,44 L44.1,38.5 C44.1,31.1771429 29.421,27.5 22.05,27.5 Z M7.371,37.7142857 C10.017,35.8914286 16.4115,33.7857143 22.05,33.7857143 C27.6885,33.7857143 34.083,35.8914286 36.729,37.7142857 L7.371,37.7142857 Z M22.05,22 C28.1295,22 33.075,17.0657143 33.075,11 C33.075,4.93428571 28.1295,0 22.05,0 C15.9705,0 11.025,4.93428571 11.025,11 C11.025,17.0657143 15.9705,22 22.05,22 Z M22.05,6.28571429 C24.6645,6.28571429 26.775,8.39142857 26.775,11 C26.775,13.6085714 24.6645,15.7142857 22.05,15.7142857 C19.4355,15.7142857 17.325,13.6085714 17.325,11 C17.325,8.39142857 19.4355,6.28571429 22.05,6.28571429 Z M44.226,27.6885714 C47.88,30.3285714 50.4,33.8485714 50.4,38.5 L50.4,44 L63,44 L63,38.5 C63,32.1514286 51.975,28.5371429 44.226,27.6885714 L44.226,27.6885714 Z M40.95,22 C47.0295,22 51.975,17.0657143 51.975,11 C51.975,4.93428571 47.0295,0 40.95,0 C39.249,0 37.674,0.408571429 36.225,1.1 C38.2095,3.89714286 39.375,7.32285714 39.375,11 C39.375,14.6771429 38.2095,18.1028571 36.225,20.9 C37.674,21.5914286 39.249,22 40.95,22 Z' />
        </g>
      </g>
    ),
    viewBox: '0 0 58 40',
  },
  'question-outline': {
    path: (
      <g>
        <g>
          <path d='M0,0H24V24H0Z' fill='none' />
          <path
            d='M20,4V16H8V4H20m0-2H8A2,2,0,0,0,6,4V16a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V4A2,2,0,0,0,20,2Zm-8.5,9.67,1.69,2.26,2.48-3.1L19,15H9ZM2,6V20a2,2,0,0,0,2,2H18V20H4V6Z'
            fill='#17294c'
          />
        </g>
      </g>
    ),
    viewBox: '0 0 24 24',
  },
  widgets: {
    path: (
      <svg fill='white' width='24px' height='24px'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path
          fill='currentColor'
          d='M13 13v8h8v-8h-8zM3 21h8v-8H3v8zM3 3v8h8V3H3zm13.66-1.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65z'
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  sync: {
    path: (
      <svg height='24' width='24'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path
          fill='currentColor'
          d='M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z'
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  home: {
    path: (
      <svg fill='white' width='24px' height='24px'>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z' />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  reminder: {
    path: (
      <svg fill='white' width='24px' height='24px'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z' />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  launch: {
    path: (
      <svg fill='black' width='24px' height='24px'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  notification: {
    path: (
      <svg fill='#747474' width='24px' height='24px'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z' />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  mask: {
    path: (
      <svg fill='white' width='24px' height='24px'>
        <rect fill='none' height='24' width='24' />
        <path d='M19.5,6c-1.31,0-2.37,1.01-2.48,2.3C15.14,7.8,14.18,6.5,12,6.5c-2.19,0-3.14,1.3-5.02,1.8C6.87,7.02,5.81,6,4.5,6 C3.12,6,2,7.12,2,8.5V9c0,6,3.6,7.81,6.52,7.98C9.53,17.62,10.72,18,12,18s2.47-0.38,3.48-1.02C18.4,16.81,22,15,22,9V8.5 C22,7.12,20.88,6,19.5,6z M3.5,9V8.5c0-0.55,0.45-1,1-1s1,0.45,1,1v3c0,1.28,0.38,2.47,1.01,3.48C4.99,14.27,3.5,12.65,3.5,9z M20.5,9c0,3.65-1.49,5.27-3.01,5.98c0.64-1.01,1.01-2.2,1.01-3.48v-3c0-0.55,0.45-1,1-1s1,0.45,1,1V9z M10.69,10.48 c-0.44,0.26-0.96,0.56-1.69,0.76V10.2c0.48-0.17,0.84-0.38,1.18-0.58C10.72,9.3,11.23,9,12,9s1.27,0.3,1.8,0.62 c0.34,0.2,0.71,0.42,1.2,0.59v1.04c-0.75-0.21-1.26-0.51-1.71-0.78C12.83,10.2,12.49,10,12,10C11.51,10,11.16,10.2,10.69,10.48z' />
      </svg>
    ),
  },
  register: {
    path: (
      <svg fill='white' width='24px' height='24px'>
        <rect fill='none' height='24' width='24' />
        <path d='M11 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM5 18c.2-.63 2.57-1.68 4.96-1.94l2.04-2c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-2-2H5zm15.6-5.5l-5.13 5.17-2.07-2.08L12 17l3.47 3.5L22 13.91z' />
      </svg>
    ),
  },
  info: {
    viewBox: '0 0 45 45',
    path: (
      <svg width='45px' height='45px'>
        <circle id='Oval' fill='currentColor' cx='22.5' cy='22.5' r='22.5' />
        <path
          d='M30.9230769,11.7692308 L14.7692308,11.7692308 C13.5,11.7692308 12.4615385,12.8076923 12.4615385,14.0769231 L12.4615385,30.2307692 C12.4615385,31.5 13.5,32.5384615 14.7692308,32.5384615 L19.3846154,32.5384615 L22.8461538,36 L26.3076923,32.5384615 L30.9230769,32.5384615 C32.1923077,32.5384615 33.2307692,31.5 33.2307692,30.2307692 L33.2307692,14.0769231 C33.2307692,12.8076923 32.1923077,11.7692308 30.9230769,11.7692308 Z M25.0153846,24.3230769 L22.8461538,29.0769231 L20.6769231,24.3230769 L15.9230769,22.1538462 L20.6769231,19.9846154 L22.8461538,15.2307692 L25.0153846,19.9846154 L29.7692308,22.1538462 L25.0153846,24.3230769 Z'
          id='Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </svg>
    ),
  },
  texting: {
    path: (
      <svg height='24px' width='24px' fill='currentColor'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z' />
      </svg>
    ),
  },
  'blue-info': {
    viewBox: '0 0 45 45',
    path: (
      <g
        xmlns='http://www.w3.org/2000/svg'
        id='Admin---Texting'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'>
        <g id='Blue-Info'>
          <circle id='Oval' fill='#17294C' cx='22.5' cy='22.5' r='22.5' />
          <path
            d='M30.9230769,11.7692308 L14.7692308,11.7692308 C13.5,11.7692308 12.4615385,12.8076923 12.4615385,14.0769231 L12.4615385,30.2307692 C12.4615385,31.5 13.5,32.5384615 14.7692308,32.5384615 L19.3846154,32.5384615 L22.8461538,36 L26.3076923,32.5384615 L30.9230769,32.5384615 C32.1923077,32.5384615 33.2307692,31.5 33.2307692,30.2307692 L33.2307692,14.0769231 C33.2307692,12.8076923 32.1923077,11.7692308 30.9230769,11.7692308 Z M25.0153846,24.3230769 L22.8461538,29.0769231 L20.6769231,24.3230769 L15.9230769,22.1538462 L20.6769231,19.9846154 L22.8461538,15.2307692 L25.0153846,19.9846154 L29.7692308,22.1538462 L25.0153846,24.3230769 Z'
            id='Shape'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
        </g>
      </g>
    ),
  },
  'info-list-alt': {
    path: (
      <svg
        width='28'
        height='28'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19 5V19H5V5H19ZM20.1 3H3.9C3.4 3 3 3.4 3 3.9V20.1C3 20.5 3.4 21 3.9 21H20.1C20.5 21 21 20.5 21 20.1V3.9C21 3.4 20.5 3 20.1 3ZM11 7H17V9H11V7ZM11 11H17V13H11V11ZM11 15H17V17H11V15ZM7 7H9V9H7V7ZM7 11H9V13H7V11ZM7 15H9V17H7V15Z'
          fill={appTheme.colors.info}
        />
      </svg>
    ),
  },
}
export const CustomIcon: FC<IconProps> = (props) => {
  const {name} = props

  return (
    <CIcon viewBox={customIcons[name]?.viewBox} {...props}>
      {customIcons[name].path}
    </CIcon>
  )
}
