import React from 'react'
import {
  Button,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react'
import {CheckIcon, WarningIcon} from '@chakra-ui/icons'
import moment from 'moment'
import {MdSwapVert} from 'react-icons/md'

import {ActionButton, CustomIcon, Visible} from '../../../components'
import {AlbumSortType, PropsOf} from '../../../types'
import {simplePluralizeWord} from '../../../utils'
import copy from '../copy.json'

import {useAlbumViewState, useAlbumViewDispatch} from '../AlbumViewManager'
import {
  useAlbumPhotosDispatch,
  useAlbumPhotosState,
} from '../AlbumPhotosManager'

import {INSERT_PLACEHOLDER_ID} from '../../../constants'
import {useAlbumService} from '../../../services/useAlbumService'
import {useAlbumState} from '../AlbumManager'
import {useConfirmation} from '../../../app/ConfirmationManager'
import {ORDERED_PHOTO_SORT_TYPES} from '../types'
import {isLegacyAlbum} from '../../../utils/isLegacyAlbum'
import {getScheduleText} from '../../../utils/getScheduleText'
import {appTheme} from '../../../app/theme'

const Divider: React.FC<React.PropsWithChildren> = () => (
  <Box mx={2} display='inline-block'>
    |
  </Box>
)

export const AlbumDetails: React.FC<PropsOf<typeof Flex>> = ({...rest}) => {
  const {data: album} = useAlbumState()
  const {viewMode} = useAlbumViewState()
  const albumViewDispatch = useAlbumViewDispatch()
  const {status, failedProcessingFiles, resetPhotos} = useAlbumPhotosState()
  const albumPhotosDispatch = useAlbumPhotosDispatch()
  const {upsert} = useAlbumService()
  const showConfirmation = useConfirmation()

  const sort = ORDERED_PHOTO_SORT_TYPES.find(
    (pst) => pst.name === album?.sortType,
  )

  const onChangeSort = async (sortType: AlbumSortType): Promise<void> => {
    albumPhotosDispatch({type: 'SORTING'})

    const updatedAlbum = {
      ...album,

      // Need to revisit this. Should not have to use placeholders
      ID: album ? album.ID : INSERT_PLACEHOLDER_ID,
      name: album ? album.name : 'Placeholder',
      date: album ? album.date : new Date('12/1/2021'),
      sortType,
      constraints: [],
    }

    await upsert(updatedAlbum)

    albumPhotosDispatch({type: 'RESET'})

    resetPhotos()
  }

  const handleOnSortChanged = async (
    sortType: AlbumSortType,
  ): Promise<void> => {
    if (album?.sortType === 'Custom') {
      showConfirmation({
        variant: 'danger',
        title: copy.confirmations.sort.title,
        description: `${copy.confirmations.sort.description}${sortType}?`,
        confirmButtonText: copy.confirmations.sort.buttonConfirmation,
        testID: 'change-photo-sort-from-custom',
        onConfirm: (): Promise<void> => onChangeSort(sortType),
        cancelButtonProps: {
          bgColor: 'gray.200',
          borderRadius: '3px',
          width: '100px',
        },
        confirmButtonProps: {
          colorScheme: 'primary',
          borderRadius: '3px',
          width: '100px',
        },
      })
    } else {
      onChangeSort(sortType)
    }
  }

  if (!album) return null

  const disabledStyles = isLegacyAlbum(album)
    ? {
        _disabled: {
          backgroundColor: '#D8D8D8',
          color: '#7F7F7F',
        },
        _hover: {
          backgroundColor: '#D8D8D8',
          color: '#7F7F7F',
          cursor: 'not-allowed',
        },
      }
    : {}

  const scheduleText = getScheduleText(album)

  return (
    <Flex
      data-testid='album-details'
      alignItems='flex-start'
      flexDir={['column', 'row']}
      {...rest}>
      <Flex flexDir='column'>
        <Flex pb={[6, 0]} width={['100%', 'unset']}>
          <Box>
            <>{moment(album.date).format('LL')}</>
          </Box>
          <Visible when={!!album.photoCount}>
            <Flex>
              <Divider />
              {simplePluralizeWord('photo', album.photoCount)}
            </Flex>
          </Visible>
          <Visible when={status === 'processing'}>
            <Flex>
              <Divider />
              <Flex direction={['column', 'row']}>Processing ...</Flex>
            </Flex>
          </Visible>
          <Visible when={failedProcessingFiles.length > 0}>
            <Flex>
              <Divider />
              <Flex
                direction={['column', 'row']}
                alignItems='center'
                fontWeight='bold'>
                <WarningIcon color='red.500' mr={1} />

                <Button
                  variant='link'
                  ml={1}
                  fontSize='1em'
                  color='#000'
                  textDecoration='underline'
                  onClick={(): void => {
                    albumViewDispatch({
                      type: 'SET_VIEW_MODE',
                      payload: 'viewFailures',
                    })
                  }}>
                  {copy.albumDetails.uploadFailuresReportButton}
                </Button>
              </Flex>
            </Flex>
          </Visible>
          <Flex>
            <Visible
              when={album.photoCount > 0 || scheduleText !== 'Published'}>
              <Divider />
              {scheduleText}
            </Visible>
          </Flex>
        </Flex>

        <Flex>
          <Flex direction='column' paddingTop={1}>
            <Box
              paddingX={2}
              paddingY={1}
              border='1px solid #ced2d5'
              borderRadius='9999px'>
              <Flex
                data-testid='custom-audience-pull'
                aria-label='segment'
                color={appTheme.colors.lightText}
                fontWeight='normal'
                alignItems='center'>
                <CustomIcon name='audience' marginRight={2} />
                {album.constraints.length === 0
                  ? copy.details.constraintsPill.allAudiences
                  : copy.details.constraintsPill.customAudience}
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Visible when={viewMode === 'sortPhotos'}>
        <ActionButton
          data-testid='exit-sort-mobile'
          display={['initial', 'none']}
          width={['100%', 'unset']}
          onClick={(): void => {
            albumViewDispatch({type: 'SET_VIEW_MODE', payload: 'initial'})
          }}>
          <CheckIcon mr='2' />
          {copy.buttons.quitSort}
        </ActionButton>

        <Flex
          justifyContent='flex-end'
          flex='1'
          mt={[3, 0]}
          mb={[0, 1]}
          width={['100%', 'unset']}>
          <Menu placement='bottom-end'>
            <MenuButton
              as={Button}
              data-testid='sort-button'
              isDisabled={status === 'pending' || isLegacyAlbum(album)}
              bgColor='#dfe8ed'
              aria-label='more actions'
              px={3}
              py={2}
              borderRadius='3px'
              width={['100%', 'unset']}
              {...disabledStyles}>
              <Flex
                justifyContent='center'
                alignItems='center'
                width={['100%', 'unset']}>
                <Box mr={2} display='inline-block'>
                  <MdSwapVert size='1.75rem' />
                </Box>
                <Text fontWeight='bold' fontSize='14px' lineHeight='14px'>
                  {album.sortType}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList
              data-testid='sort-options-menu-list'
              width={['100%', 'unset']}>
              <Visible when={!isLegacyAlbum(album)}>
                {ORDERED_PHOTO_SORT_TYPES.filter(
                  (pst) => pst.name !== 'Custom',
                ).map((pst) => (
                  <MenuItem
                    key={pst.value}
                    data-testid='sort-menu-option'
                    onClick={(): void => {
                      handleOnSortChanged(pst.name)
                    }}>
                    <CheckIcon
                      mr={3}
                      opacity={pst.name === sort?.name ? 1 : 0}
                    />
                    {pst.name}
                  </MenuItem>
                ))}
              </Visible>
              <Visible
                when={!sort || sort.name === 'Custom' || isLegacyAlbum(album)}>
                <MenuItem data-testid='sort-menu-option'>
                  <CheckIcon mr={3} />
                  Custom
                </MenuItem>
              </Visible>
            </MenuList>
          </Menu>
        </Flex>
      </Visible>
    </Flex>
  )
}
