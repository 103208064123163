import React from 'react'
import {useModalContext, Step} from './ComposeAlbumModalContext'

interface Props {
  name: Step
}

export const ModalStep: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  children,
}) => {
  const {currentStep} = useModalContext()

  if (currentStep !== name) {
    return null
  }

  // This explains the unfortunate need for children to be Fragment wrapped
  // https://stackoverflow.com/questions/53688899/typescript-and-react-children-type
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
