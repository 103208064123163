import {http} from '../http'
import {APIResponse} from '../types'
import * as coreSegments from '../../coreApi/segments'
import {ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
  ID: ResourceID
}

type ResolvedReturn = APIResponse<null>

interface DeleteSegment {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.segments.deleteSegment()'

export const deleteSegment: DeleteSegment = async ({clientID, ID}) => {
  let resolvedReturn: ResolvedReturn

  const rawParamMap = {
    ClientID: parseInt(clientID, 10),
    ID: parseInt(ID, 10),
  }

  try {
    await coreSegments.delete(http, rawParamMap)

    resolvedReturn = {
      result: 'success',
      data: null,
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, 'general', e)
  }
  return Promise.resolve(resolvedReturn)
}
