import React from 'react'
import {Box, Button, Flex, Switch, Text} from '@chakra-ui/react'

import {ContentControl} from './subcomps/ContentControl'
import {Feature} from '../Feature/Feature'
import {HyperlinkAttachment} from './subcomps/HyperlinkAttachment'
import {MediaAttachment} from './subcomps/MediaAttachment'
import {ActionButton} from '../ActionButton/ActionButton'
import {
  useComposeMicropostState,
  useComposeMicropostActions,
} from './contextManagers/MicropostManager'
import {useAttachmentControlsState} from './contextManagers/AttachmentControlsManager'
import {useStatusToast} from '../../app/ToastManager'
import {useMicropostsStore} from '../../stores/MicropostsStore'
import {useModalContext} from './subcomps/Modal'
import {INSERT_PLACEHOLDER_ID} from '../../constants'
import copy from './copy.json'
import {CustomIcon} from '../CustomIcon/CustomIcon'
import {appTheme} from '../../app/theme'

const Header: React.FC<React.PropsWithChildren> = () => {
  const {publishDate} = useComposeMicropostState()

  return !publishDate ? (
    <Box textAlign='center' flex={1}>
      New Micropost
    </Box>
  ) : (
    <Box textAlign='center' flex={1}>
      Edit Micropost
    </Box>
  )
}

const Body: React.FC<React.PropsWithChildren> = () => {
  const {setCurrentStep} = useModalContext()
  const {segmentID} = useComposeMicropostState()
  const {isImageUploading} = useAttachmentControlsState()

  return (
    <Flex direction='column' h={['100%']}>
      <Flex px={6} py={2}>
        <Feature name='segment-microposts'>
          <Flex direction='column'>
            <Box p={1}>
              <Button
                data-testid='segment-button'
                aria-label='segment'
                leftIcon={<CustomIcon name='audience' />}
                variant='outline'
                rounded='full'
                size='sm'
                color={appTheme.colors.lightText}
                fontWeight='normal'
                isDisabled={isImageUploading}
                onClick={(): void => setCurrentStep('segment')}>
                {!segmentID
                  ? copy.buttons.segment.all
                  : copy.buttons.segment.custom}
              </Button>
            </Box>
          </Flex>
        </Feature>
      </Flex>

      <ContentControl px={6} />

      <HyperlinkAttachment.Control />

      <MediaAttachment.Control />
    </Flex>
  )
}

const Footer: React.FC<{onClose(): void}> = ({onClose}) => {
  const {isVideoValid, isHyperlinkValid, ...micropost} =
    useComposeMicropostState()
  const {isImageUploading} = useAttachmentControlsState()
  const {createMicropost, updateMicropost} = useMicropostsStore()
  const [isLoading, setIsLoading] = React.useState(false)
  const {setValue} = useComposeMicropostActions()

  const {toast} = useStatusToast()

  const isValidToSubmit = (): boolean => {
    if (
      micropost.content.length &&
      isVideoValid &&
      isHyperlinkValid &&
      !isImageUploading
    ) {
      return true
    }

    return false
  }

  const handleOnSubmit = async (): Promise<void> => {
    setIsLoading(true)

    const response =
      micropost.ID === INSERT_PLACEHOLDER_ID
        ? await createMicropost(micropost)
        : await updateMicropost(micropost)

    setIsLoading(false)

    if (response.status !== 'success') {
      toast.error(response.message)
    } else {
      onClose()
    }
  }

  const toggleWillSendNotification = (): void => {
    setValue('notify', !micropost.notify)
  }

  return (
    <Flex direction={['column', 'row']} flex='1 1 auto'>
      <Flex flex={[1]} justifyContent={['center', 'flex-start']}>
        <HyperlinkAttachment.ControlToggle />
        <MediaAttachment.ImageControlToggle />
        <MediaAttachment.VideoControlToggle />
      </Flex>
      {!micropost.publishDate ? (
        <Flex
          flex={[2]}
          justifyContent={['space-between', 'flex-end']}
          alignItems={['flex-start', 'center']}
          pr={[0, 10]}
          pt={['10px', 0]}
          pb={['20px', '5px']}>
          <Text pr={[0, 5]} color='#69747D'>
            Send push notification
          </Text>
          <Switch
            pt={[0, '5px']}
            data-testid='push-slider'
            size='lg'
            colorScheme='darkgreen'
            variant='info'
            onChange={toggleWillSendNotification}
            isChecked={micropost.notify}
          />
        </Flex>
      ) : (
        <Flex
          flex={[2]}
          justifyContent={['space-between', 'flex-end']}
          alignItems={['center']}
          pr={[0, 10]}
          pb={['20px', '5px']}>
          <Text pr={[0, 5]} color='#69747D'>
            Push notifications can only be sent for new Microposts
          </Text>
          <Switch
            data-testid='push-slider'
            size='lg'
            variant='info'
            ml={['20px', 0]}
            onChange={toggleWillSendNotification}
            isChecked={micropost.notify}
            isDisabled
          />
        </Flex>
      )}

      <ActionButton
        // mr={3}
        onClick={handleOnSubmit}
        isLoading={isLoading}
        loadingText={copy.buttons.confirm.loadingText}
        isDisabled={!isValidToSubmit()}>
        {copy.buttons.confirm.text}
      </ActionButton>
    </Flex>
  )
}

export const ComposeStepUI = {
  Header,
  Body,
  Footer,
}
