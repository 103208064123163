interface ParseVideoURLReturn {
  type: 'youtube' | 'vimeo' | 'unsupported'
  id: string
}

export const parseVideoURL = (url: string): ParseVideoURLReturn => {
  // - Supported YouTube URL formats:
  //   - www.youtube.com/watch?v=My2FRPA3Gf8
  //   - youtu.be/My2FRPA3Gf8
  //   - youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - vimeo.com/25451551
  //   - player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    // eslint-disable-next-line no-useless-escape
    /(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/,
  )

  let type: ParseVideoURLReturn['type'] = 'unsupported'
  if (RegExp.$2.indexOf('youtu') > -1) {
    type = 'youtube'
  } else if (RegExp.$2.indexOf('vimeo') > -1) {
    type = 'vimeo'
  }

  return {
    type,
    id: RegExp.$5,
  }
}
