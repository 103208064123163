import React, {useState} from 'react'
import {
  Box,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {log, setLogContext} from '@vanguard/logger'

import {SegmentGroup, EzTextingGroup} from '../../types'
import {ActionButton} from '..'
import {OptionSelector, OptionList} from '../OptionSelector/OptionSelector'
import {api} from '../../services/api'
import {useStatusToast} from '../../app/ToastManager'
import copy from './copy.json'
import {Visible} from '../Visible/Visible'
import {FormSelect} from '../FormSelect/FormSelect'
import {FormTextArea} from '../FormTextArea/FormTextArea'
import {FormInput} from '../FormInput/FormInput'

export const AUDIENCE_PLACEHOLDER_TEXT = 'Select Audience'
export const NAME_INPUT_PLACEHOLDER_TEXT =
  'This name will display in EZ Texting.'
export const MAX_GROUPNAME_LENGTH = 12
export const MAX_DESCRIPTION_LENGTH = 100
export const NAME_CONSTRAINT_TEXT = `${MAX_GROUPNAME_LENGTH} character limit`
export const DESCRIPTION_CONSTRAINT_TEXT = `${MAX_DESCRIPTION_LENGTH} character limit`
export const DESCRIPTION_PLACEHOLDER =
  'Please leave specific details about this audience. These will be used in EZ Texting.'

interface Props {
  initial: EzTextingGroup | null
  clientID: string
  onClose: (group?: EzTextingGroup) => void
  audiences: SegmentGroup[]
}

export const ComposeGroupModal: React.FC<React.PropsWithChildren<Props>> = ({
  initial,
  clientID,
  onClose,
  audiences,
}) => {
  setLogContext('ComposeGroupModal')

  const initialFocusRef = React.useRef<HTMLDivElement>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedAudience, setSelectedAudience] = useState(
    initial?.audienceIDs[0] || '',
  )
  const [ezgroupName, setEzgroupName] = useState(initial?.ez.name || '')
  const [description, setDescription] = useState(initial?.ez.description || '')
  const [selectedHouseholdIDs, setSelectedHouseholdIDs] = useState<
    EzTextingGroup['ez']['householdIDs']
  >(initial?.ez.householdIDs || [])

  const {toast} = useStatusToast()

  const selectedAudienceIsCamperParent = React.useMemo(
    () =>
      audiences.find((sg) => sg.ID === selectedAudience)?.type ===
      'Camper/Parent',
    [audiences, selectedAudience],
  )

  const isValid = React.useMemo(() => {
    setEzgroupName(ezgroupName.trimStart())
    const nameLength = ezgroupName.length
    return (
      selectedAudience !== '' &&
      nameLength > 0 &&
      nameLength <= MAX_GROUPNAME_LENGTH &&
      (!selectedAudienceIsCamperParent || selectedHouseholdIDs.length > 0)
    )
  }, [
    selectedAudience,
    ezgroupName,
    selectedHouseholdIDs,
    selectedAudienceIsCamperParent,
  ])

  const handleOnSave = async (): Promise<void> => {
    setEzgroupName(ezgroupName.trimEnd())

    setIsSaving(true)
    const response = await api.eztexting.upsert({
      ID: initial?.ID,
      clientID,
      ezTextingID: initial?.ezTextingID,
      ez: {
        name: ezgroupName.trimEnd(),
        description,
        householdIDs: selectedHouseholdIDs,
      },
      audienceIDs: [selectedAudience],
    })
    setIsSaving(false)

    if (response.result === 'success') {
      log.breadcrumb('Close Modal')
      onClose(response.data)
    } else {
      toast.error(response.error.message)
      // onClose() // TODO: Typically we don't close modal's on errors. Verify this isn't an exception
    }
  }

  const handleAudienceChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setSelectedAudience(event.currentTarget.value)
  }

  const handleEzGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setEzgroupName(event.currentTarget.value)
  }

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setDescription(event.currentTarget.value)
  }

  return (
    <Modal
      isOpen
      onClose={(): void => {
        onClose()
      }}
      initialFocusRef={initialFocusRef}
      size='xl' // {['full', 'xl']} must revisit this
      closeOnOverlayClick={false}
      closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent
        data-testid='compose-content'
        borderRadius={['none', 'md']}
        my={[0, '3.75rem']}
        minHeight={['100%', 'unset']}>
        <ModalHeader borderBottom='1px' borderColor='#DFE8ED'>
          <ModalCloseButton title='close' top='16px' />
          <Box textAlign='center' flex={1}>
            Group Details
          </Box>
        </ModalHeader>

        <ModalBody paddingX={8} marginBottom='2em'>
          <Flex direction='column' h={['100%']}>
            <FormSelect
              title='group'
              id='group'
              name='group'
              label='Audience'
              fontSize='16px'
              labelFontSize='16px'
              labelFontWeight='bold'
              placeholder={AUDIENCE_PLACEHOLDER_TEXT}
              labelPaddingTop={4}
              labelPaddingBottom={1}
              labelMarginBottom={0}
              value={selectedAudience}
              onChange={handleAudienceChange}
              borderColor='#ced2d5'
              ref={initialFocusRef}>
              {audiences.map((a) => (
                <option key={a.ID} value={a.ID}>
                  {a.name}
                </option>
              ))}
            </FormSelect>

            <FormInput
              title='eztextname'
              id='eztextname'
              name='eztextname'
              type='text'
              isRequired
              maxLength={MAX_GROUPNAME_LENGTH}
              label='Name'
              fontSize='16px'
              paddingLeft='11px'
              labelFontSize='16px'
              labelFontWeight='bold'
              labelMarginBottom='1'
              placeholder={NAME_INPUT_PLACEHOLDER_TEXT}
              labelPaddingTop={4}
              labelPaddingBottom={0}
              value={ezgroupName}
              onChange={handleEzGroupNameChange}
              borderColor='#ced2d5'
              _placeholder={{fontSize: '14px'}}
            />
            <Text fontSize='sm' color='#7C8791'>
              {ezgroupName.length}/{NAME_CONSTRAINT_TEXT}
            </Text>

            <FormTextArea
              _placeholder={{fontSize: '14px'}}
              borderColor='#ced2d5'
              fontSize='16px'
              id='description'
              label='Description'
              labelFontSize='16px'
              labelFontWeight='bold'
              labelPaddingBottom={1}
              labelMarginBottom={0}
              labelPaddingTop={4}
              maxLength={MAX_DESCRIPTION_LENGTH}
              name='description'
              onChange={handleDescriptionChange}
              paddingLeft='11px'
              placeholder={DESCRIPTION_PLACEHOLDER}
              preventNewLines
              title='description'
              // type='text' need to check if still valid
              value={description}
            />
            <Text fontSize='sm' color='#7C8791'>
              {description.length}/{DESCRIPTION_CONSTRAINT_TEXT}
            </Text>
            <Visible when={selectedAudienceIsCamperParent}>
              <OptionSelector
                key='household'
                options={[
                  {ID: 'Household1Parent1', label: 'Household 1, Parent 1'},
                  {ID: 'Household1Parent2', label: 'Household 1, Parent 2'},
                  {ID: 'Household2Parent1', label: 'Household 2, Parent 1'},
                  {ID: 'Household2Parent2', label: 'Household 2, Parent 2'},
                ]}
                selectedIDs={selectedHouseholdIDs}
                onChange={(nextIds: string[]): void => {
                  setSelectedHouseholdIDs(
                    nextIds as EzTextingGroup['ez']['householdIDs'],
                  )
                }}>
                <Box>
                  <FormLabel fontSize='16px' fontWeight='bold' pt={4}>
                    Household
                  </FormLabel>

                  <Flex wrap='wrap'>
                    <OptionList
                      data-testid='household-option'
                      colorScheme='secondary'
                      flex={['1 1 100%', '1 1 50%']}
                      isDisabled={false}
                      mb={[2]}
                    />
                  </Flex>
                </Box>
              </OptionSelector>
            </Visible>
          </Flex>
        </ModalBody>

        <ModalFooter borderTop='1px' borderColor='#DFE8ED'>
          <Flex
            flexDir='row'
            justifyContent={['space-around', 'flex-end']}
            width='100%'>
            <ActionButton
              mr={3}
              onClick={handleOnSave}
              isLoading={isSaving}
              isDisabled={!isValid}
              loadingText='Saving'
              flexGrow={[1, 0]}>
              {copy.buttons.save}
            </ActionButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
