import React from 'react'

import {useToast as useChakraToast} from '@chakra-ui/react'

import {decode} from 'he'
import {StatusToast} from '../components/StatusToast/StatusToast'

type StatusToastProps = React.ComponentProps<typeof StatusToast>

type PositionOptions =
  | 'top'
  | 'bottom'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | undefined

interface ShowToastOptions {
  status: StatusToastProps['status']
  duration: number | null
  position: PositionOptions
  isClosable?: boolean
  variant: StatusToastProps['variant']
}
type ShowToast = (description: string, options: ShowToastOptions) => void
type StatusToastType = (description: string) => void

interface UseStatusToast {
  toast: {
    success: StatusToastType
    error: StatusToastType
    warn: StatusToastType
    info: StatusToastType
  }
}

export const useStatusToast = (): UseStatusToast => {
  const toast = useChakraToast()

  const returnValue = React.useMemo(() => {
    const statusToastOptions: Omit<ShowToastOptions, 'status'> = {
      duration: 9000,
      position: 'bottom-left',
      isClosable: true,
      variant: 'left-accent',
    }

    const show: ShowToast = (description, {duration, position, ...rest}) => {
      const escapedDescription = decode(description)
      toast({
        duration,
        position,
        render: ({onClose}) =>
          StatusToast({
            description: escapedDescription,
            onClose,
            ...rest,
          }),
      })
    }

    const success: StatusToastType = (description) =>
      show(description, {status: 'success', ...statusToastOptions})

    const error: StatusToastType = (description) =>
      show(description, {status: 'error', ...statusToastOptions})

    const warn: StatusToastType = (description) =>
      show(description, {status: 'warning', ...statusToastOptions})

    const info: StatusToastType = (description) =>
      show(description, {status: 'info', ...statusToastOptions})

    return {
      toast: {
        success,
        error,
        warn,
        info,
      },
    }
  }, [toast])

  return returnValue
}
