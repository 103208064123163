import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import type {ButtonProps} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  title: string
  description: string
  confirmButtonText: string
  testID?: string
  onConfirm(): Promise<void>
  onClose(): void
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps
}

export const ConfirmationDialog: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  title,
  description,
  confirmButtonText,
  testID,
  onConfirm,
  onClose,
  cancelButtonProps,
  confirmButtonProps,
}) => {
  const cancelRef = React.useRef(null)

  const handleConfirmClick = async (): Promise<void> => {
    await onConfirm()

    onClose()
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent
        rounded='md'
        data-testid={testID || 'confirmation-dialog'}>
        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>{description}</AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} {...cancelButtonProps}>
            Cancel
          </Button>
          <Button
            colorScheme='red'
            onClick={handleConfirmClick}
            ml={3}
            {...confirmButtonProps}>
            {confirmButtonText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
