import React, {useRef} from 'react'
import {Terms} from '../types'
import {applyCasing, Casing} from '../utils/applyCasing'
import {useRequiredAuth} from '../stores/AuthStore'

interface TermForge {
  value(): string
  plural(): TermForge
  possessive(): TermForge
  singular(): TermForge
  titleCase(): TermForge
  lowerCase(): TermForge
  upperCase(): TermForge
  sentenceCase(): TermForge
}

type Plurality = 'plural' | 'singular'

export const useTerm = (t: keyof Terms): TermForge => {
  const {client} = useRequiredAuth()
  const term = useRef(client.terms[t])
  const isPlural = useRef(false)
  const isPossessive = useRef(false)
  const casing = useRef<Casing>('title')

  const getPlurality = (): Plurality =>
    isPlural.current ? 'plural' : 'singular'
  const getPossession = (): string => (isPossessive.current ? 'Possessive' : '')

  const forge = React.useMemo(
    () => ({
      plural: (): TermForge => {
        isPlural.current = true
        return forge
      },
      possessive: (): TermForge => {
        isPossessive.current = true
        return forge
      },
      singular: (): TermForge => {
        isPlural.current = false
        return forge
      },
      titleCase: (): TermForge => {
        casing.current = 'title'
        return forge
      },
      lowerCase: (): TermForge => {
        casing.current = 'lower'
        return forge
      },
      upperCase: (): TermForge => {
        casing.current = 'upper'
        return forge
      },
      sentenceCase: (): TermForge => {
        casing.current = 'sentence'
        return forge
      },

      value: (): string => {
        const plurality = getPlurality()
        const possession = getPossession()
        const val = term && term.current[`${plurality}${possession}`]
        return applyCasing(val, casing.current)
      },
    }),
    [],
  )

  return forge
}

useTerm.displayName = 'useTerm'
