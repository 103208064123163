import React from 'react'
import TagManager, {TagManagerArgs} from 'react-gtm-module'
import {QueryClient, QueryClientProvider} from 'react-query'
import LightboxContextProvider from 'simple-react-lightbox'
import {ChakraProvider} from '@chakra-ui/react'

import {appTheme} from './theme'
import {Router} from './Router'
import {AppProviders} from './AppProviders'
import {GTM_PREVIEW, GTM_AUTH} from '../constants'
import {Logger} from './Logger'

const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-5KX84HX',
  auth: GTM_AUTH,
  preview: GTM_PREVIEW,
}

TagManager.initialize(tagManagerArgs)

const queryClient = new QueryClient() // Not apart of AppProviders so can configure different client for Jest testing

export const App: React.FC<React.PropsWithChildren> = () => (
  <Logger>
    <LightboxContextProvider>
      <ChakraProvider theme={appTheme}>
        <QueryClientProvider client={queryClient}>
          <AppProviders>
            <Router />
          </AppProviders>
        </QueryClientProvider>
      </ChakraProvider>
    </LightboxContextProvider>
  </Logger>
)
