import React from 'react'
import {Flex, forwardRef, Text} from '@chakra-ui/react'

import {PropsOf} from '../../types'
import {FormInput} from '../FormInput/FormInput'

interface Props extends PropsOf<typeof Flex> {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const AlbumNameControl: React.FC<React.PropsWithChildren<Props>> =
  forwardRef(({value, onChange, ...rest}, ref) => (
    <Flex direction='column' {...rest}>
      <FormInput
        title='albumname'
        id='albumname'
        name='name'
        type='text'
        label='Album Name'
        fontSize='16px'
        paddingLeft='11px'
        labelFontSize='16px'
        labelFontWeight='bold'
        placeholder='Name your album'
        labelPaddingTop={2}
        labelPaddingBottom={1}
        labelMarginBottom={0}
        value={value}
        onChange={onChange}
        borderColor='#ced2d5'
        ref={ref}
      />
      <Text color='#7C8791'>100 character max</Text>
    </Flex>
  ))

AlbumNameControl.displayName = 'AlbumNameControl'
