import React from 'react'
import {log} from '@vanguard/logger'
import {useRequiredAuth} from '../stores/AuthStore'
import {api} from './api'

type Status = 'idle' | 'pending' | 'notfound' | 'rejected' | 'success'

interface UseFormRemindersRecipientsReturn {
  validatedPersonIDs: string[]
  creditsNeeded: number | undefined
  status: Status | null
  reset: () => void
  fetchValidatedRecipients: (params: {
    household1Parent1?: boolean
    household1Parent2?: boolean
    household2Parent1?: boolean
    household2Parent2?: boolean
    required?: boolean | null
    overdue?: boolean | null
  }) => Promise<void>
}

interface UseFormRemindersRecipients {
  (): UseFormRemindersRecipientsReturn
}

export const useFormRemindersRecipients: UseFormRemindersRecipients = () => {
  const {client} = useRequiredAuth()
  const [validatedPersonIDs, setValidatedPersonIDs] = React.useState<string[]>(
    [],
  )
  const [status, setStatus] = React.useState<Status>('idle')
  const [creditsNeeded, setCreditsNeeded] = React.useState<number | undefined>(
    undefined,
  )

  const reset = (): void => {
    setStatus('idle')
    setCreditsNeeded(undefined)
  }

  const fetchValidatedRecipients = React.useCallback<
    UseFormRemindersRecipientsReturn['fetchValidatedRecipients']
  >(
    async (params) => {
      log.breadcrumb('Begin fetch validated recipients')

      setStatus('pending')

      const applicabilityResponse = await api.forms.getFormApplicability({
        clientID: client.ID,
        ...params,
      })

      if (applicabilityResponse.result === 'failure') {
        log.error(new Error(applicabilityResponse.error.message))

        if (applicabilityResponse.error.message === 'notfound') {
          setStatus('notfound')
        } else {
          setStatus('rejected')
        }
      }

      if (applicabilityResponse.result === 'success') {
        if (!applicabilityResponse.data.personIDs.length) {
          setStatus('success')
          setValidatedPersonIDs([])
          setCreditsNeeded(0)
          return
        }

        const validateNumbersResponse = await api.eztexting.validateNumbers({
          clientID: client.ID,
          personIDs: applicabilityResponse.data.personIDs.map((personID) =>
            Number(personID),
          ),
        })

        if (validateNumbersResponse.result === 'failure') {
          log.error(new Error(validateNumbersResponse.error.message))

          if (validateNumbersResponse.error.message === 'notfound') {
            setStatus('notfound')
          } else {
            setStatus('rejected')
          }
        }

        if (validateNumbersResponse.result === 'success') {
          setStatus('success')

          setValidatedPersonIDs(validateNumbersResponse.data.recipients || [])
          setCreditsNeeded(validateNumbersResponse.data.recipients?.length || 0)
        }
      }
    },
    [client.ID],
  )

  return React.useMemo(
    () => ({
      validatedPersonIDs,
      creditsNeeded,
      status,
      reset,
      fetchValidatedRecipients,
    }),
    [validatedPersonIDs, creditsNeeded, status, fetchValidatedRecipients],
  )
}
