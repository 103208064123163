import {upsertSegment} from './upsertSegment'
import {getSegment} from './getSegment'
import {deleteSegment} from './deleteSegment'
import {getSegmentCurrentSnapShot} from './getSegmentCurrentSnapshot'

export const segments = {
  upsertSegment,
  getSegment,
  deleteSegment,
  getSegmentCurrentSnapShot,
}
