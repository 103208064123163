import React from 'react'
import moment from 'moment'
import {Album} from '../../../types'
import {ComposeAlbumModal} from '../../../components/ComposeAlbumModal/ComposeAlbumModal'

import {useAlbumDispatch, useAlbumState} from '../AlbumManager'
import {useAlbumViewState, useAlbumViewDispatch} from '../AlbumViewManager'
import {useAlbumPhotosState} from '../AlbumPhotosManager'
import {useStatusToast} from '../../../app/ToastManager'

import copy from '../copy.json'

export const EditAlbumModal: React.FC<React.PropsWithChildren> = () => {
  const {data: album} = useAlbumState()
  const albumDispatch = useAlbumDispatch()
  const {viewMode} = useAlbumViewState()
  const albumViewDispatch = useAlbumViewDispatch()
  const {resetPhotos} = useAlbumPhotosState()
  const {toast} = useStatusToast()

  const getAlbumSchedulingState = (
    albumData: Album,
  ): 'Draft' | 'PublishNow' | 'SaveForLater' => {
    if (albumData.isDraft) return 'Draft'
    if (albumData.publishDate) return 'SaveForLater'

    return 'PublishNow'
  }

  const modalProps = {
    initial: album,
    onClose: (): void => {
      albumViewDispatch({type: 'SET_VIEW_MODE', payload: 'initial'})
    },
    onSuccess: (albumResponse: Album): void => {
      albumDispatch({type: 'SAVE_ALBUM', payload: albumResponse})
      albumViewDispatch({type: 'SET_VIEW_MODE', payload: 'initial'})

      const updatedAlbumSchedule = getAlbumSchedulingState(albumResponse)

      if (!album || updatedAlbumSchedule !== getAlbumSchedulingState(album)) {
        switch (updatedAlbumSchedule) {
          case 'Draft':
            toast.success(copy.toastMessages.scheduleUpdate.saveAsDraft)
            break
          case 'PublishNow':
            toast.success(copy.toastMessages.scheduleUpdate.publishNow)
            break
          case 'SaveForLater':
            toast.success(
              `${copy.toastMessages.scheduleUpdate.scheduleForLater} ${moment(
                albumResponse.publishDate,
              ).format('MM/DD/YYYY')} at ${moment(
                albumResponse.publishDate,
              ).format('hh:mm A')}`,
            )
            break
          default:
            break
        }
      }
    },
    resetPhotos,
  }

  return viewMode === 'editAlbum' ? <ComposeAlbumModal {...modalProps} /> : null
}
