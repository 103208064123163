import {http} from '../http'
import {APIResponse} from '../types'
import * as coreSegments from '../../coreApi/segments'
import {ResourceID, SegmentCurrentSnapshot} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
  ID: ResourceID
  seasonID: ResourceID
}

type ResolvedReturn = APIResponse<SegmentCurrentSnapshot>

interface GetSegmentCurrentSnapshot {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.segments.getSegmentCurrentSnapShot()'

export const getSegmentCurrentSnapShot: GetSegmentCurrentSnapshot = async ({
  clientID,
  ID,
  seasonID,
}) => {
  let resolvedReturn: ResolvedReturn
  const rawParamMap = {
    ClientID: parseInt(clientID, 10),
    ID: parseInt(ID, 10),
    SeasonID: parseInt(seasonID, 10),
  }

  try {
    const response = await coreSegments.current(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Get Segment Current Snapshot')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        segmentID: response.data.SegmentID.toString(),
        clientID: response.data.ClientID,
        seasonID: response.data.SeasonID,
        personIDs: response.data.PersonIDs,
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, 'general', e)
  }
  return Promise.resolve(resolvedReturn)
}
