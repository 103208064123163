import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {CreateRequest, SegmentResponse} from './types'

export interface Create<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const create: Create<CreateRequest, SegmentResponse> = (
  http,
  params,
) => {
  const path = '/v0/segments'

  return http.request<SegmentResponse>({
    method: 'POST',
    url: `${path}`,
    data: params,
  })
}
