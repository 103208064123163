import React from 'react'

import {Button} from '@chakra-ui/react'
import {ExternalLinkIcon} from '@chakra-ui/icons'

import {Visible} from '../Visible/Visible'

type Props = React.ComponentProps<typeof Button>

interface ButtonProps extends Props {
  icon?: string
}

export const ActionButton: React.FC<ButtonProps> = ({
  icon,
  children,
  ...rest
}) => (
  <Button
    variant='solid'
    colorScheme='primary'
    borderRadius='3px'
    fontWeight='bold'
    textTransform='uppercase'
    fontSize='14px'
    lineHeight='14px'
    letterSpacing='1px'
    display='flex'
    alignItems='center'
    {...rest}>
    <Visible when={!!icon}>
      <ExternalLinkIcon boxSize='18px' marginTop='-2px' marginRight='0.5em' />
    </Visible>
    {children}
  </Button>
)
