import React from 'react'
import {Flex, Text} from '@chakra-ui/react'

import {
  OptionList,
  OptionSelector,
} from '../../../components/OptionSelector/OptionSelector'
import {RadioList} from '../../../components/RadioList/RadioList'
import copy from '../copy.json'

export const messageSettingsOptions = {
  householdOptions: [
    {ID: 'household1Parent1', label: 'Household 1, Parent 1'},
    {ID: 'household1Parent2', label: 'Household 1, Parent 2'},
    {ID: 'household2Parent1', label: 'Household 2, Parent 1'},
    {ID: 'household2Parent2', label: 'Household 2, Parent 2'},
  ],
  typeOptions: [
    {ID: 'required', label: 'Required Forms'},
    {ID: 'non-required', label: 'Non-Required Forms'},
    {ID: 'both', label: 'Both'},
  ],
  statusOptions: [
    {ID: 'incomplete', label: 'Incomplete / Unsubmitted'},
    {ID: 'overdue', label: 'Overdue'},
  ],
}

export type MessageSettingsState = {
  householdSettings: (typeof messageSettingsOptions.householdOptions)[number]['ID'][]
  typeSetting: (typeof messageSettingsOptions.typeOptions)[number]['ID']
  statusSetting: (typeof messageSettingsOptions.statusOptions)[number]['ID']
}

interface Props {
  createChangeSettingsHandler: (
    group: keyof MessageSettingsState,
  ) => (value: string[] | string) => void
  houseHoldSettingsValue: (typeof messageSettingsOptions.householdOptions)[number]['ID'][]
  typeSettingValue: (typeof messageSettingsOptions.typeOptions)[number]['ID']
  statusSettingValue: (typeof messageSettingsOptions.statusOptions)[number]['ID']
}

export const MessageSettings: React.FC<React.PropsWithChildren<Props>> = ({
  createChangeSettingsHandler,
  houseHoldSettingsValue,
  typeSettingValue,
  statusSettingValue,
}) => (
  <>
    <Flex direction='column' width='80%'>
      <Flex direction='column'>
        <Text fontSize='lg' fontWeight={500}>
          Who would you like to send this reminder to?
        </Text>
        <Text fontSize='sm' color='#69747D' mb='10px'>
          You must select at least one.
        </Text>
      </Flex>
      <OptionSelector
        key='Test'
        options={messageSettingsOptions.householdOptions}
        selectedIDs={houseHoldSettingsValue}
        onChange={createChangeSettingsHandler('householdSettings')}>
        <Flex wrap='wrap'>
          <OptionList
            colorScheme='secondary'
            flex={['1 1 100%', '1 1 50%']}
            size='lg'
            my='10px'
            isDisabled={false}
          />
        </Flex>
      </OptionSelector>
    </Flex>
    {!houseHoldSettingsValue.length && (
      <Text color='Red'>
        {copy.authorized.messageSettings.noHouseholdSelected}
      </Text>
    )}
    <Flex borderTop='1px' mx={-6} my={5} />
    <Flex direction='column' mb={['unset', 'unset', 'unset', 2]}>
      <Flex mb={5}>
        <Text fontSize='lg' fontWeight={500}>
          What type of forms do you want to include?
        </Text>
      </Flex>
      <RadioList
        onRadioChange={createChangeSettingsHandler('typeSetting')}
        options={messageSettingsOptions.typeOptions}
        size='lg'
        value={typeSettingValue}
        colorScheme='secondary'
      />
    </Flex>
    <Flex borderTop='1px' mx={-6} my={5} />
    <Flex direction='column' mb={['unset', 'unset', 'unset', 2]}>
      <Flex mb={5}>
        <Text fontSize='lg' fontWeight={500}>
          What status of forms do you want to include?
        </Text>
      </Flex>
      <RadioList
        onRadioChange={createChangeSettingsHandler('statusSetting')}
        options={messageSettingsOptions.statusOptions}
        size='lg'
        value={statusSettingValue}
        colorScheme='secondary'
      />
    </Flex>
  </>
)
