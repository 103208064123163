import React, {FC, CSSProperties, PropsWithChildren} from 'react'
import {Box, Flex} from '@chakra-ui/react'

import {appTheme} from '../../app/theme'
import {CustomIcon} from '../CustomIcon/CustomIcon'

interface TableProps {
  style?: CSSProperties
}

interface TablePropsId extends TableProps {
  id?: string | number
}

interface TablePropsExtended extends TablePropsId {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void
}

const border = `1px solid ${appTheme.colors.gray[200]}`

const baseStyles: CSSProperties = {
  boxSizing: 'border-box',
  display: 'block',
  width: '100%',
}

export const TableRoot: FC<React.PropsWithChildren<TableProps>> = ({
  children,
  style,
}) => {
  const styles: CSSProperties = {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    width: '100%',
    height: '100%',
    borderRadius: `${appTheme.radii.md}`,
    display: 'flex',
    flexDirection: 'column',
    ...style,
  }
  return <table style={styles}>{children}</table>
}

export const TableHead: FC<React.PropsWithChildren<TableProps>> = ({
  children,
  style,
}) => {
  const styles = {
    ...baseStyles,
    display: 'inline-block',
    border: `${border}`,
    backgroundColor: `${appTheme.colors.gray[100]}`,
    borderTopLeftRadius: `${appTheme.radii.md}`,
    borderTopRightRadius: `${appTheme.radii.md}`,
    ...style,
  }
  return <thead style={styles}>{children}</thead>
}

export const TableBody: FC<React.PropsWithChildren<TableProps>> = ({
  children,
  style,
}) => {
  const styles: CSSProperties = {
    ...baseStyles,
    flexGrow: 1,
    border: `${border}`,
    borderTop: 'none',
    borderBottomRightRadius: `${appTheme.radii.md}`,
    borderBottomLeftRadius: `${appTheme.radii.md}`,
    ...style,
  }

  return <tbody style={styles}>{children}</tbody>
}

export const TableFoot: FC<React.PropsWithChildren<TableProps>> = ({
  children,
  style,
}) => {
  const styles: CSSProperties = {
    ...baseStyles,
    textAlign: 'center',
    ...style,
  }
  return <tfoot style={styles}>{children}</tfoot>
}

export const TableRow: FC<React.PropsWithChildren<TablePropsId>> = ({
  id,
  children,
  style,
}) => {
  const styles: CSSProperties = {
    ...baseStyles,
    display: 'flex',
    alignItems: 'start',
    padding: '0.75em 1.5em',
    borderBottom: `${border}`,
    position: 'relative',
    ...style,
  }

  const lastRowStyles = {
    borderBottom: '0 !important',
  }

  return (
    <Box key={id} as='tr' _last={lastRowStyles} style={styles}>
      {children}
    </Box>
  )
}

export const TableHeadRow: FC<React.PropsWithChildren<TablePropsId>> = ({
  id,
  children,
  style,
}) => {
  const styles = {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5em 1.5em',
    ...style,
  }

  return (
    <tr key={id} style={styles}>
      {children}
    </tr>
  )
}

const cellBaseStyles: CSSProperties = {
  boxSizing: 'border-box',
  display: 'inline-block',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingLeft: '0.5em',
  paddingRight: '0.5em',
  lineHeight: '1.2',
}

const cellFirstChildStyles = {
  paddingLeft: '0 !important',
}

const cellLastChildStyles = {
  paddingRight: '0 !important',
}

export const TableCell: FC<React.PropsWithChildren<TablePropsId>> = ({
  id,
  children,
  style,
}) => {
  const styles: CSSProperties = {
    ...cellBaseStyles,
    ...style,
  }
  return (
    <Box
      as='td'
      key={id}
      style={styles}
      _first={cellFirstChildStyles}
      _last={cellLastChildStyles}>
      {children}
    </Box>
  )
}

export const TableHeaderCell: FC<
  React.PropsWithChildren<TablePropsExtended>
> = ({id, children, style, onClick}) => {
  const styles: CSSProperties = {
    ...cellBaseStyles,
    fontWeight: 600,
    fontSize: '0.85em',
    textTransform: 'uppercase',
    letterSpacing: '0.09em',
    ...style,
  }

  const a = id
  return (
    <Box
      as='th'
      key={a}
      style={styles}
      onClick={onClick}
      _first={cellFirstChildStyles}
      _last={cellLastChildStyles}>
      {children}
    </Box>
  )
}

type TableHeadCellContainerProps = {
  canSort: boolean
}
export const TableHeadCellContainer: FC<
  PropsWithChildren<TableHeadCellContainerProps>
> = ({children, canSort}) => {
  const hoverColor = appTheme.colors.secondary[500]
  const hoverStyles = canSort
    ? {
        cursor: 'pointer',
        color: `${hoverColor}`,
        textDecoration: 'underline',
      }
    : {}

  const styles: CSSProperties = {
    position: 'relative',
  }

  return (
    <Box
      as='span'
      display='inline-flex'
      alignItems='center'
      style={styles}
      _hover={hoverStyles}>
      {children}
    </Box>
  )
}

interface SortIndicatorProps {
  icon: string
  label: string
}

export const SortIcon: React.FC<SortIndicatorProps> = ({icon, label}) => (
  <CustomIcon
    name={icon}
    boxSize='24px'
    position='absolute'
    top='-5px'
    aria-label={`Sort ${label}`}
  />
)

interface Sorted {
  isSorted: boolean
  isDesc: boolean
}

export const SortIndicator: React.FC<Sorted> = ({isSorted, isDesc}) => {
  const unsortedColor = appTheme.colors.gray[300]
  const sortedColor = appTheme.colors.secondary[500]
  return (
    <Flex direction='column' marginLeft='0.5em'>
      <CustomIcon
        name='sort-asc'
        color={isSorted && !isDesc ? sortedColor : unsortedColor}
      />
      <CustomIcon
        name='sort-dsc'
        position='relative'
        top='-2px'
        color={isSorted && isDesc ? sortedColor : unsortedColor}
      />
    </Flex>
  )
}
