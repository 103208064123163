import React from 'react'
import {Box, Image} from '@chakra-ui/react'

import {createVideoURL, transformImageURL} from '../../../utils'

export const ItemMedia: React.FC<{
  videoURL?: string
  imageURL?: string
}> = React.memo(({videoURL, imageURL}) => {
  if (!videoURL && !imageURL) {
    return null
  }

  let mediaElement: React.ReactNode = null
  let heightProp = {}
  if (videoURL) {
    const validUrl = createVideoURL(videoURL)

    if (validUrl) {
      heightProp = {height: ['200px', '340px']}

      mediaElement = (
        <iframe
          title='Micropost Video'
          src={validUrl}
          width='100%'
          height='100%'
        />
      )
    }
  } else if (imageURL) {
    mediaElement = (
      <Image
        src={transformImageURL(imageURL, 'micropost')}
        alt='micropost photo'
        ignoreFallback
        objectFit='contain'
        objectPosition='top center'
      />
    )
  }

  return (
    <Box
      overflow='hidden'
      roundedTopLeft={3}
      roundedTopRight={3}
      {...heightProp}>
      {mediaElement}
    </Box>
  )
})

ItemMedia.displayName = 'ItemMedia'
