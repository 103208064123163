import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react'

import {simplePluralizeWord} from '../../../utils'
import {useAlbumPhotosState} from '../AlbumPhotosManager'
import {useAlbumViewState, useAlbumViewDispatch} from '../AlbumViewManager'
import {Visible} from '../../../components'

export const PhotoErrorsModal: React.FC<React.PropsWithChildren> = () => {
  const {viewMode} = useAlbumViewState()
  const albumViewDispatch = useAlbumViewDispatch()
  const {failedProcessingFiles} = useAlbumPhotosState()

  const handleOnClose = (): void => {
    albumViewDispatch({type: 'SET_VIEW_MODE', payload: 'initial'})
  }

  return (
    <Modal
      isOpen={viewMode === 'viewFailures'}
      onClose={handleOnClose}
      size='xl' // {{base: 'full', lg: 'xl'}} revisit why size doesn't take responsive array values
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius={['0px', '3px']}
        my={[0, '3.75rem']}
        minHeight={['100%', 'unset']}>
        <ModalHeader borderBottom='1px solid #CED2D5' w='100%' pr={8}>
          <Flex flexDir='row' w='100%' justify='center' align='center'>
            <Box>Failed Photo Upload Report</Box>
            <ModalCloseButton
              justifySelf='flex-end'
              marginTop='3px'
              marginRight='14px'
              onClick={handleOnClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody px={8}>
          <Alert status='error' variant='solid' borderRadius={4} mt={4} mb={8}>
            <AlertIcon />
            <AlertTitle mr={2}>
              {simplePluralizeWord('photo', failedProcessingFiles.length)}{' '}
              failed during upload
            </AlertTitle>
          </Alert>

          <Flex direction='column' mb={4}>
            <Text mb={2}>
              This is most likely a problem with the photo itself and additional
              upload attempts might not succeed.
            </Text>

            <Visible when={!!failedProcessingFiles.length}>
              <Text mt={3} fontWeight='bold'>
                The following{' '}
                {simplePluralizeWord(
                  'photo',
                  failedProcessingFiles.length,
                  false,
                )}{' '}
                have failed:
              </Text>
              <List styleType='none' overflowY='auto' maxHeight='300px'>
                {failedProcessingFiles.map(
                  (photo): React.ReactNode => (
                    <ListItem key='photo'> -{photo}</ListItem>
                  ),
                )}
              </List>
            </Visible>
            <Text fontWeight='bold' mt={6}>
              Be advised that we allow duplicate photos to be uploaded.
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
