import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {UpdateRequest, AlbumResponse} from './types'

export interface Update<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const update: Update<UpdateRequest, AlbumResponse> = (http, params) => {
  const {ID} = params

  const path = '/v1/albums/{id}'.replace('{id}', ID.toString())

  return http.request<AlbumResponse>({
    method: 'PUT',
    url: `${path}`,
    data: params,
  })
}
