import React from 'react'
import {useSortable} from '@dnd-kit/sortable'

interface Props {
  id: string
  children: React.ReactNode
}

export const SortableItem: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  children,
}) => {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} =
    useSortable({id})

  React.useEffect(() => {
    if (isDragging) document.body.style.cursor = 'grabbing'

    return (): void => {
      document.body.style.cursor = ''
    }
  }, [isDragging])

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition: transition || undefined,
    touchAction: 'none',
    userSelect: 'none',
    cursor: 'grab',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as React.CSSProperties

  return (
    <div
      data-testid='sortable-item'
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}>
      {children}
    </div>
  )
}
