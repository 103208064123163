import Axios from 'axios'

import {handleError} from '../handleError'

import {
  CreateShortenedUrlLinkRequest,
  CreateShortenedUrlLinkResponse,
} from './types'

import {APIResponse} from '../types'

import {FIREBASE_DYNAMIC_LINKS_URL} from '../../../constants'

export type Params = CreateShortenedUrlLinkRequest
type Return = APIResponse<CreateShortenedUrlLinkResponse>
export interface GetDynamicLink {
  (params: Params): Promise<Return>
}
const ERROR_PATH = 'firebase.createShortenedLink()'

export const getDynamicLink: GetDynamicLink = async (params) => {
  try {
    const path = FIREBASE_DYNAMIC_LINKS_URL
    const response = await Axios.post(path, params)

    return Promise.resolve({data: response.data, result: 'success'})
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e && e.message ? e.message : e.toString()
    }
    const result = handleError(ERROR_PATH, message, e)
    return Promise.resolve(result)
  }
}
