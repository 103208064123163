import {http} from '../http'
import * as coreEZTexting from '../../coreApi/eztexting'
import {APIResponse, PagedCollectionResponse} from '../types'
import {ResourceID, SyncResult} from '../../../types'
import {handleError} from '../handleError'
import {GetSyncResultsResponse} from '../../coreApi/eztexting/types'

interface Params {
  clientID: ResourceID
  pageNumber: number
  pageSize: number
  groupID: ResourceID
  orderBy?: keyof GetSyncResultsResponse
  orderAscending?: boolean
}

type ResolvedReturn = APIResponse<PagedCollectionResponse<SyncResult>>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.fetchSyncResults()'
const GENERIC_ERROR =
  'Something went wrong while getting your sync results. Please try again.'

export const fetchSyncResults: ServiceMethod = async ({
  clientID,
  pageNumber,
  pageSize,
  groupID,
  orderBy = 'lastname',
  orderAscending = true,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      PageNumber: pageNumber,
      PageSize: pageSize,
      GroupID: parseInt(groupID, 10),
      OrderBy: orderBy,
      OrderAscending: orderAscending,
    }

    const response = await coreEZTexting.getSyncResults(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Response')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        total: response.data.TotalCount,
        nextURL: response.data.Next,
        results: response.data.Results.map((result, i) => ({
          ID: `${pageNumber.toString()}_${i}`,
          groupID: result.GroupID.toString(),
          cellNumber: result.CellNumber,
          friendlyMessage: result.FriendlyMessage,
          name: `${result.LastName}, ${result.FirstName}`,
          personID: result.PersonID,
        })),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
