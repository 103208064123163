import React from 'react'
import {Flex, Divider} from '@chakra-ui/react'

import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {PageHeader} from '../../components'
import {IntegrationList} from './IntegrationsList'

import integrationOptions from './integrations.json'
import {useReleaseFlags} from '../../hooks/useReleaseFlags'
import {FeatureName} from '../../types'
import {useImagesContext} from '../../hooks/useImageContext'

export const IntegrationsView: React.FC<React.PropsWithChildren> = () => {
  const {getIsReleasedToUser} = useReleaseFlags()
  const {images} = useImagesContext()

  const integrations = integrationOptions.map((integration) => ({
    ...integration,
    connected: getIsReleasedToUser(integration.connectionFlag as FeatureName),
    logo: images(`./integrations/${integration.logo}`),
  }))

  const headerDescription =
    'Do more with Campanion by connecting your favorite tools.'
  return (
    <PageTemplate
      header={
        <PageHeader
          title='Integrations'
          logContext='IntegrationsView'
          description={headerDescription}
        />
      }
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <Flex direction='column' px='.75em' py={1}>
        <Divider mb='4' opacity={1} border-bottom='1px solid' mx='0.2em' />
        <IntegrationList integrations={integrations} />
      </Flex>
    </PageTemplate>
  )
}
