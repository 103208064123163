import {parseVideoURL} from './parseVideoURL'

export const createVideoURL = (url: string): string | null => {
  if (!url || !url.length) {
    return null
  }

  const videoObj = parseVideoURL(url)
  let src = 'https://'

  if (videoObj.type === 'youtube') {
    src = `${src}www.youtube.com/embed/${videoObj.id}`
  } else if (videoObj.type === 'vimeo') {
    src = `${src}player.vimeo.com/video/${videoObj.id}?title=0&byline=0&portrait=0`
  } else {
    return null
  }

  return src
}

// export const getVideoThumbnail = (
//   url: string,
//   cb: (thumbnail: string) => void,
// ): void => {
//   // Obtains the video's thumbnail and passed it back to a callback function.
//   const videoObj = parseVideo(url)
//   if (videoObj.type === 'youtube') {
//     cb(`//img.youtube.com/vi/${videoObj.id}/maxresdefault.jpg`)
//   } else if (videoObj.type === 'vimeo') {
//     // Requires jQuery
//     $.get('http://vimeo.com/api/v2/video/' + videoObj.id + '.json', function(
//       data,
//     ) {
//       cb(data[0].thumbnail_large)
//     })
//   }
// }
