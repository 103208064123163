import React, {useCallback, useRef} from 'react'
import {API_SSE_URL} from '../constants'
import {createContextHelper} from '../utils/createContextHelper'
import {useAuth} from '../stores/AuthStore'
import {buildEventSource} from '../utils/buildEventSource'
import {SSEMessage, WebSendRequest} from '../services/coreApi/types'
import {Topic as EZTTopic} from '../services/api/eztexting/sse'
import {Topic as PhotoRotateTopic} from '../services/api/photos/rotatePhotoSSE'
import {Topic as ProcessPhotoTopic} from '../services/api/photos/processPhotoSSE'
import {TopicUploadSessionComplete} from '../services/api/photos/processUploadSessionCompleteSSE'

type Topic =
  | ''
  | EZTTopic
  | PhotoRotateTopic
  | ProcessPhotoTopic
  | typeof TopicUploadSessionComplete

type Subscribe = (topic: Topic, subscription: Subscription) => () => void
type Subscription = (message: SSEMessage) => void
type Subscriptions = {
  [topic: string]: Subscription[]
}
type Unsubscribe = () => void

export const [SubscribeContextProvider, useSSE, SSEContext] =
  createContextHelper<Subscribe>()

type SubscriptionsAction = {
  type: 'subscribe' | 'unsubscribe'
  payload: {
    topic: Topic
    subscription: Subscription
  }
}

const subscriptionsReducer: React.Reducer<
  Subscriptions,
  SubscriptionsAction
> = (state, action) => {
  switch (action.type) {
    case 'subscribe': {
      const {topic, subscription} = action.payload
      const mutatedState = {
        ...state,
      }
      if (mutatedState[topic]) {
        mutatedState[topic] = [...mutatedState[topic], subscription]
      } else {
        mutatedState[topic] = [subscription]
      }
      return mutatedState
    }
    case 'unsubscribe': {
      const {topic, subscription} = action.payload
      if (!state[topic]) {
        return state
      }
      const index = state[topic].indexOf(subscription)
      if (index === -1) {
        return state
      }
      const mutatedState = {
        ...state,
      }
      mutatedState[topic] = [
        ...state[topic].slice(0, index),
        ...state[topic].slice(index + 1),
      ]
      return mutatedState
    }
    default: {
      throw new Error('Unhandled action')
    }
  }
}

export const SSEProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const {client, person} = useAuth()

  const sseRef = useRef<{sse?: EventSource; backoff: number}>({
    sse: undefined,
    backoff: 1,
  })
  const [subscriptions, dispatchSubscriptions] = React.useReducer(
    subscriptionsReducer,
    {},
  )

  const subscribe = React.useCallback<Subscribe>(
    (topic: Topic, subscription: Subscription): Unsubscribe => {
      dispatchSubscriptions({
        type: 'subscribe',
        payload: {
          topic,
          subscription,
        },
      })
      return (): void => {
        dispatchSubscriptions({
          type: 'unsubscribe',
          payload: {
            topic,
            subscription,
          },
        })
      }
    },
    [],
  )

  React.useEffect(() => {
    if (sseRef.current.sse) {
      sseRef.current.sse.onmessage = (e: MessageEvent): void => {
        const data: WebSendRequest = JSON.parse(e.data)
        if (!data.Topic || !subscriptions[data.Topic] || !data.Message) {
          return
        }
        const msg: SSEMessage = JSON.parse(data.Message)
        subscriptions[data.Topic].forEach((subscription): void => {
          subscription(msg)
        })
      }
    }
  }, [subscriptions])

  const teardown = (): void => {
    if (sseRef.current.sse) {
      sseRef.current.sse.close()
    }
  }
  const setup = useCallback((clientID: string, personID: string): void => {
    teardown()
    sseRef.current.sse = buildEventSource(
      `${API_SSE_URL}/v2/notifications/web/subscribe?clientid=${clientID}&personid=${personID}`,
      {
        withCredentials: true,
      },
    )
    sseRef.current.sse.onopen = (_ev: Event): void => {
      sseRef.current.backoff = 1
    }
    sseRef.current.sse.onerror = (_ev: Event): void => {
      // https://stackoverflow.com/questions/24564030/is-an-eventsource-sse-supposed-to-try-to-reconnect-indefinitely
      setTimeout(() => {
        setup(clientID, personID)
      }, sseRef.current.backoff * 1000)

      sseRef.current.backoff *= 2
      if (sseRef.current.backoff >= 64) {
        sseRef.current.backoff = 64
      }
    }
  }, [])

  React.useEffect(() => {
    if (client && person) {
      setup(client.ID, person.ID)
    }
    window.addEventListener('beforeunload', teardown)
    return teardown
  }, [client, person, setup])

  return (
    <SubscribeContextProvider value={subscribe}>
      {children}
    </SubscribeContextProvider>
  )
}
