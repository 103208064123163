/* eslint-disable no-console */
import {http} from '../http'
import * as corePhotos from '../../coreApi/photos'

import {APIResponse} from '../types'
import {ResourceID} from '../../../types'
import {handleError} from '../handleError'

type DeletePhotoReturn = APIResponse<null>

export interface DeletePhoto {
  (clientID: string, ID: ResourceID): Promise<DeletePhotoReturn>
}

const ERROR_PATH = 'api.photos.deletePhoto()'
const GENERIC_ERROR = 'failed'

export const deleteAlbumPhoto: DeletePhoto = async (
  clientID: string,
  ID: string,
) => {
  let resolvedResponse: DeletePhotoReturn

  try {
    const params = {
      ClientID: parseInt(clientID, 10),
      ID: parseInt(ID, 10),
    }

    await corePhotos.deleteRawPhoto(http, params)

    resolvedResponse = {
      result: 'success',
      data: null,
    }
  } catch (e) {
    resolvedResponse = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedResponse)
}
