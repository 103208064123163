import React from 'react'
import {Box, Flex, Text} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'

import {PageHeader} from '../../components/PageHeader/PageHeader'
import {useCreateDynamicLink} from '../../services/useCreateDynamicLink'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'

import copy from './copy.json'
import {TextDisplayWithClipboardCopy} from '../../components'

export const RegistrationLinkView: React.FC<React.PropsWithChildren> = () => {
  const {
    dynamicLink,
    getDynamicLink,
    status: dynamicLinkStatus,
  } = useCreateDynamicLink()

  React.useEffect(() => {
    if (dynamicLinkStatus !== 'failure' && !dynamicLink) {
      getDynamicLink('registration', {
        shouldPromptMobileInstall: false,
      })
    }
  })

  const pageHeader = (
    <PageHeader
      title={copy.title}
      logContext='RegistrationLink'
      description={copy.description}
    />
  )

  return (
    <PageTemplate
      header={pageHeader}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <Flex direction='column' px='.75em'>
        <Box borderTop='1px' mt={3} mb={12} />
        <TextDisplayWithClipboardCopy
          text={dynamicLink}
          placeholder={
            dynamicLinkStatus === 'failure'
              ? 'Failed to generate your link.'
              : 'Fetching your link...'
          }
          onAfterCopy={(copyText): void => {
            TagManager.dataLayer({
              dataLayer: {
                event: 'copy_dynamic_link',
                dynamicLinkType: 'healthCheck',
                dynamicLinkURL: copyText,
              },
            })
          }}
        />
        <Flex direction='column' maxW='650px' pt='10px'>
          <Text fontSize='1.1rem' fontWeight='bold'>
            {copy.explanation.title}
          </Text>
          <Text mb='20px'>{copy.explanation.body1}</Text>
          <Text>{copy.explanation.body2}</Text>
        </Flex>
      </Flex>
    </PageTemplate>
  )
}
