import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Flex,
} from '@chakra-ui/react'

export const ViewError: React.FC<React.PropsWithChildren> = ({children}) => (
  <Flex>
    <Alert
      status='warning'
      variant='subtle'
      flexDirection='column'
      justifyContent='center'
      textAlign='center'
      width='100%'
      height='200px'
      border='1px solid #E68C4E'
      borderRadius={4}
      m={6}>
      <AlertIcon boxSize='40px' mr={0} />
      <AlertTitle mt={4} mb={1} fontSize='lg'>
        Error
      </AlertTitle>
      <AlertDescription maxWidth='sm'>{children}</AlertDescription>
    </Alert>
  </Flex>
)
