import React from 'react'

type Props = {
  when: boolean
}

export const Visible: React.FC<React.PropsWithChildren<Props>> = ({
  when,
  children,
}) =>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  when ? <>{children}</> : null
