import React from 'react'
import {log} from '@vanguard/logger'
import moment from 'moment'
import {splitEvery} from 'ramda'
import TagManager from 'react-gtm-module'
import {useMutation} from 'react-query'

import {useRequiredAuth} from '../stores/AuthStore'
import {Unpacked} from '../types'
import {api} from './api'
import {SEND_TEXT_BATCH_SIZE} from '../constants'

export type Status = 'idle' | 'pending' | 'failed' | 'success'
type Params = {personIDs: string[]; message: string}

interface UseTextMessagingReturn {
  status: Status
  sendTexts: (params: Params) => Promise<void>
  lastMessageSentDetails: string | null
  fetchLastMessageSentDetails: () => Promise<void>
}

interface UseTextMessagingTexts {
  (): UseTextMessagingReturn
}

type SendParams = Parameters<typeof api.eztexting.sendTextMessage>
type SendReturn = Unpacked<ReturnType<typeof api.eztexting.sendTextMessage>>

export const useTextMessaging: UseTextMessagingTexts = () => {
  const {client, person} = useRequiredAuth()
  const [status, setStatus] = React.useState<Status>('idle')
  const [lastMessageSentDetails, setLastMessageSentDetails] = React.useState<
    string | null
  >(null)
  const mutation = useMutation<SendReturn, unknown, SendParams[0]>(
    api.eztexting.sendTextMessage,
    {
      retry: 2,
    },
  )

  const fetchLastMessageSentDetails = React.useCallback<
    UseTextMessagingReturn['fetchLastMessageSentDetails']
  >(async () => {
    log.breadcrumb('Begin fetch last message sent details')

    const response = await api.eztexting.listHistory({
      clientID: client.ID,
      pageNumber: 1,
      pageSize: 1,
      orderBy: '',
      orderAscending: false,
    })

    if (
      response.result === 'success' &&
      response.data.records?.length &&
      response.data.records?.[0].createdDate
    ) {
      const lastSentDisplay = moment(
        response.data.records[0].createdDate,
      ).format('[Last message sent:] l[,] h:mmA')

      setLastMessageSentDetails(lastSentDisplay)
    }
  }, [client.ID])

  const sendTexts = React.useCallback<UseTextMessagingReturn['sendTexts']>(
    async ({personIDs, message}) => {
      log.breadcrumb('Begin Send Texts')

      setStatus('pending')

      const personIDBatches = splitEvery(SEND_TEXT_BATCH_SIZE, personIDs)

      try {
        const responseBatches = await Promise.allSettled(
          personIDBatches.map((batch) =>
            mutation.mutateAsync({
              clientID: client.ID,
              personID: person.ID,
              personIDs: batch.map((ID) => Number(ID)),
              message,
            }),
          ),
        )

        const successfulBatches = responseBatches.filter(
          (resp) => resp.status === 'fulfilled',
        )

        let unsentPersonIDs: string[] = []
        const failedBatches = responseBatches.filter((resp, index) => {
          if (resp.status === 'rejected') {
            unsentPersonIDs = [...unsentPersonIDs, ...personIDBatches[index]]
            return true
          }

          return false
        })

        if (failedBatches.length) {
          log.error('Form Reminder Batch Failure', (event) => {
            event.addMetadata('batch details', {
              totalBatches: responseBatches.length,
              numFailedBatches: failedBatches.length,
              unsentPersonIDs,
              batchSize: SEND_TEXT_BATCH_SIZE,
            })
          })
        }

        if (successfulBatches.length) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'form_reminder_sent',
              totalRemindersAttempted: personIDs.length,
              numRemindersSuccessful: personIDs.length - unsentPersonIDs.length,
              numRemindersUnsuccessful: unsentPersonIDs.length,
            },
          })

          setStatus('success')
        } else {
          setStatus('failed')
        }
      } catch {
        setStatus('failed')
      }
    },
    [client.ID, person.ID, mutation],
  )

  return React.useMemo(
    () => ({
      status,
      sendTexts,
      fetchLastMessageSentDetails,
      lastMessageSentDetails,
    }),
    [status, sendTexts, fetchLastMessageSentDetails, lastMessageSentDetails],
  )
}
