import moment from 'moment'
import {LEGACY_ALBUM_CUTOFF_DATE} from '../constants'
import {Album} from '../types'

export const isLegacyAlbum = (album?: Album): boolean => {
  if (!album) return false
  return moment(album.date).isBefore(LEGACY_ALBUM_CUTOFF_DATE)
}

export const isLegacyAlbumDate = (date?: Date): boolean => {
  if (!date) return false
  return moment(date).isBefore(LEGACY_ALBUM_CUTOFF_DATE)
}
