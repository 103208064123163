import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {ResourceID, Album} from '../../../types'
import {handleError} from '../handleError'

interface Params extends Pick<Album, 'ID' | 'constraints'> {
  clientID: ResourceID
}

type ResolvedReturn = APIResponse<Pick<Album, 'constraints'>>

export interface UpdateConstraints {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.updateConstraints()'
const GENERIC_ERROR =
  'Something went wrong while updating your album. Please try again.'

export const updateConstraints: UpdateConstraints = async ({
  ID,
  clientID,
  constraints,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      AlbumID: Number(ID),
      ClientID: Number(clientID),
      AlbumConstraints: constraints.map((c) => ({
        Type: c.type,
        ConstraintID: Number(c.constraintID),
      })),
    }

    const response = await coreAlbums.updateConstraints(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Album Constraint Update')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        constraints: (response.data.AlbumConstraints || []).map(
          (constraint) => ({
            type: constraint.Type,
            constraintID: constraint.ConstraintID.toString(),
          }),
        ),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
