import {SSEMessage} from '../types'
import {GroupResponse} from './types'

export type Topic = 'EzTexting.SynchronizeGroupComplete'
export const TopicSyncGroupComplete: Topic =
  'EzTexting.SynchronizeGroupComplete'

export type SyncDetails = {
  Completed: boolean
  Errors: boolean
  FriendlyMessage: string
}

export type SynchronizeGroupCompleteEvent = {
  GroupResponse?: GroupResponse
  SyncDetails?: SyncDetails
}

export function handleSSE(msg: SSEMessage): SynchronizeGroupCompleteEvent {
  const event: SynchronizeGroupCompleteEvent = {}
  if (msg.Details) {
    event.SyncDetails = JSON.parse(msg.Details.Properties)
  }
  if (msg.GroupResponse) {
    event.GroupResponse = JSON.parse(msg.GroupResponse.Properties)
  }
  return event
}
