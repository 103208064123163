import React from 'react'
import {Modal as UIModal} from '@chakra-ui/react'
import {Album, PropsOf} from '../../../types'
import {ComposeAlbumManager} from '../contextManagers/ComposeAlbumManager'
import {ComposeAlbumModalContext} from './ComposeAlbumModalContext'
import {ConstraintManager} from '../contextManagers/ConstraintManager'

type Props = PropsOf<typeof UIModal> & {
  initial?: Album
}

export const StateWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  initial,
  children,
  ...rest
}) => (
  <ComposeAlbumModalContext {...rest}>
    <ComposeAlbumManager initial={initial}>
      <ConstraintManager>{children}</ConstraintManager>
    </ComposeAlbumManager>
  </ComposeAlbumModalContext>
)
