import React from 'react'
import {Flex, Stack, Radio, RadioGroup, Text} from '@chakra-ui/react'
import {PropsOf} from '../../types'

type RadioProps = PropsOf<typeof Radio>

interface Option {
  ID: string
  label: string
}

interface RadioListProps extends RadioProps {
  options: Option[]
  value: string

  onRadioChange: (value: string) => void
}

export const RadioList: React.FC<RadioListProps> = ({
  children,
  options,
  value,
  onRadioChange,
  ...rest
}) => {
  if (!options.length) {
    return (
      <Text color='#7D8790' fontStyle='italic'>
        No Options
      </Text>
    )
  }

  return (
    <RadioGroup
      onChange={(nextValue): void => {
        onRadioChange(nextValue)
      }}
      as={Flex}
      // flexDirection={['column', 'column', 'column', 'row']}
      value={value}>
      <Stack direction={['column', 'column', 'column', 'row']}>
        {options.map((opt: Option) => (
          <Radio
            width={['unset', 'unset', 'unset', '370px']}
            key={opt.ID}
            value={opt.ID}
            {...rest}>
            {opt.label}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  )
}
