import {extendTheme} from '@chakra-ui/react'
import {borders} from './borders'
import {colors} from './colors'
import {components} from './components'
import {fonts} from './fonts'

// Setup for
// https://chakra-ui.com/docs/styled-system/theming/customize-theme#scaling-out-your-project

export const appTheme = extendTheme({
  colors,
  components,
  fonts,
  borders,
})
