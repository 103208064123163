export const browserSupportsInputType = (inputType = 'time'): boolean => {
  let doesSupport = false

  try {
    const input = document.createElement('input')

    input.type = 'time'

    if (input.type === inputType) {
      doesSupport = true
    }
  } catch (e) {
    // noop
  }

  return doesSupport
}
