import {decode} from 'he'
import {http} from '../http'
import * as coreMicroposts from '../../coreApi/microposts'
import {APIResponse} from '../types'
import {ResourceID, Micropost} from '../../../types'
import {EmptyResultError} from '../../../customErrors'
import {handleError} from '../handleError'

interface Params extends Micropost {
  clientID: ResourceID
  personID: ResourceID
}

type ResolvedReturn = APIResponse<Micropost>

export interface CreateMicropost {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.createMicropost()'
const GENERIC_ERROR = 'Something that went wrong. Please try again.'

export const createMicropost: CreateMicropost = async ({
  clientID,
  content,
  hyperlinkText,
  hyperlinkURL,
  imageURL,
  notify,
  personID,
  segmentID,
  videoURL,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      Content: content,
      CreatedBy: parseInt(personID, 10),
      ImageURL: imageURL && imageURL.length ? imageURL : null,
      Notify: notify,
      PublishDate: new Date(Date.now()).toISOString(),
      SegmentID: segmentID ? parseInt(segmentID, 10) : null,
      TargetText: hyperlinkText && hyperlinkText.length ? hyperlinkText : null,
      TargetURL: hyperlinkURL && hyperlinkURL.length ? hyperlinkURL : null,
      VideoURL: videoURL && videoURL.length ? videoURL : null,
    }

    const response = await coreMicroposts.create(http, rawParamMap)

    if (response.data.Status !== 'Succeeded') {
      if (response.data.Status === 'Failed - No Recipients') {
        throw new EmptyResultError('Unsuccessful Micropost Create: No Results')
      }

      throw new Error('Unsuccessful Micropost Create')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        ID: response.data.Micropost.ID.toString(),
        content: decode(response.data.Micropost.Content || ''),
        videoURL: decode(response.data.Micropost.VideoURL || ''),
        hyperlinkURL: decode(response.data.Micropost.TargetURL || ''),
        hyperlinkText: decode(response.data.Micropost.TargetText || ''),
        imageURL: decode(response.data.Micropost.ImageURL || ''),
        publishDate: new Date(response.data.Micropost.PublishDate),
        notify: response.data.Micropost.Notify,
        segmentID: response.data.Micropost.SegmentID
          ? response.data.Micropost.SegmentID.toString()
          : null,
      },
    }
  } catch (e) {
    const isEmptyResult = e instanceof EmptyResultError

    const logErrorPath = isEmptyResult
      ? `${ERROR_PATH}::NoRecipients`
      : ERROR_PATH

    const userMessage = isEmptyResult
      ? 'No recipients for the Custom Audience'
      : GENERIC_ERROR

    resolvedReturn = handleError(logErrorPath, userMessage, e)
  }

  return Promise.resolve(resolvedReturn)
}
