import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Box, Flex, Collapse, Image, Text} from '@chakra-ui/react'
import {setLogContext} from '@vanguard/logger'

import {api} from '../../services/api'
import copy from './copy.json'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {ActionButton, FormInput, FormInputPassword} from '../../components'
import {imageDataURL} from './Image'
import {useRequiredAuth} from '../../stores/AuthStore'
import {useStatusToast} from '../../app/ToastManager'

export const MAX_USERNAME_CHARACTERS = 100
export const MIN_USERNAME_CHARACTERS = 2
export const MAX_PASSWORD_CHARACTERS = 50
export const MIN_PASSWORD_CHARACTERS = 5

export const EZTLoginView: React.FC<React.PropsWithChildren> = () => {
  setLogContext('EZTLoginView')

  const {client} = useRequiredAuth()
  const history = useHistory()
  const {toast} = useStatusToast()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    const init = async (): Promise<void> => {
      await Promise.all([
        api.eztexting.credentials
          .read({
            clientID: client.ID,
          })
          .then((readResponse) => {
            if (readResponse.result === 'failure') {
              toast.error(copy.errors.generic)
            } else {
              setUsername(readResponse.data.userName)
              setPassword(readResponse.data.password)
            }
          }),
        api.eztexting.credentials
          .check({
            clientID: client.ID,
          })
          .then((checkResponse) => {
            if (checkResponse.result === 'failure') {
              toast.error(copy.errors.generic)
            } else if (!checkResponse.data.validated) {
              setError(copy.errors.invalid)
            }
          }),
      ])
    }
    init()
  }, [client.ID, toast])

  const handleFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()

    const response = await api.eztexting.credentials.add({
      clientID: client.ID,
      username,
      password,
    })
    if (response.result === 'failure') {
      toast.error(copy.errors.generic)
      return
    }
    if (response.data.validated) {
      setError(undefined)
      history.goBack()
    } else {
      setError(copy.errors.invalid)
    }
  }

  const handleUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.value.length <= MAX_USERNAME_CHARACTERS) {
      setUsername(event.target.value)
    }
  }

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.value.length <= MAX_PASSWORD_CHARACTERS) {
      setPassword(event.target.value)
    }
  }

  const hasValidInputs = (): boolean =>
    username.length >= MIN_USERNAME_CHARACTERS &&
    password.length >= MIN_PASSWORD_CHARACTERS

  return (
    <PageTemplate width={['100%']} maxWidth={['1030px']} borderX={['0px']}>
      <form onSubmit={handleFormSubmit}>
        <Flex
          justifyContent='center'
          alignItems='center'
          h='90vh'
          flexDir='column'>
          <Box
            bg='white'
            p={50}
            w={['100%', '100%', '520px']}
            justifyContent='space-between'
            borderRadius='md'
            border='1px'>
            <Image
              src={imageDataURL}
              alt='Campanion and EZ Texting'
              width={['100%', '80%', '60%']}
              margin='0 auto 40px auto'
            />
            <Box mb='40px'>
              <Text fontWeight='500' fontSize={['lg']} pb='0.5rem'>
                {copy.headers.primary}
              </Text>
              <Text fontSize={['base']}>{copy.headers.secondary}</Text>
            </Box>
            <Collapse in={!!error}>
              <Box
                data-testid='error-viewer'
                style={{
                  border: '1px',
                  borderColor: 'rgb(255,0,0)',
                  background: 'rgba(255,0,0,0.5)',
                  borderRadius: 'md',
                  padding: 1,
                }}>
                {error}
              </Box>
            </Collapse>
            <FormInput
              title='username'
              id='username'
              name='username'
              type='text'
              label={copy.inputs.username.label}
              labelFontSize={['base']}
              labelFontWeight='bold'
              marginBottom='20px'
              value={username}
              onChange={handleUsernameChange}
            />
            <FormInputPassword
              title='password'
              id='password'
              name='password'
              autoComplete='current-password'
              label={copy.inputs.password.label}
              labelFontSize={['base']}
              labelFontWeight='bold'
              value={password}
              onChange={handlePasswordChange}
              marginBottom='20px'
            />
            <Flex justifyContent={['flex-start', 'flex-end']}>
              <ActionButton
                variant='solid'
                type='submit'
                isDisabled={!hasValidInputs()}
                width={['100%', '40%']}>
                {copy.buttons.submit}
              </ActionButton>
            </Flex>
          </Box>
        </Flex>
      </form>
    </PageTemplate>
  )
}
