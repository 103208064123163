import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {CreateRequest, CreateResponse} from './types'

export interface Create<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const create: Create<CreateRequest, CreateResponse> = (http, params) => {
  const path = '/v1/microposts'

  return http.request<CreateResponse>({
    method: 'POST',
    url: `${path}`,
    data: params,
  })
}
