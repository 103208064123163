import {albums} from './albums'
import {auth} from './auth'
import {clients} from './clients'
import {eztexting} from './eztexting'
import {featureSettings} from './featuresettings'
import {firebase} from './firebase'
import {forms} from './forms'
import {microposts} from './microposts'
import {permissions} from './permissions'
import {persons} from './persons'
import {photos} from './photos'
import {segments} from './segments'

export const api = {
  albums,
  auth,
  clients,
  eztexting,
  featureSettings,
  firebase,
  forms,
  microposts,
  permissions,
  persons,
  photos,
  segments,
}
