import {FC} from 'react'
import {Flex} from '@chakra-ui/react'

import {Integration} from './Integration'
import {IntegrationOptions} from '../../types'

interface Props {
  integrations: IntegrationOptions[]
}

export const IntegrationList: FC<Props> = ({integrations}) => (
  <Flex
    as='ul'
    data-testid='integration-list'
    justifyContent='space-between'
    marginX='0.2em'
    flexWrap='wrap'>
    {integrations.map((integration) => (
      <Integration key={integration.ID} options={integration} />
    ))}
  </Flex>
)
