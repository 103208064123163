import {AxiosResponse} from 'axios'
import {RawProgram, RawPagedCollectionResponse} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  ClientID: number
  SeasonID: number
  IncludeInactive?: boolean
  PageNumber: number
  PageSize: number
}

type FetchProgramsResponse = RawPagedCollectionResponse<RawProgram>

export interface FetchPrograms<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchPrograms: FetchPrograms<Params, FetchProgramsResponse> = (
  http,
  params,
) => {
  const {ClientID, SeasonID, IncludeInactive, PageNumber, PageSize} = params

  const path = '/v2/sessions/programs'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (SeasonID !== undefined) {
    queryParams.push(`seasonid=${SeasonID}`)
  }
  if (IncludeInactive !== undefined) {
    queryParams.push(`includeinactive=${IncludeInactive}`)
  }
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.get<FetchProgramsResponse>(`${path}${query}`)
}
