import {AxiosResponse} from 'axios'

// Configure default retry attempts and delay
const defaultRetryConfig = {
  retries: 3,
  delay: 1000,
  isExponential: true,
}

export const retryRequest = async <T>(
  fn: () => Promise<AxiosResponse<T>>,
  {retries, delay, isExponential} = defaultRetryConfig,
): Promise<AxiosResponse<T>> => {
  try {
    return await fn()
  } catch (error) {
    if (retries) {
      await new Promise((r) => {
        setTimeout(r, delay)
      })

      return retryRequest(fn, {
        retries: retries - 1,
        delay: isExponential ? delay * 2 : delay,
        isExponential,
      })
    }

    throw new Error('Max retries reached for retriable HTTP Request')
  }
}
