import React, {useState, useEffect, useReducer} from 'react'
import {api} from './api'
import {Micropost} from '../types'
import {useRequiredAuth} from '../stores/AuthStore'

interface UseFetchMicropostsReturn {
  data: Micropost[]
  hasError: boolean
  isLoading: boolean
  hasMore: boolean
  maybeFetchMore: () => void
  total: number
}
type FetchData = (pageNumber: number, didCancel?: boolean) => Promise<void>

export const MICROPOSTS_PAGE_SIZE = 10

interface State {
  status: 'idle' | 'pending' | 'resolved' | 'rejected'
  data: Micropost[]
  total: number
  nextURL: string
}

type Action =
  | {type: 'FETCH_INIT'}
  | {
      type: 'FETCH_SUCCESS'
      payload: {
        data: Micropost[]
        total: number
        nextURL: string
      }
    }
  | {type: 'FETCH_FAILURE'}

const fetchReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_INIT': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'FETCH_SUCCESS': {
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        nextURL: action.payload.nextURL,
        status: 'resolved',
      }
    }
    case 'FETCH_FAILURE': {
      return {
        ...state,
        status: 'rejected',
      }
    }
    default:
      throw new Error('Unhandled action')
  }
}

export const useFetchMicroposts = (): UseFetchMicropostsReturn => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const {client} = useRequiredAuth()

  const [state, dispatch] = useReducer(fetchReducer, {
    status: 'idle',
    data: [],
    total: 0,
    nextURL: '',
  })

  const fetchData = React.useCallback<FetchData>(
    async (pageNumber, didCancel = false) => {
      dispatch({type: 'FETCH_INIT'})

      const response = await api.microposts.fetchMicroposts({
        pageNumber,
        pageSize: MICROPOSTS_PAGE_SIZE,
        clientID: client.ID,
      })

      if (!didCancel) {
        if (response.result === 'failure') {
          dispatch({type: 'FETCH_FAILURE'})
        } else {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: {
              data: response.data.results,
              total: response.data.total,
              nextURL: response.data.nextURL,
            },
          })
        }
      }
    },
    [client.ID],
  )

  useEffect(() => {
    let didCancel = false

    fetchData(currentPageNumber, didCancel)

    return (): void => {
      didCancel = true
    }
  }, [currentPageNumber, fetchData])

  const maybeFetchMore = (): void => {
    if (state.status !== 'pending' && Boolean(state.nextURL.length)) {
      setCurrentPageNumber(currentPageNumber + 1)
    }
  }

  return {
    data: state.data,
    isLoading: state.status === 'pending',
    hasError: state.status === 'rejected',
    hasMore: Boolean(state.nextURL.length),
    maybeFetchMore,
    total: state.total,
  }
}
