import {EzTextingGroup} from '../../../types'
import * as coreApi from '../../coreApi/eztexting'
import {SSEMessage} from '../../coreApi/types'
import {transformGroup} from './group.transform'

export const {TopicSyncGroupComplete} = coreApi
export type Topic = coreApi.Topic

export type SyncDetails = {
  completed: boolean
  errors: boolean
  friendlyMessage: string
}

export type SynchronizeGroupCompleteEvent = {
  ezTextingGroup?: EzTextingGroup
  syncDetails?: SyncDetails
}

export function handleSSE(msg: SSEMessage): SynchronizeGroupCompleteEvent {
  const coreEvent = coreApi.handleSSE(msg)
  const event: SynchronizeGroupCompleteEvent = {}
  if (coreEvent.GroupResponse) {
    event.ezTextingGroup = transformGroup(coreEvent.GroupResponse)
  }
  if (coreEvent.SyncDetails) {
    event.syncDetails = {
      completed: coreEvent.SyncDetails.Completed,
      errors: coreEvent.SyncDetails.Errors,
      friendlyMessage: coreEvent.SyncDetails.FriendlyMessage,
    }
  }
  return event
}
