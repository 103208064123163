import React, {useReducer, createContext, useContext, useMemo} from 'react'
import {setLogContext} from '@vanguard/logger'

/* *********************************************** */
interface State {
  viewMode: 'initial' | 'sortPhotos' | 'editAlbum' | 'viewFailures'
}

type Action = {
  type: 'SET_VIEW_MODE'
  payload: State['viewMode']
}

const AlbumViewManagerStateContext = createContext<State | undefined>(undefined)
const AlbumViewManagerDispatchContext = createContext<
  React.Dispatch<Action> | undefined
>(undefined)

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_VIEW_MODE': {
      return {
        ...state,
        viewMode: action.payload,
      }
    }
    default:
      return state
  }
}

/* *********************************************** */
export const AlbumViewManager: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const initialState: State = {
    viewMode: 'initial',
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const logContext = useMemo(() => {
    let val = 'AlbumView'

    switch (state.viewMode) {
      case 'editAlbum':
        val = 'ComposeAlbumModal'
        break
      default:
    }

    return val
  }, [state.viewMode])

  setLogContext(logContext)

  const stateReturn = useMemo(
    () => ({
      ...state,
    }),
    [state],
  )

  return (
    <AlbumViewManagerStateContext.Provider value={stateReturn}>
      <AlbumViewManagerDispatchContext.Provider value={dispatch}>
        {children}
      </AlbumViewManagerDispatchContext.Provider>
    </AlbumViewManagerStateContext.Provider>
  )
}

export const useAlbumViewState = (): State => {
  const context = useContext(AlbumViewManagerStateContext)
  if (!context) {
    throw new Error(
      'useAlbumViewState must be used within a AlbumViewManagerStateContextProvider',
    )
  }

  return context
}

export const useAlbumViewDispatch = (): React.Dispatch<Action> => {
  const context = useContext(AlbumViewManagerDispatchContext)
  if (!context) {
    throw new Error(
      'useAlbumViewDispatch must be used within a AlbumViewManagerStateContextProvider',
    )
  }

  return context
}
