/* eslint-disable no-console */
import React, {useEffect, useState, useMemo} from 'react'
import {Flex} from '@chakra-ui/react'

import {PrimaryNavigation} from '../PrimaryNavigation/PrimaryNavigation'

export interface TemplateMediaQuery {
  query: string
  props: Record<string, string>
}

interface Props {
  children: React.ReactNode
  header?: React.ReactElement
  width?: string[]
  maxWidth?: string[]
  borderX?: string[]
  className?: string
  maxContentWidth?: number
  overflowX?: string
  marginBottom?: string
}

export const PageTemplate: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  children,
  width = ['100%', '100%', '50%'],
  maxWidth = [],
  borderX = ['1px'],
  maxContentWidth,
  marginBottom = '0',
}): React.ReactElement => {
  const [mediaQueryRules, setMediaQueryRules] = useState<
    Record<string, string> | {}
  >()

  const templateMediaQuery: TemplateMediaQuery = useMemo(
    (): TemplateMediaQuery => ({
      query: `screen and (min-width: ${maxContentWidth}px)`,
      props: {
        minWidth: `${maxContentWidth}px`,
        maxWidth: `${maxContentWidth}px`,
        width: 'unset',
      },
    }),
    [maxContentWidth],
  )

  useEffect(() => {
    const mql = window.matchMedia(templateMediaQuery.query)

    const handleQueryListener = (): void => {
      if (mql.matches) {
        setMediaQueryRules(templateMediaQuery.props)
      } else {
        setMediaQueryRules({})
      }
    }

    if (templateMediaQuery.query) {
      mql.addListener(handleQueryListener)
      // mql.addEventListener('change', handleQueryListener)

      handleQueryListener()
    }

    return (): void => {
      if (templateMediaQuery.query) {
        mql.removeListener(handleQueryListener)
        // mql.removeEventListener('change', handleQueryListener)
      }
    }
  }, [templateMediaQuery])

  return (
    <Flex alignItems='center' flexDirection='column' flexGrow={1}>
      <PrimaryNavigation width={['100vw']} />

      <Flex
        overflowX='hidden'
        w={width}
        maxW={maxWidth}
        borderX={borderX}
        borderColor='#CED2D5'
        direction='column'
        minHeight={['calc(100vh - 40px)', 'calc(100vh - 64px)']}
        h='100%'
        mt={['40px', '64px']}
        mb={marginBottom}
        {...mediaQueryRules}>
        {header}
        <div data-testid='view-content'>{children}</div>
      </Flex>
    </Flex>
  )
}
