import React, {useEffect, useMemo, useCallback} from 'react'

import {log} from '@vanguard/logger'

import {useRequiredAuth} from '../../../stores/AuthStore'

import {Photo, Album, FeatureName} from '../../../types'
import {useReleaseFlags} from '../../../hooks/useReleaseFlags'
import {useConfirmation} from '../../../app/ConfirmationManager'
import {useStatusToast} from '../../../app/ToastManager'
import {deleteAlbumPhoto} from '../../../services/api/photos/deleteAlbumPhoto'
import {useAlbumService} from '../../../services/useAlbumService'
import {usePhotoRotation} from '../../../services/usePhotoRotation'

import copy from '../copy.json'
import {usePreventTabClose} from '../../../hooks/usePreventTabClose'

import {GalleryPhoto} from '../types'
import {useAlbumDispatch} from '../AlbumManager'
import {
  useAlbumPhotosDispatch,
  useAlbumPhotosState,
} from '../AlbumPhotosManager'
import {useSSE} from '../../../app/SSEProvider'
import {useFetchAlbumUploadSessions} from '../../../services/useFetchAlbumUploadSessions'

import {
  TopicUploadSessionComplete,
  processUploadSessionCompleteSSE,
} from '../../../services/api/photos/processUploadSessionCompleteSSE'
import {PhotoGallery} from '../../../components'

const fallbackImage =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('../../../assets/images/placeholderPhoto.jpg').default

type PhotoType = 'standard' | 'thumbnail'

const getPhotoDimension = (
  photo: Photo,
  photoType: PhotoType,
  dimension: 'Height' | 'Width',
): number =>
  photo[`${photoType}${dimension}`] > 0
    ? photo[`${photoType}${dimension}`]
    : 150

interface Props {
  isVisible: boolean
  album: Album
}

export const DefaultGallery: React.FC<React.PropsWithChildren<Props>> =
  React.memo(({isVisible, album}) => {
    const albumDispatch = useAlbumDispatch()
    const {
      data: photos,
      // processSSE,
      status,
      // numUnprocessed,
    } = useAlbumPhotosState()
    const albumPhotosDispatch = useAlbumPhotosDispatch()

    const {client} = useRequiredAuth()
    const {getIsReleasedToUser} = useReleaseFlags()
    const showConfirmation = useConfirmation()

    const {toast} = useStatusToast()
    const {upsert} = useAlbumService()

    const {
      numUnprocessedFiles,
      failedProcessingFiles,
      fetchData: fetchAlbumUploadSessions,
      status: albumSessionStatus,
    } = useFetchAlbumUploadSessions(album?.ID)

    const {
      displayPhotoRotations,
      addRotation,
      rotatePhotoStatus,
      processingPhotos,
      clearAllPhotoRotations,
    } = usePhotoRotation()

    // prevent close or refresh if we have processing photos
    const preventTabClose = !!Object.keys(processingPhotos).length
    usePreventTabClose(preventTabClose)

    // SSE Subscriptions
    const subscribe = useSSE()
    // // Process Photo SSE
    // useEffect(() => {
    //   if (!subscribe) {
    //     return (): void => {
    //       // don't unsubscribe before you can subscribe
    //     }
    //   }

    //   const unsubscribe = subscribe(TopicProcessPhotoComplete, processSSE)

    //   return unsubscribe
    // }, [client, subscribe, processSSE])

    // Upload Session Complete SSE
    useEffect(() => {
      if (!subscribe) {
        return (): void => {
          // don't unsubscribe before you can subscribe
        }
      }

      const unsubscribe = subscribe(TopicUploadSessionComplete, (msg) => {
        if (status === 'uploading') return

        const sse = processUploadSessionCompleteSSE(msg)

        // When we get an Upload Complete SSE for this album, refresh the data
        if (sse.albumID === album.ID) {
          albumDispatch({type: 'RESET'})
          albumPhotosDispatch({type: 'RESET'})
        }
      })

      return unsubscribe
    }, [album, subscribe, albumDispatch, albumPhotosDispatch, status])

    // Rotate Errors
    useEffect(() => {
      if (rotatePhotoStatus !== 'ok') {
        toast.error(copy.rotatePhoto.failure)
      }
    }, [toast, rotatePhotoStatus])

    // Clear rotations if after successfully fetching photos
    useEffect(() => {
      if (status === 'resolved') {
        clearAllPhotoRotations()
      }
    }, [status, clearAllPhotoRotations])

    useEffect(() => {
      if (
        Object.keys(displayPhotoRotations).length &&
        !Object.keys(processingPhotos).length
      ) {
        albumPhotosDispatch({type: 'RESET'})
      }
    }, [processingPhotos, displayPhotoRotations, albumPhotosDispatch])

    // Fetch Album Upload Session data after the photo fetching completes successfully
    useEffect(() => {
      const fetchAlbumData = async (): Promise<void> => {
        if (status === 'resolved') {
          await fetchAlbumUploadSessions()
        }
      }

      fetchAlbumData()
    }, [status, fetchAlbumUploadSessions])

    // Determine when we are currently processing photos
    useEffect(() => {
      if (albumSessionStatus === 'resolved' && numUnprocessedFiles > 0) {
        albumPhotosDispatch({
          type: 'PROCESSING_PHOTOS',
          payload: numUnprocessedFiles,
        })
      }
    }, [numUnprocessedFiles, albumPhotosDispatch, albumSessionStatus])

    // Show failed file UI when there are failures
    useEffect(() => {
      if (
        albumSessionStatus === 'resolved' &&
        failedProcessingFiles.length > 0
      ) {
        albumPhotosDispatch({
          type: 'SET_FAILED_FILES',
          payload: failedProcessingFiles,
        })
      }
    }, [failedProcessingFiles, albumPhotosDispatch, albumSessionStatus])

    // After all photos are processed, reset everything to get fresh album/albumphotos data pulls
    // useEffect(() => {
    // TODO: i've seen negative numUnprocessed. Not sure if I should change condition or find out why that is happening
    // if (numUnprocessed === 0 && status === 'processing') {
    //   albumDispatch({type: 'RESET'})
    //   albumPhotosDispatch({type: 'RESET'})
    // }
    // }, [numUnprocessed, albumDispatch, albumPhotosDispatch, status])

    const transformPhotos = React.useCallback(
      (photoList: Photo[], photoType: PhotoType): GalleryPhoto[] => {
        const transformedPhotos = photoList.map((p) => {
          const transformed = {
            key: p.ID,
            src:
              p[`${photoType}Height`] > 0 && p[`${photoType}Width`] > 0
                ? p[`${photoType}URL`]
                : fallbackImage,
            width: [90, 270].includes(displayPhotoRotations[p.ID])
              ? getPhotoDimension(p, photoType, 'Height')
              : getPhotoDimension(p, photoType, 'Width'),
            height: [90, 270].includes(displayPhotoRotations[p.ID])
              ? getPhotoDimension(p, photoType, 'Width')
              : getPhotoDimension(p, photoType, 'Height'),
            rotation: displayPhotoRotations[p.ID]
              ? displayPhotoRotations[p.ID]
              : 0,
            processing: processingPhotos[p.ID],
            originalFileName: p.originalFileName,
          }
          return transformed
        })
        return transformedPhotos
      },
      [displayPhotoRotations, processingPhotos],
    )

    const [transformedThumbnailPhotos] = React.useMemo(
      () => [transformPhotos(photos, 'thumbnail')],
      [photos, transformPhotos],
    )

    const onDelete = useCallback(
      (itemID: Photo['ID']) => (): void => {
        showConfirmation({
          variant: 'danger',
          title: 'Delete Photo',
          description: "Are you sure? You can't undo this action afterwards.",
          confirmButtonText: 'Delete',
          testID: 'delete-photo',
          onConfirm: async (): Promise<void> => {
            const deletion = await deleteAlbumPhoto(client.ID, itemID)

            if (deletion.result === 'success') {
              albumPhotosDispatch({type: 'REMOVE_PHOTO', payload: itemID})
              albumDispatch({type: 'REMOVE_PHOTO'})
              toast.success('Photo successfully deleted.')

              log.breadcrumb('Album photo successfully deleted', {
                photoID: itemID,
              })
            } else {
              const GENERIC_DELETE_ERROR =
                'Something went wrong deleting your photo. Please try again.'

              toast.error(deletion.error.message || GENERIC_DELETE_ERROR)
              log.breadcrumb('Album photo unsuccessfully deleted', {
                photoID: itemID,
              })
            }
          },
        })
      },
      [albumDispatch, albumPhotosDispatch, client.ID, showConfirmation, toast],
    )

    const onCoverPhotoSelection = useCallback(
      (itemID: Album['coverPhotoID']) => async (): Promise<void> => {
        const albumToUpdate = {...album, coverPhotoID: itemID}
        const response = await upsert(albumToUpdate)

        if (response.status === 'success') {
          albumDispatch({type: 'SET_COVER_PHOTO', payload: itemID})

          toast.success('Cover photo successfully updated.')
          log.breadcrumb('Album cover photo successfully set', {
            photoID: itemID,
          })
        } else {
          const GENERIC_UPDATE_ERROR =
            'Something went wrong, your cover photo did not update. Please try again.'
          toast.error(response.message || GENERIC_UPDATE_ERROR)
          log.breadcrumb('Album cover photo unsuccessfully set', {
            photoID: itemID,
          })
        }
      },
      [album, albumDispatch, toast, upsert],
    )

    const onRotate = useCallback(
      (itemID: Photo['ID']) => (): void => {
        const photo = transformedThumbnailPhotos.find(
          (image) => image.key === itemID,
        )

        if (!photo || !photo.key) return

        addRotation(photo.key)
      },
      [addRotation, transformedThumbnailPhotos],
    )

    const actions = useMemo<
      {
        name: string
        onClick: (itemID: Photo['ID']) => () => void
        feature: FeatureName
      }[]
    >(
      () => [
        {
          name: 'Make Album Cover',
          onClick: onCoverPhotoSelection,
          feature: 'photo-action',
        },
        {
          name: 'Rotate 90° Clockwise',
          onClick: onRotate,
          feature: 'photo-action',
        },
        {
          name: 'Delete',
          onClick: onDelete,
          feature: 'photo-action',
        },
      ],
      [onCoverPhotoSelection, onRotate, onDelete],
    )

    if (!isVisible) return null

    return (
      <div data-testid='photo-gallery'>
        <PhotoGallery
          photos={photos}
          galleryPhotos={transformedThumbnailPhotos}
          fallbackPhoto={fallbackImage}
          actions={actions.filter((action) =>
            getIsReleasedToUser(action.feature),
          )}
        />
      </div>
    )
  })

DefaultGallery.displayName = 'DefaultGallery'
