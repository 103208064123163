import {
  QueryFunction,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query'
import {useInfiniteQueryUntilEnd} from '../hooks/useInfiniteQueryUntilEnd'
import {http} from '../services/api/http'
import {fetchPrograms} from '../services/coreApi/sessions'
import {useRequiredAuth} from '../stores/AuthStore'

type Program = {
  ID: string
  clientID: string
  self: string
  name: string
  description: string
  isActive: boolean
}

type QueryFnData = {
  results: Program[]
  totalCount: number
  nextPage: string
}
type Client = ReturnType<typeof useRequiredAuth>['client'] // I think there is a better way with Generics, but don't have time to figure it out
type GetQueryOptions = (params: {
  client: Client
  season: number
}) => UseInfiniteQueryOptions<QueryFnData>
type QueryFn = (params: {
  client: Client
  season: number
}) => QueryFunction<QueryFnData>

const PAGE_SIZE = 100
const QUERY_STALE_TIME = 1000 * 60 * 30 // 30 minutes

const queryKeys = {
  all: () => [{resource: 'programs'}] as const, // [{ resource: 'programs'}]
  lists: () => [{...queryKeys.all()[0], type: 'list'}] as const, // [{ resource: 'programs', type: 'list}]
  list: ({season}: {season: number}) =>
    [{...queryKeys.all()[0], type: 'list', season}] as const, // [{ resource: 'programs', type: 'list, season: '2020'}]
}

const queryFn: QueryFn =
  ({client, season}) =>
  async ({pageParam = 1}) => {
    const response = await fetchPrograms(http, {
      ClientID: Number(client.ID), // technically doesn't need to be apart of query key, but currently passing it for queryFn so kind of should be
      PageNumber: pageParam,
      PageSize: PAGE_SIZE,
      SeasonID: season,
    })

    const {Results, TotalCount, Next} = response.data

    return {
      results: Results.map((result) => ({
        ID: result.ID.toString(),
        clientID: result.ClientID.toString(),
        self: result.Self,
        name: result.Name,
        description: result.Description,
        isActive: result.IsActive,
      })),
      totalCount: TotalCount,
      nextPage: Next,
    }
  }

const getQueryOptions: GetQueryOptions = ({client, season}) => ({
  queryKey: queryKeys.list({season}),
  queryFn: queryFn({client, season}),
  staleTime: QUERY_STALE_TIME,
  getNextPageParam: (lastPage, allPages) =>
    // If the last page has some real next URL string, then set the pageParam to the next page
    lastPage.nextPage !== '' ? allPages.length + 1 : undefined,
})

type UsePrograms = (params: {
  season: number
}) => UseInfiniteQueryResult<QueryFnData>

export const usePrograms: UsePrograms = ({season}) => {
  const {client} = useRequiredAuth()

  return useInfiniteQueryUntilEnd<QueryFnData>(
    getQueryOptions({client, season}),
  )
}
