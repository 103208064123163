import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {ModifyGroupRequest, GroupResponse} from './types'

export interface ModifyGroup<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const modifyGroup: ModifyGroup<ModifyGroupRequest, GroupResponse> = (
  http,
  params,
) => {
  const {ID} = params

  const path = '/v1/eztexting/group/{id}'.replace('{id}', ID.toString())

  return http.request<GroupResponse>({
    method: 'PUT',
    url: `${path}`,
    data: params,
  })
}
