import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {ValidateNumbersRequest, ValidateNumbersResponse} from './types'

export interface ValidateNumbers<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const validateNumbers: ValidateNumbers<
  ValidateNumbersRequest,
  ValidateNumbersResponse
> = (http, params) => {
  const path = '/v1/eztexting/validatenumbers'

  return http.request<ValidateNumbersResponse>({
    method: 'POST',
    url: path,
    data: params,
  })
}
