import React from 'react'
import {useClipboard, InputGroup, Input, Button} from '@chakra-ui/react'

interface TextDisplayWithClipboardCopyProps {
  text: string
  placeholder: string
  height?: string
  onAfterCopy?: (value: string) => void
}

export const TextDisplayWithClipboardCopy: React.FC<
  TextDisplayWithClipboardCopyProps
> = ({text, placeholder, height = '4rem', onAfterCopy}) => {
  const {onCopy, hasCopied} = useClipboard(text)

  return (
    <InputGroup minH='100px' size='lg' maxW='400px'>
      <Input
        isReadOnly
        placeholder={placeholder}
        height={height}
        borderRadius='5px 0 0 5px'
        border='1px solid'
        borderColor='#868686'
        fontSize='1.2rem'
        value={text}
        padding='20px'
      />
      <Button
        onClick={(): void => {
          if (onCopy) onCopy()

          if (onAfterCopy) onAfterCopy(text)
        }}
        backgroundColor='#568d88'
        color='white'
        size='lg'
        height={height}
        width='9rem'
        fontSize='1.2rem'
        borderRadius='0 5px 5px 0'>
        {!hasCopied ? 'COPY' : 'COPIED'}
      </Button>
    </InputGroup>
  )
}
