import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {FormApplicabilityRequest, ApplicabilityResponse} from './types'

export interface FormApplicability<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const formApplicability: FormApplicability<
  FormApplicabilityRequest,
  ApplicabilityResponse
> = (http, params) => {
  const path = '/v1/forms/legacy/applicability'

  return http.request<ApplicabilityResponse>({
    method: 'POST',
    url: `${path}`,
    data: params,
  })
}
