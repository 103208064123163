import React, {useReducer, createContext, useContext} from 'react'
import {Micropost} from '../../../types'
import {isValidVideoURL, isValidURL, stripUrlProtocol} from '../../../utils'
import {INSERT_PLACEHOLDER_ID} from '../../../constants'

/* *********************************************** */
type ReducerState = Micropost

type Action = {
  type: 'UPDATE_VALUE'
  payload: {
    key: string // keyof Micropost
    value: string | boolean | null
  }
}

const reducer: React.Reducer<ReducerState, Action> = (state, action) => {
  switch (action.type) {
    case 'UPDATE_VALUE': {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    }

    default:
      throw new Error('Unhandled action')
  }
}

/* *********************************************** */

type ContextState = ReducerState

const ComposeMicropostManagerStateContext = createContext<
  ContextState | undefined
>(undefined)

// interface ContextDispatch {
//   dispatch: React.Dispatch<Action>
// }

const ComposeMicropostManagerDispatchContext = createContext<
  React.Dispatch<Action> | undefined
>(undefined)

/* *********************************************** */
export const MicropostManager: React.FC<
  React.PropsWithChildren<{
    initial: Micropost | null
  }>
> = ({initial, children}) => {
  const newMicropostDefaults: ReducerState = {
    ID: INSERT_PLACEHOLDER_ID,
    content: '',
    videoURL: '',
    hyperlinkURL: '',
    hyperlinkText: '',
    imageURL: '',
    publishDate: undefined,
    notify: false,
    segmentID: null,
  }

  const initialState: ReducerState = initial
    ? {
        ...initial,
        hyperlinkURL: stripUrlProtocol(initial.hyperlinkURL),
        videoURL: stripUrlProtocol(initial.videoURL),
      }
    : newMicropostDefaults

  const [state, dispatch] = useReducer(reducer, initialState)

  const stateValue: ContextState = state
  // const dispatchValue: ContextDispatch = {
  //   dispatch,
  // }

  return (
    <ComposeMicropostManagerStateContext.Provider value={stateValue}>
      <ComposeMicropostManagerDispatchContext.Provider value={dispatch}>
        {children}
      </ComposeMicropostManagerDispatchContext.Provider>
    </ComposeMicropostManagerStateContext.Provider>
  )
}

/* *********************************************** */
interface ComposeMicropostState extends ContextState {
  isVideoValid: boolean
  isHyperlinkValid: boolean
}

export const useComposeMicropostState = (): ComposeMicropostState => {
  const state = useContext(ComposeMicropostManagerStateContext)
  if (!state) {
    throw new Error(
      'useComposeMicropostState must be used within a StepProvider',
    )
  }

  const isVideoValid =
    !state.videoURL.length ||
    isValidVideoURL(state.videoURL, ['youtube', 'vimeo'])

  const isHyperlinkValid =
    !state.hyperlinkURL.length || isValidURL(state.hyperlinkURL)

  return {
    ...state,
    isVideoValid,
    isHyperlinkValid,
  }
}

/* *********************************************** */
interface ComposeMicropostActions {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  setValue: (name: keyof Micropost, value: string | boolean | null) => void
}

export const useComposeMicropostActions = (): ComposeMicropostActions => {
  const dispatch = useContext(ComposeMicropostManagerDispatchContext)
  if (!dispatch) {
    throw new Error(
      'useComposeMicropostDispatch must be used within a StepProvider',
    )
  }

  const handleInputChange = React.useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
      event.persist()

      dispatch({
        type: 'UPDATE_VALUE',
        payload: {key: event.target.name, value: event.target.value},
      })
    },
    [dispatch],
  )

  const setValue: ComposeMicropostActions['setValue'] = React.useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_VALUE',
        payload: {key: name, value},
      })
    },
    [dispatch],
  )

  return {
    handleInputChange,
    setValue,
  }
}
