import {AxiosResponse} from 'axios'
import {HttpInstance} from '../../createHttpInstance'
import {PutAlbumConstraint} from './types'

export interface UpdateConstraint<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const updateConstraints: UpdateConstraint<
  PutAlbumConstraint,
  PutAlbumConstraint
> = (http, params) => {
  const {AlbumID} = params

  const path = '/v1/albums/{albumid}/constraint'.replace(
    '{albumid}',
    AlbumID.toString(),
  )

  return http.request<PutAlbumConstraint>({
    method: 'PUT',
    url: `${path}`,
    data: params,
  })
}
