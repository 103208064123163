import {useState} from 'react'

interface LoginCredentials {
  username: string
  password: string
}

interface AuthServiceReturn {
  data: {
    inputs: LoginCredentials
    error: LoginError | null
  }
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setError: React.Dispatch<React.SetStateAction<LoginError | null>>
  hasValidInputs: (quiet?: boolean) => boolean
}

interface LoginError {
  title: string
  description: string
}

// TODO: Investigate a React Form Management Library like Formik or React Form Hooks
// I'm just reinventing the wheel with this
export const useAuthService = (
  initialInputValues: LoginCredentials,
): AuthServiceReturn => {
  const [inputs, setInputs] = useState(initialInputValues)
  const [error, setError] = useState<LoginError | null>(null)

  const hasValidInputs = (quiet = true): boolean => {
    let isValid = false

    if (!inputs.username.trim().length) {
      if (!quiet) {
        setError({
          title: 'Missing username',
          description: 'Please enter a valid username.',
        })
      }
    } else if (!inputs.password.trim().length) {
      if (!quiet) {
        setError({
          title: 'Missing password',
          description: 'Please enter a valid password.',
        })
      }
    } else {
      isValid = true
    }

    return isValid
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    event.persist()

    if (error) {
      setError(null)
    }

    const {value} = event.target

    setInputs((currInputs) => ({
      ...currInputs,
      [event.target.name]: value,
    }))
  }

  return {
    data: {
      inputs,
      error,
    },
    handleInputChange,
    setError,
    hasValidInputs,
  }
}
