import React from 'react'

import {ScrollList} from '../../../components'
import {useAlbumViewState} from '../AlbumViewManager'
import {
  useAlbumPhotosDispatch,
  useAlbumPhotosState,
} from '../AlbumPhotosManager'

import {DefaultGallery} from './DefaultGallery'
import {useAlbumState} from '../AlbumManager'
import {SortableGallery} from './SortableGallery'

export const PhotoGallery: React.FC<React.PropsWithChildren> = () => {
  const {viewMode} = useAlbumViewState()
  const {data: album} = useAlbumState()
  const {status} = useAlbumPhotosState()
  const dispatch = useAlbumPhotosDispatch()

  return (
    <ScrollList
      isLoading={status === 'pending'}
      loadingText='Loading photos...'
      hasError={status === 'rejected'}
      onNext={(): void => dispatch({type: 'MAYBE_FETCH_NEXT_PAGE'})}>
      {album && (
        <>
          <DefaultGallery isVisible={viewMode !== 'sortPhotos'} album={album} />
          <SortableGallery
            isVisible={viewMode === 'sortPhotos'}
            album={album}
          />
        </>
      )}
    </ScrollList>
  )
}
