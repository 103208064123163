import React from 'react'
import {
  Flex,
  Box,
  Collapse,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from '@chakra-ui/react'
import {CheckCircleIcon, WarningTwoIcon} from '@chakra-ui/icons'

import {
  useAttachmentControlsState,
  useAttachmentControlsDispatch,
} from '../contextManagers/AttachmentControlsManager'
import {AttachmentButton} from '../../AttachmentButton/AttachmentButton'
import {stripUrlProtocol} from '../../../utils/stripUrlPrototcol'
import {MAX_HYPERLINK_TEXT_CHARS} from '../../../constants'
import {
  useComposeMicropostState,
  useComposeMicropostActions,
} from '../contextManagers/MicropostManager'
import composeCopy from '../copy.json'
import {CustomIcon} from '../../CustomIcon/CustomIcon'

const ControlToggle: React.FC<React.PropsWithChildren> = () => {
  const state = useAttachmentControlsState()
  const dispatch = useAttachmentControlsDispatch()

  return (
    <AttachmentButton
      attachmentType='hyperlink'
      onClick={(): void => {
        dispatch({type: 'TOGGLE_HYPERLINK'})
      }}
      isDisabled={state.hyperlink === 'disabled'}
    />
  )
}

const Control: React.FC<React.PropsWithChildren> = () => {
  const {hyperlinkText, hyperlinkURL, isHyperlinkValid} =
    useComposeMicropostState()
  const {handleInputChange, setValue} = useComposeMicropostActions()
  const state = useAttachmentControlsState()

  const renderValidHyperlinkUrlIcon = (): React.ReactNode => {
    if (!hyperlinkURL.length) {
      return null
    }

    return isHyperlinkValid ? (
      <CheckCircleIcon color='#7ABC9B' />
    ) : (
      <WarningTwoIcon color='#E58D55' />
    )
  }

  return (
    <Collapse in={state.hyperlink === 'active'}>
      <Flex align='center' bgColor='#DFE8ED' px='6'>
        <Flex flex={1} flexDirection={['column', 'row']} my={3}>
          <InputGroup flex='2'>
            <InputLeftAddon>
              <CustomIcon name='link' boxSize='6' color='#535353' pr={[0, 2]} />
              <Box display={['none', 'unset']}>https://</Box>
            </InputLeftAddon>
            <Input
              bgColor='white'
              name='hyperlinkURL'
              // width='lg'
              placeholder={composeCopy.inputs.hyperlinkURL.placeholder}
              value={hyperlinkURL}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                e.currentTarget.value = stripUrlProtocol(e.currentTarget.value)
                handleInputChange(e)
              }}
            />
            <InputRightElement>
              {renderValidHyperlinkUrlIcon()}
            </InputRightElement>
          </InputGroup>
          <InputGroup minWidth='220px' flex='1' mt={[2, 'unset']}>
            <Input
              bgColor='white'
              name='hyperlinkText'
              placeholder={composeCopy.inputs.hyperlinkText.placeholder}
              value={hyperlinkText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                if (e.currentTarget.value.length <= MAX_HYPERLINK_TEXT_CHARS) {
                  handleInputChange(e)
                }
              }}
              ml={[0, 4]}
            />
          </InputGroup>
        </Flex>
        <Flex flex='0 0 auto'>
          <AttachmentButton
            attachmentType='clear'
            title='clear hyperlink'
            mx={4}
            onClick={(): void => {
              setValue('hyperlinkURL', '')
              setValue('hyperlinkText', '')
            }}
          />
        </Flex>
      </Flex>
    </Collapse>
  )
}

const HyperlinkAttachment = {
  ControlToggle,
  Control,
}

export {HyperlinkAttachment}
