import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {SendRequest, SendResponse} from './types'

export interface Send<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const send: Send<SendRequest, SendResponse> = (http, params) => {
  const path = '/v1/eztexting/send'

  return http.request<SendResponse>({
    method: 'POST',
    url: `${path}`,
    data: params,
  })
}
