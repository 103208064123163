import React from 'react'
import {Flex, Text} from '@chakra-ui/react'
import {ChevronDownIcon, AddIcon} from '@chakra-ui/icons'
import {CustomIcon} from '../CustomIcon/CustomIcon'

// Note: I've reach brown-belt level with my TypeScriptFu with this one
type IconName = React.ComponentProps<typeof CustomIcon>['name']

interface Props {
  iconName?: IconName
}

export const BlankSlate: React.FC<React.PropsWithChildren<Props>> = ({
  iconName = 'add',
  children,
}) => {
  const renderIcon = (param: string): React.ReactElement => {
    switch (param) {
      case 'email':
        return <ChevronDownIcon boxSize='24px' />
      case 'add':
        return <AddIcon boxSize='24px' />
      default:
        return <CustomIcon name={param} boxSize='24px' />
    }
  }

  return (
    <Flex direction='column' alignItems='center'>
      <Flex
        boxSize='64px'
        background='#DFE8ED'
        borderRadius='32px'
        justifyContent='center'
        alignItems='center'
        mt={20}
        mb={5}>
        {renderIcon(iconName)}
      </Flex>
      <Text>{children}</Text>
    </Flex>
  )
}
