import * as React from 'react'
import {log} from '@vanguard/logger'
import TagManager from 'react-gtm-module'
import {useRequiredAuth} from '../stores/AuthStore'
import {api} from './api'
import {Params} from './api/firebase/getDynamicLink'
import {
  CAMPANION_MOBILE_BUNDLE_ID,
  CAMPANION_MOBILE_IOS_APP_STORE_ID,
  UNIVERSAL_LINK_BASE_URL,
} from '../constants'

type Status = 'idle' | 'failure'

// ADD NEW DESTINATION/ENDPOINT LOCATIONS HERE *********
const destinations = ['forms', 'healthcheck', 'registration'] as const // trying to provide a non-type iterable of destinations
const linkSocialTitle = 'Download Campanion App'
const linkSocialDescription =
  'Campanion is a mobile app that helps you connect with camp. Access and complete camp forms, then upload or scan paper forms from your mobile device.'
const linkSocialImage =
  'https://firebasestorage.googleapis.com/v0/b/campanion-push-test.appspot.com/o/Firebase-interstitial-basic.jpg?alt=media&token=8d7078d4-2cde-4d96-8266-98014b536674'

type Destination = (typeof destinations)[number] // This is how you get a union type from a const array

type DestinationMap = {[key in Destination]: string}

// Map short destination names to full paths here
const destinationToPathMap: DestinationMap = {
  forms: '/v2/forms/Dashboard.aspx',
  healthcheck: '/ui/medical/CovidHealthCheck',
  registration: '/ui/forms/application/camper/App',
}

interface Return {
  dynamicLink: string
  status: Status
  getDynamicLink: (
    destination: Destination,
    config?: {shouldPromptMobileInstall: boolean},
  ) => Promise<void>
}

interface UseCreateDynamicLink {
  (): Return
}

export const useCreateDynamicLink: UseCreateDynamicLink = () => {
  const {client} = useRequiredAuth()
  const [dynamicLink, setDynamicLink] = React.useState('')
  const [status, setStatus] = React.useState<Status>('idle')

  const getDynamicLink = React.useCallback<Return['getDynamicLink']>(
    async (
      destination: Destination,
      config = {shouldPromptMobileInstall: true},
    ) => {
      log.breadcrumb(`Begin Fetch Dynamic Link for ${destination}`)

      const redirectURL = `https://${client.citURL}${destinationToPathMap[destination]}`

      // When not prompting users to install app, do not show social preview stuff
      const socialMetaTagInfo = config.shouldPromptMobileInstall
        ? {
            socialTitle: linkSocialTitle,
            socialDescription: linkSocialDescription,
            socialImageLink: linkSocialImage,
          }
        : undefined

      // When not prompting user to install mobile app take them staight to webpage on mobile browser
      const fallbackLink = !config.shouldPromptMobileInstall
        ? `${redirectURL}` // 'https://www.google.com'
        : undefined
      const navigationInfo = !config.shouldPromptMobileInstall
        ? {
            enableForcedRedirect: true,
          }
        : undefined

      const linkParams: Params = {
        dynamicLinkInfo: {
          domainUriPrefix: UNIVERSAL_LINK_BASE_URL,
          link: redirectURL,
          androidInfo: {
            androidPackageName: CAMPANION_MOBILE_BUNDLE_ID,
            androidFallbackLink: fallbackLink,
          },
          iosInfo: {
            iosAppStoreId: CAMPANION_MOBILE_IOS_APP_STORE_ID,
            iosBundleId: CAMPANION_MOBILE_BUNDLE_ID,
            iosFallbackLink: fallbackLink,
          },
          socialMetaTagInfo,
          navigationInfo,
        },
        suffix: {
          option: 'SHORT',
        },
      }

      const response = await api.firebase.getDynamicLink(linkParams)

      if (response.result === 'failure') {
        log.error(new Error(response.error.message))
        setStatus('failure')
      }

      if (response.result === 'success') {
        const {
          data: {shortLink},
        } = response

        setDynamicLink(shortLink)

        setStatus('idle')

        log.breadcrumb(`Generated Dynamic Link successfully- ${shortLink}`)
        // Add this data to GTM dataLayer so a GA4 event can use it to track event
        TagManager.dataLayer({
          dataLayer: {
            dynamicLinkType: destination,
            dynamicLinkURL: shortLink,
          },
        })
      }
    },
    [client.citURL],
  )

  return React.useMemo(
    () => ({
      dynamicLink,
      getDynamicLink,
      status,
    }),
    [dynamicLink, getDynamicLink, status],
  )
}
