import React from 'react'
import {Flex, Box} from '@chakra-ui/react'

import {ActionButton, FormInput} from '../../../components'
import {useAudience} from './contextManagers/AudienceManager'
import {useModalContext} from './subcomps/Modal'
import copy from './copy.json'

export const MAX_AUDIENCE_NAME_LENGTH = 50
export const NAME_INPUT_PLACEHOLDER_TEXT = 'Name your audience'

const Header: React.FC<React.PropsWithChildren> = () => (
  <Box textAlign='center' flex={1}>
    Audience Details
  </Box>
)

const Body: React.FC<React.PropsWithChildren> = () => {
  const {initialFocusRef} = useModalContext()
  const {audience, updateName} = useAudience()

  const handleNameUpdate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.currentTarget.value.length <= MAX_AUDIENCE_NAME_LENGTH) {
      updateName(event.currentTarget.value)
    }
  }

  return (
    <Flex direction='column' h={['100%']}>
      <FormInput
        title='audiencename'
        id='audiencename'
        name='audiencename'
        type='text'
        label='Name'
        fontSize='16px'
        paddingLeft='11px'
        labelFontSize='16px'
        labelFontWeight='bold'
        marginBottom='2em'
        placeholder={NAME_INPUT_PLACEHOLDER_TEXT}
        labelPaddingTop={4}
        labelPaddingBottom={1}
        value={audience.name}
        onChange={handleNameUpdate}
        borderColor='#ced2d5'
        ref={initialFocusRef as React.RefObject<HTMLInputElement>}
      />
    </Flex>
  )
}

const Footer: React.FC<React.PropsWithChildren> = () => {
  const {setCurrentStep} = useModalContext()
  const {audience} = useAudience()

  const isValid = audience.name && audience.name.length > 0

  return (
    <Flex
      flexDir='row'
      justifyContent={['space-around', 'flex-end']}
      width='100%'>
      <ActionButton
        mr={3}
        onClick={(): void => {
          setCurrentStep('type')
        }}
        isDisabled={!isValid}
        flexGrow={[1, 0]}>
        {copy.buttons.primaryAction.next}
      </ActionButton>
    </Flex>
  )
}

export const ComposeStepUI = {
  Header,
  Body,
  Footer,
}
