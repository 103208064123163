import {AxiosResponse} from 'axios'
import {Paginated, PermissionResponse, PermissionRequest} from './types'
import {HttpInstance} from '../createHttpInstance'

export interface ListUserPermission<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const listUserPermission: ListUserPermission<
  PermissionRequest,
  Paginated<PermissionResponse>
> = (http, params) => {
  const {ClientID, PersonID, PageNumber, PageSize} = params

  const path = '/v1/auth/permission'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (PersonID !== undefined) {
    queryParams.push(`personid=${PersonID}`)
  }
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<Paginated<PermissionResponse>>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
