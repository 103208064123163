import React from 'react'
import TagManager from 'react-gtm-module'

import {setLogUser, addLogMetadata} from '@vanguard/logger'
import {api} from '../services/api'
import {AUTH_SESSION_STORAGE_KEY} from '../constants'
import {
  Client,
  Person,
  FeatureSettings,
  Feature,
  AuthState,
  UserPermissions,
} from '../types'
import {useSecureStoredReducer} from '../hooks/useSecureStoredReducer'

type loginStatus = 'success' | 'failure' | 'error' | 'locked'

type Action =
  | {
      type: 'LOGIN_SUCCESS'
      payload: {
        client: Client
        person: Person
        features: Feature[]
        featureSettings: FeatureSettings
        userPermissions: UserPermissions
      }
    }
  | {
      type: 'LOGOUT'
    }

// This removes the null type from the AuthState
type RequiredAuthState = {
  [P in keyof AuthState]: NonNullable<AuthState[P]>
}

interface AuthStore extends AuthState {
  login: (username: string, password: string) => Promise<loginStatus>
  logout(): void
}

const AuthContext = React.createContext<AuthStore | undefined>(undefined)

export const useAuth = (): AuthStore => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

// Expects the auth state properties are set values (not null), else it's an error
// This is great for using for api calls that you know the person and client id
// are there so you can avoid having null checks everywhere
export const useRequiredAuth = (): RequiredAuthState => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  const {client, person, releaseFlags, userPermissions} = context

  if (!client || !person) {
    // TODO: Perhaps check required auth items and logout with reason instead of throw error

    throw new Error(
      'RequiredState values cannot be null or undefined in useRequiredAuth',
    )
  }

  return {
    client,
    person,
    releaseFlags: releaseFlags || [],
    userPermissions: userPermissions || [],
  }
}

const authReducer: React.Reducer<AuthState, Action> = (state, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS': {
      const releaseFlags =
        (action.payload.featureSettings.clientFeatures &&
          action.payload.featureSettings.clientFeatures.map((cf) => {
            const feature = action.payload.features.find(
              (feat) => feat.ID === cf.featureID,
            )

            return {
              ID: cf.featureID,
              name: (feature && feature.name) || 'unknown',
              displayName: (feature && feature.displayName) || 'unknown',
              description: (feature && feature.description) || 'unknown',
            }
          })) ||
        []

      return {
        ...state,
        person: action.payload.person,
        client: action.payload.client,
        releaseFlags,
        userPermissions: action.payload.userPermissions.permissions,
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        person: null,
        client: null,
        releaseFlags: null,
        userPermissions: null,
      }
    }

    default:
      throw new Error('Unhandled action')
  }
}

export const AuthProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [state, dispatch] = useSecureStoredReducer(
    AUTH_SESSION_STORAGE_KEY,
    authReducer,
    {
      client: null,
      person: null,
      releaseFlags: null,
      userPermissions: null,
    },
  )

  // When user logs in successfully or the page is refreshed, this sets additional logger data for error reporting
  const authStatePersonID = (state.person && state.person.ID) || 'unset'
  const authStateClientID = (state.client && state.client.ID) || 'unset'
  const authStateCampName = (state.client && state.client.name) || 'unset'

  setLogUser(authStatePersonID)
  addLogMetadata('client', {ID: authStateClientID, Name: authStateCampName})

  // Send needed data to Google Tag Manager. Used to trigger marketing tags or provide context to them.
  // This is not apart of login success flow because someone could refresh and then we'd lose the context.
  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        personID: state.person?.ID,
        firstName: state.person?.name.first,
        lastName: state.person?.name.last,
        email: state.person?.email,
        clientID: state.client?.ID,
        campName: state.client?.name,
        salesforceAccountID: state.client?.salesforceAccountID,
      },
    })
  }, [state.client, state.person])

  const login: AuthStore['login'] = async (username, password) => {
    try {
      const response = await api.auth.login({
        username,
        password,
      })

      if (response.result === 'success' && response.data) {
        const {clientID, personID} = response.data

        const [
          personResponse,
          clientResponse,
          featuresResponse,
          featureSettingsResponse,
          userPermissionsResponse,
        ] = await Promise.all([
          api.persons.getPerson({
            clientID,
            personID,
          }),
          api.clients.getClient({
            clientID,
          }),
          api.featureSettings.fetchFeatures({
            pageNumber: 1,
            pageSize: 100,
          }),
          api.featureSettings.getFeatureSettings({
            clientID,
          }),
          api.permissions.getUserPermissions({
            clientID,
            personID,
          }),
        ])

        if (
          personResponse.result === 'success' &&
          personResponse.data &&
          clientResponse.result === 'success' &&
          clientResponse.data &&
          featuresResponse.result === 'success' &&
          featuresResponse.data &&
          featureSettingsResponse.result === 'success' &&
          featureSettingsResponse.data &&
          userPermissionsResponse.result === 'success' &&
          userPermissionsResponse.data
        ) {
          // Doing here and in useEffect to ensure data is present when going to first screen after login
          TagManager.dataLayer({
            dataLayer: {
              personID: personResponse.data?.ID,
              firstName: personResponse.data?.name.first,
              lastName: personResponse.data?.name.last,
              email: personResponse.data?.email,
              clientID: clientResponse.data?.ID,
              campName: clientResponse.data?.name,
              salesforceAccountID: clientResponse.data?.salesforceAccountID,
            },
          })

          // logging meta data will be updated with this data when component rerenders after this dispatched action
          dispatch({
            type: 'LOGIN_SUCCESS',
            payload: {
              person: personResponse.data,
              client: clientResponse.data,
              features: featuresResponse.data.results,
              featureSettings: featureSettingsResponse.data,
              userPermissions: userPermissionsResponse.data,
            },
          })
        }

        return Promise.resolve('success')
      }

      if (response.result === 'failure' && response.error) {
        switch (response.error.message) {
          case 'locked':
            return Promise.resolve('locked')
          case 'error':
            return Promise.resolve('error')
          default:
            return Promise.resolve('failure')
        }
      }

      throw new Error('Unhandled Login Response')
    } catch {
      return Promise.resolve('error')
    }
  }

  const logout = (): void => {
    sessionStorage.removeItem(AUTH_SESSION_STORAGE_KEY)
    dispatch({
      type: 'LOGOUT',
    })
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: AuthStore = {login, logout, ...state}

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
