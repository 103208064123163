import React from 'react'
import {Flex, Box, Heading, Text} from '@chakra-ui/react'
import {ChevronLeftIcon} from '@chakra-ui/icons'

import {useTerm} from '../../../hooks/useTerm'
import {useRequiredAuth} from '../../../stores/AuthStore'
import {
  useBunks,
  useBunkPlans,
  useDivisions,
  useGenders,
  useSessions,
  useOrganizationalCategories,
  usePositions,
  usePrograms,
  useProgramAreas,
} from '../../../queries'
import {ActionButton} from '../../../components'

import {useModalContext} from './subcomps/Modal'

import {
  Segment,
  SegmentGroup,
  StaffSegment,
  CamperSegment,
} from '../../../types'
import {
  OptionSelector,
  BatchSelector,
  OptionList,
} from '../../../components/OptionSelector/OptionSelector'
import {isLastElement} from '../../../utils'
import {useAudience} from './contextManagers/AudienceManager'
import {useStatusToast} from '../../../app/ToastManager'
import copy from './copy.json'

type Option = {
  ID: string
  label: string
}

const Header: React.FC<React.PropsWithChildren> = () => {
  const {setCurrentStep} = useModalContext()

  return (
    <Flex flex={1}>
      <ChevronLeftIcon
        data-testid='back-icon'
        aria-label='back'
        boxSize='30px'
        onClick={(): void => {
          setCurrentStep('type')
        }}
      />
      <Box flex={1} textAlign='center'>
        Audience Segments
      </Box>
    </Flex>
  )
}

const Body: React.FC<React.PropsWithChildren> = () => {
  const {client} = useRequiredAuth()

  const gendersQuery = useGenders()
  const divisionsQuery = useDivisions()
  const sessionsQuery = useSessions({season: client.currentSeasonID}) // TODO: Currently audiences don't have season data. We need to add that at some point because an audience created 2 seasons ago might have different session values than what get fetched in current season.
  const programsQuery = usePrograms({season: client.currentSeasonID})
  const bunksQuery = useBunks({season: client.currentSeasonID})
  const bunkPlansQuery = useBunkPlans({season: client.currentSeasonID})
  const organizationalCategoriesQuery = useOrganizationalCategories()
  const positionsQuery = usePositions()
  const programAreasQuery = useProgramAreas()

  const BunkTerm = useTerm('bunk')
  const {audience, updateValue} = useAudience()

  const camperSegment = audience.content as CamperSegment
  const staffSegment = audience.content as StaffSegment

  const isEditing = false // mode === 'edit'

  const options = React.useMemo(() => {
    const convertToLookup = (data?: {
      pages: Array<{results: Array<{ID: string; name: string}>}>
    }): Option[] => {
      if (!data) return []

      return data.pages.flatMap((p) =>
        p.results.map((item) => ({
          ID: item.ID,
          label: item.name,
        })),
      )
    }

    return {
      gender: gendersQuery.data.map(({ID, name}) => ({
        ID,
        label: name,
      })),
      divisions: convertToLookup(divisionsQuery.data),
      sessions: convertToLookup(sessionsQuery.data),
      programs: convertToLookup(programsQuery.data),
      bunks: convertToLookup(bunksQuery.data),
      bunkPlans: convertToLookup(bunkPlansQuery.data),
      organizationalCategories: convertToLookup(
        organizationalCategoriesQuery.data,
      ),
      positions: convertToLookup(positionsQuery.data),
      programAreas: convertToLookup(programAreasQuery.data),
    }
  }, [
    gendersQuery.data,
    divisionsQuery.data,
    sessionsQuery.data,
    programsQuery.data,
    bunksQuery.data,
    bunkPlansQuery.data,
    organizationalCategoriesQuery.data,
    positionsQuery.data,
    programAreasQuery.data,
  ])

  const selectedIDs = React.useMemo((): Segment => {
    const getSelectedIDs = (
      idList: string[] | null,
      optionList: Option[],
    ): string[] =>
      idList && !idList.length ? optionList.map((opt) => opt.ID) : idList ?? []
    if (audience.type === 'Camper/Parent') {
      return {
        genderIDs: getSelectedIDs(camperSegment.genderIDs, options.gender),
        divisionIDs: getSelectedIDs(
          camperSegment.divisionIDs,
          options.divisions,
        ),
        sessionIDs: getSelectedIDs(camperSegment.sessionIDs, options.sessions),
        programIDs: getSelectedIDs(camperSegment.programIDs, options.programs),
        bunkIDs: getSelectedIDs(camperSegment.bunkIDs, options.bunks),
        bunkPlanIDs: getSelectedIDs(
          camperSegment.bunkPlanIDs,
          options.bunkPlans,
        ),
      }
    }
    return {
      genderIDs: getSelectedIDs(staffSegment.genderIDs, options.gender),
      divisionIDs: getSelectedIDs(staffSegment.divisionIDs, options.divisions),
      organizationalCategoryIDs: getSelectedIDs(
        staffSegment.organizationalCategoryIDs,
        options.organizationalCategories,
      ),
      positionIDs: getSelectedIDs(staffSegment.positionIDs, options.positions),
      programAreaIDs: getSelectedIDs(
        staffSegment.programAreaIDs,
        options.programAreas,
      ),
    }
  }, [
    audience.type,
    staffSegment.genderIDs,
    staffSegment.divisionIDs,
    staffSegment.organizationalCategoryIDs,
    staffSegment.positionIDs,
    staffSegment.programAreaIDs,
    options.gender,
    options.divisions,
    options.organizationalCategories,
    options.positions,
    options.programAreas,
    options.sessions,
    options.programs,
    options.bunks,
    options.bunkPlans,
    camperSegment.genderIDs,
    camperSegment.divisionIDs,
    camperSegment.sessionIDs,
    camperSegment.programIDs,
    camperSegment.bunkIDs,
    camperSegment.bunkPlanIDs,
  ])

  const handleOnChange = (
    key: string,
    filter: keyof CamperSegment | keyof StaffSegment,
    nextIDs: string[],
  ): void => {
    if (nextIDs.length === options[key].length) {
      updateValue(filter, [])
    } else if (!nextIDs.length) {
      updateValue(filter, null)
    } else {
      updateValue(filter, nextIDs)
    }
  }

  const bunkTerm = BunkTerm.titleCase().value()

  let segmentSelectors: {
    name: string
    description: string
    options: Option[]
    selectedIDs: string[]
    onChange: (nextIDs: string[]) => void
    hideBatchSelectors: boolean
    isLoading?: boolean
  }[] = []
  if ('sessionIDs' in selectedIDs) {
    segmentSelectors.push(
      {
        name: 'Camper Gender',
        description: 'You must select at least one.',
        options: options.gender,
        selectedIDs: selectedIDs.genderIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('gender', 'genderIDs', nextIDs)
        },
        hideBatchSelectors: true,
      },
      {
        name: 'Camper Division',
        description: 'You must select at least one.',
        options: options.divisions,
        selectedIDs: selectedIDs.divisionIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('divisions', 'divisionIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: divisionsQuery.isLoading,
      },
      {
        name: 'Camper Session',
        description: 'You must select at least one.',
        options: options.sessions,
        selectedIDs: selectedIDs.sessionIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('sessions', 'sessionIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: sessionsQuery.isLoading,
      },
      {
        name: 'Camper Program',
        description: 'You must select at least one.',
        options: options.programs,
        selectedIDs: selectedIDs.programIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('programs', 'programIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: programAreasQuery.isLoading,
      },
      {
        name: `Camper ${bunkTerm} Plan`,
        description: 'You must select at least one.',
        options: options.bunkPlans,
        selectedIDs: selectedIDs.bunkPlanIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('bunkPlans', 'bunkPlanIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: bunkPlansQuery.isLoading,
      },
      {
        name: `Camper ${bunkTerm}`,
        description: 'You must select at least one.',
        options: options.bunks,
        selectedIDs: selectedIDs.bunkIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('bunks', 'bunkIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: bunksQuery.isLoading,
      },
    )
  } else {
    segmentSelectors.push(
      {
        name: 'Staff Gender',
        description: 'You must select at least one.',
        options: options.gender,
        selectedIDs: selectedIDs.genderIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('gender', 'genderIDs', nextIDs)
        },
        hideBatchSelectors: true,
      },
      {
        name: 'Staff Division',
        description: 'You must select at least one.',
        options: options.divisions,
        selectedIDs: selectedIDs.divisionIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('divisions', 'divisionIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: divisionsQuery.isLoading,
      },
      {
        name: 'Staff Organizational Category',
        description: 'You must select at least one.',
        options: options.organizationalCategories,
        selectedIDs: selectedIDs.organizationalCategoryIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange(
            'organizationalCategories',
            'organizationalCategoryIDs',
            nextIDs,
          )
        },
        hideBatchSelectors: isEditing,
        isLoading: organizationalCategoriesQuery.isLoading,
      },
      {
        name: 'Staff Position',
        description: 'You must select at least one.',
        options: options.positions,
        selectedIDs: selectedIDs.positionIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('positions', 'positionIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: positionsQuery.isLoading,
      },
      {
        name: 'Staff Program Area',
        description: 'You must select at least one.',
        options: options.programAreas,
        selectedIDs: selectedIDs.programAreaIDs,
        onChange: (nextIDs: string[]): void => {
          handleOnChange('programAreas', 'programAreaIDs', nextIDs)
        },
        hideBatchSelectors: isEditing,
        isLoading: programAreasQuery.isLoading,
      },
    )
  }

  // Hide selectors without options
  segmentSelectors = segmentSelectors.filter(
    (ss) => ss.isLoading || ss.options.length > 0,
  )

  return (
    <>
      {segmentSelectors.map((selector, index) => (
        <OptionSelector
          key={selector.name}
          options={selector.options}
          selectedIDs={selector.selectedIDs}
          onChange={selector.onChange}>
          <Box px={10} pt={6} data-testid='segment-filter-options'>
            <Box
              borderBottom={
                isLastElement(segmentSelectors, index)
                  ? 'unset'
                  : '1px solid #CED2D5'
              }
              pb={6}>
              <Flex justifyContent='space-between' mb={4}>
                <Flex direction='column'>
                  <Heading size='md'>{selector.name}</Heading>
                  <Text fontSize='sm' color='gray.500'>
                    {selector.description}
                  </Text>
                </Flex>
                {selector.hideBatchSelectors || <BatchSelector />}
              </Flex>

              <Flex wrap='wrap'>
                <OptionList
                  colorScheme='secondary'
                  flex={['1 1 100%', '1 1 50%']}
                  isDisabled={isEditing}
                  isLoading={selector.isLoading}
                />
              </Flex>
            </Box>
          </Box>
        </OptionSelector>
      ))}
    </>
  )
}

Body.displayName = 'Segment Step UI - Body'

interface FooterProps {
  onClose(newAudience?: SegmentGroup): void
}

const Footer: React.FC<FooterProps> = ({onClose}) => {
  const {allowSaving, save} = useAudience()
  const [isLoading, setIsLoading] = React.useState(false)
  const {toast} = useStatusToast()

  const handleOnSave = async (): Promise<void> => {
    setIsLoading(true)
    const response = await save()
    setIsLoading(false)

    if (response.result !== 'success') {
      toast.error(response.error.message)
    } else {
      onClose(response.data) // send back data to update client list cache/store
    }
  }

  return (
    <Flex
      flexDir='row'
      justifyContent={['space-around', 'flex-end']}
      width='100%'>
      <ActionButton
        mr={3}
        onClick={handleOnSave}
        isLoading={isLoading}
        loadingText='Saving'
        isDisabled={!allowSaving}
        flexGrow={[1, 0]}>
        {copy.buttons.primaryAction.save}
      </ActionButton>
    </Flex>
  )
}

export const SegmentStepUI = {
  Header,
  Body,
  Footer,
}
