/* eslint-disable @typescript-eslint/no-explicit-any */
import {AxiosResponse, AxiosError} from 'axios'
import {HttpInstance} from './createHttpInstance'

interface TransformResponse {
  (
    http: HttpInstance,
    onFulfilledTransformFn:
      | ((
          value: AxiosResponse<any>,
        ) => AxiosResponse<any> | Promise<AxiosResponse<any>>)
      | undefined,
    onRejectedTransformFn: (error: AxiosError) => any,
  ): void
}

export const transformResponse: TransformResponse = (
  http,
  onFulfilledTransformFn,
  onRejectedTransformFn,
) => {
  http.interceptors.response.use(onFulfilledTransformFn, onRejectedTransformFn)
}
