import React from 'react'
import {useHistory} from 'react-router-dom'
import {Button} from '@chakra-ui/react'
import {CalendarIcon, EmailIcon} from '@chakra-ui/icons'

import {FeatureName} from '../../types'
import {CustomIcon, Feature} from '../../components'

interface Props {
  feature?: FeatureName
  url: string
  icon: string
  children: string
}

const renderIcon = (icon: string): React.ReactNode => {
  if (icon === 'calendar') {
    return <CalendarIcon pr={2} w='24px' />
  }

  if (icon === 'email') {
    return <EmailIcon pr={2} w='24px' />
  }

  return <CustomIcon name={icon} pr={2} w='24px' />
}

export const SideMenuItem: React.FC<React.PropsWithChildren<Props>> = ({
  url,
  icon,
  feature,
  children,
}): React.ReactElement => {
  const history = useHistory()

  const menuItem = (
    <Button
      variant='ghost'
      onClick={(): void => history.push(url)}
      fontSize='lg'
      w='100%'
      h={['3.5rem']}
      color='white'
      borderRadius='0'
      justifyContent='left'
      flexDirection='row'
      _hover={{
        bg: '#575D60',
      }}
      verticalAlign='center'>
      {renderIcon(icon)}
      {children}
    </Button>
  )

  return !feature ? menuItem : <Feature name={feature}>{menuItem}</Feature>
}
