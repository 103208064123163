import {http} from '../http'
import * as corePersons from '../../coreApi/persons'

import {APIResponse} from '../types'
import {Client, Person} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: Client['ID']
  personID: Person['ID']
}
type GetPersonReturn = APIResponse<Person>

export interface GetPerson {
  (params: Params): Promise<GetPersonReturn>
}

const ERROR_PATH = 'api.persons.getPersons()'

export const getPerson: GetPerson = async ({personID, clientID}) => {
  let resolvedReturn: GetPersonReturn

  try {
    const response = await corePersons.getPerson(http, {
      personID,
      clientID,
    })
    resolvedReturn = {
      result: 'success',
      data: {
        ID: response.data.ID.toString(),
        name: {
          first: response.data.Name.First,
          last: response.data.Name.Last,
        },
        faces: response.data.Faces,
        relatives: response.data.Relatives.map((rel) => ({
          isWard: rel.IsWard,
          // person: rel.Person, // Not adding this recursive prop until it's needed
        })),
        email:
          response.data.ContactDetails.Emails.find((e) => e.IsLogin)?.Address ||
          null,
      },
    }

    return Promise.resolve(resolvedReturn)
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e && e.message ? e.message : e.toString()
    }
    resolvedReturn = handleError(ERROR_PATH, message, e)
    return Promise.resolve(resolvedReturn)
  }
}
