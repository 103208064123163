import {ReactElement} from 'react'

import {
  FormControl,
  FormLabel,
  forwardRef,
  Select,
  SelectProps,
  ChakraComponent,
} from '@chakra-ui/react'

export interface FormSelectProps extends SelectProps {
  label: string
  labelFontSize?: string | string[]
  labelFontWeight?: string | string[]
  labelPaddingTop?: string | string[] | number
  labelPaddingLeft?: string | string[] | number
  labelPaddingBottom?: string | string[] | number
  labelPaddingRight?: string | string[] | number
  labelMarginBottom?: string | string[] | number
  id: string
  children: ReactElement[]
}

export const FormSelect: ChakraComponent<'div', FormSelectProps> = forwardRef(
  (
    {
      label,
      labelFontSize = '2xl',
      labelFontWeight = 'normal',
      labelPaddingTop = '',
      labelPaddingLeft = '',
      labelPaddingBottom = '',
      labelPaddingRight = '',
      labelMarginBottom = '',
      id,
      children,
      ...rest
    },
    ref,
  ) => (
    <FormControl d='flex' flexDir='column'>
      <FormLabel
        htmlFor={id}
        fontSize={labelFontSize}
        fontWeight={labelFontWeight}
        paddingTop={labelPaddingTop}
        paddingLeft={labelPaddingLeft}
        paddingBottom={labelPaddingBottom}
        paddingRight={labelPaddingRight}
        marginBottom={labelMarginBottom}>
        {label}
      </FormLabel>
      <Select id={id} ref={ref} {...rest}>
        {children}
      </Select>
    </FormControl>
  ),
)
FormSelect.displayName = 'FormSelect'
