interface SessionManager {
  init(
    sessionExpiredCallback: () => void,
    intervalTimeSeconds: number,
    sessionTimeoutMins: number,
  ): void
  reset(): void
  immediateExpire(): void
  cleanUp(): void
}

// let sessionTimeoutMins = 30
// let intervalTimeSeconds = 60
let interval: NodeJS.Timeout
let sessionExpiredCallback: () => void
let minutes = 0

const expireSession = (): void => {
  clearInterval(interval)
  sessionExpiredCallback()
}

export const sessionManager: SessionManager = {
  init: (
    cb: () => void,
    intervalTimeSeconds: number,
    sessionTimeoutMins,
  ): void => {
    sessionExpiredCallback = cb
    // sessionTimeoutMins = sessionTimeoutMins
    // intervalTimeSeconds = intervalTimeSeconds
    minutes = 0
    if (interval) {
      clearInterval(interval)
    }
    interval = setInterval(() => {
      if (minutes >= sessionTimeoutMins) {
        expireSession()
        return
      }
      minutes += 1
    }, 1000 * intervalTimeSeconds)
  },
  reset: () => {
    minutes = 0
  },
  immediateExpire: () => {
    expireSession()
  },
  cleanUp: () => {
    clearInterval(interval)
  },
}
