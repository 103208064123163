import React, {ChangeEvent} from 'react'
import {Box, Button, Checkbox, Skeleton, Text} from '@chakra-ui/react'
import {createContextHelper} from '../../utils/createContextHelper'
import {PropsOf} from '../../types'
// type EventOrValue = React.ChangeEvent<HTMLInputElement> | string

interface Option {
  ID: string
  label: string
}

interface OptionSelectorProps {
  options: Option[]
  selectedIDs: string[]
  onChange: (selectedIDs: string[]) => void
}

// ************************************************************* */
// type Context = OptionSelectorProps
interface Context {
  options: Option[]
  selectedIDs: string[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectAll(): void
  clearAll(): void
}

const [OptionSelectorContextProvider, useOptionSelectorContext] =
  createContextHelper<Context>()

// ************************************************************* */

export const OptionSelector: React.FC<
  React.PropsWithChildren<OptionSelectorProps>
> = ({options, selectedIDs, onChange: onChangeProp, children}) => {
  const selectAll = React.useCallback(() => {
    const nextSelectedIDs = options.map((opt) => opt.ID)

    onChangeProp(nextSelectedIDs)
  }, [onChangeProp, options])

  const clearAll = React.useCallback(() => {
    onChangeProp([])
  }, [onChangeProp])

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked
      const selectedValue = event.target.value

      const nextSelectedIDs: string[] = isChecked
        ? [selectedValue, ...selectedIDs]
        : selectedIDs.filter((ID) => ID !== selectedValue)

      onChangeProp(nextSelectedIDs)
    },
    [onChangeProp, selectedIDs],
  )

  const context = {
    options,
    selectedIDs,
    onChange,
    selectAll,
    clearAll,
  }

  return (
    <OptionSelectorContextProvider value={context}>
      {children}
    </OptionSelectorContextProvider>
  )
}

// ************************************************************* */
type OptionListProps = PropsOf<typeof Checkbox> & {
  isLoading?: boolean
}

export const OptionList: React.FC<OptionListProps> = ({
  children,
  isLoading,
  ...rest
}) => {
  const {options, selectedIDs, onChange} = useOptionSelectorContext()

  if (isLoading) {
    return (
      <>
        <Skeleton height='15px' m='5px' flex={rest.flex} />
        <Skeleton height='15px' m='5px' flex={rest.flex} />
        <Skeleton height='15px' m='5px' flex={rest.flex} />
      </>
    )
  }

  if (!options.length) {
    return (
      <Text color='#69747D' fontStyle='italic'>
        No Options
      </Text>
    )
  }

  return (
    <>
      {options.map((opt: Option) => (
        <Checkbox
          key={opt.ID}
          value={opt.ID}
          isChecked={selectedIDs.includes(opt.ID)}
          onChange={onChange}
          {...rest}>
          {opt.label}
        </Checkbox>
      ))}
    </>
  )
}

// ************************************************************* */

export const BatchSelector: React.FC<React.PropsWithChildren> = () => {
  const {selectAll, clearAll} = useOptionSelectorContext()

  return (
    <Box>
      <Button
        onClick={selectAll}
        size='sm'
        variant='link'
        colorScheme='secondary'
        m={2}>
        Select All
      </Button>
      |
      <Button
        onClick={clearAll}
        size='sm'
        variant='link'
        colorScheme='secondary'
        m={2}>
        Clear All
      </Button>
    </Box>
  )
}
