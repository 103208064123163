import {http} from '../http'
import * as coreEzTexting from '../../coreApi/eztexting'
import {APIResponse} from '../types'
import {CheckCredentialsResponse, ResourceID} from '../../../types'
import {handleError} from '../handleError'
import {CredentialsRequest} from '../../coreApi/eztexting/types'

interface Params {
  clientID: ResourceID
  username: CredentialsRequest['UserName']
  password: CredentialsRequest['Password']
}

type ResolvedReturn = APIResponse<CheckCredentialsResponse>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.updateCredentials()'
const GENERIC_ERROR =
  'Something went wrong while getting your group. Please try again.'

export const updateCredentials: ServiceMethod = async ({
  clientID,
  username,
  password,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const response = await coreEzTexting.updateCredentials(http, {
      ClientID: parseInt(clientID, 10),
      UserName: username,
      Password: password,
    })
    if (!response || !response.data) {
      throw new Error('Unsuccessful updateCredentials')
    }
    resolvedReturn = {
      result: 'success',
      data: {
        clientID: response.data.ClientID.toString(),
        validated: response.data.Validated,
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
