import React from 'react'
import {Flex, Text} from '@chakra-ui/react'

interface Props {
  label: string
  footer?: React.ReactElement
}

export const LabelledSectionBox: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  label,
  footer,
}) => (
  <Flex m={[4]} borderRadius='3px' border='1px' direction='column'>
    <Flex px={6} py={4} bg='#F9F9F9' borderBottom='1px'>
      <Text fontSize='xl'>{label}</Text>
    </Flex>
    <Flex px={6} py={3} direction='column'>
      {children}
    </Flex>
    {footer && (
      <Flex px={6} py={3} borderTop='1px'>
        {footer}
      </Flex>
    )}
  </Flex>
)
