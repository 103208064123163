import {decode} from 'he'
import {http} from '../http'
import * as featureSettingsApi from '../../coreApi/feature-settings'

import {APIResponse, PagedCollectionResponse} from '../types'
import {Feature} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  pageNumber: number
  pageSize: number
}

type FetchFeaturesReturn = APIResponse<PagedCollectionResponse<Feature>>

export interface FetchFeatures {
  (params: Params): Promise<FetchFeaturesReturn>
}

const ERROR_PATH = 'api.featuresettings.fetchFeatures()'

export const fetchFeatures: FetchFeatures = async ({pageNumber, pageSize}) => {
  try {
    const response = await featureSettingsApi.fetchFeatures(http, {
      pageNumber,
      pageSize,
    })

    const resolved: FetchFeaturesReturn = {
      result: 'success',
      data: {
        total: response.data.TotalCount,
        nextURL: response.data.Next,
        results: response.data.Results.map((result) => ({
          ID: result.ID.toString(),
          name: decode(result.Name),
          displayName: decode(result.DisplayName || ''),
          description: decode(result.Description || ''),
        })),
      },
    }
    return Promise.resolve(resolved)
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e && e.message ? e.message : e.toString()
    }
    const result = handleError(ERROR_PATH, message, e)
    return Promise.resolve(result)
  }
}
