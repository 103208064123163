import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {Paginated} from '../segments'
import {ListAlbumUploadSessionRequest, AlbumUploadSession} from '../types'

export interface ListAlbumUploadSessions<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const listAlbumUploadSessions: ListAlbumUploadSessions<
  ListAlbumUploadSessionRequest,
  Paginated<AlbumUploadSession>
> = (http, params) => {
  const {ClientID, PageNumber, PageSize, AlbumID} = params

  const path = '/v1/albums/{albumid}/uploadsession'.replace(
    '{albumid}',
    AlbumID.toString(),
  )

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<Paginated<AlbumUploadSession>>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
