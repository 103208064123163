type Gender = {
  ID: string
  name: string
}

type UseGenders = () => {data: Gender[]; status: 'success'}
export const useGenders: UseGenders = () => ({
  data: [
    {
      ID: '1',
      name: 'Male',
    },
    {
      ID: '0',
      name: 'Female',
    },
  ],
  status: 'success',
})
