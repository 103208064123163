import {http} from '../http'
import * as coreSegments from '../../coreApi/segments'
import {APIResponse} from '../types'
import {ResourceID, SegmentGroup} from '../../../types'
import {shouldDoCreate, convertToInt} from '../../../utils'
import {transformSegment} from './segment.transform'
import {handleError} from '../handleError'

interface Params {
  segmentGroup: Omit<SegmentGroup, 'self' | 'createdByID' | 'dateModified'>
  clientID: ResourceID
}

export const ERROR_PATH = 'api.segments.upsertSegment()'
export const GENERIC_ERROR =
  'Something went wrong while saving your segment. Please try again.'

type ResolvedReturn = APIResponse<SegmentGroup>

interface UpsertSegment {
  (params: Params): Promise<ResolvedReturn>
}

export const upsertSegment: UpsertSegment = async ({
  clientID,
  segmentGroup,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    let rawParamMap
    if ('sessionIDs' in segmentGroup.content) {
      rawParamMap = {
        ClientID: Number(clientID),
        Name: segmentGroup.name,
        Content: {
          Type: segmentGroup.type,
          GenderIDs: convertToInt(segmentGroup.content.genderIDs),
          DivisionIDs: convertToInt(segmentGroup.content.divisionIDs),
          SessionIDs: convertToInt(segmentGroup.content.sessionIDs),
          ProgramIDs: convertToInt(segmentGroup.content.programIDs),
          BunkIDs: convertToInt(segmentGroup.content.bunkIDs),
          BunkPlanIDs: convertToInt(segmentGroup.content.bunkPlanIDs),
        },
      }
    } else {
      rawParamMap = {
        ClientID: Number(clientID),
        Name: segmentGroup.name,
        Content: {
          Type: segmentGroup.type,
          GenderIDs: convertToInt(segmentGroup.content.genderIDs),
          DivisionIDs: convertToInt(segmentGroup.content.divisionIDs),
          OrganizationalCategoryIDs: convertToInt(
            segmentGroup.content.organizationalCategoryIDs,
          ),
          PositionIDs: convertToInt(segmentGroup.content.positionIDs),
          ProgramAreaIDs: convertToInt(segmentGroup.content.programAreaIDs),
        },
      }
    }

    const response = shouldDoCreate({ID: segmentGroup.ID})
      ? await coreSegments.create(http, rawParamMap)
      : await coreSegments.update(http, {
          ID: Number(segmentGroup.ID),
          ...rawParamMap,
        })

    if (!response || !response.data) {
      throw new Error('Unsuccessful Segment Upsert')
    }

    resolvedReturn = {
      result: 'success',
      data: transformSegment(response.data),
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }
  return Promise.resolve(resolvedReturn)
}
