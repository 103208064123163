import {http} from '../http'
import * as coreEzTexting from '../../coreApi/eztexting'
import {APIResponse} from '../types'
import {FormReminderHistoryRecordList, ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
  pageNumber: number
  pageSize: number
  orderBy: string
  orderAscending: boolean
}

type ResolvedReturn = APIResponse<FormReminderHistoryRecordList>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.listHistory()'
const GENERIC_ERROR =
  'Something went wrong while getting form reminder text history. Please try again.'

export const listHistory: ServiceMethod = async ({
  clientID,
  pageNumber,
  pageSize,
  orderBy,
  orderAscending,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: parseInt(clientID, 10),
      PageNumber: pageNumber,
      PageSize: pageSize,
      OrderBy: orderBy,
      OrderAscending: orderAscending,
    }

    const response = await coreEzTexting.listHistory(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful List History')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        records: response.data.Results.map((raw) => ({
          ID: raw.ID.toString(),
          clientID: raw.ClientID.toString(),
          createdBy: raw.CreatedBy,
          createdDate: raw.CreatedDate,
          subject: raw.Subject,
          message: raw.Message,
          response: raw.Response,
        })),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
