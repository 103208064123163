import React from 'react'
import {Route, Switch, Router as BrowserRouter} from 'react-router-dom'
import {CompatRouter} from 'react-router-dom-v5-compat'

import {
  AlbumsView,
  AlbumView,
  AudiencesView,
  EzTextingGroupView,
  EzTextingView,
  EZTLoginView,
  FormRemindersView,
  HealthCheckLinkView,
  IntegrationsView,
  LoginView,
  MicropostsView,
  PhotoUploaderView,
  RegistrationLinkView,
} from '../views'
import {MicropostsProvider} from '../stores/MicropostsStore'
import {AuthRoute} from '../components/AuthRoute/AuthRoute'
import {history} from './history'
import {PageNotFoundView} from '../views/PageNotFoundView/PageNotFoundView'
import {sessionManager} from '../utils'
import {useAuth} from '../stores/AuthStore'
import {HomeView} from '../views/HomeView/HomeView'

// TODO: Find a better way to let us match authenticated app calls.
// So for things like lookups that could be used in several places, but also require a authenticated user
// It needs to wrap all autheticated routes, so maybe some other kind of AuthenticateApp components
// that has the routing config for all the AuthetnicatedRotues

export const Router: React.FC<React.PropsWithChildren> = () => {
  const {logout} = useAuth()

  React.useEffect(() => {
    sessionManager.init(
      (): void => {
        // console.log(history.location.pathname)
        if (
          history.location &&
          history.location.pathname &&
          !(history.location.pathname.indexOf('/login') >= 0)
        ) {
          logout()

          window.location.href = `${window.location.protocol}//${window.location.host}/login?expired=1`
        }
      },
      60,
      30,
    )
    return (): void => {
      sessionManager.cleanUp()
    }
  }, [logout])

  return (
    <BrowserRouter history={history}>
      <CompatRouter>
        <Switch>
          <Route path='/login' component={LoginView} />

          <AuthRoute
            exact
            path='/'
            render={(): React.ReactNode => <HomeView />}
          />

          <AuthRoute
            path='/microposts'
            render={(): React.ReactNode => (
              <MicropostsProvider>
                <MicropostsView />
              </MicropostsProvider>
            )}
          />

          <AuthRoute
            path='/albums'
            clientAuthorization='view-photo-uploader'
            render={(): React.ReactNode => <AlbumsView />}
          />
          <AuthRoute
            path='/album/:id'
            clientAuthorization='view-photo-uploader'
            render={(): React.ReactNode => <AlbumView />}
            exact
          />
          <AuthRoute
            path='/album/:id/upload'
            render={(): React.ReactNode => <PhotoUploaderView />}
          />
          <AuthRoute
            path='/audiences'
            render={(): React.ReactNode => <AudiencesView />}
          />
          <AuthRoute
            exact
            path='/eztexting'
            render={(): React.ReactNode => <EZTLoginView />}
          />
          <AuthRoute
            exact
            path='/integrations'
            render={(): React.ReactNode => <IntegrationsView />}
          />
          <AuthRoute
            path='/integrations/eztexting'
            exact
            render={(): React.ReactNode => <EzTextingView />}
          />
          <AuthRoute
            path='/integrations/eztexting/groups/:id'
            render={(): React.ReactNode => <EzTextingGroupView />}
          />
          <AuthRoute
            path='/home'
            render={(): React.ReactNode => <HomeView />}
          />
          <AuthRoute
            path='/formreminders'
            clientAuthorization='forms-reminder'
            render={(): React.ReactNode => <FormRemindersView />}
          />
          <AuthRoute
            path='/healthcheck'
            clientAuthorization='covid-health-check'
            render={(): React.ReactNode => <HealthCheckLinkView />}
          />
          <AuthRoute
            path='/registration'
            clientAuthorization='admin-menu-registration'
            render={(): React.ReactNode => <RegistrationLinkView />}
          />

          <Route path='*' component={PageNotFoundView} />
        </Switch>
      </CompatRouter>
    </BrowserRouter>
  )
}
