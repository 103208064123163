import React from 'react'

// type useStoredState = <T>(
//   key: string,
// ) => [T | null, React.Dispatch<React.SetStateAction<T | null>>]

type Return<T> = [
  T | null,
  React.Dispatch<React.SetStateAction<T | null>>,
  () => void,
]

export const useStoredState = <T>(
  key: string,
  location: 'local' | 'session' = 'local',
): Return<T> => {
  const storageObject = location === 'session' ? sessionStorage : localStorage

  const stored = React.useMemo(
    () => storageObject.getItem(key),
    [storageObject, key],
  )

  const parsedValue = React.useMemo(
    () => (stored ? JSON.parse(stored) : null),
    [stored],
  )

  const [value, setValue] = React.useState<T | null>(parsedValue)

  React.useEffect(() => {
    if (value) {
      storageObject.setItem(key, JSON.stringify(value))
    }
  }, [value, key, storageObject])

  const remove = React.useCallback(() => {
    storageObject.removeItem(key)
  }, [storageObject, key])

  return [value, setValue, remove]
}
