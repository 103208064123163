import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  CloseButton,
} from '@chakra-ui/react'

type ToastStatus = 'error' | 'warning' | 'info' | 'success'
type AlertType = React.ComponentProps<typeof Alert>

interface Props extends AlertType {
  status: ToastStatus
  description: string
  onClose: () => void
  isClosable?: boolean
}

export const StatusToast: React.FC<React.PropsWithChildren<Props>> = ({
  description,
  status,
  isClosable = false,
  onClose,
  variant,
}) => (
  <Alert
    status={status}
    textAlign='left'
    boxShadow='lg'
    rounded='md'
    alignItems='start'
    variant={variant}
    bg='#17294C'
    color='#F9F9F9'
    m={2}
    pr={8}>
    <AlertIcon />
    <Box flex='1'>
      {description && <AlertDescription>{description}</AlertDescription>}
    </Box>
    {isClosable && (
      <CloseButton
        size='sm'
        onClick={onClose}
        position='absolute'
        right='4px'
        top='25%'
      />
    )}
  </Alert>
)
