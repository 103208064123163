import {decode} from 'he'
import {http} from '../http'
import * as coreMicroposts from '../../coreApi/microposts'

import {APIResponse, PagedCollectionResponse} from '../types'
import {Micropost} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  pageNumber: number
  pageSize: number
  clientID: string
}

type FetchMicropostsReturn = APIResponse<PagedCollectionResponse<Micropost>>

export interface FetchMicroposts {
  (params: Params): Promise<FetchMicropostsReturn>
}

const ERROR_PATH = 'api.microposts.fetchMicroposts()'

export const fetchMicroposts: FetchMicroposts = async ({
  pageNumber,
  pageSize,
  clientID,
}) => {
  let resolvedReturn: FetchMicropostsReturn
  try {
    const response = await coreMicroposts.fetchMicroposts(http, {
      pageNumber,
      pageSize,
      clientID,
    })
    resolvedReturn = {
      result: 'success',
      data: {
        total: 0,
        nextURL: '',
        results: [],
      },
    }
    if (
      response &&
      response.data &&
      response.data.TotalCount &&
      response.data.Results
    ) {
      resolvedReturn.result = 'success'
      resolvedReturn.data = {
        total: response.data.TotalCount,
        nextURL: response.data.Next,
        results: response.data.Results.map((result) => ({
          ID: result.ID.toString(),
          content: decode(result.Content),
          videoURL: decode(result.VideoURL || ''),
          hyperlinkURL: decode(result.TargetURL || ''),
          hyperlinkText: decode(result.TargetText || ''),
          notify: result.Notify,
          imageURL: decode(result.ImageURL || ''),
          publishDate: new Date(result.PublishDate),
          segmentID: result.SegmentID ? result.SegmentID.toString() : null, // TODO: TEMP
        })),
      }
    }

    return Promise.resolve(resolvedReturn)
  } catch (e) {
    let message = ''
    if (e instanceof Error) {
      message = e && e.message ? e.message : e.toString()
    }
    resolvedReturn = handleError(ERROR_PATH, message, e)
    return Promise.resolve(resolvedReturn)
  }
}
