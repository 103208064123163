import React, {useReducer, useState, useEffect} from 'react'

import {useDebouncedCallback} from 'use-debounce'
import {useSSE} from '../app/SSEProvider'
import {ROTATE_PHOTO_DEBOUNCE_TIMEOUT} from '../constants'

import {useRequiredAuth} from '../stores/AuthStore'
import {photos as photosApi} from './api/photos'

type RotationState = 0 | 90 | 180 | 270

export type PhotoRotations = {
  [key: string]: RotationState
}

export type ProcessingRequestCounts = {
  [key: string]: number
}

export type ProcessingPhotos = {
  [key: string]: boolean
}

type TrackingReducer<T, P> = (state: T, action: P) => T

type RotationAction =
  | {
      type: 'SET'
      target: string
      value: RotationState
    }
  | {
      type: 'CLEAR'
      target: string
    }
  | {type: 'CLEAR_ALL'}

type ProcessingPhotosAction = {type: 'SET_ON' | 'SET_OFF'; target: string}

type ProcessingRequestCountsAction =
  | {
      type: 'INCREMENT' | 'DECREMENT'
      target: string
    }
  | {type: 'CLEAR_ALL'}

export type RotatePhotoStatus = 'ok' | 'failure'

type UsePhotoRotation = () => UsePhotoRotationReturn

const rotationReducer: TrackingReducer<PhotoRotations, RotationAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        [action.target]: action.value,
      }
    case 'CLEAR': {
      const newState = {...state}
      delete newState[action.target]
      return newState
    }
    case 'CLEAR_ALL':
      return {}
    default:
      return {}
  }
}

const processingPhotoReducer: TrackingReducer<
  ProcessingPhotos,
  ProcessingPhotosAction
> = (state, action) => {
  switch (action.type) {
    case 'SET_ON':
      return {
        ...state,
        [action.target]: true,
      }
    case 'SET_OFF': {
      const newState = {...state}
      delete newState[action.target]
      return newState
    }
    default:
      return {}
  }
}

// keeps track of requests in flight via the count
const processingRequestCountsReducer: TrackingReducer<
  ProcessingRequestCounts,
  ProcessingRequestCountsAction
> = (state, action) => {
  switch (action.type) {
    case 'INCREMENT':
      return {
        ...state,
        [action.target]: (state[action.target] || 0) + 1,
      }
    case 'DECREMENT': {
      const newState = {...state}
      const newValue = state[action.target] - 1
      if (newValue > 0) {
        return {
          ...newState,
          [action.target]: newValue,
        }
      }
      delete newState[action.target]
      return newState
    }
    case 'CLEAR_ALL':
      return {}
    default:
      return {}
  }
}

type RotationReducer = typeof rotationReducer
type ProcessingPhotosReducer = typeof processingPhotoReducer
type ProcessingRequestCountsReducer = typeof processingRequestCountsReducer

const add90Degrees = (currentRotation: RotationState): RotationState => {
  if (!currentRotation) return 90

  if (currentRotation === 270) return 0

  return (currentRotation + 90) as RotationState
}

const subtractDegrees = (
  initial: RotationState,
  subtractor: RotationState,
): RotationState => {
  const decrements = subtractor / 90

  let output = initial

  for (let i = 0; i < decrements; i += 1) {
    if (output === 0) output = 270
    else output -= 90
  }

  return output
}

interface UsePhotoRotationReturn {
  displayPhotoRotations: PhotoRotations
  requestPhotoRotations: PhotoRotations
  rotatePhotoStatus: RotatePhotoStatus
  processingPhotos: ProcessingPhotos
  addRotation: (id: string) => void
  clearAllPhotoRotations: () => void
}

export const usePhotoRotation: UsePhotoRotation = () => {
  const {client} = useRequiredAuth()
  const subscribe = useSSE()

  const [displayPhotoRotations, dispatchDisplayPhotoRotations] =
    useReducer<RotationReducer>(rotationReducer, {})

  const [requestPhotoRotations, dispatchRequestPhotoRotations] =
    useReducer<RotationReducer>(rotationReducer, {})

  const [processingPhotos, dispatchProcessingPhotos] =
    useReducer<ProcessingPhotosReducer>(processingPhotoReducer, {})

  const [processingRequestCounts, dispatchProcessingRequestCounts] =
    useReducer<ProcessingRequestCountsReducer>(
      processingRequestCountsReducer,
      {},
    )

  const [rotatePhotoStatus, setRotatePhotoStatus] =
    useState<RotatePhotoStatus>('ok')

  const addRotation = React.useCallback(
    async (photoId: string) => {
      const currentLocalRotation = displayPhotoRotations[photoId] // logic to switch on Server rotations
      const currentRequestRotation = requestPhotoRotations[photoId] // logic to switch on Server rotations

      const newLocalRotation = add90Degrees(currentLocalRotation)
      const newRequestRotation = add90Degrees(currentRequestRotation)

      dispatchDisplayPhotoRotations({
        type: 'SET',
        target: photoId,
        value: newLocalRotation,
      })
      dispatchRequestPhotoRotations({
        type: 'SET',
        target: photoId,
        value: newRequestRotation,
      })
      dispatchProcessingPhotos({
        type: 'SET_ON',
        target: photoId,
      })
      if (newRequestRotation === 90 && currentRequestRotation === undefined) {
        dispatchProcessingRequestCounts({
          type: 'INCREMENT',
          target: photoId,
        })
      }
      setRotatePhotoStatus('ok')
    },
    [displayPhotoRotations, requestPhotoRotations],
  )

  const sendRotation = React.useMemo(
    () =>
      async (photoId: string): Promise<void> => {
        const rotation = requestPhotoRotations[photoId]

        if (!rotation) {
          dispatchProcessingRequestCounts({
            type: 'DECREMENT',
            target: photoId,
          })
          dispatchProcessingPhotos({
            type: 'SET_OFF',
            target: photoId,
          })
          return
        }

        const res = await photosApi.rotatePhoto({
          ClientID: Number(client.ID),
          ID: Number(photoId),
          Degrees: rotation,
        })

        if (res.result !== 'success') {
          const newDisplayRotation = subtractDegrees(
            displayPhotoRotations[photoId],
            rotation,
          )
          dispatchDisplayPhotoRotations({
            type: 'SET',
            target: photoId,
            value: newDisplayRotation,
          })
          dispatchProcessingRequestCounts({
            type: 'INCREMENT',
            target: photoId,
          })

          setRotatePhotoStatus('failure')
        }
      },
    [client, requestPhotoRotations, displayPhotoRotations],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSendRotation = React.useCallback(
    useDebouncedCallback(() => {
      Object.keys(requestPhotoRotations).forEach((id) => {
        sendRotation(id)
      })
      dispatchRequestPhotoRotations({type: 'CLEAR_ALL'})
    }, ROTATE_PHOTO_DEBOUNCE_TIMEOUT),
    [requestPhotoRotations, sendRotation, processingRequestCounts],
  )

  const clearAllPhotoRotations = React.useCallback((): void => {
    dispatchDisplayPhotoRotations({type: 'CLEAR_ALL'})
  }, [])

  useEffect(
    () =>
      subscribe(photosApi.TopicRotatePhotoComplete, (msg) => {
        const result = photosApi.handleRotatePhotoSSE(msg)

        if (result?.photo?.ID) {
          dispatchProcessingRequestCounts({
            type: 'DECREMENT',
            target: result.photo.ID,
          })

          const requestCount =
            (processingRequestCounts[result.photo.ID] || 0) - 1

          if (requestCount < 1) {
            dispatchProcessingPhotos({
              type: 'SET_OFF',
              target: result.photo.ID,
            })
          }

          if (!result?.details?.completed) {
            dispatchDisplayPhotoRotations({
              type: 'CLEAR',
              target: result.photo.ID,
            })
            setRotatePhotoStatus('failure')
          }
        }
      }),
    [processingRequestCounts, subscribe],
  )

  useEffect(() => {
    if (Object.keys(requestPhotoRotations).length) debouncedSendRotation()
  }, [debouncedSendRotation, requestPhotoRotations])

  useEffect(
    () => (): void => {
      debouncedSendRotation.flush()
    },
    [debouncedSendRotation],
  )

  return {
    displayPhotoRotations,
    requestPhotoRotations,
    processingPhotos,
    rotatePhotoStatus,
    addRotation,
    clearAllPhotoRotations,
  }
}
