import {PickerFileMetadata} from 'filestack-js/build/main/lib/picker'

export const getFileUrl = (res: PickerFileMetadata): string => {
  const file = res

  // .heic
  if (file.mimetype === 'image/heic') {
    const modify = `output=format:jpg/${file.handle}`
    file.url = file.url.replace(file.handle, modify)
  }
  return file.url
}
