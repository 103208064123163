import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {DeleteGroupRequest, DeleteGroupResponse} from './types'

export interface DeleteGroup<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const deleteGroup: DeleteGroup<
  DeleteGroupRequest,
  DeleteGroupResponse
> = (http, params) => {
  const {EzTextingID, ClientID, ID} = params

  const path = '/v1/eztexting/group/{id}'.replace('{id}', ID.toString())

  const queryParams = []
  if (EzTextingID !== undefined) {
    queryParams.push(`eztextingid=${EzTextingID}`)
  }
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<DeleteGroupResponse>({
    method: 'DELETE',
    url: `${path}${query}`,
  })
}
