import React from 'react'
import {Flex, Box, Text} from '@chakra-ui/react'
import {ChevronLeftIcon} from '@chakra-ui/icons'

import {ActionButton} from '../../../components'
import {RadioList} from '../../../components/RadioList/RadioList'

import {useAudience} from './contextManagers/AudienceManager'
import {useModalContext} from './subcomps/Modal'
import copy from './copy.json'
import {SegmentType} from '../../../services/coreApi/segments/types'

const Header: React.FC<React.PropsWithChildren> = () => {
  const {setCurrentStep} = useModalContext()
  return (
    <Flex flex={1}>
      <ChevronLeftIcon
        data-testid='back-icon'
        aria-label='back'
        boxSize='30px'
        onClick={(): void => {
          setCurrentStep('compose')
        }}
      />
      <Box textAlign='center' flex={1}>
        {copy.steps.type.title}
      </Box>
    </Flex>
  )
}

const Body: React.FC<React.PropsWithChildren> = () => {
  const {audience, updateType} = useAudience()

  const handleTypeUpdate = (selectedValue: string): void => {
    updateType(selectedValue as SegmentType)
  }

  return (
    <Flex direction='column' h={['100%']}>
      <Text fontSize='lg' fontWeight={500} pt='1rem'>
        {copy.steps.type.header}
      </Text>
      <Text fontSize='sm' color='#69747D' mb='10px'>
        {copy.steps.type.description}
      </Text>
      <RadioList
        onRadioChange={handleTypeUpdate}
        options={[
          {ID: 'Camper/Parent', label: copy.steps.type.options.camper},
          {ID: 'Staff', label: copy.steps.type.options.staff},
        ]}
        size='md'
        value={audience.type}
        colorScheme='secondary'
        width={['unset', 'unset', 'unset', '256px']}
      />
    </Flex>
  )
}

const Footer: React.FC<React.PropsWithChildren> = () => {
  const {setCurrentStep} = useModalContext()
  return (
    <Flex
      flexDir='row'
      justifyContent={['space-around', 'flex-end']}
      width='100%'>
      <ActionButton
        mr={3}
        onClick={(): void => {
          setCurrentStep('segment')
        }}
        flexGrow={[1, 0]}>
        {copy.buttons.primaryAction.next}
      </ActionButton>
    </Flex>
  )
}

export const TypeStepUI = {
  Header,
  Body,
  Footer,
}
