/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {decode} from 'he'
import {APIResponseFailure} from './types'

export const handleError = (
  logErrorPath: string,
  defaultError: string,
  e: any,
  notFoundRedirects = false,
): APIResponseFailure => {
  let userMessage = defaultError

  const statusCode = e?.response?.status
  const statusMessage = e?.response?.data?.message

  if (statusCode >= 400 && statusCode < 500) {
    userMessage = statusMessage ? decode(statusMessage) : userMessage

    if (statusCode === 404 && notFoundRedirects) {
      userMessage = 'notfound'
    }
  }

  return {
    result: 'failure',
    error: {
      path: logErrorPath,
      message: userMessage,
      cause: e instanceof Error ? e.toString() : e,
    },
  }
}
