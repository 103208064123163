import React from 'react'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query'

// TODO: I know there is a better way to use TypeScript to infer my generics, but my typescriptfu is too weak
export const useInfiniteQueryUntilEnd = <T extends Record<string, unknown>>(
  queryOptions: UseInfiniteQueryOptions<T>,
): UseInfiniteQueryResult<T> => {
  const query = useInfiniteQuery(queryOptions)

  React.useEffect(() => {
    if (query.hasNextPage) {
      query.fetchNextPage()
    }
  }, [query, query.hasNextPage, query.fetchNextPage])

  return query
}
