export const colors = {
  primary: {
    50: '#e8eaed',
    100: '#b9bfc9',
    200: '#8b94a6',
    300: '#747f94',
    400: '#5d6982',
    500: '#17294C',
    600: '#455470',
    700: '#1e4e8c',
    800: '#101d35',
    900: '#0c1526',
  },
  secondary: {
    50: '#EFF4F4',
    100: '#D0DFDE',
    200: '#B2CBC8',
    300: '#93B6B2',
    400: '#74A19C',
    500: '#568D87',
    600: '#47746F',
    700: '#375A56',
    800: '#28413E',
    900: '#101A19',
  },
  error: {
    900: '#86331F',
    800: '#9C3B24',
    700: '#B3442A',
    600: '#C94C2F',
    500: '#E05535',
    400: '#E6775D',
    300: '#EC9986',
    200: '#F3BBAE',
    100: '#F9DDD7',
  },
  attention: {
    300: '#F6E8C1',
    400: '#F5CF63',
  },
  success: {
    300: '#B0DAD6',
  },
  darkgreen: {
    500: '#3b8585',
  },
  gray: {
    100: '#F1F5F7',
    200: '#DFE8ED',
    300: '#A6A6A6',
    500: '#CBCFD2',
    600: '#7D8790',
  },
  info: '#598D87',
  lightText: '#5E5E5E',
  semanticError: '#EB1525',
  borderLight: '#D8D8D8',
  bgLight: '#F9FAFE',
  iconMid: '#5E5E5E',
  iconLight: '#A6A6A6',
  actionButtonBlue: '#182A4B',
  // error
  // success
  // warning
}
