import React from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Flex, Heading, Link, Text} from '@chakra-ui/react'
import {setLogContext} from '@vanguard/logger'
import {CustomIcon} from '../../components'

export const PageNotFoundView: React.FC<React.PropsWithChildren> = () => {
  setLogContext('PageNotFoundView')

  const history = useHistory()

  return (
    <Flex
      data-testid='view-content'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      h='100vh'
      flexDir='column'
      p={8}>
      <CustomIcon name='star' h={100} w={100} />

      <Heading mt={8} mb={4}>
        Oops! That page doesn&apos;t exist.
      </Heading>

      <Text mt={4}>
        Sorry, but we can&apos;t seem to find the page you requested.
      </Text>
      <Text>It might be an old link, or maybe it moved.</Text>

      <Text mt={20}>
        Click here to go to our{' '}
        <Button
          variant='link'
          color='#1A202C'
          textDecoration='underline'
          onClick={(): void => history.push('/')}>
          home page
        </Button>
        , or{' '}
        <Link
          href='https://campminder.com/support/'
          color='#1A202C'
          textDecoration='underline'
          fontWeight='bold'>
          contact support
        </Link>
      </Text>
    </Flex>
  )
}
