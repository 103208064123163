import {
  FormControl,
  FormLabel,
  forwardRef,
  Input,
  InputProps,
  ChakraComponent,
} from '@chakra-ui/react'

export interface FormInputProps extends InputProps {
  label: string
  labelFontSize?: string | string[]
  labelFontWeight?: string | string[]
  labelPaddingTop?: string | string[] | number
  labelPaddingLeft?: string | string[] | number
  labelPaddingBottom?: string | string[] | number
  labelPaddingRight?: string | string[] | number
  labelMarginBottom?: string | string[] | number
  id: string
}

export const FormInput: ChakraComponent<'div', FormInputProps> = forwardRef(
  (
    {
      label,
      labelFontSize = '2xl',
      labelFontWeight = 'normal',
      labelPaddingTop = '',
      labelPaddingLeft = '',
      labelPaddingBottom = '',
      labelPaddingRight = '',
      labelMarginBottom = '',
      id,
      ...rest
    },
    ref,
  ) => (
    <FormControl d='flex' flexDir='column'>
      <FormLabel
        htmlFor={id}
        fontSize={labelFontSize}
        fontWeight={labelFontWeight}
        paddingTop={labelPaddingTop}
        paddingLeft={labelPaddingLeft}
        paddingBottom={labelPaddingBottom}
        paddingRight={labelPaddingRight}
        marginBottom={labelMarginBottom}>
        {label}
      </FormLabel>
      <Input id={id} type='text' ref={ref} {...rest} />
    </FormControl>
  ),
)
FormInput.displayName = 'FormInput'
