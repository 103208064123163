import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {GetSyncResultsResponse, SynchronizeIndividualRequest} from './types'

export interface SynchronizeIndividual<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const synchronizeIndividual: SynchronizeIndividual<
  SynchronizeIndividualRequest,
  GetSyncResultsResponse
> = (http, params) => {
  const {GroupID, PersonID} = params

  const path = '/v1/eztexting/group/{groupid}/person/{personid}/synchronize'
    .replace('{groupid}', GroupID.toString())
    .replace('{personid}', PersonID.toString())

  return http.request<GetSyncResultsResponse>({
    method: 'POST',
    url: `${path}`,
  })
}
