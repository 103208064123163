import React from 'react'
import {Flex, Text} from '@chakra-ui/react'

import {formatDate} from '../../../utils'
import {CustomIcon} from '../../../components'

export const ItemMeta: React.FC<
  {
    visibleDate: Date
    notificationSent?: boolean
  } & React.ComponentProps<typeof Flex>
> = ({visibleDate, notificationSent, ...rest}) => (
  <Flex {...rest} flexDirection='column'>
    <Text fontSize='sm' fontWeight='medium' color='#747474' lineHeight='short'>
      {formatDate(visibleDate, 'longString')}
    </Text>

    {notificationSent && (
      <Flex>
        <CustomIcon
          width='1.6rem'
          name='notification'
          style={{paddingRight: '.5vw'}}
        />
        <Text
          fontSize='sm'
          fontWeight='medium'
          color='#747474'
          lineHeight='short'>
          Push notification sent
        </Text>
      </Flex>
    )}
  </Flex>
)
