import React from 'react'
import {Flex, Link} from '@chakra-ui/react'

import {ActionButton} from '../../../components'

export const ItemHyperlink: React.FC<{
  url: string
  text: string
}> = ({url, text}) => {
  if (url && text) {
    return (
      <Flex p={5} justifyContent='center'>
        <Link
          href={url}
          isExternal
          _hover={{textDecoration: 'none'}}
          data-testid='micropostHyperlink'>
          <ActionButton>{text}</ActionButton>
        </Link>
      </Flex>
    )
  }

  return null
}
