import React from 'react'
import {log} from '@vanguard/logger'
import {createContextHelper} from '../../../utils/createContextHelper'
import {api} from '../../../services/api'
import {useRequiredAuth} from '../../../stores/AuthStore'
import {ResourceID, CamperSegment, SegmentCurrentSnapshot} from '../../../types'
import {allArraysAreEmpty} from '../../../utils'

/* ***************************************************** */
type CreatePayload = {segmentID: string | null} | null

type ReturnStatus = 'success' | 'failure'

interface SegmentManagerContext extends ReducerState {
  mode: 'edit' | 'create'
  dispatch: React.Dispatch<Action>
  createSegment(): Promise<{
    status: ReturnStatus
    data: CreatePayload
  }>
  deleteSegment(segmentID?: string | null): Promise<{
    status: ReturnStatus
  }>
  getSegmentCurrentSnapShot(segmentID: ResourceID): Promise<{
    status: ReturnStatus
    data: SegmentCurrentSnapshot | null
  }>
}

const [SegmentManagerContextProvider, useSegmentManagerContext] =
  createContextHelper<SegmentManagerContext>()

/* ***************************************************** */
interface ReducerState {
  status: 'idle' | 'pending' | 'rejected' | 'owned' | 'unowned'
  segments: CamperSegment
  storedSegments: CamperSegment
  segmentID: ResourceID | null
}

type Action =
  | {type: 'FETCH_INIT'}
  | {
      type: 'FETCH_SUCCESS'
      payload: {
        data: CamperSegment
      }
    }
  | {type: 'FETCH_FAILURE'}
  | {type: 'CREATE_INIT'}
  | {
      type: 'CREATE_SUCCESS'
      payload: {
        segmentID: ResourceID | null
      }
    }
  | {type: 'CREATE_FAILURE'}
  | {
      type: 'UPDATE_SEGMENT_FILTER'
      payload: {
        filter: keyof CamperSegment
        values: CamperSegment[keyof CamperSegment]
      }
    }
  | {
      type: 'RESET'
    }

const reducer: React.Reducer<ReducerState, Action> = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
    case 'CREATE_INIT': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'FETCH_SUCCESS': {
      return {
        ...state,
        segments: action.payload.data,
        storedSegments: action.payload.data,
        status: 'owned',
      }
    }
    case 'FETCH_FAILURE':
    case 'CREATE_FAILURE': {
      return {
        ...state,
        status: 'rejected',
      }
    }
    case 'CREATE_SUCCESS': {
      return {
        ...state,
        status: 'unowned',
        segmentID: action.payload.segmentID,
        storedSegments: state.segments,
      }
    }
    case 'UPDATE_SEGMENT_FILTER': {
      return {
        ...state,
        segments: {
          ...state.segments,
          [action.payload.filter]: action.payload.values,
        },
      }
    }
    case 'RESET': {
      return {
        ...state,
        segments: state.storedSegments,
      }
    }
    default:
      throw new Error('Unhandled action')
  }
}

/* ***************************************************** */
interface SegmentManagerProps {
  initial: {
    segmentID?: string | null
  }
}

export const SegmentManager: React.FC<
  React.PropsWithChildren<SegmentManagerProps>
> = ({initial, children}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    status: 'idle',
    storedSegments: {
      genderIDs: [],
      sessionIDs: [],
      divisionIDs: [],
      programIDs: [],
      bunkIDs: [],
      bunkPlanIDs: [],
    },
    segments: {
      genderIDs: [],
      sessionIDs: [],
      divisionIDs: [],
      programIDs: [],
      bunkIDs: [],
      bunkPlanIDs: [],
    },
    segmentID: initial.segmentID || null,
  })
  const {client} = useRequiredAuth()

  React.useEffect(() => {
    let didCancel = false

    const fetchData = async (): Promise<void> => {
      if (!initial.segmentID) return // No stored custom segment, using defaults

      dispatch({type: 'FETCH_INIT'})
      try {
        const response = await api.segments.getSegment({
          clientID: client.ID,
          ID: initial.segmentID,
        })

        if (response.result === 'success' && response.data !== null) {
          if (!didCancel) {
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: {
                data: response.data as CamperSegment,
              },
            })
          }
        } else {
          throw new Error('Unsuccessful Fetch Segments Response')
        }
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        }

        if (!didCancel) {
          dispatch({type: 'FETCH_FAILURE'})
        }
      }
    }

    fetchData()

    return (): void => {
      didCancel = true
    }
  }, [initial.segmentID, client])

  const deleteSegment: SegmentManagerContext['deleteSegment'] = async (
    segmentID = state.segmentID,
  ) => {
    let response: {result: ReturnStatus} = {
      result: 'success',
    }

    // Only delete if there is a segment to delete
    if (segmentID) {
      response = await api.segments.deleteSegment({
        clientID: client.ID,
        ID: segmentID,
      })
    }

    return Promise.resolve({
      status: response.result,
    })
  }

  const createSegment: SegmentManagerContext['createSegment'] = async () => {
    dispatch({type: 'CREATE_INIT'})

    let payload: CreatePayload = {
      segmentID: null,
    }
    let resolvedReturn: {
      status: ReturnStatus
      data: CreatePayload | null
    }

    if (!allArraysAreEmpty(state.segments)) {
      // Don't create segment for empty filter, but make sure segmentID tied to MP is cleared
      const response = await api.segments.upsertSegment({
        clientID: client.ID,
        segmentGroup: {
          ID: '0',
          // name: '', // These are anonymous segments
          type: 'Camper/Parent',
          content: state.segments,
        },
      })

      if (response.result === 'success') {
        payload.segmentID = response.data.ID // set new segmentID value
      } else {
        payload = null // failure
      }
    }

    if (!payload) {
      dispatch({type: 'CREATE_FAILURE'})
      resolvedReturn = {status: 'failure', data: null}
    } else {
      resolvedReturn = {status: 'success', data: payload}
    }

    return Promise.resolve(resolvedReturn)
  }

  const getSegmentCurrentSnapShot: SegmentManagerContext['getSegmentCurrentSnapShot'] =
    async (segmentID) => {
      let resolvedReturn: {
        status: ReturnStatus
        data: SegmentCurrentSnapshot | null
      }

      const response = await api.segments.getSegmentCurrentSnapShot({
        clientID: client.ID,
        seasonID: client.currentSeasonID.toString(),
        ID: segmentID,
      })

      if (response.result === 'success') {
        resolvedReturn = {status: 'success', data: response.data}
      } else {
        resolvedReturn = {status: 'failure', data: null} // failure
      }

      return Promise.resolve(resolvedReturn)
    }

  const value: SegmentManagerContext = {
    ...state,
    mode: initial.segmentID === undefined ? 'create' : 'edit',
    dispatch,
    createSegment,
    deleteSegment,
    getSegmentCurrentSnapShot,
  }

  return (
    <SegmentManagerContextProvider value={value}>
      {children}
    </SegmentManagerContextProvider>
  )
}

/* ***************************************************** */
export const useSegments = useSegmentManagerContext
