import React from 'react'
import {Flex, Box} from '@chakra-ui/react'

import {Micropost} from '../../../types'
import {MoreActionsMenu} from '../../../components'
import {ItemHyperlink, ItemMedia, ItemText, ItemMeta} from './index'
import {Feature} from '../../../components/Feature/Feature'

type Props = {
  micropost: Micropost
  handleEdit: () => void
  handleDelete: () => void
}

const Wrapper: React.FC<React.PropsWithChildren> = ({children}) => (
  <Flex
    data-testid='micropost-item'
    mx={[4, 8]}
    my={[2, 4]}
    shadow='md'
    borderWidth='1px'
    borderRadius={3}
    direction='column'>
    {children}
  </Flex>
)

export const MicropostItem: React.FC<React.PropsWithChildren<Props>> = ({
  micropost,
  handleEdit,
  handleDelete,
}) => {
  const actionMenuItems = [
    {key: '1', text: 'Edit', handleClick: handleEdit},
    {key: '2', text: 'Delete', handleClick: handleDelete},
  ]

  return (
    <Wrapper>
      <ItemMedia videoURL={micropost.videoURL} imageURL={micropost.imageURL} />
      <Flex p={4} direction='column' position='relative'>
        <Feature name='manage-microposts'>
          <Box position='absolute' top={[2, 4]} right={[2, 4]}>
            <MoreActionsMenu actions={actionMenuItems} />
          </Box>
        </Feature>
        <ItemMeta
          visibleDate={micropost.publishDate || new Date(Date.now())}
          notificationSent={micropost.notify}
        />
        <ItemText my={2} text={micropost.content} />
      </Flex>
      <ItemHyperlink
        url={micropost.hyperlinkURL}
        text={micropost.hyperlinkText}
      />
    </Wrapper>
  )
}
