import React from 'react'
import {Divider, Flex} from '@chakra-ui/react'

import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {ActionButton, Feature, PageHeader} from '../../components'
import {useReleaseFlags} from '../../hooks/useReleaseFlags'
import {useScript} from '../../hooks/useScript'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown
    }
  }
}

export const HomeView: React.FC<React.PropsWithChildren> = () => {
  useScript('https://embed.explo.co/bundle.js')

  const {getIsReleasedToUser} = useReleaseFlags()

  const usesDataInsights = getIsReleasedToUser('data-insights')

  let headerDescription =
    'Engage with your summer camp parents with Campanion. Get in touch today to find out how to sign your camp up for next year.'

  let headerTitle = 'Home'

  if (usesDataInsights) {
    headerDescription =
      'This requires an email associated with a Gmail account. If you have any feedback please contact benh@campminder.com.'

    headerTitle = 'Campanion Data Dashboard (Pilot)'
  }

  return (
    <PageTemplate
      header={
        <PageHeader
          title={headerTitle}
          logContext='HomeView'
          description={headerDescription}
          callToAction={
            usesDataInsights ? undefined : (
              <ActionButton
                data-testid='exploreCampanion'
                width={['100%', 'unset']}
                onClick={(): void => {
                  window.open(
                    'https://campanionapp.com/?utm_source=admin&utm_medium=banner&utm_campaign=admin_home_2020',
                  )
                }}>
                Explore Campanion
              </ActionButton>
            )
          }
        />
      }
      width={['100%']}
      maxWidth={usesDataInsights ? [] : ['1030px']}
      borderX={['0px']}>
      <>
        <Divider
          mb='4'
          mt={1}
          opacity={1}
          border-bottom='1px solid'
          mx={4}
          width={usesDataInsights ? '95vw' : 'unset'}
        />
        <Feature name='data-insights'>
          <Flex width='95vw' height='1200px' pt='10px' px={4}>
            <iframe
              data-testid='dataStudio'
              title='dataStudio'
              width='100%'
              height='100%'
              src='https://datastudio.google.com/embed/reporting/1DoT5ZIzVgbGx3xQBctytBhERkS4Wbgvi/page/FtCYB'
              frameBorder='0'
              style={{border: 0}}
              allowFullScreen
            />
          </Flex>
        </Feature>
        <Feature name='explo'>
          <explo-dashboard
            dash-user-group-token='E3Aa5VRAOq:2db0f057766767e88bb58e58d2216d73cfe478e06414a960ff7797359944add9'
            isProduction
            environment='production'
            variables={JSON.stringify({
              client_id: 1,
            })}
          />
        </Feature>
      </>
    </PageTemplate>
  )
}
