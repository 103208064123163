import React from 'react'
import {Menu, MenuButton, MenuList, MenuItem} from '@chakra-ui/react'
import {CheckIcon, ChevronDownIcon} from '@chakra-ui/icons'

import {useHistory} from 'react-router-dom'
import {PageHeader, Feature, ActionButton} from '../../../components'
import copy from '../copy.json'
import {useAlbumState} from '../AlbumManager'
import {useStatusToast} from '../../../app/ToastManager'

import {isMobileDevice} from '../../../utils/isMobileDevice'
import {useAlbumViewDispatch, useAlbumViewState} from '../AlbumViewManager'

export const AlbumViewPageHeader: React.FC<React.PropsWithChildren> = () => {
  const {data: album} = useAlbumState()
  const {viewMode} = useAlbumViewState()
  const albumViewDispatch = useAlbumViewDispatch()
  const {toast} = useStatusToast()
  const history = useHistory()

  if (!album) return null

  return (
    <PageHeader
      title={album.name}
      showBackButton
      backButtonText={copy.buttons.back}
      callToAction={
        viewMode !== 'sortPhotos' ? (
          <Feature name='upload-photos'>
            <ActionButton
              width={['100%', 'unset']}
              onClick={(): void => {
                history.push(`/album/${album.ID}/upload`)
              }}>
              {copy.buttons.addPhotos}
            </ActionButton>
          </Feature>
        ) : (
          <ActionButton
            data-testid='exit-sort-desktop'
            display={['none', 'flex']}
            width={['100%', 'unset']}
            onClick={(): void => {
              albumViewDispatch({type: 'SET_VIEW_MODE', payload: 'initial'})
            }}>
            <CheckIcon mr='2' />
            {copy.buttons.quitSort}
          </ActionButton>
        )
      }
      controls={
        viewMode !== 'sortPhotos' ? (
          <Feature name='edit-album'>
            <Menu placement='bottom-end'>
              <MenuButton fontWeight='500' data-testid='manage-album-button'>
                {copy.buttons.manage}
                <ChevronDownIcon boxSize='6' />
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={(): void => {
                    albumViewDispatch({
                      type: 'SET_VIEW_MODE',
                      payload: 'editAlbum',
                    })
                  }}
                  data-testid='edit-album-details-button'>
                  {copy.buttons.edit}
                </MenuItem>

                <MenuItem
                  data-testid='sort-photo-order-button'
                  onClick={(): void => {
                    albumViewDispatch({
                      type: 'SET_VIEW_MODE',
                      payload: 'sortPhotos',
                    })

                    if (!isMobileDevice())
                      toast.info('Select sort option or drag photo to reorder')
                  }}>
                  {copy.buttons.sort}
                </MenuItem>
              </MenuList>
            </Menu>
          </Feature>
        ) : undefined
      }
    />
  )
}
