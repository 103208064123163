import {AxiosResponse} from 'axios'
import {RawBunk, RawPagedCollectionResponse} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  ClientID: number
  SeasonID: number
  OrderAscending: boolean
  OrderBy: string
  PageNumber: number
  PageSize: number
  IncludeInactive?: boolean
}

type FetchBunksResponse = RawPagedCollectionResponse<RawBunk>

export interface FetchBunks<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchBunks: FetchBunks<Params, FetchBunksResponse> = (
  http,
  params,
) => {
  const {
    ClientID,
    SeasonID,
    OrderAscending,
    OrderBy,
    PageNumber,
    PageSize,
    IncludeInactive,
  } = params

  const path = '/v0/bunks'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (SeasonID !== undefined) {
    queryParams.push(`seasonid=${SeasonID}`)
  }
  if (OrderAscending !== undefined) {
    queryParams.push(`orderascending=${OrderAscending}`)
  }
  if (OrderBy !== undefined) {
    queryParams.push(`orderby=${OrderBy}`)
  }
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  if (IncludeInactive !== undefined) {
    queryParams.push(`includeinactive=${IncludeInactive}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.get<FetchBunksResponse>(`${path}${query}`)
}
