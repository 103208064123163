import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {UpdateRequest, SegmentResponse} from './types'

export interface Update<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const update: Update<UpdateRequest, SegmentResponse> = (
  http,
  params,
) => {
  const {ID} = params

  const path = '/v0/segments/{id}'.replace('{id}', ID.toString())

  return http.request<SegmentResponse>({
    method: 'PUT',
    url: `${path}`,
    data: params,
  })
}
