import {SSEMessage} from '../types'
import {UploadSessionCompleteMeta} from './types'

export const TopicUploadSessionComplete = 'Photos.UploadSessionComplete'

type ProcessUploadSessionCompleteSSE = (msg: SSEMessage) => {
  Response?: {
    completed: boolean
    error: string
  }
  UploadSessionCompleteMeta?: UploadSessionCompleteMeta
}

export const processUploadSessionCompleteSSE: ProcessUploadSessionCompleteSSE =
  (msg) => {
    const transformedSSE: ReturnType<ProcessUploadSessionCompleteSSE> = {}
    if (msg.Response) {
      transformedSSE.Response = JSON.parse(msg.Response.Properties)
    }
    if (msg.UploadSessionCompleteMeta) {
      transformedSSE.UploadSessionCompleteMeta = JSON.parse(
        msg.UploadSessionCompleteMeta.Properties,
      )
    }
    return transformedSSE
  }
