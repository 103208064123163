import {useCallback} from 'react'
import {FEATURE_ENABLEMENT_MAP} from '../constants'
import {FeatureName} from '../types'
import {useAuth} from '../stores/AuthStore'

interface UseReleaseFlags {
  (): {
    getIsReleasedToUser(featureName: FeatureName): boolean
  }
}

export const useReleaseFlags: UseReleaseFlags = () => {
  const {releaseFlags} = useAuth()

  const getIsReleasedToUser = useCallback(
    (featureName: FeatureName): boolean => {
      const enablement = FEATURE_ENABLEMENT_MAP[featureName]

      if (enablement && !enablement.isUnderReleaseFlag) {
        return true
      }

      if (!releaseFlags) {
        return false
      }

      return Boolean(releaseFlags.find((rf) => rf.name === featureName))
    },
    [releaseFlags],
  )

  return {
    getIsReleasedToUser,
  }
}
