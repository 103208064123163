import {FC, CSSProperties} from 'react'
import {Box, Button, Flex, Heading, Text} from '@chakra-ui/react'
import {SettingsIcon} from '@chakra-ui/icons'
import {useHistory} from 'react-router-dom'

import {appTheme} from '../../app/theme'
import {IntegrationOptions} from '../../types'
import copy from './copy.json'
import {Visible} from '../../components'

interface Props {
  options: IntegrationOptions
}

export const Integration: FC<Props> = ({options}) => {
  const history = useHistory()

  const borderStyle = `1px solid ${appTheme.colors.gray[200]}`

  const cardStyles: CSSProperties = {
    border: borderStyle,
    borderRadius: appTheme.radii.md,
    maxWidth: '475px',
    flexDirection: 'column',
    marginBottom: '1em',
  }

  const logoStyles = {
    borderRadius: '50%',
    maxWidth: '100px',
    border: borderStyle,
  }

  return (
    <Box as='li' display='flex' style={cardStyles}>
      <Flex
        alignItems='center'
        justifyContent='space-between'
        padding='1em'
        paddingRight='1.5em'>
        <Flex direction='column' width='65%'>
          <Heading
            as='h5'
            style={{fontWeight: 400, fontSize: '1.5em', marginBottom: '0.5em'}}>
            {options.title}
          </Heading>
          <Text role='document'>
            {options.description}
            {options.connected ? '' : ` ${options.outreach}`}
          </Text>
        </Flex>
        <img src={options.logo} alt={options.title} style={logoStyles} />
      </Flex>
      <Visible when={options.connected}>
        <Flex
          style={{borderTop: borderStyle}}
          alignItems='center'
          justifyContent='space-between'
          paddingX='1em'
          paddingY='0.5em'>
          <Text>Connected</Text>
          <Button
            variant='ghost'
            leftIcon={<SettingsIcon />}
            fontWeight='500'
            color={appTheme.colors.secondary[500]}
            onClick={(): void => history.push(options.managementUrl)}>
            {copy.buttons.manage}
          </Button>
        </Flex>
      </Visible>
    </Box>
  )
}
