import React from 'react'

const preventTabClose = (event: Event): void => {
  // Cancel the event as stated by the standard.
  event.preventDefault()

  // Chrome requires returnValue to be set.
  // eslint-disable-next-line no-param-reassign
  event.returnValue = false
}

export const usePreventTabClose = (enabled = true): void => {
  React.useEffect(() => {
    if (enabled) {
      window.addEventListener('beforeunload', preventTabClose)
    }

    return (): void => {
      if (enabled) {
        window.removeEventListener('beforeunload', preventTabClose)
      }
    }
  }, [enabled])
}
