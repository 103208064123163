import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {GetAlbumUploadSessionRequest, AlbumUploadSession} from '../types'

export interface GetAlbumUploadSession<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const getAlbumUploadSession: GetAlbumUploadSession<
  GetAlbumUploadSessionRequest,
  AlbumUploadSession
> = (http: HttpInstance, params) => {
  const {ClientID, ID, AlbumID} = params

  const path = '/v1/albums/{albumid}/uploadsession/{id}'
    .replace('{id}', ID.toString())
    .replace('{albumid}', AlbumID.toString())

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<AlbumUploadSession>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
