import React from 'react'
import {CamperSegment, SegmentGroup, StaffSegment} from '../../../../types'
import {createContextHelper} from '../../../../utils/createContextHelper'
import {api} from '../../../../services/api'
import {useRequiredAuth} from '../../../../stores/AuthStore'
import {INSERT_PLACEHOLDER_ID} from '../../../../constants'
import {SegmentType} from '../../../../services/coreApi/segments/types'

interface Context {
  audience: SegmentGroup
  allowSaving: boolean
  updateName(value: string): void
  updateType(value: SegmentType): void
  updateValue(
    key: keyof CamperSegment | keyof StaffSegment,
    value: string | string[] | null,
  ): void
  save(): ReturnType<typeof api.segments.upsertSegment>
}

/* *********************************************** */
const [AudienceManagerContextProvider, useAudienceManagerContext] =
  createContextHelper<Context>()

export const useAudience = useAudienceManagerContext

/* *********************************************** */
export const AudienceManager: React.FC<
  React.PropsWithChildren<{
    initial: SegmentGroup | null
  }>
> = ({initial, children}) => {
  const {client} = useRequiredAuth()

  const {
    ID = '',
    self = '',
    createdByID = '',
    dateModified = new Date(),
    name = '',
    type = 'Camper/Parent',
    content = {},
  } = initial || {}
  const camperContent = content as CamperSegment
  const staffContent = content as StaffSegment
  const {
    genderIDs: genderIDsCamper = [],
    sessionIDs = [],
    divisionIDs: divisionIDsCamper = [],
    programIDs = [],
    bunkIDs = [],
    bunkPlanIDs = [],
  } = camperContent
  const {
    genderIDs: genderIDsStaff = [],
    divisionIDs: divisionIDsStaff = [],
    organizationalCategoryIDs = [],
    positionIDs = [],
    programAreaIDs = [],
  } = staffContent
  const genderIDs = genderIDsCamper.concat(genderIDsStaff)
  const divisionIDs = divisionIDsCamper.concat(divisionIDsStaff)
  const [input, setInput] = React.useState({
    ID,
    self,
    createdByID,
    dateModified,
    name,
    type,
    genderIDs,
    sessionIDs,
    divisionIDs,
    programIDs,
    bunkIDs,
    bunkPlanIDs,
    organizationalCategoryIDs,
    positionIDs,
    programAreaIDs,
  })

  const allowSaving = React.useMemo(() => {
    if (!input.name.length) return false

    if (input.type === 'Camper/Parent') {
      return Boolean(
        input.genderIDs &&
          input.sessionIDs &&
          input.divisionIDs &&
          input.programIDs &&
          input.bunkIDs &&
          input.bunkPlanIDs,
      )
    }
    return Boolean(
      input.genderIDs &&
        input.divisionIDs &&
        input.organizationalCategoryIDs &&
        input.positionIDs &&
        input.programAreaIDs,
    )
  }, [input])

  const audience = React.useMemo((): SegmentGroup => {
    if (input.type === 'Camper/Parent') {
      return {
        ID: input.ID,
        self: input.self,
        createdByID: input.createdByID,
        dateModified: input.dateModified,
        name: input.name,
        type: input.type,
        content: {
          genderIDs: input.genderIDs,
          sessionIDs: input.sessionIDs,
          divisionIDs: input.divisionIDs,
          programIDs: input.programIDs,
          bunkIDs: input.bunkIDs,
          bunkPlanIDs: input.bunkPlanIDs,
        },
      }
    }
    return {
      ID: input.ID,
      self: input.self,
      createdByID: input.createdByID,
      dateModified: input.dateModified,
      name: input.name,
      type: input.type,
      content: {
        genderIDs: input.genderIDs,
        divisionIDs: input.divisionIDs,
        organizationalCategoryIDs: input.organizationalCategoryIDs,
        positionIDs: input.positionIDs,
        programAreaIDs: input.programAreaIDs,
      },
    }
  }, [input])

  const updateName: Context['updateName'] = (value): void => {
    setInput((prev) => ({...prev, name: value}))
  }
  const updateType: Context['updateType'] = (value): void => {
    setInput((prev) => ({...prev, type: value}))
  }
  const updateValue: Context['updateValue'] = (key, value): void => {
    setInput((prev) => ({...prev, [key]: value}))
  }

  const save: Context['save'] = async () => {
    let request: Parameters<typeof api.segments.upsertSegment>[0]
    if (input.type === 'Camper/Parent') {
      if (
        !input.genderIDs ||
        !input.sessionIDs ||
        !input.divisionIDs ||
        !input.programIDs ||
        !input.bunkIDs ||
        !input.bunkPlanIDs
      ) {
        throw new Error('invalid segment filter values')
      }
      request = {
        clientID: client.ID,
        segmentGroup: {
          ID: initial?.ID || INSERT_PLACEHOLDER_ID,
          type: input.type,
          name: input.name,
          content: {
            genderIDs: input.genderIDs,
            sessionIDs: input.sessionIDs,
            divisionIDs: input.divisionIDs,
            programIDs: input.programIDs,
            bunkIDs: input.bunkIDs,
            bunkPlanIDs: input.bunkPlanIDs,
          },
        },
      }
    } else {
      if (
        !input.genderIDs ||
        !input.divisionIDs ||
        !input.organizationalCategoryIDs ||
        !input.positionIDs ||
        !input.programAreaIDs
      ) {
        throw new Error('invalid segment filter values')
      }
      request = {
        clientID: client.ID,
        segmentGroup: {
          ID: initial?.ID || INSERT_PLACEHOLDER_ID,
          type: input.type,
          name: input.name,
          content: {
            genderIDs: input.genderIDs,
            divisionIDs: input.divisionIDs,
            organizationalCategoryIDs: input.organizationalCategoryIDs,
            positionIDs: input.positionIDs,
            programAreaIDs: input.programAreaIDs,
          },
        },
      }
    }

    if (!request) {
      throw new Error('invalid segment filter values')
    }

    return api.segments.upsertSegment(request)
  }

  return (
    <AudienceManagerContextProvider
      value={{
        audience,
        allowSaving,
        updateName,
        updateType,
        updateValue,
        save,
      }}>
      {children}
    </AudienceManagerContextProvider>
  )
}
