import React from 'react'
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'
import {log, setLogContext} from '@vanguard/logger'
import {Modal} from './subcomps/Modal'

import {useMicropostsStore} from '../../stores/MicropostsStore'
import {StateWrapper} from './subcomps/StateWrapper'
import {ModalStep} from './subcomps/ModalStep'
import {ComposeStepUI} from './ComposeStepUI'
import {SegmentStepUI} from './SegmentStepUI'

interface Props {
  onClose(): void
}

export const ComposeMicropostModal: React.FC<
  React.PropsWithChildren<Props>
> = ({onClose}) => {
  setLogContext('ComposeMicropostModal')

  const {selectedMicropost, setSelectedMicropost} = useMicropostsStore()
  const initialFocusRef = React.useRef<HTMLTextAreaElement>(null)

  const handleOnClose = (): void => {
    log.breadcrumb('Close Modal')
    setSelectedMicropost(null)
    onClose()
  }

  return (
    <StateWrapper initial={selectedMicropost}>
      <Modal
        isOpen
        steps={['compose', 'segment']}
        onClose={handleOnClose}
        initialFocusRef={initialFocusRef}
        size='4xl' // {['full', '4xl']} revisit why Modal size prop doesn't take responsive syntax
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent
          data-testid='compose-content'
          borderRadius={['none', 'md']}
          my={[0, '3.75rem']}
          minHeight={['100%', 'unset']}>
          <ModalHeader borderBottom='1px' borderColor='#DFE8ED'>
            <ModalStep name='compose'>
              <ModalCloseButton title='close' top='16px' />
              <ComposeStepUI.Header />
            </ModalStep>

            <ModalStep name='segment'>
              <SegmentStepUI.Header />
            </ModalStep>
          </ModalHeader>

          <ModalBody p={0}>
            <ModalStep name='compose'>
              <ComposeStepUI.Body />
            </ModalStep>

            <ModalStep name='segment'>
              <SegmentStepUI.Body />
            </ModalStep>
          </ModalBody>

          <ModalFooter borderTop='1px' borderColor='#DFE8ED'>
            <ModalStep name='compose'>
              <ComposeStepUI.Footer onClose={handleOnClose} />
            </ModalStep>

            <ModalStep name='segment'>
              <SegmentStepUI.Footer />
            </ModalStep>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </StateWrapper>
  )
}
