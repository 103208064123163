import {AxiosResponse} from 'axios'
import {RawClient} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  clientID: string
}

export interface GetClient<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const getClient: GetClient<Params, RawClient> = (http, {clientID}) =>
  http.get(`/v1/clients/${clientID}`)
