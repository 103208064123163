import {fetchAlbumPhotos} from './fetchAlbumPhotos'
import {getThumbnail} from './getThumbnail'
import {processPhoto} from './processPhoto'
import {deleteAlbumPhoto} from './deleteAlbumPhoto'
import {handleRotatePhotoSSE, TopicRotatePhotoComplete} from './rotatePhotoSSE'
import {rotatePhoto} from './rotatePhoto'

export const photos = {
  fetchAlbumPhotos,
  getThumbnail,
  processPhoto,
  deleteAlbumPhoto,
  rotatePhoto,
  handleRotatePhotoSSE,
  TopicRotatePhotoComplete,
}
