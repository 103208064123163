import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {AlbumUploadSession, ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  ID: string
  albumID: ResourceID
  clientID: string
}

type ResolvedReturn = APIResponse<AlbumUploadSession>

export interface GetAlbumUploadSession {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.getAlbumUploadSession()'
const GENERIC_ERROR =
  'Something went wrong while getting your albumUploadSession. Please try again.'

export const getAlbumUploadSession: GetAlbumUploadSession = async ({
  ID,
  albumID,
  clientID,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ID,
      AlbumID: parseInt(albumID, 10),
      ClientID: parseInt(clientID, 10),
    }

    const response = await coreAlbums.getAlbumUploadSession(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful AlbumUploadSession Get')
    }

    const {data} = response

    resolvedReturn = {
      result: 'success',
      data: {
        ID: data.ID.toString(),
        self: data.Self,
        albumID: data.AlbumID.toString(),
        clientID: data.ClientID.toString(),
        fileCount: data.FileCount,
        failuresCount: data.FailuresCount,
        receivedCount: data.ReceivedCount,
        successCount: data.SuccessCount,
        createDateTime: new Date(data.CreateDateTime),
        files: data.Files.map((f) => ({
          fileName: f.FileName,
          received: f.Received,
          failed: f.Failed,
        })),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
