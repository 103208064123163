import {fetchMicroposts} from './fetchMicroposts'
import {createMicropost} from './createMicropost'
import {updateMicropost} from './updateMicropost'
import {deleteMicropost} from './deleteMicropost'

export const microposts = {
  fetchMicroposts,
  createMicropost,
  updateMicropost,
  deleteMicropost,
}
