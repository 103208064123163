import React from 'react'

import {FeatureName} from '../../types'
import {useReleaseFlags} from '../../hooks/useReleaseFlags'

interface Props {
  name: FeatureName
  render?: (isFeatureEnabled: boolean) => React.ReactElement
}

export const Feature: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  render,
  children,
}) => {
  const {getIsReleasedToUser} = useReleaseFlags()

  const isReleased = getIsReleasedToUser(name)

  // const {getRolePermissions} = usePermissions()
  // const userRoleHasPermission = getRolePermissions(roles)

  const isFeatureEnabled = isReleased //  && userRoleHasPermission

  // return the flag value to be able to make UI for both scenarios
  if (render) {
    return render(isFeatureEnabled) // I couldn't get typescript to play nice to type this boolean flag, so has to be done at implementation time.
  }

  // show if is enabled
  if (isFeatureEnabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{React.Children.only(children)}</>
  }

  // don't show if not enabled
  return null
}

Feature.displayName = 'Feature'
