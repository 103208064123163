import React from 'react'
import {Box, Button, Flex, Heading} from '@chakra-ui/react'
import {ArrowBackIcon} from '@chakra-ui/icons'
import {setLogContext} from '@vanguard/logger'
import {useHistory} from 'react-router-dom'
import {Visible} from '../Visible/Visible'

interface Props {
  title: string
  description?: string | React.ReactElement
  showBackButton?: boolean
  backButtonText?: string
  controls?: React.ReactElement
  callToAction?: React.ReactElement
  logContext?: string
}

export const PageHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  showBackButton = false,
  backButtonText,
  controls,
  logContext,
  callToAction,
}): React.ReactElement => {
  if (logContext) {
    setLogContext(logContext)
  }

  const history = useHistory()

  const backButton = showBackButton ? (
    <Button
      variant='link'
      display='flex'
      alignItems='center'
      color='#000'
      fontWeight='500'
      lineHeight='1.25em'
      aria-label='Go Back'
      data-testid='back-button'
      height='2.5em'
      onClick={(): void => {
        history.goBack()
      }}
      flexGrow={1}
      justifyContent='flex-start'>
      <ArrowBackIcon boxSize='1.5em' mr='.5em' />
      <Box display={['none', 'initial']}>{backButtonText}</Box>
    </Button>
  ) : null

  return (
    <Flex
      p={[4]}
      mt={1}
      mb={0}
      pb={[0, 2]}
      direction='column'
      data-testid='page-header'>
      <Flex
        mb={4}
        alignItems='center'
        justifyContent='space-between'
        data-testid='header-controls'>
        <Flex flexGrow={[1, 0]}>{backButton}</Flex>
        <Flex flexGrow={[1, 0]} justify='flex-end'>
          {controls}
        </Flex>
      </Flex>

      <Flex
        flexDirection={['column', 'row']}
        justifyContent='space-between'
        alignItems='flex-end'>
        <Flex flexDirection='column' width={['100%', '66%']}>
          <Heading
            data-testid='header-title'
            fontSize={['32px']}
            textAlign='left'
            fontWeight={400}>
            {title}
          </Heading>

          <Visible when={!!description}>
            <Box
              data-testid='header-description'
              fontSize={['s']}
              textAlign='left'
              mt={[4]}>
              {description}
            </Box>
          </Visible>
        </Flex>

        <Flex
          ml={[0]}
          width={['100%', '33%']}
          justifyContent={['flex-end']}
          my={!(description && callToAction) ? ['1', 'unset'] : ['4', 'unset']}>
          {callToAction}
        </Flex>
      </Flex>
    </Flex>
  )
}
