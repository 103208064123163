import {ComponentStyleConfig} from '@chakra-ui/react'
import {colors} from './colors'

const Switch: ComponentStyleConfig = {
  variants: {
    info: {
      track: {
        bg: '#D8D8D8',
        _checked: {
          bg: colors.info,
        },
      },
    },
  },
}

const Tooltip: ComponentStyleConfig = {
  variants: {
    light: {
      bg: 'white',
      color: 'black',
      fontSize: '14px',
      paddingY: '8px',
      paddingX: '16px',
      fontWeight: 400,
      borderRadius: '4px',
      border: '1px solid #D8D8D8',
      boxShadow: '0px 2px 7px 0px #00000033',
    },
  },
}

const radioCheckedStyles = {
  bg: 'white',
  color: colors.info,
  borderColor: colors.info,
  borderWidth: '2px',
}

const Radio: ComponentStyleConfig = {
  variants: {
    cmInfo: {
      control: {
        _checked: {
          ...radioCheckedStyles,
          _hover: radioCheckedStyles,
        },
        borderColor: '#4c4b4b',
        borderWidth: '1px',
      },
    },
  },
}

const Button: ComponentStyleConfig = {
  variants: {
    back_navigation: {
      variant: 'link',
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      color: '#000',
      fontWeight: '500',
      lineHeight: '1.25em',
      ariaLabel: 'Go Back',
      height: '2.5em',
      flexGrow: '1',
    },
    light_background: {
      variant: 'link',
      display: 'flex',
      alignItems: 'center',
      color: '#000',
      fontWeight: '500',
      lineHeight: '1.25em',
      ariaLabel: 'Next',
      height: '2.5em',
      flexGrow: 1,
      justifyContent: 'center',
    },
  },
}

const Divider: ComponentStyleConfig = {
  baseStyle: {
    border: 1, // This is an odd requirement. Without a "border: 1" entry the divider disappears in the UI.
  },
  variants: {
    standard: {
      mb: '4',
      mt: '1',
      opacity: '1',
      borderBottom: '1px solid',
      mx: '4',
    },
  },
}

export const components = {
  Switch,
  Tooltip,
  Button,
  Divider,
  Radio,
}
