import {GetRequest} from './types'
import {HttpInstance} from '../createHttpInstance'

export interface GetThumbnail<P> {
  (http: HttpInstance, params: P): string
}

export const getThumbnail: GetThumbnail<GetRequest> = (http, params) => {
  const {ClientID, ID} = params

  const {baseURL} = http.defaults
  const path = `/v1/photos/${ID}/thumbnail?clientid=${ClientID}`

  return `${baseURL}${path}`
}
