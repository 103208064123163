import {AxiosResponse} from 'axios'
import {HttpInstance} from '../../createHttpInstance'
import {GetConstraintCountRequest, GetConstraintCountResponse} from './types'

export interface GetConstraintCount<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const getConstraintCount: GetConstraintCount<
  GetConstraintCountRequest,
  GetConstraintCountResponse
> = (http, params) => {
  const {ClientID, SeasonID, AlbumConstraints} = params

  const path = '/v1/albums/constraint/count'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (SeasonID !== undefined) {
    queryParams.push(`seasonid=${SeasonID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<GetConstraintCountResponse>({
    method: 'POST',
    url: `${path}${query}`,
    data: {AlbumConstraints},
  })
}
