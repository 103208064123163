import {FC} from 'react'
import {Box} from '@chakra-ui/react'
import {appTheme} from '../../app/theme'

interface Props {
  onClick?: () => void
  selected?: boolean
}

export const PageSelector: FC<React.PropsWithChildren<Props>> = ({
  onClick,
  selected = false,
  children,
}) => {
  const styles = {
    fontWeight: 700,
    width: '2em',
    height: '2em',
    color: appTheme.colors.secondary[500],
    borderRadius: '50%',
    border: selected ? `2px solid ${appTheme.colors.secondary[200]}` : 'none',
  }

  const hoverStyles = {
    backgroundColor: `${appTheme.colors.gray[200]} !important`,
    cursor: 'pointer',
  }

  return (
    <Box
      as='div'
      display='flex'
      style={styles}
      justifyContent='center'
      alignItems='center'
      _hover={hoverStyles}
      onClick={onClick}>
      {children}
    </Box>
  )
}
