import {http} from '../http'
import * as coreGroups from '../../coreApi/eztexting'
import {APIResponse} from '../types'
import {EzTextingGroup} from '../../../types'
import {shouldDoCreate, convertToInt} from '../../../utils'
import {transformGroup} from './group.transform'
import {handleError} from '../handleError'

interface Params
  extends Pick<EzTextingGroup, 'audienceIDs' | 'ez' | 'clientID'> {
  ID?: EzTextingGroup['ID']
  ezTextingID?: EzTextingGroup['ezTextingID']
}

type ResolvedReturn = APIResponse<EzTextingGroup>

interface UpsertGroup {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.upsertGroup()'
const GENERIC_ERROR =
  'Something went wrong while saving your group. Please try again.'

export const upsertGroup: UpsertGroup = async ({
  ID,
  ez,
  audienceIDs,
  ezTextingID,
  clientID,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: Number(clientID),
      Name: ez.name,
      Note: ez.description.length ? ez.description : null,
      SegmentIDs: convertToInt(audienceIDs),
      Household1Parent1: ez.householdIDs.includes('Household1Parent1'),
      Household1Parent2: ez.householdIDs.includes('Household1Parent2'),
      Household2Parent1: ez.householdIDs.includes('Household2Parent1'),
      Household2Parent2: ez.householdIDs.includes('Household2Parent2'),
    }

    const response = shouldDoCreate({ID})
      ? await coreGroups.createGroup(http, rawParamMap)
      : await coreGroups.modifyGroup(http, {
          ID: Number(ID),
          EzTextingID: String(ezTextingID),
          ...rawParamMap,
        })

    if (!response || !response.data) {
      throw new Error('Unsuccessful EzTexting Upsert')
    }

    resolvedReturn = {
      result: 'success',
      data: transformGroup(response.data),
    }
  } catch (e) {
    return handleError(ERROR_PATH, GENERIC_ERROR, e)
  }
  return Promise.resolve(resolvedReturn)
}
