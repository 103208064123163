import {AxiosResponse} from 'axios'
import {PhotoRotateRequest, PhotoRotateResponse} from './types'
import {HttpInstance} from '../createHttpInstance'

export interface RotatePhoto<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const rotatePhoto: RotatePhoto<
  PhotoRotateRequest,
  PhotoRotateResponse
> = (http, params) => {
  const {ClientID, Degrees, ID} = params

  const path = '/v1/photos/{id}/rotate'.replace('{id}', ID.toString())

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (Degrees !== undefined) {
    queryParams.push(`degrees=${Degrees}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<PhotoRotateResponse>({
    method: 'PUT',
    url: `${path}${query}`,
  })
}
