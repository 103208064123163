import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {Paginated, PositionsResponse, StaffRequest} from './types'

export interface Positions<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const positions: Positions<
  StaffRequest,
  Paginated<PositionsResponse>
> = (http, params) => {
  const {ClientID, PageNumber, PageSize} = params

  const path = '/v0/staff/positions'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<Paginated<PositionsResponse>>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
