export const splitMilitaryTimeString = (
  timeString: string,
): [string, string, string] => {
  const splitString = timeString.split(':')

  if (splitString.length !== 2) throw new Error('Invalid time string')

  let hoursInt = Number(splitString[0])
  const minutesInt = Number(splitString[1])
  const isPM = hoursInt > 11

  hoursInt = isPM ? hoursInt - 12 : hoursInt

  return [
    hoursInt === 0 ? '12' : hoursInt.toString().padStart(2, '0'),
    minutesInt.toLocaleString().padStart(2, '0'),
    isPM ? 'PM' : 'AM',
  ]
}
