import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {GetSyncResultsResponse, GetSyncResultsRequest, Paginated} from './types'

export interface GetSyncResults<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const getSyncResults: GetSyncResults<
  GetSyncResultsRequest,
  Paginated<GetSyncResultsResponse>
> = (http, params) => {
  const {PageNumber, PageSize, ClientID, OrderBy, OrderAscending, GroupID} =
    params

  const path = '/v1/eztexting/group/{groupid}/syncresults'.replace(
    '{groupid}',
    GroupID.toString(),
  )

  const queryParams = []
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  if (OrderBy !== undefined) {
    queryParams.push(`orderby=${OrderBy}`)
  }
  if (OrderAscending !== undefined) {
    queryParams.push(`orderascending=${OrderAscending}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<Paginated<GetSyncResultsResponse>>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
