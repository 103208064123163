/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

export interface CreateContextOptions {
  /**
   * If `true`, React will throw if context is `null` or `undefined`
   * In some cases, you might want to support nested context, so you can set it to `false`
   */
  strict?: boolean
  /**
   * Error message to throw if the context is `undefined`
   */
  errorMessage?: string
  /**
   * The display name of the context
   */
  // name?: string
}

type CreateContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>]

export const createContextHelper = <
  ContextType,
>(): CreateContextReturn<ContextType> => {
  const strict = true
  const errorMessage = 'useContext must be inside a Provider with a value'

  // const {
  //   strict = true,
  //   errorMessage = 'useContext must be inside a Provider with a value',
  //   name,
  // } = options

  const Context = React.createContext<ContextType | undefined>(undefined)

  // Context.displayName = name

  const useContext = (): ContextType => {
    const context = React.useContext(Context)
    if (!context && strict) throw new Error(errorMessage)

    return context as ContextType
  }

  return [
    Context.Provider,
    useContext,
    Context,
  ] as CreateContextReturn<ContextType>
}
