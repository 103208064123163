/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dispatch,
  Reducer,
  ReducerState,
  ReducerAction,
  useReducer,
  useEffect,
} from 'react'

export const useSecureStoredReducer = <R extends Reducer<any, any>>(
  key: string,
  reducer: R,
  initialState: ReducerState<R>,
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
  const stored = sessionStorage.getItem(key)

  const parsedInitialState: ReducerState<R> = stored
    ? JSON.parse(stored)
    : initialState

  const [state, dispatch] = useReducer(reducer, parsedInitialState)

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state))
  }, [state, key])

  return [state, dispatch]
}
