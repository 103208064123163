import {decode} from 'he'
import {http} from '../http'
import * as coreMicroposts from '../../coreApi/microposts'
import {APIResponse} from '../types'
import {ResourceID, Micropost} from '../../../types'
import {handleError} from '../handleError'

interface Params extends Micropost {
  clientID: ResourceID
  personID: ResourceID
}

type ResolvedReturn = APIResponse<Micropost>

export interface UpdateMicropost {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.microposts.updateMicropost()'
const GENERIC_ERROR = 'general'

export const updateMicropost: UpdateMicropost = async ({
  clientID,
  personID,
  ID,
  publishDate,
  content,
  imageURL,
  videoURL,
  hyperlinkText,
  hyperlinkURL,
  segmentID,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ID: parseInt(ID, 10),
      ClientID: parseInt(clientID, 10),
      CreatedBy: parseInt(personID, 10),
      PublishDate: publishDate?.toISOString() || new Date().toISOString(),
      Content: content,
      ImageURL: imageURL && imageURL.length ? imageURL : null,
      VideoURL: videoURL && videoURL.length ? videoURL : null,
      TargetText: hyperlinkText && hyperlinkText.length ? hyperlinkText : null,
      TargetURL: hyperlinkURL && hyperlinkURL.length ? hyperlinkURL : null,
      SegmentID: segmentID ? parseInt(segmentID, 10) : null,
    }

    const response = await coreMicroposts.updateMicropost(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Micropost Update')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        ID: response.data.ID.toString(),
        content: decode(response.data.Content),
        videoURL: decode(response.data.VideoURL || ''),
        hyperlinkURL: decode(response.data.TargetURL || ''),
        hyperlinkText: decode(response.data.TargetText || ''),
        imageURL: decode(response.data.ImageURL || ''),
        publishDate: new Date(response.data.PublishDate),
        notify: response.data.Notify,
        segmentID: response.data.SegmentID
          ? response.data.SegmentID.toString()
          : null,
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
