import {useState, useEffect, useReducer} from 'react'
import {api} from './api'
import {Album} from '../types'
import {useRequiredAuth} from '../stores/AuthStore'
// import {logger} from './api/logger'

export const ALBUMS_PAGE_SIZE = 25

/* *******************************  */

interface State {
  status: 'idle' | 'pending' | 'resolved' | 'rejected'
  data: Album[]
  totalCount: number
  hasMore: boolean
}

type Action =
  | {type: 'FETCH_INIT'}
  | {
      type: 'FETCH_SUCCESS'
      payload: {
        data: Album[]
        total: number
        nextURL: string
      }
    }
  | {type: 'FETCH_FAILURE'}

const fetchReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_INIT': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'FETCH_SUCCESS': {
      return {
        ...state,
        status: 'resolved',
        data: [...state.data, ...action.payload.data],
        totalCount: action.payload.total,
        hasMore: Boolean(action.payload.nextURL),
      }
    }
    case 'FETCH_FAILURE': {
      return {
        ...state,
        status: 'rejected',
      }
    }
    default:
      throw new Error('Unhandled action')
  }
}

/* ******************************************** */
interface UseFetchAlbumsReturn extends State {
  maybeFetchMore: () => void
  // resetPageNumber: () => void
}

export const useFetchPaginatedAlbums = (): UseFetchAlbumsReturn => {
  const [pageNumber, setPageNumber] = useState(1)
  const {client} = useRequiredAuth()

  const [state, dispatch] = useReducer(fetchReducer, {
    status: 'idle',
    data: [],
    totalCount: 0,
    hasMore: false,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async (): Promise<void> => {
      dispatch({type: 'FETCH_INIT'})

      const response = await api.albums.fetchAlbums({
        pageNumber,
        pageSize: ALBUMS_PAGE_SIZE,
        clientID: client.ID,
      })

      if (response.result === 'failure' || didCancel) {
        dispatch({type: 'FETCH_FAILURE'})
      } else {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: {
            data: response.data.results,
            total: response.data.total,
            nextURL: response.data.nextURL,
          },
        })
      }
    }

    fetchData()

    return (): void => {
      didCancel = true
    }
  }, [pageNumber, client.ID])

  const maybeFetchMore = (): void => {
    if (state.status !== 'pending' && state.hasMore) {
      setPageNumber(pageNumber + 1)
    }
  }

  // const resetPageNumber = (): void => {
  //   fetchData(1, false)
  // }

  return {
    status: state.status,
    data: state.data,
    hasMore: state.hasMore,
    totalCount: state.totalCount,
    maybeFetchMore,
  }
}
