import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {SynchronizeGroupRequest, SynchronizeGroupResponse} from './types'

export interface SynchronizeGroup<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const synchronizeGroup: SynchronizeGroup<
  SynchronizeGroupRequest,
  SynchronizeGroupResponse
> = (http, params) => {
  const {ClientID, GroupID} = params

  const path = '/v1/eztexting/group/{groupid}/synchronize'.replace(
    '{groupid}',
    GroupID.toString(),
  )

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<SynchronizeGroupResponse>({
    method: 'POST',
    url: `${path}${query}`,
  })
}
