import {SSEMessage} from '../types'
import {PhotoSSEMeta} from './types'

export type TopicRotatePhotoCompleteType = 'Photos.RotationComplete'
export const TopicRotatePhotoComplete: TopicRotatePhotoCompleteType =
  'Photos.RotationComplete'

export type RotatePhotoMessageProperties = {
  Completed: boolean
  Error: string
}

export type RotatePhotoCompleteEvent = {
  Response?: RotatePhotoMessageProperties
  RotateMeta?: PhotoSSEMeta
}

export function handleRotatePhotoSSE(
  msg: SSEMessage,
): RotatePhotoCompleteEvent {
  const event: RotatePhotoCompleteEvent = {}
  if (msg.Response) {
    event.Response = JSON.parse(msg.Response.Properties)
  }
  if (msg.RotateMeta) {
    event.RotateMeta = JSON.parse(JSON.parse(msg.RotateMeta.Properties).PhotoID)
  }
  return event
}
