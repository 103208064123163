export const convertToMilitaryHour = (
  hour: number,
  meridiem: string,
): string => {
  if (meridiem === 'PM') {
    return hour === 12 ? '12' : (hour + 12).toString()
  }

  return hour === 12 ? '00' : hour.toString()
}
