import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse} from '../types'
import {ResourceID, Album} from '../../../types'
import {handleError} from '../handleError'

interface Params extends Pick<Album, 'season' | 'constraints'> {
  clientID: ResourceID
}

type ResolvedReturn = APIResponse<{constraintCount: number}>

export interface GetConstraintCount {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.getConstraintCount()'
const GENERIC_ERROR =
  'Something went wrong while updating your audience segments. Please try again.'

export const getConstraintCount: GetConstraintCount = async ({
  clientID,
  season,
  constraints,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      ClientID: Number(clientID),
      SeasonID: Number(season),
      AlbumConstraints: constraints.map((c) => ({
        Type: c.type,
        ConstraintID: Number(c.constraintID),
      })),
    }

    const response = await coreAlbums.getConstraintCount(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful Album Constraint Update')
    }

    resolvedReturn = {
      result: 'success',
      data: {
        constraintCount: response.data.ConstraintCount,
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
