import React, {useReducer, createContext, useContext} from 'react'
import {Micropost} from '../../../types'

interface State {
  hyperlink: 'inactive' | 'active' | 'disabled'
  enabledMedia: 'all' | 'image' | 'video'
  mediaControl: 'inactive' | 'imageUploader' | 'imageAdded' | 'video'
  isImageUploading: false | true
}

type Action =
  | {type: 'TOGGLE_HYPERLINK'}
  | {type: 'TOGGLE_IMAGE'}
  | {type: 'TOGGLE_VIDEO'}
  | {type: 'IMAGE_UPLOAD_ERROR'}
  | {type: 'IMAGE_UPLOAD_IN_PROGRESS'}
  | {type: 'IMAGE_UPLOAD_SUCCESS'}

type Dispatch = (action: Action) => void

const AttachmentControlsStateContext = createContext<State | undefined>(
  undefined,
)
const AttachmentControlsDispatchContext = createContext<Dispatch | undefined>(
  undefined,
)

const attachmentControlsReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'TOGGLE_HYPERLINK': {
      return {
        ...state,
        hyperlink: state.hyperlink === 'active' ? 'inactive' : 'active',
      }
    }
    case 'TOGGLE_IMAGE': {
      return {
        ...state,
        enabledMedia: state.enabledMedia === 'all' ? 'image' : 'all',
        mediaControl:
          state.enabledMedia === 'image' ? 'inactive' : 'imageUploader',
      }
    }
    case 'TOGGLE_VIDEO': {
      return {
        ...state,
        enabledMedia: state.enabledMedia === 'all' ? 'video' : 'all',
        mediaControl: state.enabledMedia === 'video' ? 'inactive' : 'video',
      }
    }
    case 'IMAGE_UPLOAD_ERROR': {
      return {
        ...state,
        isImageUploading: false,
      }
    }
    case 'IMAGE_UPLOAD_IN_PROGRESS': {
      return {
        ...state,
        isImageUploading: true,
      }
    }
    case 'IMAGE_UPLOAD_SUCCESS': {
      return {
        ...state,
        isImageUploading: false,
        mediaControl: 'imageAdded',
      }
    }

    default:
      throw new Error('Unhandled action')
  }
}

export const AttachmentControlsManager: React.FC<
  React.PropsWithChildren<{
    initial: Partial<{
      hyperlinkURL: Micropost['hyperlinkURL']
      videoURL: Micropost['videoURL']
      imageURL: Micropost['imageURL']
    }>
  }>
> = ({initial, children}) => {
  const initialState: State = {
    hyperlink: initial.hyperlinkURL ? 'active' : 'inactive',
    enabledMedia: 'all',
    mediaControl: 'inactive',
    isImageUploading: false,
  }

  if (initial.videoURL) {
    initialState.mediaControl = 'video'
    initialState.enabledMedia = 'video'
  } else if (initial.imageURL) {
    initialState.mediaControl = 'imageAdded'
    initialState.enabledMedia = 'image'
  }

  const [state, dispatch] = useReducer(attachmentControlsReducer, initialState)

  return (
    <AttachmentControlsStateContext.Provider value={state}>
      <AttachmentControlsDispatchContext.Provider value={dispatch}>
        {children}
      </AttachmentControlsDispatchContext.Provider>
    </AttachmentControlsStateContext.Provider>
  )
}

export const useAttachmentControlsState = (): State => {
  const context = useContext(AttachmentControlsStateContext)
  if (!context) {
    throw new Error(
      'useAttachmentControlsState must be used within a AttachmentControlsProvider',
    )
  }

  return context
}

export const useAttachmentControlsDispatch = (): React.Dispatch<Action> => {
  const context = useContext(AttachmentControlsDispatchContext)
  if (!context) {
    throw new Error(
      'useAttachmentControlsDispatch must be used within a AttachmentControlsProvider',
    )
  }

  return context
}
