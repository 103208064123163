import React, {
  useMemo,
  useEffect,
  useReducer,
  createContext,
  useContext,
} from 'react'

import {api} from '../../services/api'
import {handleProcessPhotoSSE} from '../../services/api/photos/processPhotoSSE'
import {} from '../../services/coreApi/photos'
// import {SSEMessage} from '../../services/coreApi/types'

import {useRequiredAuth} from '../../stores/AuthStore'
import {Album, Photo} from '../../types'
import {useAlbumDispatch} from './AlbumManager'

export const PAGE_SIZE = 100
/* *********************************************** */
interface State {
  status:
    | 'idle'
    | 'pending'
    | 'resolved'
    | 'rejected'
    | 'uploading'
    | 'processing'
  data: Photo[]
  error?: string
  hasMore: boolean
  currentPageNumber: number
  numUnprocessed: number
  failedProcessingFiles: string[]
}

type Action =
  | {
      type: 'FETCH_PHOTOS_INIT'
    }
  | {
      type: 'FETCH_PHOTOS_SUCCESS'
      payload: {
        data: Photo[]
        nextURL: string
        isFirstPage: boolean
      }
    }
  | {
      type: 'FETCH_PHOTOS_FAILURE'
      payload: {
        message: string
      }
    }
  | {
      type: 'MAYBE_FETCH_NEXT_PAGE'
    }
  | {
      type: 'REMOVE_PHOTO'
      payload: Photo['ID']
    }
  | {
      type: 'PROCESS_SSE'
      payload: ReturnType<typeof handleProcessPhotoSSE>
    }
  | {
      type: 'SET_PHOTO_DATA'
      payload: Photo[]
    }
  | {
      type: 'START_UPLOADING'
    }
  | {
      type: 'PROCESSING_PHOTOS'
      payload: number
    }
  | {
      type: 'RESET'
    }
  | {
      type: 'SORTING'
    }
  | {
      type: 'SET_FAILED_FILES'
      payload: string[]
    }

type StateContext = State & {
  fetchData(pageNumber: number, didCancel?: boolean): Promise<void>
  // processSSE: (msg: SSEMessage) => void
  resetPhotos: () => void
}

const AlbumPhotosStateContext = createContext<StateContext | undefined>(
  undefined,
)
const AlbumPhotosDispatchContext = createContext<
  React.Dispatch<Action> | undefined
>(undefined)

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'FETCH_PHOTOS_INIT': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'FETCH_PHOTOS_SUCCESS': {
      const photos: Photo[] = action.payload.isFirstPage
        ? [...action.payload.data]
        : [...state.data, ...action.payload.data]

      return {
        ...state,
        status: 'resolved',
        data: photos,
        hasMore: Boolean(action.payload.nextURL),
      }
    }
    case 'FETCH_PHOTOS_FAILURE': {
      return {
        ...state,
        status: 'rejected',
        error: action.payload.message,
      }
    }
    case 'MAYBE_FETCH_NEXT_PAGE': {
      if (!state.hasMore || state.status === 'pending') {
        return state
      }

      return {
        ...state,
        status: 'idle',
        currentPageNumber: state.currentPageNumber + 1, // Should trigger effect to fetch next page of data
      }
    }
    case 'REMOVE_PHOTO': {
      return {
        ...state,
        data: state.data.filter((photo) => photo.ID !== action.payload),
      }
    }
    // case 'PROCESS_SSE': {
    //   // const {
    //   // payload: {photo},
    //   // } = action

    //   // let {data} = state

    //   // only add a photo to the photo data if there isn't another page to fetch or we've added enough already to fill the first page of data
    //   // if (photo && !state.hasMore && data.length <= PAGE_SIZE) {
    //   //   data = [...state.data, photo]
    //   // }
    //   // console.log('process SSE', state)

    //   return {
    //     ...state,
    //     // data,
    //     numUnprocessed: state.numUnprocessed - 1,
    //   }
    // }
    case 'SET_PHOTO_DATA': {
      return {
        ...state,
        data: action.payload,
      }
    }
    case 'START_UPLOADING': {
      return {
        ...state,
        status: 'uploading',
      }
    }
    case 'PROCESSING_PHOTOS': {
      return {
        ...state,
        status: 'processing',
        numUnprocessed: action.payload,
      }
    }
    case 'SORTING': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'RESET': {
      return {
        ...state,
        status: 'idle',
        // data: [],
        hasMore: false,
        currentPageNumber: 1,
      }
    }
    case 'SET_FAILED_FILES': {
      return {
        ...state,
        failedProcessingFiles: action.payload,
      }
    }
    default:
      throw new Error('Unhandled action')
  }
}

interface Props {
  ID: Album['ID']
}

/* *********************************************** */
export const AlbumPhotosManager: React.FC<React.PropsWithChildren<Props>> = ({
  ID,
  children,
}) => {
  const {client} = useRequiredAuth()

  const albumDispatch = useAlbumDispatch()

  const initialState: State = {
    status: 'idle',
    data: [],
    hasMore: false,
    currentPageNumber: 1,
    numUnprocessed: 0,
    failedProcessingFiles: [],
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // Fetch Album Photos when mounted
  const fetchData = React.useCallback<StateContext['fetchData']>(
    async (pageNumber, didCancel = false) => {
      dispatch({type: 'FETCH_PHOTOS_INIT'})

      const response = await api.photos.fetchAlbumPhotos({
        clientID: client.ID,
        pageSize: PAGE_SIZE,
        pageNumber,
        albumID: ID,
      })

      if (!didCancel) {
        if (response.result === 'failure') {
          dispatch({
            type: 'FETCH_PHOTOS_FAILURE',
            payload: {message: 'Fetch Photos Failed'},
          })
        } else {
          dispatch({
            type: 'FETCH_PHOTOS_SUCCESS',
            payload: {
              data: response.data.results,
              nextURL: response.data.nextURL,
              isFirstPage: pageNumber === 1,
            },
          })
        }
      }
    },
    [client.ID, ID],
  )

  // Fetch photos
  useEffect(() => {
    let didCancel = false
    if (state.status === 'idle') {
      fetchData(state.currentPageNumber, didCancel)
    }

    return (): void => {
      didCancel = true
    }
  }, [state.status, state.currentPageNumber, fetchData])

  // Process a Photo Server Sent Event
  // const processSSE: StateContext['processSSE'] = React.useCallback(
  //   (msg) => {
  //     console.log('processSSE', msg)

  //     const result = handleProcessPhotoSSE({msg, clientID: client.ID})

  // TODO: see if this can be removed
  // if (ID === result.uploadSession.albumID && state.status !== 'uploading') {
  //   // Maybe check that the photo doesn't already existin in photo data? I've seen an overcount before so someone it must add photo by accident
  //   // albumDispatch({type: 'PROCESS_SSE', payload: result})
  //   // dispatch({type: 'PROCESS_SSE', payload: result})
  // }

  // if (ID === result.uploadSession.albumID) {
  //   // If this isn't the last SSE, keep processing
  //   if (state.numUnprocessed > 1) {
  //     albumDispatch({type: 'PROCESS_SSE', payload: result})
  //     dispatch({type: 'PROCESS_SSE', payload: result})
  //   } else {
  //     // reset everything
  //     albumDispatch({type: 'RESET'})
  //     dispatch({type: 'RESET'})
  //   }
  // }
  // },
  // [ID, client, state.status, albumDispatch],
  // )

  const resetPhotos: StateContext['resetPhotos'] = React.useCallback(() => {
    albumDispatch({type: 'RESET'})
    dispatch({type: 'RESET'})
  }, [albumDispatch])

  const stateReturn = useMemo(
    () => ({
      ...state,
      fetchData,
      // processSSE,
      resetPhotos,
    }),
    [state, resetPhotos, fetchData],
  )

  return (
    <AlbumPhotosStateContext.Provider value={stateReturn}>
      <AlbumPhotosDispatchContext.Provider value={dispatch}>
        {children}
      </AlbumPhotosDispatchContext.Provider>
    </AlbumPhotosStateContext.Provider>
  )
}

export const useAlbumPhotosState = (): StateContext => {
  const context = useContext(AlbumPhotosStateContext)
  if (!context) {
    throw new Error(
      'useAlbumPhotosState must be used within a AlbumPhotosStateContextProvider',
    )
  }

  return context
}

export const useAlbumPhotosDispatch = (): React.Dispatch<Action> => {
  const context = useContext(AlbumPhotosDispatchContext)
  if (!context) {
    throw new Error(
      'useAlbumPhotosDispatch must be used within a AlbumPhotosDispatchContextProvider',
    )
  }

  return context
}
