import React from 'react'
import {ConfirmationDialog} from '../components/ConfirmationDialog/ConfirmationDialog'
import {PropsOf} from '../types'

interface ConfirmationOptions {
  variant: 'danger'
  title: string
  description: string
  confirmButtonText?: string
  testID?: string
  onConfirm(): Promise<void>
  cancelButtonProps?: PropsOf<typeof ConfirmationDialog>['cancelButtonProps']
  confirmButtonProps?: PropsOf<typeof ConfirmationDialog>['confirmButtonProps']
}

type Confirmation = (options: ConfirmationOptions) => Promise<void>

const ConfirmationManagerContext = React.createContext<Confirmation>(
  Promise.reject,
)

// type UseConfirmation = (options: ConfirmationOptions) => Promise<void>

export const useConfirmation = (): Confirmation => {
  const context = React.useContext(ConfirmationManagerContext)
  if (context === undefined) {
    throw new Error(
      'useConfirmation must be used within a ConfirmationProvider',
    )
  }

  return context
}

export const ConfirmationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [currentOptions, setCurrentOptions] =
    React.useState<ConfirmationOptions | null>(null)

  const openConfirmation = React.useCallback(
    (options: ConfirmationOptions): Promise<void> => {
      setCurrentOptions(options)
      return Promise.resolve()
    },
    [],
  )

  const handleOnConfirm = async (): Promise<void> => {
    await currentOptions?.onConfirm()
    setCurrentOptions(null)
  }

  const handleOnClose = (): void => {
    setCurrentOptions(null)
  }

  return (
    <ConfirmationManagerContext.Provider value={openConfirmation}>
      {children}

      <ConfirmationDialog
        isOpen={Boolean(currentOptions)}
        title={(currentOptions && currentOptions.title) || 'Please Confirm'}
        description={
          (currentOptions && currentOptions.description) ||
          'Are you sure you want to proceed?'
        }
        confirmButtonText={
          (currentOptions && currentOptions.confirmButtonText) || 'Ok'
        }
        testID={(currentOptions && currentOptions.testID) || undefined}
        onConfirm={handleOnConfirm}
        onClose={handleOnClose}
        cancelButtonProps={currentOptions?.cancelButtonProps}
        confirmButtonProps={currentOptions?.confirmButtonProps}
      />
    </ConfirmationManagerContext.Provider>
  )
}
