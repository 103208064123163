import {http} from '../http'
import * as coreEzTexting from '../../coreApi/eztexting'
import {APIResponse} from '../types'
import {ReadCredentialsResponse, ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  clientID: ResourceID
}

type ResolvedReturn = APIResponse<ReadCredentialsResponse>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.readCredentials()'
const GENERIC_ERROR =
  'Something went wrong while getting your group. Please try again.'

export const readCredentials: ServiceMethod = async ({clientID}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const response = await coreEzTexting.readCredentials(http, {
      ClientID: parseInt(clientID, 10),
    })
    if (!response || !response.data) {
      throw new Error('Unsuccessful readCredentials')
    }
    resolvedReturn = {
      result: 'success',
      data: {
        clientID: response.data.ClientID.toString(),
        userName: response.data.UserName,
        password: response.data.Password,
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e, true)
  }

  return Promise.resolve(resolvedReturn)
}
