import React from 'react'
import {ReactQueryDevtools} from 'react-query/devtools'
import {AuthProvider} from '../stores/AuthStore'
import {ConfirmationProvider} from './ConfirmationManager'
import {SSEProvider} from './SSEProvider'

export const AppProviders: React.FC<React.PropsWithChildren> = ({children}) => (
  <AuthProvider>
    <SSEProvider>
      <ConfirmationProvider>
        {children}
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        )}
      </ConfirmationProvider>
    </SSEProvider>
  </AuthProvider>
)
