import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {GetRequest, SegmentResponse} from './types'

export interface Get<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const get: Get<GetRequest, SegmentResponse> = (http, params) => {
  const {ClientID, ID} = params

  const path = '/v0/segments/{id}'.replace('{id}', ID.toString())

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<SegmentResponse>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
