import {AxiosResponse} from 'axios'
import {RawSession, RawPagedCollectionResponse} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  clientID: string
  seasonID: number
  pageNumber: number
  pageSize: number
}

type FetchSessionsResponse = RawPagedCollectionResponse<RawSession>

export interface FetchSessions<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchSessions: FetchSessions<Params, FetchSessionsResponse> = (
  http,
  {clientID, seasonID, pageNumber, pageSize},
) =>
  http.get<FetchSessionsResponse>(
    `/v2/sessions?clientid=${clientID}&seasonid=${seasonID}&pagenumber=${pageNumber}&pagesize=${pageSize}`,
  )
