/* eslint-disable max-classes-per-file */
// https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
class ExtendableError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

export class EmptyResultError extends ExtendableError {}

export class NotAuthorizedError extends ExtendableError {}
