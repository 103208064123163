import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {CheckCredentialsResponse, CredentialsRequest} from './types'

export interface UpdateCredentials<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const updateCredentials: UpdateCredentials<
  CredentialsRequest,
  CheckCredentialsResponse
> = (http, params) => {
  const {ClientID} = params

  const path = '/v1/eztexting/credentials'

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<CheckCredentialsResponse>({
    method: 'PUT',
    url: `${path}${query}`,
    data: params,
  })
}
