import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {
  CreateAlbumUploadSessionRequest,
  CreateAlbumUploadSessionResponse,
} from '../types'

export interface CreateAlbumUploadSession<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const createAlbumUploadSession: CreateAlbumUploadSession<
  CreateAlbumUploadSessionRequest,
  CreateAlbumUploadSessionResponse
> = (http, params) => {
  const {ClientID, ID, AlbumID} = params

  const path = '/v1/albums/{albumid}/uploadsession/{id}'
    .replace('{id}', ID.toString())
    .replace('{albumid}', AlbumID.toString())

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<CreateAlbumUploadSessionResponse>({
    method: 'POST',
    url: `${path}${query}`,
    data: params,
  })
}
