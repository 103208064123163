import {decode} from 'he'
import {EzTextingGroup} from '../../../types'
import {GroupResponse} from '../../coreApi/eztexting/types'

// TODO: I don't think I'm a fan of this and think we need to avoid mixing types to suite a UI need
const getSyncDate = (date: string | null): Date | 'Not Synced' => {
  if (date) {
    return new Date(date)
  }
  return 'Not Synced'
}

export const transformGroup = (rawData: GroupResponse): EzTextingGroup => {
  const householdIDs: EzTextingGroup['ez']['householdIDs'] = []

  if (rawData.Household1Parent1) householdIDs.push('Household1Parent1')
  if (rawData.Household1Parent2) householdIDs.push('Household1Parent2')
  if (rawData.Household2Parent1) householdIDs.push('Household2Parent1')
  if (rawData.Household2Parent2) householdIDs.push('Household2Parent2')

  return {
    ID: rawData.ID.toString(),
    clientID: rawData.ClientID.toString(),
    ezTextingID: rawData.EzTextingID,
    ez: {
      name: decode(rawData.Name || ''),
      description: decode(rawData.Note || ''),
      householdIDs,
    },
    audienceIDs: rawData.SegmentIDs?.map((id) => id.toString()),
    dateModified: new Date(rawData.ModifiedDate),
    lastSynced: getSyncDate(rawData.LastSynchronized),
    syncing: rawData.Synchronizing,
    result: {
      resultCount: rawData.ResultCount,
      successCount: rawData.SuccessCount,
      failureCount: rawData.FailureCount,
    },
  }
}
