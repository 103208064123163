import {
  QueryFunction,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query'
import {useInfiniteQueryUntilEnd} from '../hooks/useInfiniteQueryUntilEnd'
import {http} from '../services/api/http'
import {fetchBunks} from '../services/coreApi/bunks'
import {useRequiredAuth} from '../stores/AuthStore'

type Bunk = {
  ID: string
  clientID: string
  name: string
  code: string
  isActive: boolean
  sortOrder: number
  areaID: string
  createdDate: Date
  modifiedDate: Date
}

type QueryFnData = {
  results: Bunk[]
  totalCount: number
  nextPage: string
}
type Client = ReturnType<typeof useRequiredAuth>['client'] // I think there is a better way with Generics, but don't have time to figure it out
type GetQueryOptions = (params: {
  client: Client
  season: number
}) => UseInfiniteQueryOptions<QueryFnData>
type QueryFn = (params: {
  client: Client
  season: number
}) => QueryFunction<QueryFnData>

const PAGE_SIZE = 100
const QUERY_STALE_TIME = 1000 * 60 * 30 // 30 minutes

const queryKeys = {
  all: () => [{resource: 'bunks'}] as const, // [{ resource: 'bunks'}]
  lists: () => [{...queryKeys.all()[0], type: 'list'}] as const, // [{ resource: 'bunks', type: 'list}]
  list: ({season}: {season: number}) =>
    [{...queryKeys.all()[0], type: 'list', season}] as const, // [{ resource: 'bunks', type: 'list, season: '2020'}]
}

const queryFn: QueryFn =
  ({client, season}) =>
  async ({pageParam = 1}) => {
    const response = await fetchBunks(http, {
      ClientID: Number(client.ID), // technically doesn't need to be apart of query key, but currently passing it for queryFn so kind of should be
      SeasonID: season,
      OrderAscending: true,
      OrderBy: 'Name',
      PageNumber: pageParam,
      PageSize: PAGE_SIZE,
    })

    const {Results, TotalCount, Next} = response.data

    return {
      results: Results.map((result) => ({
        ID: result.ID.toString(),
        clientID: result.ClientID.toString(),
        name: result.Name,
        code: result.Code,
        isActive: result.IsActive,
        sortOrder: result.SortOrder,
        areaID: result.AreaID.toString(),
        createdDate: new Date(result.CreatedDate),
        modifiedDate: new Date(result.ModifiedDate),
      })),
      totalCount: TotalCount,
      nextPage: Next,
    }
  }

const getQueryOptions: GetQueryOptions = ({client, season}) => ({
  queryKey: queryKeys.list({season}),
  queryFn: queryFn({client, season}),
  staleTime: QUERY_STALE_TIME,
  getNextPageParam: (lastPage, allPages) =>
    // If the last page has some real next URL string, then set the pageParam to the next page
    lastPage.nextPage !== '' ? allPages.length + 1 : undefined,
})

type UseBunks = (params: {
  season: number
}) => UseInfiniteQueryResult<QueryFnData>
export const useBunks: UseBunks = ({season}) => {
  const {client} = useRequiredAuth()

  return useInfiniteQueryUntilEnd<QueryFnData>(
    getQueryOptions({client, season}),
  )
}
