import {ORDERED_PHOTO_SORT_TYPES} from '../../../../views/AlbumView/types'
import {AlbumSortType} from '../../../../types'

// function signatures to setup conditional return types
// based on input type
export function translateAlbumSortType(input: number | null): AlbumSortType
export function translateAlbumSortType(input: AlbumSortType): number

export function translateAlbumSortType(
  input: number | AlbumSortType | null,
): number | AlbumSortType {
  if (input === null || input === undefined) {
    return 'Custom'
  }

  if (typeof input === 'number') {
    const result = ORDERED_PHOTO_SORT_TYPES.find((type) => type.value === input)
    if (result) return result.name

    throw new Error(`Value ${input} does not match any photo sort options`)
  }

  if (typeof input === 'string') {
    const result = ORDERED_PHOTO_SORT_TYPES.find((type) => type.name === input)
    if (result) return result.value

    throw new Error(`Name ${input} does not match any photo sort options`)
  }

  throw new Error(
    'Input does not match applicable type: number, AlbumSortType, null.',
  )
}
