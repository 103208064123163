import React from 'react'
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text,
} from '@chakra-ui/react'
import {log} from '@vanguard/logger'
import {ActionButton} from '../ActionButton/ActionButton'
import {Status} from '../../services/useTextMessaging'
import copy from './copy.json'

export interface SendFormRemindersModalProps {
  isOpen: boolean
  onClose: (shouldRefresh?: boolean) => void
  recipientsCount?: number
  handleSend: () => void
  sendStatus: Status
}

export const SendFormRemindersModal: React.FC<SendFormRemindersModalProps> = ({
  isOpen,
  recipientsCount,
  onClose,
  handleSend,
  sendStatus,
}) => {
  const initialFocusRef = React.useRef(null)

  const handleOnClose = (): void => {
    log.breadcrumb('Close Modal')
    onClose()
  }

  const statusPending = sendStatus === 'pending'

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      size='xl'
      closeOnOverlayClick={false}
      initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent
        borderRadius={['0px', '3px']}
        minHeight={['100%', 'unset']}
        my={[0, '3.75rem']}>
        <ModalHeader
          borderBottom='1px'
          borderBottomColor='#CED2D5'
          width='100%'
          paddingRight={8}>
          <Flex
            flexDir='row'
            width='100%'
            justifyContent='center'
            alignItems='center'>
            <Box>Message Confirmation</Box>
            <ModalCloseButton
              justifySelf='flex-end'
              marginTop='3px'
              marginRight='14px'
              onClick={(): void => onClose()}
            />
          </Flex>
        </ModalHeader>
        <ModalBody paddingX={8}>
          <Text mt='20px' mb='5px'>
            You are about to send {recipientsCount} text messages.
          </Text>
          <Text mb='35px'>
            This will cost you{' '}
            <strong>
              {recipientsCount} credit
              {recipientsCount && recipientsCount > 1 && 's'}
            </strong>
            .
          </Text>
        </ModalBody>
        <ModalFooter
          borderTop='1px'
          borderTopColor='#CED2D5'
          paddingRight={['1.5rem', 8]}
          paddingY={2}>
          <Flex
            flexDir='row'
            justifyContent={['center', 'flex-end']}
            width='100%'>
            <ActionButton
              onClick={handleSend}
              width={['100%', 'unset']}
              isDisabled={statusPending}>
              {statusPending ? (
                <>
                  <Spinner size='sm' mr='10px' />
                  {copy.buttons.confirm.textPending}
                </>
              ) : (
                copy.buttons.confirm.text
              )}
            </ActionButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
