import React from 'react'
import {Box, Button} from '@chakra-ui/react'
import {CustomIcon} from '../CustomIcon/CustomIcon'

export interface ControlProps {
  icon?: string
  textColor?: string
  ariaLabel?: string
  children?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isDisabled?: boolean
}

export const PageControl: React.FC<ControlProps> = ({
  icon,
  textColor = '#000',
  ariaLabel,
  onClick,
  children,
  isDisabled,
}) => {
  const getIcon = (): React.ReactElement | null => {
    if (icon) {
      return <CustomIcon name={icon} boxSize='1.25em' mr={['0', '0.5em']} />
    }
    return null
  }

  return (
    <Button
      variant='link'
      color={textColor}
      height='2.5em'
      fontWeight='500'
      onClick={onClick}
      data-testid={`control-${icon}`}
      aria-label={ariaLabel}
      flexGrow={1}
      justifyContent='flex-end'
      isDisabled={isDisabled}>
      {getIcon()}
      <Box display={['none', 'initial']}>{children}</Box>
    </Button>
  )
}
