interface TransformImageURL {
  (url: string, type: 'micropost'): string
}

export const transformImageURL: TransformImageURL = (url, type) => {
  if (url === '') {
    return url
  }
  const parts = url.split('/')

  let resizeWidth: number

  switch (type) {
    case 'micropost':
      resizeWidth = 800
      break
    default:
      resizeWidth = 1000
  }

  const handle = parts[parts.length - 1]
  const adjust = `resize=width:${resizeWidth}/rotate=deg:exif/cache=expiry:max/${handle}`
  return url.replace(handle, adjust)
}
