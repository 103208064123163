import React from 'react'
import {Flex, Text} from '@chakra-ui/react'

export const ItemText: React.FC<
  {
    text: string
  } & React.ComponentProps<typeof Flex>
> = ({text, ...rest}) => (
  <Flex {...rest}>
    <Text fontSize='md' lineHeight='base' color='#333' wordBreak='break-word'>
      {text}
    </Text>
  </Flex>
)
