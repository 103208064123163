import React, {CSSProperties} from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  BoxProps,
  PlacementWithLogical,
} from '@chakra-ui/react'
import {CustomIcon} from '../CustomIcon/CustomIcon'

interface Props {
  style?: CSSProperties
  hover?: BoxProps
  focus?: BoxProps
  placement?: PlacementWithLogical
}

export const SnowmanMenu: React.FC<React.PropsWithChildren<Props>> = ({
  style,
  hover,
  focus,
  placement,
  children,
}) => {
  const styles: CSSProperties = {
    position: 'absolute',
    color: 'white',
    borderRadius: '50%',
    marginLeft: '-2em',
    marginTop: '-0.15em',
    padding: '0.25em',
    ...style,
  }

  const hoverStyles = {
    backgroundColor: 'rgba(255, 255, 255, 50%)',
    color: 'gray.400',
    ...hover,
  }

  const focusStyles = {
    outline: 'none',
    border: 'none',
    ...focus,
  }

  const attributes = placement ? {placement} : {}

  return React.Children.count(children) > 0 ? (
    <Menu {...attributes}>
      <MenuButton
        style={styles}
        _hover={hoverStyles}
        _focus={focusStyles}
        aria-label='more actions'>
        <CustomIcon name='drag-handle' boxSize='6' />
      </MenuButton>
      <MenuList>{children}</MenuList>
    </Menu>
  ) : null
}
