import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {ErrorLoggingConfig} from './types'

export const init = ({
  onError,
  ...clientErrorConfig
}: ErrorLoggingConfig): void => {
  const errorLoggingConfig: ErrorLoggingConfig = {
    collectUserIp: false,
    enabledReleaseStages: ['production', 'test1'],
    // enabledReleaseStages: ['production', 'test1', 'development'],
    plugins: [new BugsnagPluginReact()],
    onError,
    maxBreadcrumbs: 100,
    ...clientErrorConfig,
  }

  Bugsnag.start(errorLoggingConfig)
}
