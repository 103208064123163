import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {CreditsRequest, CreditsResponse} from './types'

export interface FetchCredits<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchCredits: FetchCredits<CreditsRequest, CreditsResponse> = (
  http,
  params,
) => {
  const {ClientID} = params

  const path = `/v1/eztexting/credits?clientid=${ClientID}`

  return http.request<CreditsResponse>({
    method: 'GET',
    url: path,
  })
}
