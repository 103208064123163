import {AxiosResponse} from 'axios'
import {RawClientUser} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  username: string
  password: string
  origin: 'system' | 'campanion'
}

export interface RawLoginResponse {
  Status: 'Failure' | 'Success'
  ClientUsers: RawClientUser[]
}
export interface Login<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const login: Login<Params, RawLoginResponse> = (
  http,
  {username, password, origin},
) =>
  http.post('/v1/auth/login', {
    Username: username,
    Password: password,
    Origin: origin,
  })
