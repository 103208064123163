import {AxiosResponse} from 'axios'
import {RawMicropost, RawPagedCollectionResponse} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  clientID: string
  pageNumber: number
  pageSize: number
}

type FetchMicropostsResponse = RawPagedCollectionResponse<RawMicropost>

export interface FetchMicroposts<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchMicroposts: FetchMicroposts<
  Params,
  FetchMicropostsResponse
> = (http, {clientID, pageNumber, pageSize}) =>
  http.get<FetchMicropostsResponse>(
    `/v1/microposts?clientid=${clientID}&pagenumber=${pageNumber}&pagesize=${pageSize}`,
  )
