import React, {useState, useEffect} from 'react'
import {Flex, Divider, useDisclosure} from '@chakra-ui/react'
import moment from 'moment'
import {Column} from 'react-table'

import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {
  ActionButton,
  BlankSlate,
  LoadingState,
  PageHeader,
  Table,
  Visible,
} from '../../components'
import {useRequiredAuth} from '../../stores/AuthStore'
import {fetchSegments} from '../../services/api/segments/fetchSegments'
import {SegmentGroup} from '../../types'
import {useConfirmation} from '../../app/ConfirmationManager'
import {deleteSegment} from '../../services/api/segments/deleteSegment'
import {useStatusToast} from '../../app/ToastManager'
import {ComposeAudienceModal} from './ComposeAudienceModal/ComposeAudienceModal'
import {columnSort} from '../../utils/simpleSort'
import copy from './copy.json'

const PAGE_DESCRIPTION =
  'An audience is a set of users grouped by similar criteria. Use audiences to more effectively communicate to your customers.'
const DATE_MODIFIED_ACCESSOR = 'dateModified'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatDateModified = (cell: any): JSX.Element => (
  <>{moment(cell.value).format('MM/DD/YYYY, LT')}</>
)

export const AudiencesView: React.FC<React.PropsWithChildren> = () => {
  const [isEmpty, setIsEmpty] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedAudience, setSelectedAudience] = useState<SegmentGroup | null>(
    null,
  )
  const {client} = useRequiredAuth()
  const [audiences, setAudiences] = useState<SegmentGroup[]>([])
  const [sort, setSort] = useState([{id: 'name', desc: false}])
  const composeModalDisclosure = useDisclosure()

  const showConfirmation = useConfirmation()
  const {toast} = useStatusToast()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await fetchSegments({
        clientID: client.ID,
        pageSize: 100,
        all: true,
      })
      if (response.result === 'success') {
        setIsLoading(false)
        setAudiences(response.data)
      }
    }

    fetchData()
  }, [client.ID, setAudiences])

  const openDialog = (group: SegmentGroup | null): void => {
    setSelectedAudience(group)
    composeModalDisclosure.onOpen()
  }

  const onAdd = (): void => {
    openDialog(null)
  }

  const handleOnEditClose = async (
    index: number,
    audience: SegmentGroup,
    success: boolean,
  ): Promise<void> => {
    if (success) {
      setAudiences((auds) => {
        if (index === -1) return auds
        return [...auds.slice(0, index), audience, ...auds.slice(index + 1)]
      })
      toast.success('Audience successfully updated.')
    } else {
      toast.error(
        'Something went wrong updating the audience. Please try again.',
      )
    }
  }

  const onEdit = (ID: SegmentGroup['ID']): void => {
    const audienceIndex = audiences.findIndex((a) => a.ID === ID)
    if (audienceIndex !== undefined) {
      const audience = {...audiences[audienceIndex]}
      openDialog(audience)
    }
  }

  const onSuccessfulDeletion = (ID: SegmentGroup['ID']): void => {
    setAudiences(audiences.filter((audience) => audience.ID !== ID))
    toast.success('Audiences successfully deleted.')
  }

  const onDeletionConfirmed = async (ID: SegmentGroup['ID']): Promise<void> => {
    const deletion = await deleteSegment({clientID: client.ID, ID})

    if (deletion.result === 'success') {
      onSuccessfulDeletion(ID)
    } else {
      const GENERIC_DELETE_ERROR =
        'Something went wrong deleting the audience. Please try again.'

      toast.error(deletion.error?.message || GENERIC_DELETE_ERROR)
    }
  }

  const onDelete = (ID: SegmentGroup['ID']): void => {
    showConfirmation({
      variant: 'danger',
      title: copy.confirmations.delete.title,
      description: copy.confirmations.delete.description,
      confirmButtonText: copy.confirmations.delete.buttonConfirmation,
      testID: 'delete-audience',
      onConfirm: (): Promise<void> => onDeletionConfirmed(ID),
    })
  }

  const onSuccessfulCreation = (audience: SegmentGroup): void => {
    setSort([{id: DATE_MODIFIED_ACCESSOR, desc: true}])
    setAudiences([audience, ...audiences])
    toast.success(`${audience.name} created successfully.`)
  }

  const handleComposeClose = (audience?: SegmentGroup): void => {
    if (audience) {
      const index = audiences.findIndex((a) => a.ID === audience.ID)
      if (index === -1) {
        onSuccessfulCreation(audience)
      } else {
        handleOnEditClose(index, audience, true)
      }
    }

    composeModalDisclosure.onClose()
  }

  const pageHeader = (
    <PageHeader
      title='Audiences'
      logContext='AudiencesView'
      description={PAGE_DESCRIPTION}
      callToAction={
        <ActionButton width={['100%', 'unset']} onClick={onAdd}>
          {copy.buttons.newAudience}
        </ActionButton>
      }
    />
  )
  const columns = React.useMemo<Column<SegmentGroup>[]>(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        width: '80%',
        minWidth: 130,
        style: {
          textAlign: 'left',
          flexGrow: 1,
        },
        cellStyle: {
          flexGrow: 1,
        },
        sortType: columnSort,
      },
      {
        id: DATE_MODIFIED_ACCESSOR,
        Header: 'Date Modified',
        accessor: DATE_MODIFIED_ACCESSOR,
        width: '20%',
        minWidth: 110,
        style: {
          textAlign: 'left',
        },
        Cell: formatDateModified,
        sortType: 'datetime',
      },
    ],
    [],
  )

  React.useMemo(() => {
    setIsEmpty(audiences.length === 0)
  }, [audiences.length])

  return (
    <PageTemplate
      header={pageHeader}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Flex direction='column' px='.75em' py={1}>
          <Divider
            mb='4'
            opacity={1}
            border-bottom='1px solid'
            mx='0.2em'
            mt={2}
          />
          <Visible when={isLoading}>
            <LoadingState>Loading audiences...</LoadingState>
          </Visible>
          <Visible when={!isLoading && isEmpty}>
            <BlankSlate iconName='add-group'>
              Create your first audience.
            </BlankSlate>
          </Visible>
          <Visible when={!isLoading && !isEmpty}>
            <Table<SegmentGroup>
              name='Audiences'
              sortBy={sort}
              setSort={setSort}
              columns={columns}
              data={audiences}
              pageSize={10}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </Visible>
        </Flex>
        <Visible when={composeModalDisclosure.isOpen}>
          <ComposeAudienceModal
            onClose={handleComposeClose}
            initial={selectedAudience}
            isOpen
          />
        </Visible>
      </>
    </PageTemplate>
  )
}
