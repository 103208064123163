import React from 'react'
import {Modal as UIModal} from '@chakra-ui/react'

import type {FocusableElement} from '@chakra-ui/utils'
import {PropsOf} from '../../../types'
import {createContextHelper} from '../../../utils/createContextHelper'

export type Step = 'compose' | 'schedule' | 'published' | 'segment'

interface ModalContext {
  currentStep: Step
  setCurrentStep: React.Dispatch<React.SetStateAction<Step>>
  initialFocusRef: React.RefObject<FocusableElement> | undefined
}

const [ModalContextProvider, useModalContext] =
  createContextHelper<ModalContext>()

export {useModalContext}

export const ComposeAlbumModalContext: React.FC<PropsOf<typeof UIModal>> = ({
  children,
  initialFocusRef,
  onClose,
  ...rest
}) => {
  const [currentStep, setCurrentStep] = React.useState('compose' as Step)

  const value = {
    currentStep,
    setCurrentStep,
    initialFocusRef,
  }

  return (
    <ModalContextProvider value={value}>
      <UIModal
        {...rest}
        size={currentStep === 'segment' ? '4xl' : 'xl'}
        onClose={onClose}
        initialFocusRef={initialFocusRef}>
        {children}
      </UIModal>
    </ModalContextProvider>
  )
}
