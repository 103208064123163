import React from 'react'
import {Link, Flex, Spinner, Text} from '@chakra-ui/react'

import {ActionButton, CustomIcon} from '../../components'

import copy from './copy.json'

interface CalculateCreditsDisplayFooterProps {
  isStatusPending: boolean
  sendButtonDisabled: boolean
  calculateButtonDisabled: boolean
  lastMessageSentDetails: string | null
  handleFetchCreditsData: () => void
  handleOnOpen: () => void
}

export const CalculateCreditsDisplayFooter: React.FC<
  CalculateCreditsDisplayFooterProps
> = ({
  isStatusPending: statusPending,
  sendButtonDisabled,
  calculateButtonDisabled,
  lastMessageSentDetails,
  handleFetchCreditsData,
  handleOnOpen,
}) => (
  <Flex
    justifyContent={['flex-end', 'space-between']}
    direction={['column-reverse', 'column', 'row']}
    width='100%'>
    <Flex
      alignItems='center'
      justifyContent={['center', 'unset']}
      mt={['25px', 'unset']}
      mb={['10px', 'unset']}
      flexGrow={4}>
      <Link isExternal href={copy.authorized.linksAndButtons.eztextingUrl}>
        <CustomIcon
          name='launch'
          marginTop='-4px'
          marginRight='0.5em'
          boxSize='1.5em'
        />
        {copy.authorized.linksAndButtons.openEzTexting}
      </Link>
    </Flex>
    <Flex direction={['column', 'column', 'row']}>
      {lastMessageSentDetails && (
        <Text alignSelf='center' mr={4}>
          {lastMessageSentDetails}
        </Text>
      )}
      <Flex
        justifyContent={['flex-start', 'flex-end']}
        textAlign='right'
        alignItems='center'>
        <ActionButton
          isDisabled={statusPending || calculateButtonDisabled}
          onClick={handleFetchCreditsData}
          variant='outline'
          minW={['100%', '124px']}>
          {statusPending ? (
            <>
              <Spinner size='sm' mr='10px' />
              {copy.authorized.linksAndButtons.fetching}
            </>
          ) : (
            copy.authorized.linksAndButtons.calculateCredits
          )}
        </ActionButton>
      </Flex>
      <Flex
        justifyContent={['flex-start', 'flex-end']}
        textAlign='right'
        alignItems='center'>
        <ActionButton
          isDisabled={sendButtonDisabled}
          onClick={handleOnOpen}
          minW={['100%', '124px']}
          ml={['unset', 'unset', '20px']}
          mt={['10px', '10px', 'unset']}>
          {copy.authorized.linksAndButtons.send}
        </ActionButton>
      </Flex>
    </Flex>
  </Flex>
)
