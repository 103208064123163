import React from 'react'
import {
  Checkbox,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Textarea,
} from '@chakra-ui/react'

import copy from '../copy.json'
import {useStatusToast} from '../../../app/ToastManager'
import {MAX_SMS_CHARACTERS} from '../../../constants'
import {useCreateDynamicLink} from '../../../services/useCreateDynamicLink'
import {appTheme} from '../../../app/theme'

export const DEFAULT_MESSAGE_TEXT = `${copy.authorized.composeMessage.defaultMessageText}`

interface Props {
  messageText: string
  onChange: React.Dispatch<React.SetStateAction<string>>
  isMessageValid: boolean
  setIsMessageValid: React.Dispatch<React.SetStateAction<boolean>>
}

export const ComposeMessage: React.FC<React.PropsWithChildren<Props>> = ({
  messageText,
  onChange,
  isMessageValid,
  setIsMessageValid,
}) => {
  // useEffect to invoke getUrl on mount
  const {
    dynamicLink,
    getDynamicLink,
    status: dynamicLinkStatus,
  } = useCreateDynamicLink()

  const {toast} = useStatusToast()

  const [includeLinkWithMessage, toggleIncludeLinkWithMessage] =
    React.useState(true)

  React.useEffect(() => {
    if (dynamicLinkStatus === 'failure') {
      toast.error(
        'There was a problem generating your reminder link. Refresh the page and try again.',
      )
    }
  }, [dynamicLinkStatus, toast])

  React.useEffect(() => {
    if (!dynamicLink) getDynamicLink('forms')
  }, [getDynamicLink, dynamicLink])

  const [invalidMessage, setInvalidMessage] = React.useState('')

  const maxMessageLength = includeLinkWithMessage
    ? MAX_SMS_CHARACTERS - (dynamicLink ? dynamicLink.length + 1 : 0)
    : MAX_SMS_CHARACTERS

  const editableMessageText = dynamicLink
    ? messageText.replace(new RegExp(` ${dynamicLink}`, 'g'), '')
    : messageText

  // handle changing messageText after initial fetch
  React.useEffect(() => {
    if (dynamicLink && !editableMessageText.includes(dynamicLink)) {
      onChange(
        includeLinkWithMessage
          ? `${editableMessageText} ${dynamicLink}`
          : editableMessageText,
      )
    }
  }, [dynamicLink, includeLinkWithMessage, onChange, editableMessageText])

  const getTotalMessageLength = (message: string): number => {
    const {length: newLinesCount} = message.match(/\r?\n/g) || []

    return message.length + newLinesCount
  }

  const underCharacterMax =
    getTotalMessageLength(editableMessageText) <= maxMessageLength

  // This lets us conditionalize the style, but also let the defaults work
  const textAreaProps = isMessageValid
    ? {
        lineHeight: '24px',
      }
    : {
        lineHeight: '24px',
        borderColor: appTheme.colors.error[500],
        borderWidth: 2,
        _focus: {
          zIndex: 1,
          boxShadow: `0 0 0 1px ${appTheme.colors.error[500]}`,
          borderColor: appTheme.colors.error[500],
        },
        _hover: {
          borderColor: appTheme.colors.error[500],
        },
      }

  const updateMessage = (newMessage: string, appendLink: boolean): void => {
    // Has length
    if (getTotalMessageLength(newMessage) > 0) {
      const containsOnlyValidCharacters =
        /^[A-Za-z0-9=.,:;!?()+\-_/@$#&%'"\s]+$/.test(newMessage)
      // Has valid characters
      if (containsOnlyValidCharacters) {
        const newMaxLength = appendLink
          ? MAX_SMS_CHARACTERS - (dynamicLink ? dynamicLink.length + 1 : 0)
          : MAX_SMS_CHARACTERS

        // And isn't too long
        if (getTotalMessageLength(newMessage) <= newMaxLength) {
          // All is good
          setInvalidMessage('')
          setIsMessageValid(true)
        } else {
          setInvalidMessage(copy.authorized.composeMessage.invalidOverTextLimit)
          setIsMessageValid(false)
        }
      } else {
        setInvalidMessage(
          copy.authorized.composeMessage.invalidSpecialCharacters,
        )
        setIsMessageValid(false)
      }
    } else {
      setInvalidMessage(copy.authorized.composeMessage.invalidNoText)
      setIsMessageValid(false)
    }

    onChange(appendLink ? `${newMessage} ${dynamicLink}` : newMessage)
  }

  const handleMessageTextChange = ({
    currentTarget: {value},
  }: React.ChangeEvent<HTMLTextAreaElement>): void => {
    // value = value.replace(/[\r\n]/g, '') // prevent new lines

    updateMessage(value, includeLinkWithMessage)
  }

  const handleAppendLinkToggle = async (): Promise<void> => {
    toggleIncludeLinkWithMessage(!includeLinkWithMessage)

    // display spinner if link is not in provider already, and includeLinkWithMessage truthy
    updateMessage(editableMessageText, !includeLinkWithMessage)
  }

  return (
    <Flex direction='column' w={['100%']}>
      <Text fontSize='lg' fontWeight={500} py={4} mb='-40px'>
        Text message
      </Text>

      <Flex direction='column'>
        <Tabs align='end' colorScheme='secondary'>
          <TabList
            style={{
              borderBottom: '2px solid rgb(223,232,237)',
            }}>
            <Tab
              style={{
                fontSize: '0.9rem',
                paddingBottom: '0.2rem',
                fontWeight: 500,
              }}>
              Edit
            </Tab>
            <Tab
              style={{
                fontSize: '0.9rem',
                paddingBottom: '0.2rem',
                fontWeight: 500,
              }}>
              Preview
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel p={0}>
              <Textarea
                name='content'
                placeholder={copy.authorized.composeMessage.textPlaceholder}
                value={editableMessageText}
                onChange={handleMessageTextChange} // Might want to debouce this
                resize='none'
                mt={2}
                minHeight='5rem'
                {...textAreaProps}
              />
            </TabPanel>

            <TabPanel p={0}>
              <Flex
                borderRadius={4}
                border='1px solid #DFE8ED'
                mt={2}
                bg='#EEE'
                wordBreak='break-word'
                {...textAreaProps}>
                <Text
                  data-testid='preview-text'
                  p={2}
                  px={4}
                  textAlign='left'
                  minHeight='5rem'>
                  {
                    messageText /* messageText always includes the url if its added => except for first toggle! */
                  }
                </Text>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>

        {invalidMessage && (
          <Text mt={1} color={appTheme.colors.error[500]}>
            {invalidMessage}
          </Text>
        )}
      </Flex>

      <Flex
        mt={1}
        mb={3}
        direction={['column', 'row']}
        alignItems={['flex-start', 'flex-end']}>
        <Text
          color={underCharacterMax ? '#69747D' : appTheme.colors.error[500]}
          fontWeight={underCharacterMax ? 'auto' : 'bold'}>
          {`${getTotalMessageLength(editableMessageText)}/${maxMessageLength}`}
        </Text>
        <Flex flex='1' justify='flex-end' align='center'>
          <Checkbox
            mr={2}
            data-testid='append-link-checkbox'
            isChecked={includeLinkWithMessage}
            onChange={handleAppendLinkToggle}
            colorScheme='secondary'
          />
          {copy.authorized.composeMessage.includeLinkCheckboxLabel}
        </Flex>
      </Flex>
    </Flex>
  )
}
