import React from 'react'
import moment, {Moment} from 'moment'
import {Flex, Box, Text, useBreakpointValue} from '@chakra-ui/react'
import {TimeIcon} from '@chakra-ui/icons'
import Select, {components} from 'react-select'

import {PropsOf} from '../../../types'

interface Props extends Omit<PropsOf<typeof Flex>, 'onChange'> {
  value: Moment
  onChange(militaryTime: string): void
  minuteStep: number
}

const TimeSetIcon: React.FC<React.PropsWithChildren> = () => (
  <TimeIcon mt='11px' mb='11px' mr='10px' ml='10px' />
)

const DropdownIndicator: React.FC<
  PropsOf<typeof components.DropdownIndicator>
> = (props) => (
  <components.DropdownIndicator {...props}>
    <TimeSetIcon />
  </components.DropdownIndicator>
)

type TimeOption = {
  value: string
  label: string
}

const isTimeOption = (obj: object): obj is TimeOption =>
  'value' in obj && 'label' in obj

export const PublishTimePicker: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  minuteStep,
  ...rest
}) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})

  const items = React.useMemo(() => {
    const moment1 = moment('2000-01-01')
    const moment2 = moment('2000-01-02')

    const out = new Array<TimeOption>()

    while (moment1.isBefore(moment2)) {
      out.push({
        value: moment1.format('hh:mm A'),
        label: moment1.format('hh:mm A'),
      })

      moment1.add(minuteStep, 'minute')
    }

    return out
  }, [minuteStep])

  const customStyles: PropsOf<typeof Select>['styles'] = {
    control: (provided) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '39px',
      height: '39px',
      marginTop: '4px',
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: '39px',
      padding: '0 6px',
    }),

    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '39px',
    }),
    menu: (provided) => ({
      ...provided,
      ...(isMobile && {height: '117px'}),
    }),
    menuList: (provided) => ({
      ...provided,
      ...(isMobile && {height: '117px'}),
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...(isMobile && {height: '117px'}),
    }),
  }

  const selectedItem = items.find(
    (option) =>
      option.value === value.format('hh:mm A') &&
      option.label === value.format('hh:mm A'),
  )

  const onTimeChange = (selectedOption: unknown): void => {
    if (selectedOption && typeof selectedOption === 'object') {
      if (isTimeOption(selectedOption)) {
        const testMoment = moment(
          `2000-01-01 ${selectedOption.value}`,
          'YYYY-MM-DD hh:mm A',
        )
        onChange(testMoment.format('HH:mm'))
      }
    }
  }

  return (
    <Flex direction='column' {...rest}>
      <Text fontWeight='bold' pt={4} fontSize='16px'>
        Time
      </Text>
      <Box data-testid='time-interval-select'>
        <Select
          classNamePrefix='select'
          options={items}
          components={{
            DropdownIndicator,
          }}
          styles={customStyles}
          onChange={onTimeChange}
          isClearable={false}
          placeholder='Select a Time'
          value={selectedItem}
        />
      </Box>
    </Flex>
  )
}
