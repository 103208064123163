import React from 'react'
import {Flex, Link, Text} from '@chakra-ui/react'

import copy from './copy.json'

interface CalculateCreditsDisplayProps {
  creditsBalance: number | null
  creditsNeeded?: number
  insufficientCredits?: boolean
  noRecipientsReturned?: boolean
}

export const CalculateCreditsDisplay: React.FC<
  CalculateCreditsDisplayProps
> = ({
  creditsBalance,
  creditsNeeded,
  insufficientCredits,
  noRecipientsReturned,
}) => (
  <Flex data-testid='calculate-credits-display' direction='column' minW='100%'>
    <Flex direction='column'>
      <Text fontSize='lg' fontWeight={500}>
        {copy.authorized.calculateCreditsDisplay.header}
      </Text>
      <Text fontSize='sm' color='#69747D'>
        {copy.authorized.calculateCreditsDisplay.subHeader}
      </Text>
    </Flex>
    <Flex justifyContent='space-between' width='100%'>
      <Flex alignItems='flex-end'>
        {noRecipientsReturned && (
          <Text color='Red' justifySelf='flex-end'>
            {copy.authorized.calculateCreditsDisplay.noRecipients}
          </Text>
        )}
      </Flex>
      <Flex>
        <Flex direction='column' alignItems='flex-end'>
          <Text>{copy.authorized.calculateCreditsDisplay.yourCredits}</Text>
          <Text fontWeight={600} color={insufficientCredits ? 'Red' : 'unset'}>
            {copy.authorized.calculateCreditsDisplay.creditsNeeded}
          </Text>
          {insufficientCredits && (
            <Link
              href='http://app.campmindertexting.com/'
              isExternal
              fontWeight={500}
              textDecoration='underline'>
              {copy.authorized.calculateCreditsDisplay.purchaseCredits}
            </Link>
          )}
        </Flex>
        <Flex direction='column' alignItems='flex-end'>
          <Text data-testid='credits-balance' textAlign='right' pl={5}>
            {creditsBalance === null ? '--' : creditsBalance}
          </Text>
          <Text
            data-testid='credits-needed'
            fontWeight={600}
            textAlign='right'
            pl={5}
            color={insufficientCredits ? 'Red' : 'unset'}>
            {creditsNeeded || '--'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)
