import React from 'react'
import {Box} from '@chakra-ui/react'
import * as filestack from 'filestack-js'
import './filestackOverrides.css'

import {FILESTACK_MP_API_KEY} from '../../../constants'

const FS_FILE_RETRY = 2
const FS_FILE_RETRY_TIMEOUT = 60000
const FS_FILE_INTELLIGENT_CHUCK_SIZE = 1 * 1024 * 1024 // 1 MB
const FS_MAX_FILES_COUNT = 1
const FS_MAX_FILE_SIZE = 1024 * 1024 * 20 // 20971520 === 20MB

const defaultOptions: filestack.PickerOptions = {
  displayMode: filestack.PickerDisplayMode.dropPane,
  fromSources: ['local_file_system'],
  container: 'divDropPane',
  accept: ['image/jpeg', 'image/png'],
  uploadConfig: {
    retry: FS_FILE_RETRY,
    timeout: FS_FILE_RETRY_TIMEOUT,
    intelligent: true,
    intelligentChunkSize: FS_FILE_INTELLIGENT_CHUCK_SIZE,
  },
  customText: {
    'Drag and Drop, Copy and Paste Files': 'Upload Photo',
  },
  allowManualRetry: true, // Prevent modal close on upload failure and allow users to retry.
  disableTransformer: true,
  maxFiles: FS_MAX_FILES_COUNT,
  maxSize: FS_MAX_FILE_SIZE,
  uploadInBackground: false,
}

interface Props {
  onFileUploadFinished: NonNullable<
    filestack.PickerOptions['onFileUploadFinished']
  >
  onFileUploadFailed: NonNullable<filestack.PickerOptions['onFileUploadFailed']>
  onFileUploadStarted: NonNullable<
    filestack.PickerOptions['onFileUploadStarted']
  >
}

export const SimplePhotoUploader: React.FC<React.PropsWithChildren<Props>> =
  React.memo(
    ({onFileUploadFinished, onFileUploadFailed, onFileUploadStarted}) => {
      const pickerOptions = React.useMemo(
        () => ({
          ...defaultOptions,
          onFileUploadFinished,
          onFileUploadFailed,
          onFileUploadStarted,
        }),
        [onFileUploadFinished, onFileUploadFailed, onFileUploadStarted],
      )

      const fileStackClient = React.useRef<filestack.Client>(
        filestack.init(FILESTACK_MP_API_KEY),
      )

      React.useEffect(() => {
        const picker = fileStackClient.current.picker(pickerOptions)
        picker.open()

        return (): void => {
          picker.close()
        }
      }, [pickerOptions])

      return <Box data-testid='divDropPane' id='divDropPane' />
    },
  )

SimplePhotoUploader.displayName = 'SimplePhotoUploader'
