import React, {FC, ReactElement, useState} from 'react'
import {Flex} from '@chakra-ui/react'
import {TableInstance} from 'react-table'

import {appTheme} from '../../app/theme'
import {PageSelector} from './PageSelector'
import {Visible} from '../Visible/Visible'
import {CustomIcon} from '../CustomIcon/CustomIcon'

interface PaginationProps<T extends object> {
  table: TableInstance<T>
}

interface SlidingPages<T extends object> extends PaginationProps<T> {
  indices: number[]
  selectedIndex: number
  showStartPlaceholder?: boolean
  showEndPlaceholder?: boolean
}

const VisiblePages = <T extends object>({
  indices,
  table,
  selectedIndex,
  showStartPlaceholder = false,
  showEndPlaceholder = false,
}: SlidingPages<T>): ReactElement => {
  return (
    <>
      <Visible when={showStartPlaceholder}>
        <PageSelector>...</PageSelector>
      </Visible>
      {indices.map((index) => (
        <PageSelector
          key={index}
          selected={index === selectedIndex}
          onClick={(): void => table.gotoPage(index)}>
          {index + 1}
        </PageSelector>
      ))}
      <Visible when={showEndPlaceholder}>
        <PageSelector>...</PageSelector>
      </Visible>
    </>
  )
}

interface NavProps {
  icon: string
}

const Nav: FC<NavProps> = ({icon}) => (
  <CustomIcon
    name={icon}
    boxSize='24px'
    color={appTheme.colors.secondary[500]}
  />
)

const Range = (start: number, end: number): number[] => {
  const range: number[] = []

  // eslint-disable-next-line no-plusplus
  for (let i = start; i < end; i++) {
    range.push(i)
  }

  return range
}

export const Pagination = <T extends object>({
  table,
}: PaginationProps<T>): ReactElement | null => {
  const [visiblePages, setVisiblePages] = useState<number[]>([])
  const [showStartPlaceholder, setShowStartPlaceholder] = useState(false)
  const [showEndPlaceholder, setShowEndPlaceholder] = useState(false)

  React.useEffect(() => {
    const current = table.state.pageIndex
    if (current <= 4 || table.pageCount <= 8) {
      setShowStartPlaceholder(false)
      if (table.pageCount <= 8) {
        setShowEndPlaceholder(false)
        setVisiblePages(Range(1, table.pageCount - 1))
      } else {
        setShowEndPlaceholder(true)
        setVisiblePages([...Range(1, 6)])
      }
    } else {
      setShowStartPlaceholder(true)
      if (current >= table.pageCount - 5) {
        setShowEndPlaceholder(false)
        setVisiblePages(Range(table.pageCount - 6, table.pageCount - 1))
      } else {
        setShowEndPlaceholder(true)
        setVisiblePages(Range(current - 2, current + 2))
      }
    }
  }, [table.pageCount, table.state.pageIndex, setVisiblePages])

  if (table.pageCount <= 1) return null

  return (
    <Flex>
      <PageSelector onClick={(): void => table.previousPage()}>
        <Nav icon='prev-arrow' />
      </PageSelector>
      <PageSelector
        selected={table.state.pageIndex === 0}
        onClick={(): void => table.gotoPage(0)}>
        1
      </PageSelector>
      <VisiblePages<T>
        indices={visiblePages}
        selectedIndex={table.state.pageIndex}
        table={table}
        showStartPlaceholder={showStartPlaceholder}
        showEndPlaceholder={showEndPlaceholder}
      />
      <PageSelector
        selected={table.state.pageIndex === table.pageCount - 1}
        onClick={(): void => table.gotoPage(table.pageCount - 1)}>
        {table.pageCount}
      </PageSelector>
      <PageSelector onClick={(): void => table.nextPage()}>
        <Nav icon='next-arrow' />
      </PageSelector>
    </Flex>
  )
}
