import React from 'react'
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {ChevronDownIcon} from '@chakra-ui/icons'

import {useHistory} from 'react-router-dom'
import {SideMenuTemplate} from '../SideMenuTemplate/SideMenuTemplate'
import {useAuth} from '../../stores/AuthStore'
import copy from './copy.json'
import {CustomIcon} from '../../components'

interface Props {
  width: string[]
}

export const PrimaryNavigation: React.FC<React.PropsWithChildren<Props>> = ({
  width,
}: Props): React.ReactElement => {
  const history = useHistory()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {person, client, logout} = useAuth()

  return (
    <Flex
      width={width}
      borderBottom={['1px']}
      borderTop={['0', '3px solid #E68C4D']}
      height={['40px', '64px']}
      position='fixed'
      bg='white'
      zIndex={10}>
      <Flex width={['3%', '20%']} justifyContent='flex-start'>
        <Button
          aria-label={copy.navigationMenu.label}
          onClick={onOpen}
          h='100%'
          border='none'
          variant='outline'>
          <CustomIcon name='bell' ml={[2]} boxSize='1.5rem' />
        </Button>
        <CustomIcon
          name='phone'
          maxWidth='8rem'
          ml={[2]}
          boxSize='100%'
          display={['none', 'inline-block']}
        />
      </Flex>
      <Flex width={['94%', '10%']} justifyContent={['center', 'flex-start']}>
        <CustomIcon
          name='star'
          boxSize='10'
          ml={[0]}
          display={['inline-block', 'none']}
        />
      </Flex>
      <Flex width={['3%', '70%']} justifyContent='flex-end' flexDir='row'>
        <Menu>
          <MenuButton data-testid='user-menu-button'>
            <Flex justifyContent='right' flexDir='row' alignItems='center'>
              <Flex
                flexDir='column'
                display={['none', 'inline-block']}
                mr={[3]}>
                {person && (
                  <Text
                    fontSize='sm'
                    textAlign='left'
                    lineHeight='1rem'
                    data-testid='loggedin-user-name'>
                    {person.name.first}
                  </Text>
                )}
                {client && (
                  <Text
                    fontSize='sm'
                    textAlign='left'
                    lineHeight='1rem'
                    data-testid='loggedin-client-name'>
                    {client.name}
                  </Text>
                )}
              </Flex>
              <ChevronDownIcon
                alignSelf='center'
                display='inline-block'
                color='current.menu.iconColor'
                w='24px'
                h='24px'
                mr={3}
              />
            </Flex>
          </MenuButton>
          <MenuList data-testid='user-menu'>
            <MenuItem
              as='a'
              onClick={(): void => {
                window.open('https://campanionapp.com/support/admin-help/')
              }}>
              {copy.userMenu.links.help.label}
            </MenuItem>
            <MenuItem
              as='a'
              onClick={(): void => {
                logout()
                history.push('/login')
              }}>
              {copy.userMenu.links.logout.label}
            </MenuItem>
          </MenuList>
        </Menu>
        <SideMenuTemplate isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Flex>
  )
}
