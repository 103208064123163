import {AxiosResponse} from 'axios'
import {RawPagedCollectionResponse, RawClientFeature} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  clientID: string
  pageNumber: number
  pageSize: number
  featureID: number | null
}

type FetchClientFeaturesResponse = RawPagedCollectionResponse<RawClientFeature>

export interface FetchClientFeatures<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchClientFeatures: FetchClientFeatures<
  Params,
  FetchClientFeaturesResponse
> = (http, {clientID, pageNumber, pageSize, featureID}) => {
  try {
    let additionalParams = ''
    if (featureID && featureID > 0) {
      additionalParams += `&featureid=${featureID}`
    }
    return http.get<FetchClientFeaturesResponse>(
      `/v1/featuresettings/clientfeature?clientid=${clientID}&pagenumber=${pageNumber}&pagesize=${pageSize}${additionalParams}`,
    )
  } catch (e) {
    if (e instanceof Error) {
      throw e
    } else {
      throw new Error(e as string)
    }
  }
}
