import {http} from '../http'
import {APIResponse} from '../types'
import {EzTextingGroup} from '../../../types'
import * as coreGroups from '../../coreApi/eztexting'
import {handleError} from '../handleError'

interface Params {
  ID: EzTextingGroup['ID']
  clientID: EzTextingGroup['clientID']
}

type ResolvedReturn = APIResponse<null>

interface SyncGroup {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.eztexting.syncGroup()'
const GENERIC_ERROR =
  'Something went wrong while syncing your group. Please try again.'

export const syncGroup: SyncGroup = async ({ID, clientID}) => {
  let resolvedReturn: ResolvedReturn

  const rawParamMap = {
    ClientID: Number(clientID),
    GroupID: parseInt(ID, 10),
  }

  try {
    await coreGroups.synchronizeGroup(http, rawParamMap)

    resolvedReturn = {
      result: 'success',
      data: null,
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }
  return Promise.resolve(resolvedReturn)
}
