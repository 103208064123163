import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {HistoryEntry, ListHistoryRequest, Paginated} from './types'

export interface ListHistory<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const listHistory: ListHistory<
  ListHistoryRequest,
  Paginated<HistoryEntry>
> = (http, params) => {
  const {PageNumber, PageSize, OrderBy, OrderAscending, ClientID} = params

  const path = '/v1/eztexting/texthistory'

  const queryParams = []
  if (PageNumber !== undefined) {
    queryParams.push(`pagenumber=${PageNumber}`)
  }
  if (PageSize !== undefined) {
    queryParams.push(`pagesize=${PageSize}`)
  }
  if (OrderBy !== undefined) {
    queryParams.push(`orderby=${OrderBy}`)
  }
  if (OrderAscending !== undefined) {
    queryParams.push(`orderascending=${OrderAscending}`)
  }
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<Paginated<HistoryEntry>>({
    method: 'GET',
    url: `${path}${query}`,
  })
}
