import {FeatureName, FeatureEnablement} from './types'

export const TERMS_OF_SERVICE_URL =
  'https://legal.campminder.com/terms-of-service/'

export const PRIVACY_POLICY_URL = 'https://legal.campminder.com/privacy-policy/'

export const SYSTEM_URL = 'https://system.campminder.com/v2/login/login.aspx'

export const FIREBASE_DYNAMIC_LINKS_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`
export const UPR_URL = 'https://system.campminder.com/ui/person/Record#'

export const EZT_URL = 'https://app.campmindertexting.com'
export const EZT_GROUP_URL = `${EZT_URL}/contacts/index/group/`

export const MAX_CONTENT_CHARS = 280

export const MAX_HYPERLINK_TEXT_CHARS = 26

export const AUTH_SESSION_STORAGE_KEY = '__authsession__'

export const ROTATE_PHOTO_DEBOUNCE_TIMEOUT = 7000

export const FEATURE_ENABLEMENT_MAP: {[k in FeatureName]: FeatureEnablement} = {
  'manage-microposts': {
    isUnderReleaseFlag: false,
  },
  'segment-microposts': {
    isUnderReleaseFlag: false,
  },
  'view-photo-uploader': {
    isUnderReleaseFlag: false,
  },
  'create-album': {
    isUnderReleaseFlag: false,
  },
  'upload-photos': {
    isUnderReleaseFlag: false,
  },
  'photo-action': {
    isUnderReleaseFlag: false,
  },
  'edit-album': {
    isUnderReleaseFlag: false,
  },
  integrations: {
    isUnderReleaseFlag: false,
  },
  'texting-audience': {
    isUnderReleaseFlag: true,
  },
  'texting-enabled': {
    isUnderReleaseFlag: true,
  },
  'data-insights': {
    isUnderReleaseFlag: true,
  },
  'forms-reminder': {
    isUnderReleaseFlag: true,
  },
  'covid-health-check': {
    isUnderReleaseFlag: true,
  },
  'eng-only-server-sent-events': {
    isUnderReleaseFlag: false,
  },
  'eng-only-individual-sync': {
    isUnderReleaseFlag: false,
  },
  'admin-photo-sort': {
    isUnderReleaseFlag: false,
  },
  'admin-menu-registration': {
    isUnderReleaseFlag: true,
  },
  explo: {
    isUnderReleaseFlag: true,
  },
}

export const UNIVERSAL_LINK_BASE_URL =
  process.env.REACT_APP_UNIVERSAL_LINK_BASE_URL || ''

export const INSERT_PLACEHOLDER_ID = '0' // Use as temporary IDs to allow for upsert functionality while still using base model without optional/null checks

export const API_URL = process.env.REACT_APP_API_URL || ''
export const API_SSE_URL = process.env.REACT_APP_API_SSE_URL || ''
export const FILESTACK_MP_API_KEY =
  process.env.REACT_APP_FILESTACK_MP_API_KEY || '' // microposts
export const FILESTACK_UP_API_KEY =
  process.env.REACT_APP_FILESTACK_UP_API_KEY || '' // album photos
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY || ''
export const BUGSNAG_STAGE = process.env.REACT_APP_BUGSNAG_STAGE || ''
export const APP_VERSION =
  process.env.REACT_APP_VERSION && process.env.REACT_APP_BUILD_NUMBER
    ? `${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD_NUMBER}`
    : '0.0.0.0'

export const GTM_PREVIEW =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV_PREVIEW || ''
export const GTM_AUTH = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV_AUTH || ''

export const SEND_TEXT_BATCH_SIZE = 200

export const MAX_SMS_CHARACTERS = 160

export const CAMPANION_MOBILE_BUNDLE_ID =
  process.env.REACT_APP_CAMPANION_MOBILE_BUNDLE_ID || ''

export const CAMPANION_MOBILE_IOS_APP_STORE_ID =
  process.env.REACT_APP_CAMPANION_MOBILE_IOS_APP_STORE_ID || ''

export const LEGACY_ALBUM_CUTOFF_DATE = new Date('2021-11-01')

export const BEE_PLUGIN_CLIENT_ID =
  process.env.REACT_APP_BEE_PLUGIN_CLIENT_ID || ''
export const BEE_PLUGIN_CLIENT_SECRET =
  process.env.REACT_APP_BEE_PLUGIN_CLIENT_SECRET || ''
export const BEE_PLUGIN_AUTH_URI = 'https://auth.getbee.io/apiauth'
