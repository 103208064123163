import React from 'react'
import {Box, Flex, Image} from '@chakra-ui/react'

import copy from './copy.json'

import {PageHeader} from '../../components'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {useImagesContext} from '../../hooks/useImageContext'

export const FormRemindersUnauthorizedView: React.FC<
  React.PropsWithChildren
> = () => {
  const pageHeader = (
    <PageHeader
      title={copy.unauthorized.header.title}
      logContext='FormsPaywallView'
      description={copy.unauthorized.header.description}
    />
  )

  const {images} = useImagesContext()

  const unauthorizedImage = images(
    `./formsReminders/${copy.unauthorized.images.unauthorizedImage}`,
  )

  return (
    <PageTemplate
      header={pageHeader}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Box borderTop='1px' margin={3} />
        <Flex justifyContent='center' pt='60px'>
          <Image
            src={unauthorizedImage}
            alt='Secure - No Access'
            maxWidth={['90%', '65%', '45%']}
          />
        </Flex>
      </>
    </PageTemplate>
  )
}
