import React from 'react'
import {Flex, Box, Text} from '@chakra-ui/react'
import {ChevronLeftIcon} from '@chakra-ui/icons'

import {log} from '@vanguard/logger'
import copy from './copy.json'
import {useComposeAlbumState} from './contextManagers/ComposeAlbumManager'
import {AlbumSortType} from '../../types'
import {ActionButton} from '../ActionButton/ActionButton'
import {StatusReturn, useAlbumService} from '../../services/useAlbumService'
import {useModalContext} from './subcomps/ComposeAlbumModalContext'
import {useConstraints} from './contextManagers/ConstraintManager'

const Header: React.FC<React.PropsWithChildren> = () => {
  const {setCurrentStep} = useModalContext()
  return (
    <Flex flex={1}>
      <ChevronLeftIcon
        data-testid='back-icon'
        aria-label='back'
        boxSize='30px'
        onClick={(): void => {
          setCurrentStep('compose')
        }}
      />
      <Box textAlign='center' flex={1}>
        Publishing
      </Box>
    </Flex>
  )
}

const Body: React.FC<React.PropsWithChildren> = () => (
  <>
    <Text fontSize='lg' fontWeight='bold' mb={5}>
      {copy.steps.published.title}
    </Text>
    <Text fontSize='sm' color='#69747D' mb={5}>
      {copy.steps.published.description}
    </Text>
  </>
)

export interface FooterProps {
  handleReponse: (response: StatusReturn, sortType: AlbumSortType) => void
}

const Footer: React.FC<FooterProps> = ({handleReponse}) => {
  const album = useComposeAlbumState()
  // TODO: We'll need to check for empty date time inputs
  const [isLoading, setIsLoading] = React.useState(false)
  const {areConstraintsChanged} = useConstraints()

  const areInputsValid = (): boolean =>
    !!album.name && album.name.length > 0 && !Number.isNaN(album.date.getTime())

  const {upsert} = useAlbumService()

  const handleOnSubmit = async (): Promise<void> => {
    setIsLoading(true)
    log.breadcrumb('Submit', album)
    const response = await upsert(
      {...album},
      {shouldUpdateConstraints: areConstraintsChanged},
    )

    setIsLoading(false)
    handleReponse(response, album.sortType)
  }

  return (
    <Flex flexDir='row' justifyContent={['center', 'flex-end']} width='100%'>
      <ActionButton
        width={['100%', 'unset']}
        isDisabled={!areInputsValid()}
        isLoading={isLoading}
        onClick={handleOnSubmit}>
        {copy.buttons.primaryAction.done}
      </ActionButton>
    </Flex>
  )
}

export const PostPublishedStepUI = {
  Header,
  Body,
  Footer,
}
