import {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useCallback,
} from 'react'

import {
  TableOptions,
  TableState,
  useSortBy,
  useTable,
  usePagination,
  Row,
  Column,
  IdType,
} from 'react-table'

import {MenuItem, MenuDivider} from '@chakra-ui/react'
import {useDebounce, useStoredState} from '../../hooks'
import {
  TableRoot,
  TableHead,
  TableHeadRow,
  TableHeadCellContainer,
  TableBody,
  TableRow,
  TableFoot,
  TableCell,
  TableHeaderCell,
  SortIndicator,
} from './TableComponents'

import {Pagination} from './Pagination'
import {SnowmanMenu} from '../SnowmanMenu/SnowmanMenu'
import {Visible} from '../Visible/Visible'

interface TableEntity {
  ID: string
}

export interface Sort {
  id: string
  desc: boolean
}

export interface TProps<T extends object> extends TableOptions<T> {
  name: string
  pageSize: number
  // sortBy?: Sort[]
  fetchData?: (pageNumber: number, pageSize: number) => void
  setSort?: (sort: Sort[]) => void
  onEdit?: (itemID: string) => void
  onDelete?: (itemID: string) => void
  actionEnabledField?: string
}

export const Table = <T extends TableEntity>(
  props: PropsWithChildren<TProps<T>>,
): ReactElement => {
  const {
    initialState,
    name,
    columns,
    pageSize,
    pageCount: manualPaginationPageCount,
    fetchData,
    sortBy,
    onEdit,
    onDelete,
    setSort,
    actionEnabledField,
    manualPagination,
  } = props

  const hasEdit = onEdit !== undefined
  const hasDelete = onDelete !== undefined
  const hasActions = hasEdit || hasDelete

  const [preservedState, setPreservedState] = useStoredState<TableState<T>>(
    `tableState:${name}`,
  )

  function getRowId(originalRow: T): string {
    return originalRow.ID
  }

  const getHiddenColumnIDs = useCallback(
    (cols: readonly Column<T>[]): IdType<T>[] =>
      cols
        .filter(
          (col) =>
            !!col.hide ||
            (col.hideWhileUnder !== undefined &&
              col.hideWhileUnder >= window.innerWidth),
        )
        .map((col) => col.id || 'default'),
    [],
  )

  const manualTableProps = useMemo(() => {
    if (!manualPagination) {
      return {}
    }

    return {
      pageCount: manualPaginationPageCount,
      manualSortBy: manualPagination,
    }
  }, [manualPaginationPageCount, manualPagination])

  const instance = useTable<T>(
    {
      ...props,
      columns,
      initialState: {
        ...initialState,
        ...preservedState,
        hiddenColumns: getHiddenColumnIDs(columns),
      },
      disableSortRemove: true,
      disableMultiSort: true,
      getRowId,
      autoResetPage: false,
      ...manualTableProps,
    },
    useSortBy,
    usePagination,
  )

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    state,
    toggleSortBy,
    pageCount,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    gotoPage,
    // nextPage,
    // previousPage,
  } = instance

  const debouncedState = useDebounce(state, 500)

  useEffect(() => {
    setPreservedState(debouncedState)
  }, [setPreservedState, debouncedState])

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    if (manualPagination && fetchData) {
      fetchData(state.pageIndex, pageSize)
    }
  }, [fetchData, manualPagination, pageSize, state.pageIndex])

  useEffect(() => {
    if (sortBy) {
      sortBy.forEach((sort: Sort): void => {
        toggleSortBy(sort.id, sort.desc, true)
      })
    }
  }, [sortBy, toggleSortBy])

  useEffect(() => {
    const handleResize = (): void => {
      instance.setHiddenColumns(getHiddenColumnIDs(instance.columns))
    }

    window.addEventListener('resize', handleResize)
  }, [instance, getHiddenColumnIDs])

  useMemo(() => {
    const lastPageIndex = pageCount - 1
    if (state.pageIndex > lastPageIndex) {
      gotoPage(lastPageIndex)
    }
  }, [gotoPage, pageCount, state.pageIndex])

  const actionsAreEnabled = (row: Row<T>): boolean => {
    if (actionEnabledField) {
      return !row.values[actionEnabledField] || false
    }

    return true
  }

  return (
    <TableRoot {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => {
          const {key: rowKey, ...remainingRowProps} =
            headerGroup.getHeaderGroupProps()
          return (
            <TableHeadRow key={rowKey} {...remainingRowProps}>
              {headerGroup.headers.map((column) => {
                const {
                  key: cellKey,
                  onClick: onHeaderCellClick,
                  ...remainingCellProps
                } = column.getHeaderProps(column.getSortByToggleProps())
                return (
                  <TableHeaderCell
                    key={column.id.toString()}
                    {...remainingCellProps}
                    style={{
                      ...column.style,
                      width: column.width,
                      minWidth: column.minWidth,
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onClick={(e: any): void => {
                      if (setSort && column.canSort) {
                        setSort([
                          {
                            id: column.id,
                            desc: column.isSorted ? !column.isSortedDesc : true,
                          },
                        ])
                      }
                      if (onHeaderCellClick) {
                        onHeaderCellClick(e)
                      }
                    }}>
                    <TableHeadCellContainer canSort={column.canSort}>
                      <>
                        {column.render('Header')}
                        <Visible when={column.canSort}>
                          <SortIndicator
                            isSorted={column.isSorted}
                            isDesc={column.isSortedDesc || false}
                          />
                        </Visible>
                      </>
                    </TableHeadCellContainer>
                  </TableHeaderCell>
                )
              })}
              <Visible when={hasActions}>
                <TableHeaderCell
                  style={{
                    width: '5%',
                    minWidth: '15px',
                    paddingRight: '0 !important',
                    paddingLeft: '0 !important',
                  }}
                />
              </Visible>
            </TableHeadRow>
          )
        })}
      </TableHead>

      <TableBody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row)
          const {key, ...rest} = row.getRowProps()
          return (
            <TableRow key={key} {...rest}>
              {row.cells.map((cell) => (
                <TableCell
                  {...cell.getCellProps()}
                  key={cell.column.id}
                  style={{
                    ...cell.column.cellStyle,
                    width: cell.column.width,
                    minWidth: cell.column.minWidth,
                  }}>
                  {cell.render('Cell')}
                </TableCell>
              ))}
              <Visible when={hasActions}>
                <TableCell
                  style={{
                    textAlign: 'right',
                    width: '5%',
                    minWidth: '15px',
                    paddingRight: '0 !important',
                    paddingLeft: '0 !important',
                  }}>
                  <Visible when={actionsAreEnabled(row)}>
                    <SnowmanMenu
                      placement='bottom-end'
                      style={{
                        color: '#747474',
                        marginTop: '-0.5em',
                        marginLeft: '-1.5em',
                      }}
                      hover={{
                        backgroundColor: 'gray.100',
                      }}>
                      <Visible when={hasEdit}>
                        <MenuItem
                          onClick={(): void => {
                            if (onEdit) onEdit(row.id)
                          }}>
                          Edit
                        </MenuItem>
                      </Visible>
                      <Visible when={hasEdit && hasDelete}>
                        <MenuDivider />
                      </Visible>
                      <Visible when={hasDelete}>
                        <MenuItem
                          onClick={(): void => {
                            if (onDelete) onDelete(row.id)
                          }}>
                          Delete
                        </MenuItem>
                      </Visible>
                    </SnowmanMenu>{' '}
                  </Visible>
                </TableCell>
              </Visible>
            </TableRow>
          )
        })}
      </TableBody>

      <TableFoot>
        <TableRow style={{justifyContent: 'center'}}>
          <TableCell>
            <Pagination<T> table={instance} />
          </TableCell>
        </TableRow>
      </TableFoot>
    </TableRoot>
  )
}
