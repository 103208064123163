import React, {ReactElement, useState, useEffect, useCallback} from 'react'
import {
  Button,
  Divider,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {CheckCircleIcon, WarningIcon} from '@chakra-ui/icons'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {Column, Row} from 'react-table'

import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {PageHeader} from '../../components/PageHeader/PageHeader'
import {
  ActionButton,
  BlankSlate,
  CustomIcon,
  LoadingState,
  Table,
  ViewError,
  Visible,
} from '../../components'
import {EzTextingGroup, SegmentGroup, EzTextGroup} from '../../types'
import {useRequiredAuth} from '../../stores/AuthStore'
import {fetchSegments} from '../../services/api/segments/fetchSegments'
import {useConfirmation} from '../../app/ConfirmationManager'
import {useSSE} from '../../app/SSEProvider'
import {api} from '../../services/api'
import {useStatusToast} from '../../app/ToastManager'
import {ComposeGroupModal} from '../../components/ComposeGroupModal/ComposeGroupModal'
import {simpleSort, caseInsensitiveSort} from '../../utils/simpleSort'
import copy from './copy.json'
import {EZT_URL} from '../../constants'
import {
  handleSSE,
  TopicSyncGroupComplete,
} from '../../services/api/eztexting/sse'
import {SSEMessage} from '../../services/coreApi/types'
import {useEztCredValidation} from '../../services/useEztCredValidation'
import {appTheme} from '../../app/theme'

export const DATE_MODIFIED_ACCESSOR = 'dateModified'

const mapEzGroupAudiences = (
  groupsToMap: EzTextingGroup[],
  audienceList: SegmentGroup[],
): EzTextGroup[] =>
  groupsToMap.map((g) => {
    const audienceName = g?.audienceIDs?.length
      ? audienceList?.find((a) => a.ID === g.audienceIDs[0])?.name
      : null
    return {
      ...g,
      audience: audienceName || '',
      sync: true,
    }
  })

export const EzTextingView: React.FC<React.PropsWithChildren> = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [errorLoading, setErrorLoading] = useState(false)
  const [sort, setSort] = useState([{id: 'ez', desc: false}])
  const [selectedGroup, setSelectedGroup] = useState<EzTextingGroup | null>(
    null,
  )
  const {client} = useRequiredAuth()
  const [audiences, setAudiences] = useState<SegmentGroup[]>([])
  const [ezTextGroups, setEzTextGroups] = useState<EzTextGroup[]>([])
  const [ezTextingGroups, setEzTextingGroups] = useState<EzTextingGroup[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const pageSize = 10
  const [pageNumber, setPageNumber] = useState<number>(1)
  const showConfirmation = useConfirmation()
  const {toast} = useStatusToast()
  const modalDisclosure = useDisclosure()
  const subscribe = useSSE()

  useEztCredValidation()

  useEffect(() => {
    const initData = async (): Promise<void> => {
      const audiencesResult = await fetchSegments({
        clientID: client.ID,
        pageSize: 100,
        all: true,
      })
      if (audiencesResult.result === 'success') {
        setIsLoading(false)
        setAudiences(audiencesResult.data)
      } else {
        setErrorLoading(true)
      }
    }
    initData()
  }, [client.ID])

  useEffect(() => {
    const mappedEzGroups = mapEzGroupAudiences(ezTextingGroups, audiences)
    setEzTextGroups(mappedEzGroups)
  }, [audiences, ezTextingGroups])

  const getGroupIndex = useCallback(
    (ID: EzTextGroup['ID']): number =>
      ezTextGroups.findIndex((g: EzTextGroup) => g.ID === ID),
    [ezTextGroups],
  )

  const getGroup = useCallback(
    (ID: EzTextGroup['ID']): EzTextGroup | undefined =>
      ezTextGroups.find((g: EzTextGroup) => g.ID === ID),
    [ezTextGroups],
  )

  const updateGroup = useCallback((index: number, group: EzTextGroup): void => {
    setEzTextGroups((groups) => {
      if (index === -1) return groups
      return [...groups.slice(0, index), group, ...groups.slice(index + 1)]
    })
  }, [])

  const setSyncState = useCallback(
    (ID: EzTextGroup['ID'], syncing: boolean): void => {
      const group = getGroup(ID)
      if (group) {
        const index = getGroupIndex(ID)
        updateGroup(index, {...group, syncing})
      }
    },
    [getGroup, getGroupIndex, updateGroup],
  )

  useEffect((): (() => void) => {
    if (!subscribe) {
      return (): void => {
        // don't unsubscribe before you can subscribe
      }
    }
    const unsubscribe = subscribe(TopicSyncGroupComplete, (msg: SSEMessage) => {
      const event = handleSSE(msg)
      if (event.ezTextingGroup) {
        const g = mapEzGroupAudiences([event.ezTextingGroup], audiences)[0]
        updateGroup(getGroupIndex(g.ID), g)
        if (event.syncDetails) {
          if (event.syncDetails.errors) {
            toast.warn(
              event.syncDetails.friendlyMessage ||
                `${g.ez.name} synced with errors.`,
            )
          } else {
            toast.success(
              event.syncDetails.friendlyMessage ||
                `Group ${g.ez.name} was successfully synced.`,
            )
          }
        }
      }
    })
    return unsubscribe
  }, [audiences, getGroupIndex, subscribe, toast, updateGroup])

  const fetchData = useCallback(
    async (pageNum: number, _size: number): Promise<void> => {
      if (pageNumber !== pageNum + 1) {
        setPageNumber(pageNum + 1)
      }
    },
    [pageNumber],
  )

  useEffect(() => {
    const f = async (): Promise<void> => {
      const ezgroupResponse = await api.eztexting.fetch({
        clientID: client.ID,
        pageSize,
        pageNumber,
        orderAscending: !sort[0].desc,
        orderBy: ((): Parameters<typeof api.eztexting.fetch>[0]['orderBy'] => {
          switch (sort[0].id) {
            case DATE_MODIFIED_ACCESSOR:
              return 'ModifiedDate'
            case 'result':
              return 'FailureCount'
            case 'lastSynced':
              return 'LastSynchronized'
            case 'ez':
            default:
              return 'Name'
          }
        })(),
      })
      if (ezgroupResponse.result === 'success') {
        setEzTextingGroups(ezgroupResponse.data.Results)
        setPageCount(Math.ceil(ezgroupResponse.data.TotalCount / pageSize))
      } else {
        setErrorLoading(true)
      }
    }
    f()
  }, [client.ID, pageNumber, sort])

  const onCreateClose = (group: EzTextGroup): void => {
    setEzTextGroups([group, ...ezTextGroups])
    setSort([{id: DATE_MODIFIED_ACCESSOR, desc: true}])
    toast.success(`Group ${group.ez.name} was successfully created.`)
  }

  const onAddGroup = (): void => {
    modalDisclosure.onOpen()
  }

  const onOpenEzTexting = (): void => {
    window.open(EZT_URL)
  }

  const onEditClose = async (
    index: number,
    group: EzTextGroup,
  ): Promise<void> => {
    updateGroup(index, group)
    toast.success(`Group ${group.ez.name} was successfully updated.`)
  }

  const onEdit = (ID: EzTextGroup['ID']): void => {
    const groupIndex = getGroupIndex(ID)
    if (groupIndex !== undefined) {
      const group = {...ezTextGroups[groupIndex]}
      setSelectedGroup(group)
      modalDisclosure.onOpen()
    }
  }

  const onSuccessfulDeletion = (ID: EzTextingGroup['ID']): void => {
    const deletedGroupName = getGroup(ID)?.ez.name
    setEzTextGroups(
      ezTextGroups.filter((group: EzTextGroup) => group.ID !== ID),
    )
    toast.success(`${deletedGroupName} successfully deleted.`)
  }

  const onDeletionConfirmed = async (
    ID: EzTextingGroup['ID'],
    EzID: EzTextingGroup['ezTextingID'],
  ): Promise<void> => {
    const deletion = await api.eztexting.delete({
      ID,
      clientID: client.ID,
      EzTextingID: EzID,
    })

    if (deletion.result === 'success') {
      onSuccessfulDeletion(ID)
    } else {
      toast.error(deletion.error.message || copy.errors.deleteGroup)
    }
  }

  const handleModalClose = (group?: EzTextingGroup): void => {
    if (group) {
      const ezgroup = mapEzGroupAudiences([group], audiences)[0]
      const index = getGroupIndex(ezgroup.ID)
      if (index === -1) {
        onCreateClose(ezgroup)
      } else {
        onEditClose(index, ezgroup)
      }
    }

    setSelectedGroup(null)
    modalDisclosure.onClose()
  }

  const getEztextingID = (
    ID: EzTextingGroup['ID'],
  ): EzTextingGroup['ezTextingID'] => getGroup(ID)?.ezTextingID || ''

  const onDelete = (ID: EzTextingGroup['ID']): void => {
    const ezID = getEztextingID(ID)
    showConfirmation({
      variant: 'danger',
      title: 'Delete Group',
      description: "Are you sure? You can't undo this action afterwards.",
      confirmButtonText: 'Delete',
      onConfirm: (): Promise<void> => onDeletionConfirmed(ID, ezID),
    })
  }

  const pageHeader = (
    <PageHeader
      title={copy.header.title}
      logContext='EzTextingView'
      description={copy.header.description}
      callToAction={
        <>
          <ActionButton
            variant='outline'
            icon='external-link'
            width={['100%', 'unset']}
            marginRight='0.5em'
            onClick={onOpenEzTexting}>
            EZ Texting
          </ActionButton>
          <ActionButton width={['100%', 'unset']} onClick={onAddGroup}>
            {copy.buttons.newGroup}
          </ActionButton>
        </>
      }
    />
  )

  const columns = React.useMemo<Column<EzTextGroup>[]>(() => {
    const onSync = async (ID: EzTextGroup['ID']): Promise<void> => {
      setSyncState(ID, true)

      const response = await api.eztexting.sync({ID, clientID: client.ID})

      if (response.result === 'failure') {
        toast.error(response.error.message)
      } else {
        toast.success(copy.success.sync)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ezCellFormatter = (cell: any): ReactElement => {
      const textStyle = {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }

      return (
        <Flex direction='column'>
          <Button
            variant='link'
            style={textStyle}
            fontWeight='500'
            color={appTheme.colors.secondary[500]}
            onClick={(): void => {
              history.push(`/integrations/eztexting/groups/${cell.row.id}`)
            }}
            justifyContent='start'
            _focus={{
              boxShadow: '0',
            }}>
            {cell.value.name}
          </Button>
          <Text
            style={textStyle}
            fontSize='sm'
            marginTop='0.5em'
            color='gray.600'>
            {cell.value.description}
          </Text>
        </Flex>
      )
    }

    const ezSort = <T extends object>(
      ra: Row<T>,
      rb: Row<T>,
      columnID: string,
    ): number => {
      const a = ra.values[columnID]?.name
      const b = rb.values[columnID]?.name
      return caseInsensitiveSort(a, b)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateFormatter = (cell: any): React.ReactElement => (
      <>{moment(cell.value).format('MM/DD/YYYY, LT')}</>
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const syncDateFormatter = (cell: any): React.ReactElement => {
      if (typeof cell.value === 'string') return cell.value
      return dateFormatter(cell)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resultCountAction = (cell: any): ReactElement => (
      <>
        <Visible when={cell.row.values.syncing}>
          <Spinner size='sm' color='secondary.400' emptyColor='gray.200' />{' '}
          Syncing
        </Visible>
        <Visible when={!cell.row.values.syncing && cell.value.resultCount > 0}>
          <Visible when={cell.value.failureCount > 0}>
            <WarningIcon
              name='warning'
              style={{color: appTheme.colors.attention[400]}}
              boxSize='1.2em'
              verticalAlign='top'
              data-testid='syncwithissuesicon'
            />{' '}
            Synced
          </Visible>
          <Visible when={cell.value.failureCount <= 0}>
            <CheckCircleIcon
              style={{color: appTheme.colors.secondary[500]}}
              boxSize='1.2em'
              verticalAlign='top'
              data-testid='successicon'
            />{' '}
            Synced
          </Visible>
        </Visible>
      </>
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const syncAction = (cell: any): ReactElement => (
      <Visible when={!cell.value}>
        <Button
          variant='link'
          color='secondary.500'
          isLoading={cell.value}
          loadingText={copy.state.syncing}
          onClick={(): Promise<void> => onSync(cell.row.id)}
          _focus={{
            boxShadow: '0',
          }}>
          <CustomIcon name='sync' boxSize='24px' />
          <span style={{paddingLeft: '.5em'}}>{copy.buttons.sync}</span>
        </Button>
      </Visible>
    )

    const lastSyncedSort = <T extends object>(
      ra: Row<T>,
      rb: Row<T>,
      columnID: string,
    ): number => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const getSortValue = (val: any): number => {
        if (typeof val === 'string') {
          return 0
        }
        return val.getTime()
      }
      let a = ra.values[columnID]
      let b = rb.values[columnID]

      a = getSortValue(a)
      b = getSortValue(b)

      return simpleSort(a, b)
    }

    const cols: Column<EzTextGroup>[] = [
      {
        id: 'ez',
        Header: copy.columns.ezName,
        accessor: 'ez',
        width: '10%',
        minWidth: 150,
        Cell: ezCellFormatter,
        style: {
          flexGrow: 1,
        },
        cellStyle: {
          flexGrow: 1,
        },
        sortType: ezSort,
      },
      {
        id: 'audience',
        Header: copy.columns.audience,
        accessor: 'audience',
        width: '20%',
        minWidth: 150,
        hideWhileUnder: 575,
        disableSortBy: true,
      },
      {
        id: DATE_MODIFIED_ACCESSOR,
        Header: copy.columns.modified,
        accessor: DATE_MODIFIED_ACCESSOR,
        Cell: dateFormatter,
        width: '20%',
        hideWhileUnder: 950,
        sortType: 'datetime',
        hide: true,
      },
      {
        id: 'lastSynced',
        Header: copy.columns.synced,
        accessor: 'lastSynced',
        Cell: syncDateFormatter,
        width: '20%',
        hideWhileUnder: 750,
        sortType: lastSyncedSort,
      },
      {
        id: 'result',
        Header: copy.columns.resultCount,
        accessor: 'result',
        Cell: resultCountAction,
        width: '20%',
        sortType: 'alphanumeric',
        hideWhileUnder: 475,
      },
      {
        id: 'syncing',
        Header: '',
        accessor: 'syncing',
        width: '10%',
        minWidth: 95,
        Cell: syncAction,
        cellStyle: {
          color: appTheme.colors.secondary[500],
        },
        disableSortBy: true,
      },
    ]

    return cols
  }, [client.ID, history, setSyncState, toast])

  return (
    <PageTemplate
      header={pageHeader}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Flex direction='column' px='.75em' pt={2}>
          <Divider mb='4' opacity={1} border-bottom='1px solid' mx='0.2em' />
          <Visible when={errorLoading}>
            <ViewError>
              <Text>{copy.errors.loading}</Text>
              <Text>{copy.errors.loadingAction}</Text>
            </ViewError>
          </Visible>
          <Visible when={!errorLoading}>
            <Visible when={isLoading}>
              <LoadingState>{copy.loading}</LoadingState>
            </Visible>
            <Visible when={!ezTextGroups.length && !isLoading}>
              <BlankSlate iconName='add-group'>{copy.blankSlate}</BlankSlate>
            </Visible>
            <Visible when={!!ezTextGroups.length && !isLoading}>
              <Table<EzTextGroup>
                name='EzTextAudiences'
                pageSize={pageSize}
                data={ezTextGroups}
                columns={columns}
                onEdit={onEdit}
                onDelete={onDelete}
                sortBy={sort}
                setSort={setSort}
                actionEnabledField='syncing'
                manualPagination
                fetchData={fetchData}
                pageCount={pageCount}
              />
            </Visible>
          </Visible>
        </Flex>
        <Visible when={modalDisclosure.isOpen}>
          <ComposeGroupModal
            clientID={client.ID}
            onClose={handleModalClose}
            initial={selectedGroup}
            audiences={audiences}
          />
        </Visible>
      </>
    </PageTemplate>
  )
}
