import React from 'react'
import {Flex, Select} from '@chakra-ui/react'

import {PropsOf} from '../../types'
import {
  convertToMilitaryHour,
  rangeArray,
  splitMilitaryTimeString,
} from '../../utils'

interface Props extends Omit<PropsOf<typeof Flex>, 'onChange'> {
  value: string
  onChange(militaryTime: string): void
}

export const TimeInputFallback: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
}) => {
  const [hours, minutes, meridiem] = splitMilitaryTimeString(value)

  const handleTimePartChange =
    (part: 'hour' | 'minute' | 'meridiem') =>
    (event: React.ChangeEvent<HTMLSelectElement>): void => {
      let militaryHours
      let militaryMinutes

      switch (part) {
        case 'hour':
          militaryHours = convertToMilitaryHour(
            parseInt(event.target.value, 10),
            meridiem,
          )
          militaryMinutes = minutes
          break
        case 'minute':
          militaryHours = convertToMilitaryHour(parseInt(hours, 10), meridiem)
          militaryMinutes = parseInt(event.target.value, 10)
          break
        case 'meridiem':
          militaryHours = convertToMilitaryHour(
            parseInt(hours, 10),
            event.target.value,
          )
          militaryMinutes = minutes
          break
        default:
          throw new Error('Unknown time part')
      }

      onChange(
        `${militaryHours.toString().padStart(2, '0')}:${militaryMinutes
          .toString()
          .padStart(2, '0')}`,
      )
    }

  return (
    <Flex alignItems='center' flex={1}>
      <Select
        id='hour select'
        value={hours}
        mr={1}
        borderColor='#CBD5E0'
        onChange={handleTimePartChange('hour')}>
        {rangeArray(12).map(
          (key: number): React.ReactNode => (
            <option key={key}>{(key + 1).toString().padStart(2, '0')}</option>
          ),
        )}
      </Select>
      :
      <Select
        value={minutes}
        ml={1}
        borderColor='#CBD5E0'
        onChange={handleTimePartChange('minute')}>
        {rangeArray(60).map(
          (key: number): React.ReactNode => (
            <option key={key}>{key.toString().padStart(2, '0')}</option>
          ),
        )}
      </Select>
      <Select
        value={meridiem}
        ml={2}
        borderColor='#CBD5E0'
        onChange={handleTimePartChange('meridiem')}>
        <option>AM</option>
        <option>PM</option>
      </Select>
    </Flex>
  )
}
