import {Permission} from '../types'
import {useAuth} from '../stores/AuthStore'

interface UseUserPermissions {
  (): {
    checkUserHasPermissionAccess(
      permissionName: Permission['permissionName'],
      accessLevel: string,
    ): boolean
  }
}

export const useUserPermissions: UseUserPermissions = () => {
  const {userPermissions} = useAuth()

  return {
    checkUserHasPermissionAccess: (permissionName, accessLevel): boolean => {
      if (!userPermissions || !userPermissions.length) return false

      const targetPermission = userPermissions.find(
        (permission) => permission.permissionName === permissionName,
      )

      if (!targetPermission || targetPermission.accessLevel !== accessLevel) {
        return false
      }

      return true
    },
  }
}
