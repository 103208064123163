import {AxiosResponse} from 'axios'
import {RawPagedCollectionResponse} from '../types'
import {ListRequest, AlbumResponse} from './types'
import {HttpInstance} from '../createHttpInstance'

// interface Params {
//   clientID: string
//   pageNumber: number
//   pageSize: number
// }

// type FetchAlbumsResponse = RawPagedCollectionResponse<RawAlbum>

// export interface FetchAlbums<P, R> {
//   (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
// }

// export const fetchAlbums: FetchAlbums<Params, FetchAlbumsResponse> = (
//   http,
//   {clientID, pageNumber, pageSize},
// ) => {
//   return http.get<FetchAlbumsResponse>(
//     `/v1/albums?clientid=${clientID}&pagenumber=${pageNumber}&pagesize=${pageSize}&includeempty=true&includeprivate=true`,
//   )
// }

export interface List<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchAlbums: List<
  ListRequest,
  RawPagedCollectionResponse<AlbumResponse>
> = (http, params) => {
  const {
    ClientID,
    PageNumber,
    PageSize,
    IncludeEmpty,
    IncludePrivate,
    IncludeDraft,
    IncludeUnpublished,
    IncludeConstraints,
  } = params

  const path = '/v1/albums'

  return http.request<RawPagedCollectionResponse<AlbumResponse>>({
    method: 'GET',
    url: `${path}?clientid=${ClientID}&pagenumber=${PageNumber}&pagesize=${PageSize}&includeempty=${IncludeEmpty}&includeprivate=${IncludePrivate}&includedraft=${IncludeDraft}&includeunpublished=${IncludeUnpublished}&includeconstraints=${IncludeConstraints}`,
  })
}
