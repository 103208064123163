import {AxiosResponse} from 'axios'
import {HttpInstance} from '../createHttpInstance'
import {DeleteRequest, DeleteResponse} from './types'

export interface Delete<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const deleteRawSegment: Delete<DeleteRequest, DeleteResponse> = (
  http,
  params,
) => {
  const {ClientID, ID} = params

  const path = '/v0/segments/{id}'.replace('{id}', ID.toString())

  const queryParams = []
  if (ClientID !== undefined) {
    queryParams.push(`clientid=${ClientID}`)
  }
  const query = `?${queryParams.join('&')}`

  return http.request<DeleteResponse>({
    method: 'DELETE',
    url: `${path}${query}`,
  })
}
