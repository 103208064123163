import {decode} from 'he'
import {convertToString} from '../../../utils/convertToString'
import {SegmentGroup} from '../../../types'
import {SegmentResponse} from '../../coreApi/segments/types'

export const transformSegment = (rawData: SegmentResponse): SegmentGroup => {
  if (rawData.Content.Type === 'Camper/Parent') {
    return {
      ID: rawData.ID.toString(),
      self: rawData.Self,
      type: rawData.Content.Type,
      name: decode(rawData.Name || ''),
      createdByID: rawData.CreatedByID?.toString() || '',
      dateModified: new Date(rawData.ModifiedDate),

      content: {
        genderIDs: convertToString(rawData.Content.GenderIDs || []),
        divisionIDs: convertToString(rawData.Content.DivisionIDs || []),
        sessionIDs: convertToString(rawData.Content.SessionIDs || []),
        programIDs: convertToString(rawData.Content.ProgramIDs || []),
        bunkIDs: convertToString(rawData.Content.BunkIDs || []),
        bunkPlanIDs: convertToString(rawData.Content.BunkPlanIDs || []),
      },
    }
  }
  return {
    ID: rawData.ID.toString(),
    self: rawData.Self,
    type: rawData.Content.Type,
    name: decode(rawData.Name || ''),
    createdByID: rawData.CreatedByID?.toString() || '',
    dateModified: new Date(rawData.ModifiedDate),

    content: {
      genderIDs: convertToString(rawData.Content.GenderIDs || []),
      divisionIDs: convertToString(rawData.Content.DivisionIDs || []),
      organizationalCategoryIDs: convertToString(
        rawData.Content.OrganizationalCategoryIDs || [],
      ),
      positionIDs: convertToString(rawData.Content.PositionIDs || []),
      programAreaIDs: convertToString(rawData.Content.ProgramAreaIDs || []),
    },
  }
}
