import {APIResponse} from '../types'
import {ResourceID, SyncResult} from '../../../types'
import {fetchSyncResults} from './fetchSyncResults'

interface Params {
  clientID: ResourceID
  groupID: ResourceID
}

type ResolvedReturn = APIResponse<SyncResult[]>

export interface ServiceMethod {
  (params: Params): Promise<ResolvedReturn>
}

export const fetchAllSyncResults: ServiceMethod = async ({
  clientID,
  groupID,
}) => {
  let hasNext = true
  let responseData: SyncResult[] = []
  let page = 1

  do {
    // eslint-disable-next-line no-await-in-loop
    const apiResponse = await fetchSyncResults({
      clientID,
      groupID,
      pageSize: 100,
      pageNumber: page,
    })

    if (apiResponse.result === 'success') {
      responseData = [...responseData, ...apiResponse.data.results]
      hasNext = !!apiResponse.data.nextURL
    } else {
      return Promise.resolve<ResolvedReturn>(apiResponse)
    }
    page += 1
  } while (hasNext)

  return Promise.resolve<ResolvedReturn>({
    result: 'success',
    data: responseData,
  })
}
