import React from 'react'

import {Flex, Menu, MenuButton, MenuList, MenuItem} from '@chakra-ui/react'
import {CustomIcon} from '../CustomIcon/CustomIcon'

interface Props extends React.ComponentProps<typeof Flex> {
  actions: {
    key: string
    text: string
    handleClick: () => void
  }[]
}

export const MoreActionsMenu: React.FC<React.PropsWithChildren<Props>> = ({
  actions,
  ...rest
}) => (
  <Flex justifyContent='center' alignItems='flex-start' {...rest}>
    <Menu placement='left'>
      <MenuButton aria-label='more actions'>
        <CustomIcon name='drag-handle' boxSize='6' color='#747474' />
      </MenuButton>
      <MenuList>
        {actions.map(
          (action): React.ReactNode => (
            <MenuItem key={action.key} onClick={action.handleClick}>
              {action.text}
            </MenuItem>
          ),
        )}
      </MenuList>
    </Menu>
  </Flex>
)
