import {fetchAllEzTextingGroups, fetchEzTextingGroups} from './fetchGroups'
import {getGroup} from './getGroup'
import {deleteGroup} from './deleteGroup'
import {upsertGroup} from './upsertGroup'
import {syncGroup} from './syncGroup'
import {syncIndividual} from './syncIndividual'
import {fetchCredits} from './fetchCredits'
import {validateNumbers} from './validateNumbers'
import {sendTextMessage} from './sendTextMessage'
import {listHistory} from './listHistory'
import {fetchSyncResults} from './fetchSyncResults'
import {fetchAllSyncResults} from './fetchAllSyncResults'
import {addCredentials} from './addCredentials'
import {checkCredentials} from './checkCredentials'
import {readCredentials} from './readCredentials'
import {updateCredentials} from './updateCredentials'

export const eztexting = {
  fetchAll: fetchAllEzTextingGroups,
  fetch: fetchEzTextingGroups,
  delete: deleteGroup,
  upsert: upsertGroup,
  sync: syncGroup,
  syncIndividual,
  fetchCredits,
  validateNumbers,
  sendTextMessage,
  listHistory,
  fetchSyncResults,
  fetchAllSyncResults,
  getGroup,
  credentials: {
    add: addCredentials,
    check: checkCredentials,
    read: readCredentials,
    update: updateCredentials,
  },
}
