import {AxiosResponse} from 'axios'
import {RawDivision, RawPagedCollectionResponse} from '../types'
import {HttpInstance} from '../createHttpInstance'

interface Params {
  clientID: string
  pageNumber: number
  pageSize: number
}

export type FetchDivisionsResponse = RawPagedCollectionResponse<RawDivision>

export interface FetchDivisions<P, R> {
  (http: HttpInstance, params: P): Promise<AxiosResponse<R>>
}

export const fetchDivisions: FetchDivisions<Params, FetchDivisionsResponse> = (
  http,
  {clientID, pageNumber, pageSize},
) =>
  http.get<FetchDivisionsResponse>(
    `/v0/divisions?clientid=${clientID}&pagenumber=${pageNumber}&pagesize=${pageSize}`,
  )
