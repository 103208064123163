import {http} from '../http'
import * as coreAlbums from '../../coreApi/albums'
import {APIResponse, PagedCollectionResponse} from '../types'
import {AlbumUploadSession, ResourceID} from '../../../types'
import {handleError} from '../handleError'

interface Params {
  pageSize?: number
  pageNumber?: number
  albumID: ResourceID
  clientID: string
}

type ResolvedReturn = APIResponse<PagedCollectionResponse<AlbumUploadSession>>

export interface ListAlbumUploadSessions {
  (params: Params): Promise<ResolvedReturn>
}

const ERROR_PATH = 'api.albums.listAlbumUploadSession()'
const GENERIC_ERROR =
  'Something went wrong while getting your list of albumUploadSessions. Please try again.'

export const listAlbumUploadSessions: ListAlbumUploadSessions = async ({
  pageSize = 5,
  pageNumber = 1,
  albumID,
  clientID,
}) => {
  let resolvedReturn: ResolvedReturn

  try {
    const rawParamMap = {
      PageSize: pageSize,
      PageNumber: pageNumber,
      AlbumID: parseInt(albumID, 10),
      ClientID: parseInt(clientID, 10),
    }

    const response = await coreAlbums.listAlbumUploadSessions(http, rawParamMap)

    if (!response || !response.data) {
      throw new Error('Unsuccessful AlbumUploadSession List')
    }

    const {data} = response

    resolvedReturn = {
      result: 'success',
      data: {
        total: data.TotalCount,
        nextURL: data.Next,
        results: data.Results.map((result) => ({
          ID: result.ID.toString(),
          self: result.Self,
          albumID: result.AlbumID.toString(),
          clientID: result.ClientID.toString(),
          fileCount: result.FileCount, // total
          failuresCount: result.FailuresCount, //
          receivedCount: result.ReceivedCount, // Use these three to determine relevant counts
          successCount: result.SuccessCount, //
          createDateTime: new Date(result.CreateDateTime),
          files: result.Files.map((uploadPhoto) => ({
            fileName: uploadPhoto.FileName,
            received: uploadPhoto.Received, // worth retrying for the user
            failed: uploadPhoto.Failed, // not worth retrying for the user
          })),
        })),
      },
    }
  } catch (e) {
    resolvedReturn = handleError(ERROR_PATH, GENERIC_ERROR, e)
  }

  return Promise.resolve(resolvedReturn)
}
